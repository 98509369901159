@charset "UTF-8";
/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html {
  box-sizing: border-box;
  overflow-y: scroll;
  /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%;
  /* iOS 8+ */
}

*,
::before,
::after {
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

input, select {
  vertical-align: middle;
}

input[type=button], input[type=submit] {
  -webkit-appearance: none;
}

img {
  vertical-align: bottom;
}

html {
  font-size: 62.5%;
}

body {
  color: #2c2e31;
  font-family: "Roboto", "ゴシックMB101 R", "Hiragino Sans", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  background-color: #fff;
}

::-moz-selection, ::selection {
  background: #b3d4fc;
  text-shadow: none;
}

h1 {
  font-weight: normal;
}

h2 {
  font-weight: normal;
}

h3 {
  font-weight: normal;
}

h4 {
  font-weight: normal;
}

h5 {
  font-weight: normal;
}

h6 {
  font-weight: normal;
}

a {
  color: #f00;
  text-decoration: none;
}

li {
  list-style: none;
}

button,
input,
select,
textarea {
  color: #2c2e31;
}
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}

textarea {
  resize: vertical;
}

label,
input[type=submit] {
  cursor: pointer;
}

/* BEGIN Thin */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Thin/Roboto-Thin.woff2?v=2.137") format("woff2"), url("../fonts/Thin/Roboto-Thin.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: normal;
}
/* END Thin */
/* BEGIN Thin Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/ThinItalic/Roboto-ThinItalic.woff2?v=2.137") format("woff2"), url("../fonts/ThinItalic/Roboto-ThinItalic.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: italic;
}
/* END Thin Italic */
/* BEGIN Light */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("../fonts/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: normal;
}
/* END Light */
/* BEGIN Light Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/LightItalic/Roboto-LightItalic.woff2?v=2.137") format("woff2"), url("../fonts/LightItalic/Roboto-LightItalic.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: italic;
}
/* END Light Italic */
/* BEGIN Regular */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("../fonts/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url("../fonts/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("../fonts/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* END Regular */
/* BEGIN Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Italic/Roboto-Italic.woff2?v=2.137") format("woff2"), url("../fonts/Italic/Roboto-Italic.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Roboto;
  src: url("../fonts/Italic/Roboto-Italic.woff2?v=2.137") format("woff2"), url("../fonts/Italic/Roboto-Italic.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: italic;
}
/* END Italic */
/* BEGIN Medium */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Medium/Roboto-Medium.woff2?v=2.137") format("woff2"), url("../fonts/Medium/Roboto-Medium.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: normal;
}
/* END Medium */
/* BEGIN Medium Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/MediumItalic/Roboto-MediumItalic.woff2?v=2.137") format("woff2"), url("../fonts/MediumItalic/Roboto-MediumItalic.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: italic;
}
/* END Medium Italic */
/* BEGIN Bold */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Bold/Roboto-Bold.woff2?v=2.137") format("woff2"), url("../fonts/Bold/Roboto-Bold.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url("../fonts/Bold/Roboto-Bold.woff2?v=2.137") format("woff2"), url("../fonts/Bold/Roboto-Bold.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: normal;
}
/* END Bold */
/* BEGIN Bold Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/BoldItalic/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("../fonts/BoldItalic/Roboto-BoldItalic.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Roboto;
  src: url("../fonts/BoldItalic/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("../fonts/BoldItalic/Roboto-BoldItalic.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: italic;
}
/* END Bold Italic */
/* BEGIN Black */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Black/Roboto-Black.woff2?v=2.137") format("woff2"), url("../fonts/Black/Roboto-Black.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: normal;
}
/* END Black */
/* BEGIN Black Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/BlackItalic/Roboto-BlackItalic.woff2?v=2.137") format("woff2"), url("../fonts/BlackItalic/Roboto-BlackItalic.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: italic;
}
/* END Black Italic */
label.radio, label.radio_text, label.checkbox, label.checkbox_text {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
}
label.radio {
  width: 18px;
  height: 18px;
  border: 1px solid #dce0e3;
  border-radius: 100%;
}
label.radio_text {
  min-height: 23px;
  margin-right: 23px;
  padding-left: 23px;
  line-height: 23px;
}
label.checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #B3B3B3;
  background: #fff;
}
label.checkbox_text {
  padding-left: 25px;
  line-height: 20px;
}
label.radio:before, label.radio_text:before, label.checkbox_text:before, label.checkbox:after, label.radio_text:after, label.checkbox_text:after {
  content: "";
  position: absolute;
  box-sizing: border-box;
}
label.radio:before, label.radio_text:before {
  border-radius: 100%;
}
label.radio:before {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}
label.checkbox:after {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  border-right: 2px solid #2c2e31;
  border-bottom: 2px solid #2c2e31;
  top: calc(50% - 3.5416666667px);
  display: block;
  margin-top: -7.0833333333px;
  width: 7.0833333333px;
  height: 17px;
  z-index: 1;
}
label.checkbox_text:after {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  border-right: 2px solid #2c2e31;
  border-bottom: 2px solid #2c2e31;
  top: calc(50% - 3.5416666667px);
  display: block;
  margin-top: -7.0833333333px;
  width: 7.0833333333px;
  height: 17px;
  z-index: 1;
}
label.radio_text:before, label.radio_text:after {
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
}
label.radio:before {
  display: block;
  width: 10px;
  height: 10px;
  z-index: 1;
  background-color: #2c2e31;
}
label.radio_text:before {
  width: 18px;
  height: 18px;
  border: 1px solid #dce0e3;
  left: 0px;
  z-index: 3;
  transition-property: border;
  transition-duration: 0.25s;
}
label.radio_text:hover:before {
  border: 1px solid #2c2e31;
}
label.checkbox_text:before {
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0;
  border: 1px solid #B3B3B3;
  z-index: 3;
  box-sizing: border-box;
}
label.radio_text:after {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  left: 4px;
  background-color: #2c2e31;
  z-index: 1;
}
label.checkbox_text:after {
  left: 6.4583333333px;
}
label.radio input[type=radio], label.radio_text input[type=radio], label.checkbox input[type=checkbox], label.checkbox_text input[type=checkbox] {
  -moz-appearance: none;
  -webkit-appearance: none;
  position: absolute;
  z-index: 2;
  margin: 0px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  outline: none;
}
label.radio input[type=radio], label.radio_text input[type=radio] {
  left: -18px;
  width: 18px;
  height: 18px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 100%;
}
label.checkbox input[type=checkbox], label.checkbox_text input[type=checkbox] {
  left: -20px;
  padding: 0;
}
label.radio input[type=radio] {
  display: block;
  box-shadow: 18px 0px #FFF;
}
label.radio_text input[type=radio] {
  box-shadow: 18px 0px #FFF;
}
label.checkbox input[type=checkbox] {
  box-shadow: 20px 0px #FFF;
}
label.checkbox_text input[type=checkbox] {
  display: block;
  box-shadow: 20px 0px #FFF;
}
label.checkbox_text input[type=checkbox]:checked {
  box-shadow: none;
}
label.checkbox input[type=checkbox]:checked, label.radio_text input[type=radio]:checked, label.radio input[type=radio]:checked {
  box-shadow: none;
}
label.checkbox_text input[type=checkbox]:checked:focus, label.checkbox input[type=checkbox]:checked:focus {
  opacity: 0.1;
}
label.radio_text input[type=radio]:focus, label.radio input[type=radio]:focus {
  opacity: 0.2;
}

.robot300 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.robot400 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.robot500 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.robot700 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.pt400 {
  font-family: "PT Serif Caption", serif;
  font-weight: 400;
}

.hmw3 {
  font-family: "Hiragino Mincho W3 JIS2004", "Hiragino Mincho ProN", serif;
  font-weight: 300;
}

.hmw6 {
  font-family: "Hiragino Mincho W6 JIS2004", "Hiragino Mincho ProN", serif;
  font-weight: 600;
}

.hgw3 {
  font-family: "Hiragino Kaku Gothic W3 JIS2004", "Hiragino Kaku Gothic ProN", sans-serif;
  font-weight: 300;
}

.hgw4 {
  font-family: "Hiragino Kaku Gothic W4 JIS2004", "Hiragino Kaku Gothic ProN", sans-serif;
  font-weight: 400;
}

.hgw6 {
  font-family: "Hiragino Kaku Gothic W6 JIS2004", "Hiragino Kaku Gothic ProN", sans-serif;
  font-weight: 600;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/common/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/**
 * Lightcase - jQuery Plugin
 * The smart and flexible Lightbox Plugin.
 *
 * @author		Cornel Boppart <cornel@bopp-art.com>
 * @copyright	Author
 *
 * @version		2.5.0 (11/03/2018)
 */
/**
 * Lightcase settings
 *
 * Note: Override default settings for your project without touching this source code by simply
 * defining those variables within a SASS map called '$lightcase-custom'.
 *
 * // Example usage
 * $lightcase-custom: (
 *   'breakpoint': 768px
 * );
 */
@font-face {
  font-family: "lightcase";
  src: url("../fonts/lightcase.eot?55356177");
  src: url("../fonts/lightcase.eot?55356177#iefix") format("embedded-opentype"), url("../fonts/lightcase.woff?55356177") format("woff"), url("../fonts/lightcase.ttf?55356177") format("truetype"), url("../fonts/lightcase.svg?55356177#lightcase") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class*=lightcase-icon-]:before {
  font-family: "lightcase", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

/* Codes */
.lightcase-icon-play:before {
  content: "\e800";
}

.lightcase-icon-pause:before {
  content: "\e801";
}

.lightcase-icon-close:before {
  content: "\e802";
}

.lightcase-icon-prev:before {
  content: "\e803";
}

.lightcase-icon-next:before {
  content: "\e804";
}

.lightcase-icon-spin:before {
  content: "\e805";
}

/**
 * Mixin providing icon defaults to be used on top of font-lightcase.
 *
 * Example usage:
 * @include icon(#e9e9e9)
 */
/**
 * Mixin providing icon defaults including a hover status to be used
 * on top of font-lightcase.
 *
 * Example usage:
 * @include icon-hover(#e9e9e9, #fff)
 */
/**
 * Provides natural content overflow behavior and scrolling support
 * even so for touch devices.
 *
 * Example usage:
 * @include overflow()
 */
/**
 * Neutralizes/resets dimensions including width, height, position as well as margins,
 * paddings and styles. Used to enforce a neutral and unstyled look and behavoir!
 *
 * Example usage:
 * @include clear(true)
 *
 * @param boolean $important
 */
@-webkit-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-moz-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
#lightcase-case {
  display: none;
  position: fixed;
  z-index: 2002;
  top: 50%;
  left: 50%;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 1.5;
  text-align: left;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 640px) {
  html[data-lc-type=inline] #lightcase-case, html[data-lc-type=ajax] #lightcase-case {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    padding: 55px 0 70px 0;
    width: 100% !important;
    height: 100% !important;
    overflow: auto !important;
  }
}

#lightcase-case p.lightcase-error {
  margin: 0;
  font-size: 17px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #aaa;
}
@media screen and (max-width: 640px) {
  #lightcase-case p.lightcase-error {
    padding: 30px 0;
  }
}
@media screen and (min-width: 641px) {
  #lightcase-case p.lightcase-error {
    padding: 0;
  }
}

.lightcase-open body {
  overflow: hidden;
}
.lightcase-isMobileDevice .lightcase-open body {
  max-width: 100%;
  max-height: 100%;
}

#lightcase-info {
  position: absolute;
  padding-top: 15px;
}
#lightcase-info #lightcase-title,
#lightcase-info #lightcase-caption {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-weight: normal;
  text-overflow: ellipsis;
}
#lightcase-info #lightcase-title {
  font-size: 17px;
  color: #aaa;
}
@media screen and (max-width: 640px) {
  #lightcase-info #lightcase-title {
    position: fixed;
    top: 10px;
    left: 0;
    max-width: 87.5%;
    padding: 5px 15px;
    background: #333;
  }
}
#lightcase-info #lightcase-caption {
  clear: both;
  font-size: 13px;
  color: #aaa;
}
#lightcase-info #lightcase-sequenceInfo {
  font-size: 11px;
  color: #aaa;
}
@media screen and (max-width: 640px) {
  .lightcase-fullScreenMode #lightcase-info {
    padding-left: 15px;
    padding-right: 15px;
  }
  html:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) #lightcase-info {
    position: static;
  }
}

#lightcase-loading {
  position: fixed;
  z-index: 9999;
  width: 1.123em;
  height: auto;
  font-size: 38px;
  line-height: 1;
  text-align: center;
  text-shadow: none;
  position: fixed;
  z-index: 2001;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  opacity: 1;
  font-size: 32px;
  text-shadow: 0 0 15px #fff;
  -moz-transform-origin: 50% 53%;
  -webkit-animation: lightcase-spin 0.5s infinite linear;
  -moz-animation: lightcase-spin 0.5s infinite linear;
  -o-animation: lightcase-spin 0.5s infinite linear;
  animation: lightcase-spin 0.5s infinite linear;
}
#lightcase-loading, #lightcase-loading:focus {
  text-decoration: none;
  color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: color, opacity, ease-in-out 0.25s;
  -moz-transition: color, opacity, ease-in-out 0.25s;
  -o-transition: color, opacity, ease-in-out 0.25s;
  transition: color, opacity, ease-in-out 0.25s;
}
#lightcase-loading > span {
  display: inline-block;
  text-indent: -9999px;
}

a[class*=lightcase-icon-] {
  position: fixed;
  z-index: 9999;
  width: 1.123em;
  height: auto;
  font-size: 38px;
  line-height: 1;
  text-align: center;
  text-shadow: none;
  outline: none;
  cursor: pointer;
}
a[class*=lightcase-icon-], a[class*=lightcase-icon-]:focus {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: color, opacity, ease-in-out 0.25s;
  -moz-transition: color, opacity, ease-in-out 0.25s;
  -o-transition: color, opacity, ease-in-out 0.25s;
  transition: color, opacity, ease-in-out 0.25s;
}
a[class*=lightcase-icon-] > span {
  display: inline-block;
  text-indent: -9999px;
}
a[class*=lightcase-icon-]:hover {
  color: rgb(255, 255, 255);
  text-shadow: 0 0 15px rgb(255, 255, 255);
}
.lightcase-isMobileDevice a[class*=lightcase-icon-]:hover {
  color: #aaa;
  text-shadow: none;
}
a[class*=lightcase-icon-].lightcase-icon-close {
  position: fixed;
  top: 15px;
  right: 15px;
  bottom: auto;
  margin: 0;
  opacity: 0;
  outline: none;
}
a[class*=lightcase-icon-].lightcase-icon-prev {
  left: 15px;
}
a[class*=lightcase-icon-].lightcase-icon-next {
  right: 15px;
}
a[class*=lightcase-icon-].lightcase-icon-pause, a[class*=lightcase-icon-].lightcase-icon-play {
  left: 50%;
  margin-left: -0.5em;
}
@media screen and (min-width: 641px) {
  a[class*=lightcase-icon-].lightcase-icon-pause, a[class*=lightcase-icon-].lightcase-icon-play {
    opacity: 0;
  }
}
@media screen and (max-width: 640px) {
  a[class*=lightcase-icon-] {
    bottom: 15px;
    font-size: 24px;
  }
}
@media screen and (min-width: 641px) {
  a[class*=lightcase-icon-] {
    bottom: 50%;
    margin-bottom: -0.5em;
  }
  a[class*=lightcase-icon-]:hover, #lightcase-case:hover ~ a[class*=lightcase-icon-] {
    opacity: 1;
  }
}

#lightcase-overlay {
  display: none;
  width: 100%;
  min-height: 100%;
  position: fixed;
  z-index: 2000;
  top: -9999px;
  bottom: -9999px;
  left: 0;
  background: #333;
}
@media screen and (max-width: 640px) {
  #lightcase-overlay {
    opacity: 1 !important;
  }
}

#lightcase-overlay {
  background-color: #fff;
  opacity: 1 !important;
}

.lightcase-icon-close::before {
  color: #000;
}

.lightcase-icon-prev::before {
  color: #000;
}

.lightcase-icon-next::before {
  color: #000;
}

#lightcase-case, #lightcase-content, .lightcase-contentInner, .lightcase-inlineWrap, .modal {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  left: 0 !important;
  top: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: #fff !important;
  overflow: hidden;
}
@media screen and (max-width: 840px) {
  #lightcase-case, #lightcase-content, .lightcase-contentInner, .lightcase-inlineWrap, .modal {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: #fff !important;
  }
}

.modal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 840px) {
  .modal_wrap {
    padding-top: 50px;
  }
}
.modal_inner {
  width: 100%;
  max-width: 1240px;
  height: 100%;
  max-height: 800px;
  display: flex;
  flex-direction: column;
}
.modal_main {
  position: relative;
  padding: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
@media screen and (max-width: 840px) {
  .modal_main {
    max-height: 350px;
  }
}
.modal_main img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: "object-fit: contain;";
  cursor: pointer;
}
.modal_cap {
  flex: 1;
  max-height: 140px;
  font-size: 10px;
  color: #707980;
  line-height: 1;
  margin: 0;
  padding: 10px 24px;
  margin-top: 8px;
  text-shadow: none;
}
@media screen and (max-width: 840px) {
  .modal_cap {
    margin-top: 5px;
  }
}

#lightcase-caption, .modal_cap {
  font-size: 11px;
  color: #707980;
  line-height: 1.2;
  margin: 0;
  padding: 0 24px;
  margin-top: 8px;
  text-shadow: none;
  text-align: center;
}

.fi {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
}

.csa {
  cursor: auto !important;
}

/* ==========================================================================
   Remodal's necessary styles
   ========================================================================== */
/* Hide scroll bar */
html.remodal-is-locked {
  overflow: hidden;
  touch-action: none;
}

/* Anti FOUC */
.remodal,
[data-remodal-id] {
  display: none;
}

/* Necessary styles of the overlay */
.remodal-overlay {
  position: fixed;
  z-index: 9999;
  top: -5000px;
  right: -5000px;
  bottom: -5000px;
  left: -5000px;
  display: none;
}

/* Necessary styles of the wrapper */
.remodal-wrapper {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch;
}

.remodal-wrapper:after {
  display: inline-block;
  height: 100%;
  margin-left: -0.05em;
  content: "";
}

/* Fix iPad, iPhone glitches */
.remodal-overlay,
.remodal-wrapper {
  backface-visibility: hidden;
}

/* Necessary styles of the modal dialog */
.remodal {
  position: relative;
  outline: none;
  text-size-adjust: 100%;
}

.remodal-is-initialized {
  /* Disable Anti-FOUC */
  display: inline-block;
}

/* ==========================================================================
   Remodal's default mobile first theme
   ========================================================================== */
/* Default theme styles for the background */
.remodal-bg.remodal-is-opening,
.remodal-bg.remodal-is-opened {
  filter: blur(3px);
}

/* Default theme styles of the overlay */
.remodal-overlay {
  background: rgba(43, 46, 56, 0.9);
}

.remodal-overlay.remodal-is-opening,
.remodal-overlay.remodal-is-closing {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.remodal-overlay.remodal-is-opening {
  animation-name: remodal-overlay-opening-keyframes;
}

.remodal-overlay.remodal-is-closing {
  animation-name: remodal-overlay-closing-keyframes;
}

/* Default theme styles of the wrapper */
.remodal-wrapper {
  padding: 10px 10px 0;
}

/* Default theme styles of the modal dialog */
.remodal {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  padding: 35px;
  transform: translate3d(0, 0, 0);
  color: #2b2e38;
  background: #fff;
}

.remodal.remodal-is-opening,
.remodal.remodal-is-closing {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.remodal.remodal-is-opening {
  animation-name: remodal-opening-keyframes;
}

.remodal.remodal-is-closing {
  animation-name: remodal-closing-keyframes;
}

/* Vertical align of the modal dialog */
.remodal,
.remodal-wrapper:after {
  vertical-align: middle;
}

/* Close button */
.remodal-close {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: visible;
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: color 0.2s;
  text-decoration: none;
  color: #95979c;
  border: 0;
  outline: 0;
  background: transparent;
}

.remodal-close:hover,
.remodal-close:focus {
  color: #2b2e38;
}

.remodal-close:before {
  font-family: Arial, "Helvetica CY", "Nimbus Sans L", sans-serif !important;
  font-size: 25px;
  line-height: 35px;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 35px;
  content: "×";
  text-align: center;
}

/* Dialog buttons */
.remodal-confirm,
.remodal-cancel {
  font: inherit;
  display: inline-block;
  overflow: visible;
  min-width: 110px;
  margin: 0;
  padding: 12px 0;
  cursor: pointer;
  transition: background 0.2s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  border: 0;
  outline: 0;
}

.remodal-confirm {
  color: #fff;
  background: #81c784;
}

.remodal-confirm:hover,
.remodal-confirm:focus {
  background: #66bb6a;
}

.remodal-cancel {
  color: #fff;
  background: #e57373;
}

.remodal-cancel:hover,
.remodal-cancel:focus {
  background: #ef5350;
}

/* Remove inner padding and border in Firefox 4+ for the button tag. */
.remodal-confirm::-moz-focus-inner,
.remodal-cancel::-moz-focus-inner,
.remodal-close::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/* Keyframes
   ========================================================================== */
@keyframes remodal-opening-keyframes {
  from {
    transform: scale(1.05);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
    filter: blur(0);
  }
}
@keyframes remodal-closing-keyframes {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.95);
    opacity: 0;
    filter: blur(0);
  }
}
@keyframes remodal-overlay-opening-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes remodal-overlay-closing-keyframes {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* Media queries
   ========================================================================== */
@media only screen and (min-width: 641px) {
  .remodal {
    max-width: 700px;
  }
}
/* IE8
   ========================================================================== */
.lt-ie9 .remodal-overlay {
  background: #2b2e38;
}

.lt-ie9 .remodal {
  width: 700px;
}

.cart_modal {
  text-align: left;
  padding: 0;
}
.cart_modal_head {
  position: relative;
  padding: 24px 32px;
  border-bottom: solid 1px #ebedf0;
}
@media screen and (max-width: 840px) {
  .cart_modal_head {
    padding: 18px 16px;
  }
}
.cart_modal_head .modal_title {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  letter-spacing: 1.3px;
  color: #2c2e31;
  line-height: 1;
}
.cart_modal_head .remodal-close {
  width: 30px;
  height: 30px;
  right: 26px;
  left: auto;
  top: 0;
  bottom: 0;
  margin: auto;
  background-image: url(../img/settlement/ic-modalclose.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 17px 17px;
}
@media screen and (max-width: 840px) {
  .cart_modal_head .remodal-close {
    right: 16px;
  }
}
.cart_modal_head .remodal-close:hover {
  opacity: 0.7;
}
.cart_modal_head .remodal-close::before {
  display: none;
}
.cart_modal_main {
  padding: 24px 32px;
}
@media screen and (max-width: 840px) {
  .cart_modal_main {
    padding: 18px 16px;
  }
}
.cart_modal_main .cart_modal_table {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: 1.85;
  letter-spacing: 1.2px;
  color: #2c2e31;
  border-top: solid 1px #DADADA;
}
.cart_modal_main .cart_modal_table tr th, .cart_modal_main .cart_modal_table tr td {
  border-bottom: solid 1px #DADADA;
  padding: 8px 10px;
  word-break: break-all;
}
.cart_modal_main .cart_modal_table tr th {
  min-width: 14em;
  font-weight: normal;
}
@media screen and (max-width: 840px) {
  .cart_modal_main .cart_modal_table tr th {
    min-width: 7em;
  }
}
.cart_modal_main .cart_modal_table + h3 {
  margin-top: 24px;
}
.cart_modal_main p {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: 1.85;
  letter-spacing: 1.2px;
  color: #2c2e31;
  word-break: break-all;
}
.cart_modal_foot {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  border-top: solid 1px #ebedf0;
}
@media screen and (max-width: 840px) {
  .cart_modal_foot {
    padding: 16px;
  }
}
.cart_modal_foot .close_btn {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #707980;
  cursor: pointer;
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #ebedf0;
  transition-property: border;
  transition-duration: 0.25s;
}
.cart_modal_foot .close_btn:hover {
  border: solid 1px #bfcbd5;
}

.modal_deliverycharge {
  max-width: 552px;
}
.modal_storesettlement {
  max-width: 552px;
}
.modal_deliverycharge-criterion {
  max-width: 984px;
}

.remodal-wrapper {
  padding: 16px !important;
  overflow: scroll !important;
  height: 100vh !important;
}

.remodal-overlay {
  background-color: rgba(44, 46, 49, 0.7);
}

html, body {
  height: 100%;
}

.fixed {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  right: 0;
}

body {
  font-size: 1.3rem;
  line-height: 1.85;
  letter-spacing: 1.2px;
  color: #2c2e31;
  background-color: #F1F2F4;
  font-family: "Roboto", "Hiragino Sans", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.bg_white {
  background-color: #fff;
}

img {
  vertical-align: text-bottom;
}

.sp_br {
  display: none;
}
@media screen and (max-width: 840px) {
  .sp_br {
    display: block;
  }
}

.pc_br {
  display: block;
}
@media screen and (max-width: 840px) {
  .pc_br {
    display: none;
  }
}

a {
  cursor: pointer;
}

/*------------------------------------*\
    SVG用
\*------------------------------------*/
.defs {
  display: none;
}

@media screen and (min-width: 640px) {
  .footer_nav_menu li a, .gnav_menu > li > a {
    position: relative;
    display: inline-block;
  }
  .footer_nav_menu li a:before, .gnav_menu > li > a:before, .footer_nav_menu li a:after, .gnav_menu > li > a:after {
    position: absolute;
    bottom: 0;
    content: "";
    display: inline-block;
    width: 0;
    height: 2px;
    background: #2c2e31;
    transition-duration: 0.5s;
  }
  .footer_nav_menu li a:before, .gnav_menu > li > a:before {
    left: 50%;
  }
  .footer_nav_menu li a:after, .gnav_menu > li > a:after {
    right: 50%;
  }
  .footer_nav_menu li a:hover:before, .gnav_menu > li > a:hover:before, .footer_nav_menu li a:hover:after, .gnav_menu > li > a:hover:after {
    width: 50%;
  }
}

/*------------------------------------*\
    .wrap
\*------------------------------------*/
.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 1170px;
  margin: 0 auto;
}
@media screen and (max-width: 840px) {
  .wrap {
    min-width: 100%;
    width: 100%;
  }
}

/*------------------------------------*\
    header
\*------------------------------------*/
.header {
  position: absolute;
  z-index: 999;
  width: 100%;
  background-color: #fff;
  min-width: 1170px;
}
@media screen and (max-width: 840px) {
  .header {
    min-width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 840px) {
  .header {
    position: fixed;
  }
}
.header_inner {
  max-width: 1170px;
  margin: 0 auto;
  padding: 36px 20px 0;
}
@media screen and (max-width: 840px) {
  .header_inner {
    display: flex;
    align-items: center;
    padding: 15px 20px 16px 20px;
    height: 60px;
  }
}
.header_top {
  position: relative;
  margin-bottom: 4px;
}
@media screen and (max-width: 840px) {
  .header_top {
    width: 100%;
    margin-bottom: 0;
  }
}
.header_top .art_guide {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  font-weight: bold;
  line-height: 1;
}
.header_top .art_guide a {
  color: #2c2e31;
  transition-property: opacity;
  transition-duration: 0.25s;
}
.header_top .art_guide a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 840px) {
  .header_top .art_guide {
    display: none;
  }
}
.header_top .art_guide img {
  margin-right: 8px;
  vertical-align: middle;
}
.header .logo {
  text-align: center;
}
.header .logo img {
  vertical-align: bottom;
  width: auto;
  height: 64px;
}
@media screen and (max-width: 840px) {
  .header .logo img {
    padding: 0;
  }
}
@media screen and (max-width: 840px) {
  .header .logo {
    width: 100%;
  }
  .header .logo img {
    width: auto;
    height: 36px !important;
  }
}

.h_hide {
  position: fixed;
  top: -100%;
  transition-property: top;
  transition-duration: 1.8s;
}

.h_show {
  position: fixed;
  top: 0%;
  transition-property: top;
  transition-duration: 0.25s;
}

.sp_menu {
  display: none;
  position: absolute;
  left: 24px;
  z-index: 99;
}
.sp_menu .menu_i {
  position: absolute;
  right: -5px;
  top: -5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sp_menu .menu_i:before {
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #f55367;
  border-radius: 50%;
}
@media screen and (max-width: 840px) {
  .sp_menu {
    display: block;
  }
}

.gnav {
  display: flex;
  align-items: center;
  height: 80px;
}
@media screen and (max-width: 840px) {
  .gnav {
    position: absolute;
    right: 26px;
    margin: 0;
    justify-content: flex-end;
  }
}
.gnav_sns {
  display: flex;
  margin-right: auto;
}
@media screen and (max-width: 840px) {
  .gnav_sns {
    display: none;
  }
}
.gnav_sns li:not(:last-child) {
  margin-right: 20px;
}
.gnav_sns a:hover {
  opacity: 0.7;
}
.gnav_menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  margin-right: 104px;
}
@media screen and (max-width: 840px) {
  .gnav_menu {
    display: none;
  }
}
.gnav_menu > li {
  height: 80px;
  display: flex;
  align-items: center;
}
.gnav_menu > li > a {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
  color: #2c2e31;
}
.gnav_menu > li:not(:last-child) {
  margin-right: 35px;
}
.gnav .has_list {
  position: relative;
  display: flex;
  justify-content: center;
}
.gnav .inner_list {
  display: none;
  background-color: #fff;
  position: absolute;
  top: calc(100% - 1px);
  left: calc(50% - 80px);
  width: 160px;
  text-align: center;
}
.gnav .inner_list li {
  text-align: center;
}
.gnav .inner_list li a {
  display: block;
  width: 160px;
  padding: 12px 0;
  color: #2c2e31;
  font-weight: bold;
  transition-property: color;
  transition-duration: 0.25s;
}
.gnav .inner_list li a:hover {
  color: #707980;
}
.gnav_tools {
  display: flex;
  justify-content: center;
}
.gnav_tools .login_btn a {
  font-size: 1.3rem;
  font-weight: bold;
  color: #2c2e31;
}
.gnav_tools img {
  vertical-align: middle;
}
.gnav_tools li {
  position: relative;
}
.gnav_tools li:not(:last-child) {
  margin-right: 24px;
}
@media screen and (max-width: 840px) {
  .gnav_tools li:not(:last-child) {
    margin-right: 16px;
  }
}
.gnav_tools a:hover {
  opacity: 0.7;
}
.gnav_tools .icon_search {
  display: none;
}
@media screen and (max-width: 840px) {
  .gnav_tools .icon_search {
    display: block !important;
  }
  .gnav_tools .icon_search img {
    width: auto;
    height: 18px;
  }
}
@media screen and (max-width: 840px) {
  .gnav_tools li:not(:last-child) {
    display: none;
  }
}
.gnav_tools_sub {
  display: flex;
  justify-content: center;
  margin-left: 47px;
}
@media screen and (max-width: 840px) {
  .gnav_tools_sub {
    border: none;
    padding: 0;
    margin: 0;
    padding-right: 24px;
    order: -1;
  }
}
.gnav_tools_sub img {
  vertical-align: middle;
}
.gnav_tools_sub li:not(:last-child) {
  margin-right: 24px;
}
@media screen and (max-width: 840px) {
  .gnav_tools_sub li:not(:last-child) {
    margin: 0;
  }
}
.gnav_tools_sub a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 840px) {
  .gnav {
    align-items: center;
  }
  .gnav .menu_btn {
    display: none;
  }
}

.num_i {
  display: block;
  position: absolute;
  right: -3px;
  top: 0px;
  background-color: #f55367;
  font-style: normal;
  letter-spacing: -1px;
  color: #fff;
  font-size: 1rem;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  text-indent: -1px;
}

.search_area {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99;
  width: 100%;
  background-color: #fff;
  border-top: solid 1px #dce0e3;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  padding-bottom: 40px;
}
@media screen and (max-width: 840px) {
  .search_area {
    padding: 0;
  }
}
.search_area_inner {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  padding-top: 28px;
}
@media screen and (max-width: 840px) {
  .search_area_inner {
    padding: 0 24px;
  }
}
.search_area_inner input {
  font-weight: 300;
  display: block;
  width: 100%;
  padding: 13px 3px 13px 53px;
  border: none;
  border-bottom: solid 1px #707980;
  font-size: 2.4rem;
  background-image: url(/common/img/common/ic-search_l_b.png);
  background-repeat: no-repeat;
  background-position: left 3px center;
  background-size: 28px;
}
@media screen and (max-width: 840px) {
  .search_area_inner input {
    border-bottom: none;
    font-size: 1.6rem;
    padding: 20px 35px;
  }
}
.search_area_inner input::placeholder {
  letter-spacing: 1px;
  color: #b9c5ce;
}
.search_area_inner input:-ms-input-placeholder, .search_area_inner input::-ms-input-placeholder {
  color: #b9c5ce;
}
@media screen and (max-width: 840px) {
  .search_area_inner input {
    background-size: 19px;
  }
}
.search_area_inner ::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.search_area_close {
  position: absolute;
  top: 10px;
  bottom: 0;
  right: -154px;
  z-index: 99;
  margin: auto;
  width: 17px;
  height: 17px;
}
@media screen and (max-width: 840px) {
  .search_area_close {
    display: none;
  }
}

.header_small {
  position: fixed;
  z-index: 999;
  border-bottom: 1px solid #ebedef;
}
.header_small .header_inner {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 24px;
}
@media screen and (max-width: 840px) {
  .header_small .header_inner {
    height: 60px;
  }
}
.header_small .header_top {
  margin-bottom: 0;
}
.header_small .gnav {
  width: 100%;
  height: 64px;
}
@media screen and (max-width: 840px) {
  .header_small .gnav {
    width: auto;
  }
}
@media screen and (max-width: 840px) {
  .header_small .gnav {
    padding-left: 0;
    margin-left: 0;
    border: none;
  }
}
.header_small .gnav_menu {
  margin-right: 0;
  margin-left: auto;
}
.header_small .gnav_menu li a {
  font-size: 1.3rem;
}
.header_small .gnav_tools {
  margin-left: 35px;
}
@media screen and (max-width: 840px) {
  .header_small .gnav_tools {
    margin-left: 0;
  }
}
.header_small .art_guide, .header_small .gnav_sns {
  display: none;
}
.header_small .logo img {
  height: 36px;
}

.header_search {
  margin-left: 28px;
}
@media screen and (max-width: 840px) {
  .header_search {
    display: none;
  }
}
.header_search form {
  display: flex;
  align-items: center;
}
.header_search input[type=search] {
  display: block;
  width: 311px;
  height: 40px;
  font-size: 1.2rem;
  padding: 11px 20px 11px 17px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}
.header_search input[type=submit] {
  margin-left: -30px;
  width: 26px;
  height: 40px;
  background-image: url(/common/img/common/icon_search.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 13px 14px;
}
.header_search ::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .overlay {
    overflow: scroll !important;
  }
}
.overlay_inner {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}
@media screen and (max-width: 840px) {
  .overlay_inner {
    padding: 0 24px 50px;
  }
}
.overlay_header {
  margin-bottom: 74px;
}
@media screen and (max-width: 840px) {
  .overlay_header {
    margin-bottom: 16px;
  }
}
.overlay_header_inner {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 24px 0 24px;
}
@media screen and (max-width: 840px) {
  .overlay_header_inner {
    height: 60px;
    padding: 0;
  }
}
.overlay_header_inner .sp_close {
  display: none;
  position: absolute;
  left: 24px;
  z-index: 99;
}
@media screen and (max-width: 840px) {
  .overlay_header_inner .sp_close {
    display: block;
  }
}
@media screen and (max-width: 840px) {
  .overlay_logo {
    text-align: center;
  }
}
.overlay_logo img {
  vertical-align: bottom;
  height: 36px;
}
@media screen and (max-width: 840px) {
  .overlay_logo {
    width: 100%;
  }
  .overlay_logo img {
    height: 36px;
  }
}
.overlay_icons {
  display: flex;
  align-items: center;
  margin-left: auto;
}
@media screen and (max-width: 840px) {
  .overlay_icons {
    position: absolute;
    right: 26px;
    margin: 0;
  }
}
.overlay_icons li {
  position: relative;
}
.overlay_icons li:not(:last-child) {
  margin-right: 24px;
}
.overlay_icons_tools {
  display: flex;
  justify-content: center;
}
.overlay_icons_tools .login_btn a {
  font-size: 1.3rem;
  font-weight: bold;
  color: #fff;
}
.overlay_icons_tools img {
  vertical-align: middle;
}
.overlay_icons_tools li {
  position: relative;
}
.overlay_icons_tools li:not(:last-child) {
  margin-right: 24px;
}
.overlay_icons_tools a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 840px) {
  .overlay_icons_tools li:not(:last-child) {
    display: none;
  }
}
.overlay_icons_tools_sub {
  display: flex;
  justify-content: center;
  margin-left: 25px;
  padding-left: 25px;
  border-left: solid 2px rgba(241, 242, 244, 0.1);
}
@media screen and (max-width: 840px) {
  .overlay_icons_tools_sub {
    border: none;
    padding: 0;
    margin: 0;
    padding-right: 24px;
    order: -1;
  }
  .overlay_icons_tools_sub li:last-child {
    display: none;
  }
}
.overlay_icons_tools_sub img {
  vertical-align: middle;
}
.overlay_icons_tools_sub li:not(:last-child) {
  margin-right: 24px;
}
@media screen and (max-width: 840px) {
  .overlay_icons_tools_sub li:not(:last-child) {
    margin: 0;
  }
}
.overlay_icons_tools_sub a:hover {
  opacity: 0.7;
}
.overlay_nav {
  justify-content: center;
  display: flex;
}
@media screen and (max-width: 840px) {
  .overlay_nav {
    display: block;
  }
}
.overlay_nav li a {
  color: #fff;
}
.overlay_nav li a:hover {
  text-decoration: underline;
}
.overlay_nav_main {
  width: 298px;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 3.2px;
  margin-right: 111px;
}
@media screen and (max-width: 840px) {
  .overlay_nav_main {
    width: 100%;
    font-size: 1.8rem;
  }
}
.overlay_nav_main > li:not(:last-child) {
  margin-bottom: 24px;
}
.overlay_nav_main > li > a:hover {
  display: inline;
  position: relative;
  text-decoration: none;
}
.overlay_nav_main > li > a:hover:after {
  z-index: -1;
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  content: "";
  width: 100%;
  height: 15px;
  background-color: #4a90e2;
}
.overlay_nav_main .inner_list {
  padding-left: 1em;
  margin-top: 10px;
}
.overlay_nav_main .inner_list li::before {
  font-size: 1.6rem;
  content: "-";
  color: #fff;
  font-weight: normal;
  margin-right: 3px;
}
@media screen and (max-width: 840px) {
  .overlay_nav_main .inner_list li::before {
    font-size: 1.4rem;
  }
}
.overlay_nav_main .inner_list li a {
  display: inline-block;
  font-size: 1.6rem;
  padding: 10px 0;
  text-decoration: none;
}
@media screen and (max-width: 840px) {
  .overlay_nav_main .inner_list li a {
    font-size: 1.4rem;
    padding: 8px 0;
  }
}
.overlay_nav_main .inner_list li a:hover {
  text-decoration: underline;
}
.overlay_nav_inner {
  width: 252px;
}
@media screen and (max-width: 840px) {
  .overlay_nav_inner {
    width: 100%;
  }
}
.overlay_nav_tools {
  display: none;
  padding: 24px 0;
  margin: 24px 0;
  font-size: 1.6rem;
  font-weight: bold;
  border-top: solid 1px rgba(244, 246, 248, 0.1);
  border-bottom: solid 1px rgba(244, 246, 248, 0.1);
}
@media screen and (max-width: 840px) {
  .overlay_nav_tools {
    display: block;
  }
}
.overlay_nav_tools li:not(:last-child) {
  margin-bottom: 20px;
}
.overlay_nav_tools li a {
  display: flex;
  align-items: center;
}
.overlay_nav_tools li a img {
  margin-right: 16px;
}
.overlay_nav_tools li a:hover {
  text-decoration: none;
}
.overlay_nav_tools .num_icon {
  display: inline-block;
  background-color: #f55367;
  font-style: normal;
  letter-spacing: -1px;
  margin-left: 11px;
  color: #fff;
  font-size: 1rem;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  text-indent: -1px;
}
.overlay_nav_wrap {
  font-size: 1.4rem;
}
@media screen and (max-width: 840px) {
  .overlay_nav_wrap {
    display: flex;
  }
  .overlay_nav_wrap ul {
    width: 50%;
  }
}
.overlay_nav_wrap ul:first-child {
  margin-bottom: 7px;
}
.overlay_nav_wrap li {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  letter-spacing: normal;
  text-align: justify;
}
@media screen and (max-width: 840px) {
  .overlay_nav_wrap li {
    letter-spacing: normal;
  }
}
.overlay_nav_wrap li:not(:last-child) {
  margin-bottom: 7px;
}
.overlay_nav_sns {
  margin-top: 32px;
  font-size: 1.4rem;
  line-height: 1;
  color: #707980;
}
.overlay_nav_sns ul {
  display: flex;
  margin-top: 20px;
}
.overlay_nav_sns ul li:not(:last-child) {
  margin-right: 20px;
}
.overlay_nav_sns a:hover {
  text-decoration: none;
  opacity: 0.7;
}

img.lazy-img {
  display: block;
  border: 0;
  opacity: 0;
}
img.lazy-img:not(.initial) {
  transition: opacity 1s cubic-bezier(0.7, 0, 0.3, 1) 0.45s;
}
img.lazy-img.initial, img.lazy-img.loaded, img.lazy-img.error {
  opacity: 1;
}
img.lazy-img:not([src]) {
  visibility: hidden;
}

.lazy-img:not(img) {
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.7, 0, 0.3, 1) 0.45s;
}
.lazy-img:not(img)[data-was-processed=true] {
  opacity: 1;
}

/*------------------------------------*\
    main
\*------------------------------------*/
.main {
  display: block;
  flex: 1 0 auto;
  padding-top: 80px;
}
@media screen and (max-width: 840px) {
  .main {
    padding-top: 60px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .main {
    overflow: hidden;
  }
}
.lower_main {
  padding-top: 81px;
}
@media screen and (max-width: 840px) {
  .lower_main {
    padding-top: 61px;
  }
}

.errmsg {
  color: #f00 !important;
  font-size: 1.2rem !important;
  margin-top: 8px !important;
}
.errmsg + input, .errmsg + textarea {
  margin-top: 13px;
}
.errmsg + select {
  margin-top: 13px;
}

.pan {
  background-color: #2c2e31;
  height: 48px;
  line-height: 48px;
  margin-bottom: 24px;
}
@media screen and (max-width: 840px) {
  .pan {
    display: none;
  }
}
.pan ul {
  display: flex;
  max-width: 1128px;
  margin: 0 auto;
}
.pan ul li {
  font-family: Roboto, "Hiragino Kaku Gothic W4 JIS2004", "Hiragino Kaku Gothic ProN";
  font-weight: 400;
}
.pan ul li:not(:last-child)::after {
  display: inline-block;
  margin: 0 16px;
  content: "";
  color: #fff;
  width: 7px;
  height: 10px;
  background-image: url(../../common/img/common/right-arrow-white.png);
  background-size: cover;
}
.pan ul li a, .pan ul li span {
  color: #fff;
}
.pan ul li span {
  display: block;
  max-width: 23em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pan + #for_oil-bijutsutecho-to-page, .pan + #for_oil-bijutsutecho-to-page-sp, .pan + #for_oil-bijutsutecho-to-shop-sp, .pan + #for_oil-bijutsutecho-to-shop {
  margin: 24px auto !important;
  max-width: 1128px !important;
  width: 100% !important;
}
@media screen and (max-width: 840px) {
  .pan + #for_oil-bijutsutecho-to-page, .pan + #for_oil-bijutsutecho-to-page-sp, .pan + #for_oil-bijutsutecho-to-shop-sp, .pan + #for_oil-bijutsutecho-to-shop {
    margin: 20px auto !important;
    padding: 0 16px;
  }
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input::-ms-clear, button::-ms-clear, textarea::-ms-clear, select::-ms-clear {
  display: none;
}
input::-ms-reveal, button::-ms-reveal, textarea::-ms-reveal, select::-ms-reveal {
  display: none;
}
input::-ms-expand, button::-ms-expand, textarea::-ms-expand, select::-ms-expand {
  display: none;
}
input::placeholder, button::placeholder, textarea::placeholder, select::placeholder {
  letter-spacing: 1px;
  color: #b9c5ce;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
}

.form input, .form button, .form textarea, .form select {
  width: 100%;
  border: solid 1px #dce0e3;
  border-radius: 3px;
  font-size: 1.4rem;
  font-family: HiraKakuPro-W3;
  padding: 9px 12px 8px;
}
.form input::-ms-clear, .form button::-ms-clear, .form textarea::-ms-clear, .form select::-ms-clear {
  display: none;
}
.form input::-ms-reveal, .form button::-ms-reveal, .form textarea::-ms-reveal, .form select::-ms-reveal {
  display: none;
}
.form input::placeholder, .form button::placeholder, .form textarea::placeholder, .form select::placeholder {
  letter-spacing: 1px;
  color: #b9c5ce;
}
.form textarea {
  max-height: 167px;
}
.form select {
  width: 100%;
  padding: 8px 12px 6px;
  line-height: 1.9;
  border: solid 1px #dce0e3;
  border-radius: 3px;
  font-size: 1.4rem;
  background-color: #fff;
  background-image: url(../img/common/arrow_b.png);
  background-position: right 12px center;
  background-repeat: no-repeat;
  background-size: 12px 7px;
}
@media screen and (max-width: 840px) {
  .form select {
    background-position: right 12px center;
  }
}
.form:-ms-input-placeholder, .form::-ms-input-placeholder {
  color: #b9c5ce;
}
.form input[type=number]::-webkit-outer-spin-button,
.form input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form input[type=number] {
  -moz-appearance: textfield;
}
.form label {
  font-family: HiraKakuPro-W6;
  font-size: 12px;
  letter-spacing: 0.1px;
  color: #2c2e31;
}
.form label a {
  text-decoration: underline;
  color: #2c2e31;
  transition-property: color;
  transition-duration: 0.25s;
}
.form label a:hover {
  text-decoration: none;
}
.form .require {
  font-family: HiraKakuPro-W6;
  font-size: 1rem;
  background-color: #bfcbd5;
  color: #fff;
  padding: 0.1em 0.3em;
  margin-left: 0.3em;
  letter-spacing: 0.9px;
}
.form input[type=submit] {
  height: 40px;
  background-color: #2c2e31;
  line-height: 40px;
  padding: 0;
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  color: #fff;
  border: none;
  border-radius: 0;
  transition-property: background-color;
  transition-duration: 0.25s;
}
.form input[type=submit]:hover {
  background-color: #707980;
}

.form_table {
  width: 100%;
}
@media screen and (max-width: 840px) {
  .form_table tr {
    display: block;
  }
}
.form_table th, .form_table td {
  padding: 12px 0;
}
@media screen and (max-width: 840px) {
  .form_table th, .form_table td {
    display: block;
    padding: 0;
  }
}
.form_table th {
  text-align: right;
  padding: 24px 16px 0 0;
  white-space: nowrap;
}
@media screen and (max-width: 840px) {
  .form_table th {
    padding: 24px 0 5px 0;
  }
}
.form_table td {
  vertical-align: top;
}
@media screen and (max-width: 840px) {
  .form_table td {
    padding-top: 0;
  }
}
.form_table td .col2 {
  display: flex;
  justify-content: space-between;
}
.form_table td .col2 input {
  width: 48%;
}
@media screen and (max-width: 320px) {
  .form_table td .checkbox_text span {
    font-size: 1.1rem;
    vertical-align: top;
  }
}

.contact_form .btn_b {
  background-color: #2c2e31;
  color: #fff;
  padding: 0;
  height: 40px;
  line-height: 40px;
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  transition-property: background-color;
  transition-duration: 0.25s;
}
.contact_form .btn_b:hover {
  background-color: #707980;
}
.contact_form .err_msg {
  display: none;
  font-size: 1.2rem;
  color: #f00;
  margin-top: 1em;
}

.btn_w a {
  display: block;
  text-align: center;
  color: #707980;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  border: solid 1px #ebedf0;
  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 1.5px;
  color: #707980;
  transition-property: border;
  transition-duration: 0.25s;
}
.btn_w a:hover {
  border: solid 1px #bfcbd5;
}

.btn_disable {
  background-color: #dce0e3 !important;
  cursor: default !important;
}

/*------------------------------------*\
    footer
\*------------------------------------*/
.footer {
  background-color: #fff;
}
.footer_inner {
  max-width: 1168px;
  margin: 0 auto;
  padding: 30px 20px 33px;
}
@media screen and (max-width: 840px) {
  .footer_inner {
    position: relative;
    padding: 24px;
  }
}
@media screen and (max-width: 320px) {
  .footer_inner {
    padding: 24px 16px;
  }
}
.footer_head {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 840px) {
  .footer_head {
    align-items: flex-start;
  }
}
.footer_logo {
  margin-right: auto;
}
@media screen and (max-width: 840px) {
  .footer_logo {
    width: 50%;
  }
  .footer_logo img {
    width: 60px;
    height: auto;
  }
}
.footer_nav {
  display: flex;
}
@media screen and (max-width: 840px) {
  .footer_nav {
    display: block;
  }
}
.footer_nav_menu {
  display: flex;
  justify-content: center;
  margin-right: 44px;
}
@media screen and (max-width: 840px) {
  .footer_nav_menu {
    margin: 0;
    display: block;
  }
}
.footer_nav_menu li a {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  text-align: left;
  color: #2c2e31;
}
.footer_nav_menu li:not(:last-child) {
  margin-right: 40px;
}
@media screen and (max-width: 840px) {
  .footer_nav_menu li:not(:last-child) {
    margin: 0 0 14px 0;
  }
}
.footer_nav .inner_list {
  display: none;
}
.footer_nav_sns {
  display: flex;
  justify-content: center;
  margin-right: auto;
}
@media screen and (max-width: 840px) {
  .footer_nav_sns {
    position: absolute;
    left: 24px;
    top: 73px;
  }
}
.footer_nav_sns li:not(:last-child) {
  margin-right: 20px;
}
.footer_nav_sns a:hover {
  opacity: 0.7;
}
.footer .sub_nav {
  display: flex;
  margin: 34px 0 24px;
  padding-top: 15px;
  border-top: solid 1px #ebedf0;
}
@media screen and (max-width: 840px) {
  .footer .sub_nav {
    margin: 24px 0 50px;
    padding-top: 24px;
  }
}
.footer .sub_nav ul {
  display: flex;
}
@media screen and (max-width: 840px) {
  .footer .sub_nav ul {
    display: block;
    width: 50%;
  }
}
.footer .sub_nav ul:first-child {
  margin-right: 0.8em;
}
@media screen and (max-width: 840px) {
  .footer .sub_nav ul:first-child {
    margin: 0;
  }
}
.footer .sub_nav ul:first-child::after {
  display: inline-block;
  content: "|";
  color: #707980;
  padding-left: 0.8em;
}
@media screen and (max-width: 840px) {
  .footer .sub_nav ul:first-child::after {
    display: none;
  }
}
.footer .sub_nav ul li {
  font-size: 1.2rem;
  font-family: HiraginoSans-W3;
}
@media screen and (max-width: 840px) {
  .footer .sub_nav ul li {
    font-size: 1.4rem;
    letter-spacing: normal;
    line-height: 1.6;
  }
}
@media screen and (max-width: 320px) {
  .footer .sub_nav ul li {
    font-size: 1.3rem;
  }
}
.footer .sub_nav ul li:not(:last-child) {
  margin-right: 0.8em;
}
@media screen and (max-width: 840px) {
  .footer .sub_nav ul li:not(:last-child) {
    margin: 0 0 10px 0;
  }
}
.footer .sub_nav ul li:not(:last-child)::after {
  display: inline-block;
  content: "|";
  color: #707980;
  padding-left: 0.8em;
}
@media screen and (max-width: 840px) {
  .footer .sub_nav ul li:not(:last-child)::after {
    display: none;
  }
}
.footer .sub_nav ul li a {
  color: #707980;
}
.footer .sub_nav ul li a:hover {
  text-decoration: underline;
}
.footer .copyright {
  font-family: "Roboto";
  font-weight: 400;
  color: #707980;
  font-size: 1.1rem;
}
@media screen and (max-width: 840px) {
  .footer .copyright {
    text-align: center;
  }
}

.error {
  padding-top: 168px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 840px) {
  .error {
    padding-top: 92px;
  }
}
.error p {
  font-family: PTSerif-Caption;
  font-size: 2.4rem;
  line-height: normal;
  letter-spacing: 3px;
  text-indent: 3px;
  color: #bfcbd5;
}
@media screen and (max-width: 840px) {
  .error p {
    font-size: 2rem;
    letter-spacing: 2.5px;
  }
}
.error_code {
  font-family: PTSerif-Caption;
  font-size: 8rem;
  letter-spacing: 17px;
  text-indent: 17px;
  color: #bfcbd5;
  margin: 10px 0 26px;
  line-height: 1;
}
@media screen and (max-width: 840px) {
  .error_code {
    font-size: 6.4rem;
    letter-spacing: 13.6px;
    margin-bottom: 8px;
  }
}
.error .btn_w {
  width: 168px;
  height: 40px;
}

.error_page {
  padding-top: 80px;
}
.error_page h2 {
  text-align: center;
  font-family: Roboto;
  font-size: 2.8rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #677077;
  margin-bottom: 20px;
}
.error_page p {
  font-size: 1.4rem;
  text-align: center;
  letter-spacing: 0.1em;
  line-height: 1.75;
  color: #707980;
  margin: 24px 0 0;
}

.index_bg {
  background-color: #fff;
}

.index_wrap {
  font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, Verdana, sans-serif;
}

.delivery_small {
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 16px;
  text-align: right;
}
@media screen and (max-width: 840px) {
  .delivery_small {
    padding: 0 30px;
  }
}
.delivery_small a {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 120%;
  letter-spacing: 0.05em;
  color: #2C2E31;
  background-image: url(../img/common/icon_info.png);
  background-size: 12px 12px;
  background-repeat: no-repeat;
  padding-left: 27px;
}
.delivery_small a:hover {
  opacity: 0.7;
}

.pickup_title {
  font-family: Roboto;
  font-weight: bold;
  font-size: 2rem;
  line-height: 23px;
  text-align: center;
  margin-bottom: 30px;
  color: #000;
}
.pickup_title span {
  text-align: center;
  display: block;
  font-size: 1.2rem;
  line-height: 120%;
  margin-top: 9px;
  color: #2C2E31;
}

.index_section_title, .index_section_title_gold {
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 21px;
  color: #2C2E31;
}
.index_section_title span, .index_section_title_gold span {
  font-size: 1.2rem;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  margin-left: 16px;
}

.index_section_title_gold {
  color: #C2A47E;
}

.title_wrap {
  display: flex;
  align-items: center;
}
.title_wrap .more_link {
  font-weight: bold;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.05em;
  margin-left: auto;
}
@media screen and (max-width: 840px) {
  .title_wrap .more_link {
    display: none;
  }
}
.title_wrap .more_link a {
  color: #C2A47E;
  padding-right: 10px;
  background-image: url(../img/common/icon_more_link.png);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 4px 7px;
}
.title_wrap .more_link a:hover {
  opacity: 0.7;
}

.section_title {
  font-family: PTSerif-Caption;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .section_title {
    font-size: 1.6rem;
  }
}
.section_title span {
  font-family: HiraMinProN-W3;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #707980;
  margin-left: 8px;
}
@media screen and (max-width: 840px) {
  .section_title span {
    font-size: 1.1rem;
  }
}

.section_subtitle {
  font-family: PTSerif-Caption;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.2px;
  color: #2c2e31;
  text-align: center;
  margin-bottom: 24px;
}

.go_list {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
  padding-right: 30px;
  background-image: url(/common/img/common/bt-link.png);
  background-position: right center;
  background-size: 16px 12px;
  background-repeat: no-repeat;
  transition-property: opacity;
  transition-duration: 0.25s;
}
.go_list:hover {
  opacity: 0.7;
}
@media screen and (max-width: 840px) {
  .go_list {
    display: none;
  }
}

.add_like .bt_hart {
  width: 16px;
  height: 14px;
  fill: transparent;
  stroke: #BFCBD5;
  stroke-width: 1.5;
  cursor: pointer;
  transition-property: stroke, fill;
  transition-duration: 0.2s;
}
.add_like .bt_hart:hover {
  stroke: #f55367;
}
.add_like .bt_hart_on {
  fill: #f55367;
  stroke: #f55367;
}

/*------------------------------------*\
    sp_search
\*------------------------------------*/
.sp_search {
  display: none;
  padding: 70px 30px 0;
  margin: -56px 0 0;
}
@media screen and (max-width: 840px) {
  .sp_search {
    display: block;
  }
}
.sp_search form {
  display: flex;
  align-items: center;
}
.sp_search input[type=search] {
  display: block;
  width: 100%;
  height: 40px;
  font-size: 1.2rem;
  padding: 16px 10px 16px 30px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  background-image: url(/common/img/common/icon_search.png);
  background-position: left 10px center;
  background-repeat: no-repeat;
  background-size: 13px 14px;
}
.sp_search ::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.overlay_like {
  width: 37px;
  height: 37px;
  margin-left: auto;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay_like .icon_like {
  width: 16px;
  height: 18px;
  fill: transparent;
  stroke: #fff;
  transition-property: fill, stroke;
  transition-duration: 0.25s;
}
.overlay_like:hover .icon_like {
  fill: #fff;
  stroke: #fff;
}
.overlay_like.bt_hart_on .icon_like {
  fill: #fff;
  stroke: #fff;
}

/*------------------------------------*\
    FEATURED ARTISTS 注目の作家
\*------------------------------------*/
.buyee_tag {
  max-width: 1128px !important;
  width: 100% !important;
  height: auto !important;
  margin: 0 auto;
}

/*------------------------------------*\
    FEATURED ARTISTS 注目の作家
\*------------------------------------*/
.featured_artists {
  padding: 30px 0 0 0;
}

.featured_artists_inner {
  background: linear-gradient(0deg, rgba(203, 203, 203, 0.4158) 0%, rgba(44, 46, 49, 0) 100%);
  padding: 10px 0 45px;
  min-height: 430px;
}
@media screen and (max-width: 840px) {
  .featured_artists_inner {
    padding: 10px 0 24px;
    min-height: auto;
  }
}

#featured_artists .slide_item {
  display: inline-block;
}
#featured_artists .slide_item.swiper-slide {
  width: auto;
}
#featured_artists .slide_item:hover .slide_item_img_overlay {
  visibility: visible;
  opacity: 1;
}
#featured_artists .slide_item_img {
  position: relative;
  display: inline-block;
}
#featured_artists .img_box {
  display: inline-block;
  width: auto;
}
#featured_artists .img_box img {
  width: auto;
  height: 300px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
  vertical-align: bottom;
}
@media screen and (max-width: 840px) {
  #featured_artists .img_box img {
    width: 240px;
    height: 240px;
    object-fit: cover;
  }
}
#featured_artists .slide_item_img_overlay {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  width: 100%;
  background: linear-gradient(0deg, rgba(100, 100, 100, 0.6) 0%, rgba(60, 60, 60, 0) 100%);
  color: #fff;
  padding: 13px 14px 13px 18px;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
  transition-property: visibility, opacity;
  transition-duration: 0.25s;
}
@media screen and (max-width: 840px) {
  #featured_artists .slide_item_img_overlay {
    visibility: visible;
    opacity: 1;
  }
}
#featured_artists .slide_item_img_overlay .overlay_info {
  flex: 1;
}
#featured_artists .slide_item_img_overlay h3 {
  font-size: 1.4rem;
  line-height: 120%;
  letter-spacing: 0.05em;
  margin-bottom: 6px;
}
#featured_artists .slide_item_img_overlay .plice {
  font-family: Inter, sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 19px;
  letter-spacing: 0.05em;
}
#featured_artists .slide_item_text {
  display: flex;
  margin-top: 20px;
  padding: 0 5.842%;
  max-width: 300px;
}
#featured_artists .slide_item_text .artist_img {
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 100%;
}
#featured_artists .slide_item_text .artist_img img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit: cover;", "object-position: center center;";
}
#featured_artists .slide_item_text .artist_body {
  flex: 1;
  margin-left: 20px;
}
#featured_artists .slide_item_text .artist_body .artist_name {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #2C2E31;
  margin-bottom: 5px;
}
#featured_artists .slide_item_text .artist_body .artist_name a {
  color: #2C2E31;
}
#featured_artists .slide_item_text .artist_body .btn_follow {
  width: 100px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  color: #000000;
  vertical-align: middle;
  cursor: pointer;
}
#featured_artists .slide_item_text .artist_body .btn_follow span {
  margin-top: -0.25em;
  vertical-align: text-bottom;
}
#featured_artists .slide_item_text .artist_body .btn_follow.following {
  background-color: #000;
  color: #fff;
}
#featured_artists .slide_item_text .artist_body .btn_follow.following span {
  display: none;
}

/*------------------------------------*\
    HOT THEME 注目のテーマ
\*------------------------------------*/
.hot_theme {
  padding: 60px 0;
  background-color: #F7F7F7;
}
@media screen and (max-width: 840px) {
  .hot_theme {
    padding: 30px 0;
  }
}

.hot_theme_inner {
  max-width: 1128px;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 840px) {
  .hot_theme_inner {
    overflow: hidden;
  }
}
.hot_theme_inner + .hot_theme_inner {
  margin-top: 20px;
}
@media screen and (max-width: 840px) {
  .hot_theme_inner + .hot_theme_inner {
    margin-top: 10px;
  }
}
.hot_theme_inner .swiper-button-prev {
  left: auto;
  right: calc(100% + 8px);
  background-image: url(../../common/img/common/bt-left.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
  opacity: 0;
  visibility: hidden;
  transition-property: visibility, opacity;
  transition-delay: 0.5s;
  transition-duration: 0.5s;
}
.hot_theme_inner .swiper-button-prev::after {
  display: none;
}
.hot_theme_inner .swiper-button-next {
  right: auto;
  left: calc(100% + 8px);
  background-image: url(../../common/img/common/bt-right.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
  opacity: 0;
  visibility: hidden;
  transition-property: visibility, opacity;
  transition-delay: 0.5s;
  transition-duration: 0.5s;
}
.hot_theme_inner .swiper-button-next::after {
  display: none;
}
.hot_theme_inner .swiper-button-disabled {
  z-index: -1;
}

#hot_theme .theme_wrap {
  max-width: 1128px;
}
#hot_theme .theme_wrap + .theme_wrap {
  margin-top: 20px;
}
#hot_theme .theme_item {
  position: relative;
  width: 300px;
  height: 400px;
}
@media screen and (max-width: 840px) {
  #hot_theme .theme_item {
    width: 144px;
    height: 190px;
  }
}
#hot_theme .theme_item .theme_item_img {
  position: relative;
  width: 300px;
  height: 400px;
}
#hot_theme .theme_item .theme_item_img::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000;
  visibility: visible;
  opacity: 0.3;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  transition-property: visibility, opacity;
  transition-delay: 0.5s;
  transition-duration: 0.5s;
}
@media screen and (max-width: 840px) {
  #hot_theme .theme_item .theme_item_img {
    width: 144px;
    height: 190px;
  }
}
#hot_theme .theme_item .theme_item_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
#hot_theme .theme_item .theme_item_info {
  visibility: hidden;
  box-sizing: border-box;
  position: absolute;
  z-index: 9;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 40px 20px 18px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.52) 0%, rgba(0, 0, 0, 0) 105.33%);
  color: #fff;
  opacity: 0;
  transition-property: visibility, opacity;
  transition-delay: 0.5s;
  transition-duration: 0.5s;
}
@media screen and (max-width: 840px) {
  #hot_theme .theme_item .theme_item_info {
    padding: 15px 9px 10px;
  }
}
#hot_theme .theme_item .theme_item_info .artist_name {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 150%;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 840px) {
  #hot_theme .theme_item .theme_item_info .artist_name {
    font-size: 1.4rem;
  }
}
#hot_theme .theme_item .theme_item_info .art_title {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 140%;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 840px) {
  #hot_theme .theme_item .theme_item_info .art_title {
    font-size: 1.2rem;
  }
}
#hot_theme .theme_item .theme_item_info .art_plice {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 19px;
  margin-top: 6px;
  white-space: nowrap;
  font-family: Inter, sans-serif;
  font-weight: 600;
}
@media screen and (max-width: 840px) {
  #hot_theme .theme_item .theme_item_info .art_plice {
    font-size: 1.4rem;
    margin-top: 3px;
  }
}
#hot_theme .theme_item_first {
  padding-right: 30px;
  margin-right: 20px !important;
  display: flex;
  align-items: center;
  overflow: hidden;
}
@media screen and (max-width: 840px) {
  #hot_theme .theme_item_first {
    padding-right: 9px;
    width: 182px;
    height: 190px;
    padding-right: 9px;
    margin-right: 7px !important;
  }
}
@media screen and (max-width: 840px) {
  #hot_theme .theme_item_first .theme_item_img {
    width: 182px;
    height: 190px;
  }
}
#hot_theme .theme_item_first .theme_title_box {
  box-sizing: border-box;
  position: absolute;
  overflow: hidden;
  left: 30px;
  z-index: 9;
  width: 300px;
  padding: 30px;
}
@media screen and (max-width: 840px) {
  #hot_theme .theme_item_first .theme_title_box {
    left: 42px;
  }
}
#hot_theme .theme_item_first .theme_title_box::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 0;
  height: 100%;
  background-color: #fff;
  transition-property: width;
  transition-duration: 0.5s;
  transition-delay: 0.5s;
}
@media screen and (max-width: 840px) {
  #hot_theme .theme_item_first .theme_title_box {
    width: 148px;
    padding: 16px;
  }
}
#hot_theme .theme_item_first .theme_title_box .theme_title_box_wrap {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-delay: 0.5s;
}
#hot_theme .theme_item_first .theme_title {
  font-weight: 800;
  font-size: 2.4rem;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.05em;
  margin-bottom: 16px;
  color: #2C2E31;
}
@media screen and (max-width: 840px) {
  #hot_theme .theme_item_first .theme_title {
    font-size: 1.4rem;
  }
}
#hot_theme .theme_item_first .theme_description {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #2C2E31;
}
@media screen and (max-width: 840px) {
  #hot_theme .theme_item_first .theme_description {
    font-size: 1.1rem;
  }
}
#hot_theme .theme_item_first .view_all {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #000000;
  text-align: right;
  margin: 15px 10px 0 0;
  padding-right: 20px;
  background-image: url(../img/common/icon_viewmore.png);
  background-repeat: no-repeat;
  background-size: 13px 6px;
  background-position: right center;
}
@media screen and (max-width: 840px) {
  #hot_theme .theme_item_first .view_all {
    display: none;
  }
}
#hot_theme .inview .theme_item .theme_item_img::before {
  visibility: hidden;
  opacity: 0;
}
#hot_theme .inview .theme_item .theme_item_info {
  visibility: visible;
  opacity: 1;
}
#hot_theme .inview .theme_item_first .theme_title_box::after {
  width: 100%;
}
#hot_theme .inview .theme_item_first .theme_title_box .theme_title_box_wrap {
  opacity: 1;
}
#hot_theme .inview .swiper-button-next, #hot_theme .inview .swiper-button-prev {
  opacity: 1;
  visibility: visible;
}

/*------------------------------------*\
    PICK UP ピックアップ
\*------------------------------------*/
.pickup .pickup_inner {
  max-width: 1128px;
  margin: 0 auto;
  padding: 60px 0;
}
@media screen and (max-width: 840px) {
  .pickup .pickup_inner {
    padding: 40px 16px;
  }
}
.pickup .pickup_wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 0 -24px;
}
@media screen and (max-width: 840px) {
  .pickup .pickup_wrap {
    margin: 10px 0 0 -7px;
  }
}
.pickup .pickup_item {
  position: relative;
  width: calc(25% - 24px);
  margin-left: 24px;
}
@media screen and (max-width: 840px) {
  .pickup .pickup_item {
    width: calc(50% - 7px);
    margin-left: 7px;
  }
}
.pickup .pickup_item:hover .pickup_item_img_wrap::after {
  display: block;
  visibility: visible;
  opacity: 1;
}
@media screen and (max-width: 840px) {
  .pickup .pickup_item:hover .pickup_item_img_wrap::after {
    visibility: hidden;
  }
}
.pickup .pickup_item:hover .pickup_item_img_wrap .overlay_like {
  visibility: visible;
  opacity: 1;
}
@media screen and (max-width: 840px) {
  .pickup .pickup_item:hover .pickup_item_img_wrap .overlay_like {
    visibility: hidden;
  }
}
.pickup .pickup_item:not(:nth-last-child(-n+4)) {
  margin-bottom: 24px;
}
.pickup .pickup_item:not(:nth-last-child(-n+2)) {
  margin-bottom: 24px;
}
.pickup .pickup_item .oil_only {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  display: inline-block;
  padding: 4px 18px;
  background: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #fff;
}
@media screen and (max-width: 840px) {
  .pickup .pickup_item .oil_only {
    font-size: 1.2rem;
  }
}
.pickup .pickup_item .pickup_item_img {
  position: relative;
}
.pickup .pickup_item .pickup_item_img_wrap {
  position: relative;
  width: 100%;
}
.pickup .pickup_item .pickup_item_img_wrap:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.pickup .pickup_item .pickup_item_img_wrap .inner_img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.pickup .pickup_item .pickup_item_img_wrap img, .pickup .pickup_item .pickup_item_img_wrap picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
  vertical-align: bottom;
}
.pickup .pickup_item .pickup_item_img_wrap::after {
  display: none;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
}
.pickup .pickup_item .pickup_item_img_wrap .overlay_like {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 3;
}
.pickup .pickup_item .pickup_item_img_wrap .overlay_like.bt_hart_on {
  visibility: visible;
  opacity: 1;
}
@media screen and (max-width: 840px) {
  .pickup .pickup_item .pickup_item_img_wrap .overlay_like.bt_hart_on {
    visibility: visible;
    opacity: 1;
  }
}
.pickup .pickup_item .pickup_item_body {
  margin-top: 8px;
}
@media screen and (max-width: 840px) {
  .pickup .pickup_item .pickup_item_body {
    margin-top: 6px;
    display: block;
  }
}
.pickup .pickup_item .pickup_item_body .art_info {
  flex: 1 1 auto;
}
.pickup .pickup_item .pickup_item_body .art_info .art_info_name {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #2C2E31;
  margin-bottom: 2px;
}
.pickup .pickup_item .pickup_item_body .art_info .art_info_title {
  font-size: 1.2rem;
  line-height: 140%;
  text-align: left;
  letter-spacing: 0.05em;
  color: #2C2E31;
}
.pickup .pickup_item .pickup_item_body .art_info .art_info_date {
  font-size: 1.1rem;
  line-height: 120%;
  text-align: justify;
  letter-spacing: 0.05em;
  color: #666666;
  margin-top: 4px;
}
.pickup .pickup_item .pickup_item_body .art_info .art_info_date span + span::before {
  content: " | ";
}
@media screen and (max-width: 840px) {
  .pickup .pickup_item .pickup_item_body .art_info .art_info_date span + span::before {
    content: "";
  }
}
@media screen and (max-width: 840px) {
  .pickup .pickup_item .pickup_item_body .art_info .art_info_date {
    margin-top: 4px;
  }
  .pickup .pickup_item .pickup_item_body .art_info .art_info_date .production_year {
    display: none;
  }
}
.pickup .pickup_item .pickup_item_body .art_info .art_info_category {
  flex-grow: 1;
  font-size: 1.1rem;
  line-height: 120%;
  text-align: justify;
  letter-spacing: 0.05em;
  color: #666666;
  margin-top: 4px;
}
@media screen and (max-width: 840px) {
  .pickup .pickup_item .pickup_item_body .art_info .art_info_category {
    margin-top: 4px;
  }
}
@media screen and (max-width: 840px) {
  .pickup .pickup_item .pickup_item_body .art_info .art_info_foot {
    display: block;
    margin-top: 3px;
  }
}
.pickup .pickup_item .pickup_item_body .art_plice {
  align-self: flex-end;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 19px;
  color: #2C2E31;
  margin-top: 10px;
  white-space: nowrap;
  font-family: Inter, sans-serif;
  font-weight: 600;
  flex-grow: 1;
}

/*------------------------------------*\
    FEATURED CATEGORY 注目のカテゴリ
\*------------------------------------*/
.featured_category {
  background-color: #F7F7F7;
  padding: 30px 0;
}
.featured_category .featured_category_inner {
  max-width: 1128px;
  margin: 0 auto;
}
@media screen and (max-width: 840px) {
  .featured_category .featured_category_inner .index_section_title_gold {
    padding-left: 16px;
  }
}
.featured_category .featured_category_container {
  margin-top: 16px;
}
.featured_category .category_item {
  width: 200px;
}
@media screen and (max-width: 840px) {
  .featured_category .category_item {
    width: 125px;
  }
  .featured_category .category_item:first-child {
    padding-left: 16px;
  }
  .featured_category .category_item:last-child {
    padding-right: 16px;
  }
}
.featured_category .category_item:hover .category_item_img .category_item_img_inner .overlay {
  visibility: visible;
  opacity: 1;
}
.featured_category .category_item .category_item_img {
  box-sizing: border-box;
  width: 200px;
  height: 124px;
  border: 4px solid #FFFFFF;
}
@media screen and (max-width: 840px) {
  .featured_category .category_item .category_item_img {
    width: 125px;
    height: 87px;
  }
}
.featured_category .category_item .category_item_img .category_item_img_inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.featured_category .category_item .category_item_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
.featured_category .category_item .category_item_img .overlay {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  transition-property: visibility, opacity;
  transition-duration: 0.25s;
}
.featured_category .category_item .category_item_text {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #2C2E31;
  margin-top: 10px;
}

/*------------------------------------*\
    NEW ARTWORKS 新着作品
\*------------------------------------*/
.products {
  margin: 60px 0;
}
@media screen and (max-width: 840px) {
  .products {
    margin: 40px 0;
  }
}
.products .products_inner {
  max-width: 1128px;
  margin: 0 auto;
}
@media screen and (max-width: 840px) {
  .products .products_inner {
    padding: 0 16px;
  }
}
.products .products_wrap {
  margin: 16px 0 0 0;
}
@media screen and (max-width: 840px) {
  .products .products_wrap {
    margin: 10px 0 0 0;
  }
}
.products .products_item {
  position: relative;
  width: 240px;
}
@media screen and (max-width: 840px) {
  .products .products_item {
    width: 200px;
  }
}
.products .products_item:hover .products_item_img_wrap::after {
  display: block;
  visibility: visible;
  opacity: 1;
}
@media screen and (max-width: 840px) {
  .products .products_item:hover .products_item_img_wrap::after {
    visibility: hidden;
  }
}
.products .products_item:hover .products_item_img_wrap .overlay_like {
  visibility: visible;
  opacity: 1;
}
@media screen and (max-width: 840px) {
  .products .products_item:hover .products_item_img_wrap .overlay_like {
    visibility: hidden;
  }
}
.products .products_item .oil_only {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  display: inline-block;
  padding: 4px 18px;
  background: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #fff;
}
@media screen and (max-width: 840px) {
  .products .products_item .oil_only {
    font-size: 1.2rem;
  }
}
.products .products_item .products_item_img {
  position: relative;
}
.products .products_item .products_item_img_wrap {
  position: relative;
  width: 100%;
}
.products .products_item .products_item_img_wrap:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.products .products_item .products_item_img_wrap .inner_img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.products .products_item .products_item_img_wrap img, .products .products_item .products_item_img_wrap picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
  vertical-align: bottom;
}
.products .products_item .products_item_img_wrap::after {
  display: none;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.15);
}
.products .products_item .products_item_img_wrap .overlay_like {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 3;
}
.products .products_item .products_item_img_wrap .overlay_like.bt_hart_on {
  visibility: visible;
  opacity: 1;
}
.products .products_item .products_item_body {
  margin-top: 8px;
  display: flex;
}
@media screen and (max-width: 840px) {
  .products .products_item .products_item_body {
    margin-top: 6px;
    display: block;
  }
}
.products .products_item .products_item_body .art_info {
  flex: 1 1 auto;
}
.products .products_item .products_item_body .art_info .art_info_name {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #2C2E31;
  margin-bottom: 2px;
}
.products .products_item .products_item_body .art_info .art_info_title {
  font-size: 1.2rem;
  line-height: 1.4em;
  text-align: left;
  letter-spacing: 0.05em;
  color: #2C2E31;
}
.products .products_item .products_item_body .art_info .art_info_date {
  font-size: 1.1rem;
  line-height: 120%;
  text-align: justify;
  letter-spacing: 0.05em;
  color: #666666;
  margin-top: 4px;
}
.products .products_item .products_item_body .art_info .art_info_date span + span::before {
  content: " | ";
}
@media screen and (max-width: 840px) {
  .products .products_item .products_item_body .art_info .art_info_date span + span::before {
    content: "";
  }
}
@media screen and (max-width: 840px) {
  .products .products_item .products_item_body .art_info .art_info_date span + span {
    margin-top: 4px;
  }
}
@media screen and (max-width: 840px) {
  .products .products_item .products_item_body .art_info .art_info_date {
    margin-top: 4px;
    color: #999;
  }
  .products .products_item .products_item_body .art_info .art_info_date span {
    display: block;
  }
}
@media screen and (max-width: 840px) {
  .products .products_item .products_item_body .art_info .art_info_date .production_year {
    display: none;
  }
}
.products .products_item .products_item_body .art_info .art_info_category {
  flex-grow: 1;
  font-size: 1.1rem;
  line-height: 1;
  text-align: justify;
  letter-spacing: 0.05em;
  color: #666666;
  margin-top: 4px;
}
@media screen and (max-width: 840px) {
  .products .products_item .products_item_body .art_info .art_info_category {
    margin-top: 4px;
    color: #999;
  }
}
@media screen and (max-width: 840px) {
  .products .products_item .products_item_body .art_info .art_info_foot {
    display: block;
    margin-top: 3px;
  }
}
.products .products_item .products_item_body .art_plice {
  align-self: flex-end;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 19px;
  color: #2C2E31;
  margin-top: 10px;
  white-space: nowrap;
  font-family: Inter, sans-serif;
  font-weight: 600;
  flex-grow: 1;
}

.sp_more_btn, .sp_more_btn_w {
  display: none;
}
@media screen and (max-width: 840px) {
  .sp_more_btn, .sp_more_btn_w {
    display: block;
  }
}
.sp_more_btn a, .sp_more_btn_w a {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.05em;
  color: #2C2E31;
  background-color: #f6f6f6;
  margin-top: 30px;
}
.sp_more_btn a span, .sp_more_btn_w a span {
  display: inline-block;
  white-space: nowrap;
  padding-right: 10px;
  background-image: url(../img/common/right-arrow-black.png);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 4px 7px;
}

.sp_more_btn_w {
  padding: 0 16px;
}
.sp_more_btn_w a {
  color: #C2A47E;
  background-color: #fff;
}
.sp_more_btn_w a span {
  background-image: url(../img/common/icon_more_link.png);
}

/*------------------------------------*\
    SPECIAL 記事一覧
\*------------------------------------*/
.special {
  background-color: #F7F7F7;
  padding: 30px 0;
}
.special .special_inner {
  max-width: 1128px;
  margin: 0 auto;
}
@media screen and (max-width: 840px) {
  .special .special_inner .index_section_title_gold {
    padding-left: 16px;
  }
}
.special .special_container {
  margin-top: 16px;
}
.special .special_item {
  width: 240px;
}
@media screen and (max-width: 840px) {
  .special .special_item:first-child {
    padding-left: 16px;
  }
  .special .special_item:last-child {
    padding-right: 16px;
  }
}
.special .special_item:hover .category_item_img .category_item_img_inner .overlay {
  visibility: visible;
  opacity: 1;
}
.special .special_item .special_item_img {
  box-sizing: border-box;
  width: 240px;
  height: 124px;
  border: 4px solid #FFFFFF;
}
.special .special_item .special_item_img .special_item_img_inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.special .special_item .special_item_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
.special .special_item .special_item_img .overlay {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  transition-property: visibility, opacity;
  transition-duration: 0.25s;
}
.special .special_item .special_item_text {
  margin-top: 10px;
}
.special .special_item .special_item_text h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #2C2E31;
}
.special .special_item .special_item_text .special_item_foot {
  display: flex;
  margin-top: 20px;
}
.special .special_item .special_item_text .special_item_foot .date {
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #999999;
  margin-right: 12px;
}
.special .special_item .special_item_text .special_item_foot .date::after {
  display: block;
  content: "";
  width: 1px;
  height: 11px;
  background-color: #999;
  margin-left: 12px;
}
.special .special_item .special_item_text .special_item_foot .category {
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #999999;
}

/*------------------------------------*\
    BANNER
\*------------------------------------*/
.banner_area {
  max-width: 1128px;
  margin: 60px auto 0;
}
@media screen and (max-width: 840px) {
  .banner_area {
    padding: 0 16px 0;
    margin: 0;
  }
}
.banner_area .banner_area_inner {
  display: flex;
  margin-left: -15px;
}
@media screen and (max-width: 840px) {
  .banner_area .banner_area_inner {
    flex-direction: column;
    margin: 0;
  }
}
.banner_area .banner_area_inner a {
  width: calc(50% - 15px);
  margin-left: 15px;
  transition-property: opacity;
  transition-duration: 0.25s;
}
@media screen and (max-width: 840px) {
  .banner_area .banner_area_inner a {
    width: 100%;
    margin: 0;
  }
  .banner_area .banner_area_inner a:not(:last-child) {
    margin-bottom: 16px;
  }
}
.banner_area .banner_area_inner a:hover {
  opacity: 0.7;
}
.banner_area .banner_area_inner a img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

/*------------------------------------*\
    GALLERIES / STORES ギャラリー・ストア
\*------------------------------------*/
.galleries_stores {
  background-color: #f7f7f7;
  margin: 60px 0 0 0;
  padding: 30px 0 45px;
}
@media screen and (max-width: 840px) {
  .galleries_stores {
    padding: 30px 16px 30px;
  }
}
.galleries_stores .galleries_stores_inner {
  max-width: 1128px;
  margin: 0 auto;
}
.galleries_stores .galleries_stores_wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 0 -24px;
}
@media screen and (max-width: 840px) {
  .galleries_stores .galleries_stores_wrap {
    margin: 20px 0 0 -7px;
  }
}
.galleries_stores .galleries_stores_wrap .galleries_stores_item {
  width: calc(25% - 24px);
  margin-left: 24px;
  transition-property: opacity;
  transition-duration: 0.25s;
}
.galleries_stores .galleries_stores_wrap .galleries_stores_item:hover {
  opacity: 0.7;
}
@media screen and (max-width: 840px) {
  .galleries_stores .galleries_stores_wrap .galleries_stores_item {
    width: calc(50% - 7px);
    margin: 0 0 0 7px;
  }
}
.galleries_stores .galleries_stores_wrap .galleries_stores_item:not(:nth-last-child(-n+4)) {
  margin-bottom: 45px;
}
@media screen and (max-width: 840px) {
  .galleries_stores .galleries_stores_wrap .galleries_stores_item:not(:nth-last-child(-n+4)) {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 840px) {
  .galleries_stores .galleries_stores_wrap .galleries_stores_item:not(:nth-last-child(-n+2)) {
    margin-bottom: 24px;
  }
}
.galleries_stores .galleries_stores_wrap .galleries_stores_item .galleries_stores_item_img_wrap {
  position: relative;
  width: 100%;
}
@media screen and (max-width: 840px) {
  .galleries_stores .galleries_stores_wrap .galleries_stores_item .galleries_stores_item_img_wrap {
    display: none;
  }
}
.galleries_stores .galleries_stores_wrap .galleries_stores_item .galleries_stores_item_img_wrap:before {
  content: "";
  display: block;
  padding-top: 52.2727%;
}
.galleries_stores .galleries_stores_wrap .galleries_stores_item .galleries_stores_item_img_wrap .inner_img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.galleries_stores .galleries_stores_wrap .galleries_stores_item .galleries_stores_item_img_wrap img, .galleries_stores .galleries_stores_wrap .galleries_stores_item .galleries_stores_item_img_wrap picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
  vertical-align: bottom;
}
.galleries_stores .galleries_stores_wrap .galleries_stores_item .name {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #2C2E31;
  margin-top: 14px;
}
@media screen and (max-width: 840px) {
  .galleries_stores .galleries_stores_wrap .galleries_stores_item .name {
    display: flex;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    text-align: left;
  }
  .galleries_stores .galleries_stores_wrap .galleries_stores_item .name::before {
    content: "";
    width: 0.5em;
    height: 1px;
    margin: 0.5em 0.5em 0 0;
    background-color: #2C2E31;
  }
}
.galleries_stores .galleries_stores_wrap .galleries_stores_item .place {
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #2C2E31;
  margin-top: 10px;
}
@media screen and (max-width: 840px) {
  .galleries_stores .galleries_stores_wrap .galleries_stores_item .place {
    display: none;
  }
}
.galleries_stores .sp_more_btn_w {
  padding: 0;
}

/*------------------------------------*\
    .airtworks
\*------------------------------------*/
.artworks {
  max-width: 1128px;
  margin: 0 auto 48px;
}
@media screen and (max-width: 840px) {
  .artworks {
    padding: 0 16px;
  }
}
.artworks_head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.artworks_head .go_list {
  margin-left: auto;
}
.artworks_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1px;
}
.artworks .go_list_btn a {
  transition-property: opacity;
  transition-duration: 0.25s;
}
.artworks .go_list_btn a:hover {
  opacity: 0.7;
}
.artworks_item {
  position: relative;
  display: block;
  width: calc(25% - 1px);
  margin-left: 1px;
  background-color: #fff;
  margin-bottom: 1px;
}
@media screen and (max-width: 840px) {
  .artworks_item {
    width: calc(50% - 1px);
  }
}
.artworks_item:hover .artworks_item_img {
  position: relative;
}
.artworks_item:hover .artworks_item_img img {
  transform: scale(1.2);
}
.artworks_item:hover .artworks_item_img:after {
  background-color: rgba(0, 0, 0, 0.3);
  transition-property: background-color;
  transition-duration: 0.4s;
}
.artworks_item a {
  display: block;
  padding: 24px;
}
@media screen and (max-width: 840px) {
  .artworks_item a {
    padding: 8px 8px 18px;
  }
}
.artworks_item_img {
  position: relative;
  overflow: hidden;
  background-color: #fbfbfb;
}
.artworks_item_img:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-property: background-color;
  transition-duration: 0.4s;
  background-color: transparent;
}
.artworks_item_img .oil_only {
  position: absolute;
  left: 0;
  top: 0;
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 1.2px;
  color: #fff;
  text-align: center;
  line-height: 28px;
  width: 68px;
  height: 28px;
  background-color: #4e7192;
  z-index: 9;
}
.artworks_item_img img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: transform 0.2s linear;
}
.artworks_item_text {
  position: relative;
  margin-top: 16px;
  padding-top: 16px;
  border-top: solid 1px #ebedf0;
}
@media screen and (max-width: 840px) {
  .artworks_item_text {
    margin-top: 8px;
    padding-top: 8px;
  }
}
.artworks_item_text h3 {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  color: #2c2e31;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 840px) {
  .artworks_item_text h3 {
    font-size: 1.2rem;
    letter-spacing: 0.6px;
  }
}
.artworks_item_text .art_title {
  font-family: Roboto;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707980;
  margin-top: 6px;
  margin-bottom: 4px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 840px) {
  .artworks_item_text .art_title {
    font-size: 1.1rem;
    margin-top: 4px;
  }
}
.artworks_item_text .art_year {
  font-family: Roboto;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707980;
}
.artworks_item_text .art_plice {
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
  text-align: center;
  margin-top: 16px;
  white-space: nowrap;
}
@media screen and (max-width: 840px) {
  .artworks_item_text .art_plice {
    font-size: 1.2rem;
    letter-spacing: 0.6px;
    margin-top: 8px;
  }
}
.artworks_item .add_like {
  position: absolute;
  right: 24px;
  bottom: 24px;
  line-height: 1;
}
@media screen and (max-width: 840px) {
  .artworks_item .add_like {
    right: 8px;
    bottom: 18px;
  }
}
.artworks_cat {
  padding: 24px;
  background-color: #fff;
}
@media screen and (max-width: 840px) {
  .artworks_cat {
    padding: 16px;
  }
}
.artworks_cat_inner ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
}
@media screen and (max-width: 840px) {
  .artworks_cat_inner ul {
    margin-left: -7px;
  }
}
.artworks_cat_inner ul li {
  width: calc(25% - 24px);
  margin-left: 24px;
  border: solid 2px #ebedf0;
  height: 60px;
  margin-bottom: 16px;
  transition: border 0.2s linear;
}
.artworks_cat_inner ul li:hover {
  border: solid 2px #bfcbd5;
}
@media screen and (max-width: 840px) {
  .artworks_cat_inner ul li {
    width: calc(50% - 7px);
    margin-left: 7px;
    margin-bottom: 8px;
    padding: 8px 0;
  }
}
.artworks_cat_inner ul li:nth-last-child(-n+4) {
  margin-bottom: 0;
}
@media screen and (max-width: 840px) {
  .artworks_cat_inner ul li:nth-last-child(-n+4) {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 840px) {
  .artworks_cat_inner ul li:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
.artworks_cat_inner ul li a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.artworks_cat_inner ul li p {
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.7px;
  text-align: center;
  color: #2c2e31;
}
.artworks_cat_inner ul li p span {
  display: block;
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
  text-align: center;
  color: #707980;
}
@media screen and (max-width: 840px) {
  .artworks_cat_inner ul li p span {
    letter-spacing: 0.7px;
  }
}

.sp_go_list {
  display: none;
  padding: 11px 0;
  background-color: #fff;
  margin-top: 1px;
  text-align: center;
  border: solid 1px #ebedf0;
  margin-top: 24px;
  cursor: pointer;
}
@media screen and (max-width: 840px) {
  .sp_go_list {
    display: block;
  }
}
.sp_go_list span {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #2c2e31;
  background-image: url(/common/img/common/bt-link.png);
  background-position: right center;
  background-size: 14px 10px;
  background-repeat: no-repeat;
  padding-right: 22px;
}

.go_list_btn {
  width: 100%;
  line-height: 40px;
  text-align: right;
}
@media screen and (max-width: 840px) {
  .go_list_btn {
    text-align: center;
  }
}
.go_list_btn a {
  display: inline-block;
  height: 40px;
  padding: 0 24px;
}
@media screen and (max-width: 840px) {
  .go_list_btn a {
    width: 100%;
  }
}
.go_list_btn span {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #2c2e31;
  background-image: url(/common/img/common/bt-link.png);
  background-position: right center;
  background-size: 14px 10px;
  background-repeat: no-repeat;
  padding-right: 22px;
}

.sp_go_list_btn {
  display: none;
  width: 100%;
  height: 40px;
  margin-top: 24px;
  line-height: 40px;
  text-align: center;
  border: solid 1px #ebedf0;
}
.sp_go_list_btn span {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .sp_go_list_btn {
    display: block;
  }
}

/*------------------------------------*\
    .goods
\*------------------------------------*/
.goods {
  max-width: 1128px;
  margin: 0 auto 48px;
}
@media screen and (max-width: 840px) {
  .goods {
    padding: 0 16px;
  }
}
.goods_head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.goods_head .go_list {
  margin-left: auto;
}
.goods_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1px;
}
.goods .go_list_btn a {
  transition-property: opacity;
  transition-duration: 0.25s;
}
.goods .go_list_btn a:hover {
  opacity: 0.7;
}
.goods_item {
  position: relative;
  display: block;
  width: calc(16.6666% - 1px);
  margin-left: 1px;
  background-color: #fff;
  margin-bottom: 1px;
}
@media screen and (max-width: 840px) {
  .goods_item {
    width: calc(50% - 1px);
  }
}
.goods_item:hover .goods_item_img:after {
  background-color: rgba(0, 0, 0, 0.3);
  transition-property: background-color;
  transition-duration: 0.4s;
}
.goods_item:hover .goods_item_img img {
  transform: scale(1.2);
}
.goods_item a {
  display: block;
  padding: 16px;
}
@media screen and (max-width: 840px) {
  .goods_item a {
    padding: 8px 8px 18px;
  }
}
.goods_item_img {
  position: relative;
  overflow: hidden;
  background-color: #fbfbfb;
}
.goods_item_img:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-property: background-color;
  transition-duration: 0.4s;
  background-color: transparent;
}
.goods_item_img .oil_only {
  position: absolute;
  left: 0;
  top: 0;
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 1.2px;
  color: #fff;
  text-align: center;
  line-height: 28px;
  width: 68px;
  height: 28px;
  background-color: #4e7192;
  z-index: 9;
}
.goods_item_img img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: transform 0.2s linear;
}
.goods_item_text {
  position: relative;
  margin-top: 8px;
}
.goods_item_text .artist_names {
  position: relative;
  font-size: 1.3rem;
  font-family: HiraginoSans-W4;
  line-height: 1.54;
  letter-spacing: 0.7px;
  color: #2c2e31;
  margin-bottom: 8px;
  text-align: left;
}
.goods_item_text h3 {
  position: relative;
  font-size: 1.3rem;
  font-family: HiraginoSans-W4;
  line-height: 1.54;
  letter-spacing: 0.7px;
  color: #2c2e31;
  margin-bottom: 12px;
  text-align: left;
}
.goods_item_text .goods_plice {
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
}
.goods_item .add_like {
  position: absolute;
  right: 16px;
  bottom: 16px;
  line-height: 1;
}

.ellip {
  display: block;
  height: 100%;
}

.ellip-line {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  max-width: 100%;
}

.ellip,
.ellip-line {
  position: relative;
  overflow: hidden;
}

/*------------------------------------*\
    .banners
\*------------------------------------*/
.banners {
  max-width: 1128px;
  margin: 0 auto 48px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 840px) {
  .banners {
    padding: 0 16px;
    display: block;
    justify-content: unset;
  }
}
.banners a {
  display: block;
}
.banners img {
  width: 556px;
  height: auto;
  vertical-align: top;
}
@media screen and (max-width: 840px) {
  .banners img {
    width: 100%;
    margin-bottom: 10px;
  }
}

/*------------------------------------*\
    .magazine
\*------------------------------------*/
.section_wrap {
  background-color: #fff;
  padding: 48px 0;
  border-bottom: solid 1px #f1f2f4;
}
@media screen and (max-width: 840px) {
  .section_wrap {
    padding: 24px 16px;
  }
}

.magazine {
  max-width: 1128px;
  margin: 0 auto;
}
@media screen and (max-width: 840px) {
  .magazine {
    margin-bottom: 25px;
  }
}
.magazine_head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.magazine_head .go_list {
  margin-left: auto;
}
.magazine_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
}
@media screen and (max-width: 840px) {
  .magazine_inner {
    margin-left: 0;
  }
}
.magazine_inner + .go_list_btn {
  margin-top: 24px;
}
@media screen and (max-width: 840px) {
  .magazine_inner + .go_list_btn a {
    border: solid 1px #ebedf0;
  }
}
.magazine .go_list_btn a {
  transition-property: opacity;
  transition-duration: 0.4s;
}
.magazine .go_list_btn a:hover {
  opacity: 0.7;
}
.magazine_item {
  position: relative;
  width: calc(33.333% - 24px);
  display: block;
  margin-left: 24px;
  margin-bottom: 45px;
}
@media screen and (max-width: 840px) {
  .magazine_item {
    width: 100%;
    margin-left: 0;
  }
  .magazine_item:not(:last-child) {
    margin-bottom: 24px;
  }
}
.magazine_item:nth-last-child(-n+3) {
  margin-bottom: 0;
}
@media screen and (max-width: 840px) {
  .magazine_item:nth-last-child(-n+3) {
    margin-bottom: 24px;
  }
  .magazine_item:nth-last-child(-n+3):last-child {
    margin-bottom: 0;
  }
}
.magazine_item:hover .magazine_item_img:after {
  background-color: rgba(0, 0, 0, 0.3);
  transition-property: background-color;
  transition-duration: 0.4s;
}
.magazine_item:hover .magazine_item_img img {
  transform: scale(1.2);
}
.magazine_item a {
  display: block;
}
.magazine_item_img {
  max-width: 359px;
  max-height: 202px;
  position: relative;
  overflow: hidden;
  background-color: #fbfbfb;
}
.magazine_item_img:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-property: background-color;
  transition-duration: 0.4s;
  background-color: transparent;
}
.magazine_item_img img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: transform 0.2s linear;
}
.magazine_item_text {
  position: relative;
  margin-top: 8px;
}
.magazine_item_text h3 {
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.31;
  letter-spacing: 0.9px;
  color: #2c2e31;
  text-align: left;
  word-break: break-all;
}
@media screen and (max-width: 840px) {
  .magazine_item_text h3 {
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: 0.8px;
  }
}
.magazine_item_text .magazine_info {
  font-family: Roboto;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e31;
  margin-top: 12px;
}
@media screen and (max-width: 840px) {
  .magazine_item_text .magazine_info {
    margin-top: 8px;
  }
}

/*------------------------------------*\
    .artist
\*------------------------------------*/
.artist {
  max-width: 1128px;
  margin: 32px auto 32px;
  padding-bottom: 32px;
  border-bottom: solid 1px #f1f2f4;
  padding-top: 32px;
  border-top: solid 1px #f1f2f4;
}
@media screen and (max-width: 840px) {
  .artist {
    margin-bottom: 25px;
  }
}
.artist_head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.artist_head .go_list {
  margin-left: auto;
}
.artist ul {
  display: flex;
  flex-wrap: wrap;
}
.artist ul li {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
  width: 20%;
  text-indent: -0.8em;
  padding-left: calc(0.8em + 24px);
  transition-property: color;
  transition-duration: 0.25s;
}
@media screen and (max-width: 840px) {
  .artist ul li {
    width: 50%;
    padding-left: calc(0.8em + 16px);
  }
  .artist ul li:nth-child(odd) {
    padding-left: 0.8em;
  }
}
.artist ul li a {
  color: #2c2e31;
}
.artist ul li a:hover {
  text-decoration: underline;
}
.artist ul li:not(:last-child) {
  margin-bottom: 18px;
}
.artist ul li:before {
  content: "-";
  padding-right: 0.5em;
}

/*------------------------------------*\
    .galleries
\*------------------------------------*/
.galleries {
  max-width: 1128px;
  margin: 0 auto;
}
.galleries_head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.galleries_head .go_list {
  margin-left: auto;
}
@media screen and (max-width: 840px) {
  .galleries_head .section_title span {
    display: block;
    margin-left: 0;
    margin-top: 8px;
  }
}
.galleries_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
}
@media screen and (max-width: 840px) {
  .galleries_inner {
    margin-left: -18px;
  }
}
.galleries_item {
  position: relative;
  display: block;
  width: calc(25% - 24px);
  margin-left: 24px;
  margin-bottom: 32px;
  background-color: #fff;
}
@media screen and (max-width: 840px) {
  .galleries_item {
    width: calc(50% - 18px);
    margin-bottom: 25px;
    margin-left: 18px;
  }
}
.galleries_item:nth-last-child(-n+4) {
  margin-bottom: 0;
}
@media screen and (max-width: 840px) {
  .galleries_item:nth-last-child(-n+4) {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 840px) {
  .galleries_item:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
.galleries_item:hover .galleries_item_img img {
  transform: scale(1.2);
}
.galleries_item a {
  display: block;
}
.galleries_item_img {
  position: relative;
  overflow: hidden;
  background-color: #fbfbfb;
}
.galleries_item_img .oil_only {
  position: absolute;
  left: 0;
  top: 0;
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 1.2px;
  color: #fff;
  text-align: center;
  line-height: 28px;
  width: 68px;
  height: 28px;
  background-color: #4e7192;
  z-index: 9;
}
.galleries_item_img img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: transform 0.2s linear;
}
.galleries_item_text {
  position: relative;
  margin-top: 8px;
}
@media screen and (max-width: 840px) {
  .galleries_item_text {
    margin-top: 5px;
  }
}
.galleries_item_text h3 {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .galleries_item_text h3 {
    font-size: 1.2rem;
    line-height: 1.33;
    letter-spacing: 0.6px;
  }
}
.galleries_item_text .galleries_place {
  font-family: HiraMinProN-W3;
  font-size: 1.1rem;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #2c2e31;
  margin-top: 5px;
}
@media screen and (max-width: 840px) {
  .galleries_item_text .galleries_place {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 840px) {
  .might_component .section_title span {
    display: block;
    margin-left: 0;
  }
}

/* ***** ***** *****
** トップスライダー
***** ***** ***** */
@keyframes OverlaySlide {
  0% {
    visibility: hidden;
    opacity: 0;
    right: -100%;
  }
  50% {
    visibility: visible;
    opacity: 1;
    right: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
    right: 0;
  }
}
.index_slider {
  width: 100%;
  max-width: 1250px;
  padding: 0 40px;
  margin: 0 auto;
  height: 360px;
  margin-bottom: 15px;
}
@media screen and (max-width: 840px) {
  .index_slider {
    background: rgba(250, 250, 250, 0.9);
    height: auto;
    overflow: auto;
    padding: 0;
  }
}
.index_slider.slick-dotted.slick-slider {
  margin-bottom: 15px;
}
.index_slider + .featured_artists {
  padding-top: 0;
}
.index_slider .slider_item {
  position: relative;
  width: 100%;
  height: 360px;
}
@media screen and (max-width: 840px) {
  .index_slider .slider_item {
    height: auto;
  }
}
.index_slider .slider_item a {
  width: 100%;
  height: 100%;
  display: flex;
}
@media screen and (max-width: 840px) {
  .index_slider .slider_item a {
    flex-direction: column;
  }
}
.index_slider .slider_item picture, .index_slider .slider_item img {
  width: 100%;
  height: 100%;
  aspect-ratio: 7/4;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
@media screen and (max-width: 840px) {
  .index_slider .slider_item picture, .index_slider .slider_item img {
    height: 57.1vw;
  }
}
.index_slider .slider_item_overlay {
  max-width: 565px;
  background: rgba(250, 250, 250, 0.9);
  padding: 0 58px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 840px) {
  .index_slider .slider_item_overlay {
    position: relative;
    visibility: visible;
    opacity: 1;
    right: 0;
    padding: 20px 16px;
  }
}
.index_slider .slider_item_overlay .datetime {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 14px;
  letter-spacing: 0.012em;
  color: #2C2E31;
  margin-bottom: 1em;
}
@media screen and (max-width: 840px) {
  .index_slider .slider_item_overlay .datetime {
    margin-bottom: 9px;
  }
}
.index_slider .slider_item_overlay h2 {
  font-family: Hiragino Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 138%;
  letter-spacing: 0.05em;
  color: #2C2E31;
  margin-bottom: 1em;
}
@media screen and (max-width: 840px) {
  .index_slider .slider_item_overlay h2 {
    font-size: 1.8rem;
    margin-bottom: 0;
  }
}
.index_slider .slider_item_overlay .read_sentence {
  font-family: Hiragino Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 170%;
  color: #2C2E31;
}
@media screen and (max-width: 840px) {
  .index_slider .slider_item_overlay .read_sentence {
    display: none;
  }
}
@media screen and (max-width: 840px) {
  .index_slider .slick-current .slider_item_overlay {
    width: 100%;
    animation: none;
    visibility: visible;
    opacity: 1;
    right: 0;
  }
}
.index_slider .slick-dots {
  bottom: 14px;
}
.index_slider .slick-dots li {
  margin: 0;
}
.index_slider .slick-dots button:before {
  color: #fff;
  opacity: 1;
}
.index_slider .slick-dots .slick-active {
  opacity: 1;
}
.index_slider .slick-dots .slick-active button:before {
  color: #000;
  opacity: 1;
}
.index_slider .slick-arrow {
  position: absolute;
  z-index: 99;
  right: auto;
  left: auto;
  margin-top: -4px;
  width: 40px;
  height: 80px;
}
.index_slider .slick-arrow:before {
  display: none;
}
@media screen and (max-width: 840px) {
  .index_slider .slick-arrow {
    width: 30px;
    height: 100%;
    background-color: transparent;
  }
}
.index_slider .slick-prev {
  position: absolute;
  left: 0;
  margin-right: 380px;
  background-image: url(../../common/img/common/bt-left.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
}
@media screen and (max-width: 840px) {
  .index_slider .slick-prev {
    left: 0;
    right: auto;
    margin: auto;
  }
}
@media screen and (max-width: 840px) {
  .index_slider .slick-prev {
    background-image: url(../../common/img/common/bt-sp-left.png);
    background-size: 7px 12px;
    background-position: center top 181px;
  }
}
.index_slider .slick-next {
  right: 0;
  margin-left: 380px;
  background-image: url(../../common/img/common/bt-right.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
}
@media screen and (max-width: 840px) {
  .index_slider .slick-next {
    left: auto;
    right: 0;
    margin: auto;
  }
}
@media screen and (max-width: 840px) {
  .index_slider .slick-next {
    background-image: url(../../common/img/common/bt-sp-right.png);
    background-size: 7px 12px;
    background-position: center top 181px;
  }
}

.agreement {
  display: flex;
  max-width: 1168px;
  padding: 0 20px 64px;
  margin: 0 auto;
}
@media screen and (max-width: 840px) {
  .agreement {
    padding: 16px 16px 48px;
  }
}
.agreement_sidebar {
  position: relative;
  width: 264px;
  margin-right: 24px;
}
@media screen and (max-width: 840px) {
  .agreement_sidebar {
    display: none;
  }
}
.agreement_sidebar_inner {
  width: 264px;
}
.agreement_nav {
  background-color: #fff;
}
.agreement_nav ul li {
  font-family: HiraKakuPro-W3;
}
.agreement_nav ul li:not(:last-child) {
  border-bottom: solid 1px #ebedf0;
}
.agreement_nav ul li a {
  display: block;
  padding: 15px 32px 15px 24px;
  color: #707980;
  font-size: 1.4rem;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  transition-property: color, font-weight;
  transition-duration: 0.25s;
}
.agreement_nav ul li a img {
  vertical-align: text-bottom;
}
.agreement_nav ul li a:hover {
  color: #2c2e31;
  font-weight: bold;
}
.agreement_nav ul li .in_list {
  padding-bottom: 8px;
}
.agreement_nav ul li .in_list li {
  border-bottom: none;
  padding-left: 1em;
}
.agreement_nav ul li .in_list li a {
  font-weight: normal;
  background-image: none;
  padding: 8px 32px 8px 24px;
}
.agreement_nav ul li .in_list li a:hover {
  opacity: 0.7;
}
.agreement_nav ul .on a {
  color: #2c2e31;
  font-weight: bold;
  background-image: url(../../common/img/common/right-arrow-black.png);
  background-position: right 13px center;
  background-repeat: no-repeat;
  background-size: 6px 11px;
}
.agreement .art_guide_banner {
  margin-top: 16px;
}
.agreement .art_guide_banner a:hover {
  opacity: 0.7;
}
.agreement .art_guide_banner a img {
  vertical-align: bottom;
}
.agreement_main {
  width: 100%;
  max-width: 840px;
  background-color: #fff;
  padding: 48px;
  flex: 1;
  word-break: break-all;
}
@media screen and (max-width: 840px) {
  .agreement_main {
    padding: 24px 16px;
  }
}
.agreement_title {
  font-family: PTSerif-Caption, serif;
  letter-spacing: 2.5px;
  color: #2c2e31;
  font-size: 1.8rem;
  margin-bottom: 30px;
}
@media screen and (max-width: 840px) {
  .agreement_title {
    line-height: normal;
    margin-bottom: 24px;
    font-size: 1.6rem;
  }
}
.agreement_title span {
  font-family: HiraMinProN-W3, sans-serif;
  letter-spacing: 1px;
  color: #707980;
  font-size: 1.2rem;
}
@media screen and (max-width: 840px) {
  .agreement_title span {
    display: block;
    margin-top: 8px;
    line-height: normal;
  }
}

.fixed_slide {
  position: fixed;
  top: 80px;
}

.fixed_slide_end {
  position: absolute;
  bottom: 0;
}

.about {
  max-width: 600px;
  margin: 0 auto;
}
.about p {
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 2.15;
  color: #2c2e31;
  letter-spacing: normal;
}
.about p + p {
  margin-top: 30px;
}

.contract h3 {
  font-family: HiraKakuPro-W6;
  font-size: 1.6rem;
  line-height: 1.46;
  letter-spacing: 0.6px;
  color: #2c2e31;
}
.contract h3:not(:first-child) {
  margin-top: 1em;
}
.contract p {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: 1.46;
  letter-spacing: 0.6px;
  color: #2c2e31;
}
.contract a {
  color: #4a90e2;
  text-decoration: none;
}
.contract a:hover {
  text-decoration: underline;
}

.contact p {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: 1.54;
  letter-spacing: 0.6px;
  color: #2c2e31;
}
.contact p a {
  color: #2c2e31;
  text-decoration: underline;
}
.contact p a:hover {
  text-decoration: none;
}
.contact ul {
  padding-left: 1.5em;
  margin: 13px 0 20px;
}
@media screen and (max-width: 840px) {
  .contact ul {
    margin: 13px 0 33px;
  }
}
.contact ul li {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  list-style: disc;
  line-height: 1.67;
  letter-spacing: 0.6px;
  color: #707980;
}
.contact ul li:not(:last-child) {
  margin-bottom: 17px;
}
.contact .form_table {
  max-width: 628px;
  margin: 0 auto;
}
@media screen and (max-width: 840px) {
  .contact .form_table {
    display: flex;
    flex-direction: column;
  }
}
.contact .form_table th {
  width: 21%;
}
@media screen and (max-width: 840px) {
  .contact .form_table th {
    width: 100%;
    text-align: left;
  }
}
.contact .form_table tr:first-child {
  border-bottom: solid 1px #ebedef;
}
@media screen and (max-width: 840px) {
  .contact .form_table tr:first-child th {
    padding-top: 0;
  }
}
.contact .form_table tr:first-child td {
  padding-bottom: 22px;
}
@media screen and (max-width: 840px) {
  .contact .form_table tr:first-child td {
    padding-bottom: 24px;
  }
}
.contact .form_table tr:first-child + tr th {
  padding-top: 30px;
}
@media screen and (max-width: 840px) {
  .contact .form_table tr:first-child + tr th {
    padding-top: 24px;
  }
}
.contact .form_table tr:first-child + tr td {
  padding-top: 22px;
}
@media screen and (max-width: 840px) {
  .contact .form_table tr:first-child + tr td {
    padding-top: 0;
  }
}
.contact .co-ordernumber,
.contact .co-itemnumber {
  display: none;
}

.faq_inner:not(:first-child) {
  margin-top: 32px;
}
.faq_inner h3 {
  padding-top: 90px;
  margin-top: -90px;
  margin-bottom: 16px;
  font-family: PTSerif-Caption;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.9px;
  color: #2c2e31;
}
.faq_main {
  border: solid 1px #ebedef;
}
.faq_main:first-child {
  border-radius: 3px 3px 0 0;
}
.faq_main:last-child {
  border-radius: 0 0 3px 3px;
}
.faq_main:not(:last-child) {
  border-bottom: none;
}
.faq_main dt {
  display: flex;
  font-family: HiraKakuPro-W6;
  font-weight: bold;
  font-size: 1.4rem;
  letter-spacing: normal;
  color: #2c2e31;
  padding: 24px 28px 24px 16px;
}
@media screen and (max-width: 840px) {
  .faq_main dt {
    cursor: pointer;
    background-image: url(../../common/img/agreement/faq/plus.png);
    background-repeat: no-repeat;
    background-position: right 16px top 30px;
    background-size: 13px;
    padding-right: 45px;
  }
}
.faq_main dt::before {
  content: "Q";
  margin-right: 16px;
  font-family: PTSerif-Caption;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .faq_main .dt_open {
    background-image: url(../../common/img/agreement/faq/minus.png);
    background-repeat: no-repeat;
    background-position: right 16px top 36px;
    background-size: 13px;
    padding-right: 45px;
  }
}
.faq_main dd {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: 1.57;
  letter-spacing: normal;
  color: #2c2e31;
  word-break: normal;
  padding: 0 28px 24px 16px;
}
@media screen and (max-width: 840px) {
  .faq_main dd {
    display: none;
  }
}
.faq_main dd .faq_a_inner {
  display: flex;
}
@media screen and (max-width: 840px) {
  .faq_main dd .faq_a_inner {
    display: block;
  }
}
.faq_main dd .faq_a_inner::before {
  display: block;
  content: "A";
  margin-right: 16px;
  font-family: PTSerif-Caption;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f55367;
}
@media screen and (max-width: 840px) {
  .faq_main dd .faq_a_inner::before {
    width: 100%;
  }
}
.faq_main dd .faq_a_inner h4 {
  font-weight: bold;
  margin-top: 8px;
}
.faq_main dd .faq_a_inner a {
  color: #2c2e31;
  text-decoration: underline;
}
.faq_main dd .faq_a_inner a:hover {
  text-decoration: none;
}

.information_area_item {
  border-bottom: solid 1px #ebedef;
  padding: 24px 0;
}
.information_area_item:first-child {
  border-top: solid 1px #ebedef;
}
.information_area_data .label {
  display: inline-block;
  font-family: HiraKakuPro-W6;
  font-size: 1.2rem;
  color: #fff;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #2c2e31;
}
.information_area_data time {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  color: #1b1c1d;
}
.information_area_main h4 {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  font-weight: bold;
  color: #2c2e31;
  margin: 16px 0 8px;
}
.information_area_main p {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: normal;
  color: #2c2e31;
}
.information_area_main p a {
  color: #2c2e31;
  text-decoration: underline;
}
.information_area_main p a:hover {
  text-decoration: none;
}

.contract_agreement h4 {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 1em 0 0.5em;
  line-height: 1.46;
  letter-spacing: 0.6px;
  color: #2c2e31;
}
.contract_agreement ul ul {
  padding-left: 1em;
}
.contract_agreement ul ul ul {
  padding-left: 1em;
}

.contract_inner {
  width: 100%;
}

.contract_btns_wrap {
  margin-top: 40px;
}
.contract_btns_wrap .checkbox_text {
  display: none;
}
.contract_btns_wrap .contract_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
@media screen and (max-width: 840px) {
  .contract_btns_wrap .contract_btns {
    flex-direction: column;
    gap: 10px;
  }
}
.contract_btns_wrap .contract_btns input[type=submit] {
  display: block;
  width: 100%;
  max-width: 296px;
  background-color: #4185DE;
  border: none;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  height: 48px;
  letter-spacing: 0.7px;
  line-height: 40px;
  padding: 0;
  transition: opacity 0.4s;
}
.contract_btns_wrap .contract_btns input[type=submit]:hover {
  opacity: 0.7;
}
.contract_btns_wrap .contract_btns a {
  display: block;
  width: 100%;
  max-width: 296px;
  border: none;
  border-radius: 0;
  color: #707980;
  cursor: pointer;
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  height: 48px;
  letter-spacing: 0.7px;
  line-height: 48px;
  padding: 0;
  transition-duration: 0.25s;
  transition-property: background-color;
  text-align: center;
}

.details {
  margin-bottom: 48px;
}
@media screen and (max-width: 840px) {
  .details {
    margin-bottom: 32px;
    padding: 16px 16px 0;
  }
}
.details_inner {
  position: relative;
  max-width: 1128px;
  margin: 0 auto;
}
.details_inner .oil_only {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  width: 80px;
  height: 32px;
  line-height: 32px;
  background-color: #4e7192;
  text-align: center;
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1.4px;
  color: #ffffff;
}
@media screen and (max-width: 840px) {
  .details_inner .oil_only {
    right: 16px;
    top: 16px;
  }
}
.details_wrap {
  display: flex;
  margin-bottom: 1px;
}
@media screen and (max-width: 840px) {
  .details_wrap {
    display: block;
    margin-bottom: 0;
  }
}
.details_slide {
  width: 68.086%;
  padding-right: 1px;
}
@media screen and (max-width: 840px) {
  .details_slide {
    width: 100%;
    padding-right: 0;
  }
}
.details_slide_inner {
  height: 100%;
  padding: 24px;
  background-color: #fff;
}
@media screen and (max-width: 840px) {
  .details_slide_inner {
    padding: 16px;
  }
}
.details_about {
  margin-top: 16px;
}
@media screen and (max-width: 1128px) {
  .details_about {
    margin-top: 30px;
  }
}
@media screen and (max-width: 840px) {
  .details_about {
    display: none;
  }
}
.details_about p {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: 1.69;
  letter-spacing: 0.6px;
  color: #2c2e31;
}
.details_outline {
  width: 31.914%;
  padding: 32px;
  background-color: #fff;
}
@media screen and (max-width: 840px) {
  .details_outline {
    width: 100%;
    padding: 16px 16px 24px;
  }
}
.details_outline #for_oil-bijutsutecho-to-item,
.details_outline #for_oil-bijutsutecho-to-item-sp {
  padding: 10px 0;
}
@media screen and (max-width: 840px) {
  .details_outline #for_oil-bijutsutecho-to-item,
.details_outline #for_oil-bijutsutecho-to-item-sp {
    padding-top: 0;
    margin-top: -26px !important;
  }
}
.details_outline_head {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: solid 1px #ebedf0;
}
@media screen and (max-width: 840px) {
  .details_outline_head {
    padding-bottom: 0;
    border: none;
  }
}
.details_outline_head h2 {
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #2c2e31;
  margin-bottom: 8px;
}
.details_outline_head h2 a {
  color: #2c2e31;
  text-decoration: underline;
}
.details_outline_head h2 a:hover {
  text-decoration: none;
}
.details_outline_head h1 {
  font-family: PTSerif-Caption;
  font-size: 1.6rem;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #2c2e31;
  margin-bottom: 4px;
}
.details_outline_head .date {
  font-family: PTSerif-Caption;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #2c2e31;
}
.details_outline_main {
  padding-bottom: 24px;
  margin-bottom: 20px;
  border-bottom: solid 1px #ebedf0;
}
.details_outline_main .plice {
  font-family: Roboto;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #2c2e31;
  margin-bottom: 16px;
}
@media screen and (max-width: 840px) {
  .details_outline_main .plice {
    font-size: 2.2rem;
  }
}
.details_outline_main .plice small {
  font-family: HiraginoSans-W4;
  font-size: 1.2rem;
  letter-spacing: 0.3px;
}
.details_outline_main .plice_closed {
  font-family: Roboto;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #2c2e31;
  margin-bottom: 16px;
}
.details_outline_main .plice_closed a {
  color: #2c2e31;
  text-decoration: underline;
}
.details_outline_main .plice_closed a:hover {
  text-decoration: none;
}
@media screen and (max-width: 840px) {
  .details_outline_main .plice_closed {
    font-size: 2.2rem;
  }
}
.details_outline_main .plice_soldout {
  font-family: Roboto;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #2c2e31;
  margin-bottom: 16px;
}
@media screen and (max-width: 840px) {
  .details_outline_main .plice_soldout {
    font-size: 2.2rem;
  }
}
.details_outline_main .form input[type=submit], .details_outline_main .form input[type=button] {
  height: 48px;
  background-color: #2c2e31;
  line-height: 40px;
  padding: 0;
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  color: #fff;
  border: none;
  border-radius: 0;
  transition-property: background-color;
  transition-duration: 0.25s;
  cursor: pointer;
}
.details_outline_main .form input[type=submit]:hover, .details_outline_main .form input[type=button]:hover {
  opacity: 0.7;
}
.details_outline_main .soldout_text {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: 1.69;
  letter-spacing: 0.5px;
  color: #2c2e31;
  margin-bottom: 24px;
}
.details_outline_main .details_about_sp {
  display: none;
  margin-top: 24px;
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: 0.6px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .details_outline_main .details_about_sp {
    display: block;
  }
}
.details_outline .item_form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.details_outline .item_form_number {
  display: flex;
  align-items: center;
}
.details_outline .item_form_number label {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #707980;
  margin-right: 8px;
}
.details_outline .item_form_number select {
  width: 70px;
  padding: 0.4em;
  background-position: right 6px center;
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: 0.7px;
  color: #2c2e31;
}
.details_outline .item_form .shipping {
  display: flex;
}
.details_outline .item_form .shipping li {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.3px;
}
.details_outline .item_form .shipping li a {
  color: #2c2e31;
  text-decoration: underline;
  transition-property: color;
  transition-duration: 0.25s;
}
.details_outline .item_form .shipping li a:hover {
  color: #4a90e2;
  text-decoration: none;
}
.details_outline .item_form .shipping li:first-child {
  font-weight: bold;
}
.details_outline .item_form .shipping li:first-child:after {
  content: "|";
  padding: 0.5em;
}
.details_outline .tpoint {
  margin: 24px 0 22px;
  display: flex;
  flex-direction: column;
}
.details_outline .tpoint p:not(:first-child) {
  margin-top: 16px;
}
.details_outline .tpoint .point {
  padding-left: 20px;
  margin-right: 28px;
  font-family: HiraginoSans-W4;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #2c2e31;
  background-image: url(../../common/img/common/ic-vpoint.png);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: left top;
}
.details_outline .tpoint a {
  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #2c2e31;
  text-decoration: underline;
  transition-property: color;
  transition-duration: 0.25s;
}
.details_outline .tpoint a:hover {
  color: #4a90e2;
}
.details_btns {
  display: flex;
  margin: 16px 0 0 -16px;
}
.details_btns .btn_w {
  width: calc(50% - 16px);
  margin-left: 16px;
}
.details_btns .btn_w a {
  height: 48px;
  line-height: 48px;
  padding-left: 10px;
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  color: #707980;
}
.details_btns .like a {
  background-image: url(../../common/img/details/bt-heart.png);
  background-position: left 12% center;
  background-size: 18px 16px;
  background-repeat: no-repeat;
}
.details_btns .like.on a {
  background-image: url(../../common/img/details/bt-heart-active.png);
}
.details_btns .contact a {
  background-image: url(../../common/img/details/ic-mail.png);
  background-position: left 10% center;
  background-size: 20px 16px;
  background-repeat: no-repeat;
  padding-left: 20px;
}
.details_infotable {
  width: 100%;
}
.details_infotable th, .details_infotable td {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #2c2e31;
  padding: 4px 0;
  text-align: left;
}
.details_infotable th a, .details_infotable td a {
  font-weight: bold;
  color: #2c2e31;
  text-decoration: underline;
}
.details_infotable th a:hover, .details_infotable td a:hover {
  text-decoration: none;
}
.details_infotable th {
  position: relative;
  white-space: nowrap;
  padding-right: 0.8em;
}
@media screen and (max-width: 840px) {
  .details_infotable th {
    max-width: 10em;
  }
}
.details_infotable th:after {
  content: ":";
  position: absolute;
  right: 0;
}
.details_infotable td {
  padding-left: 0.8em;
}
.details_infotable .search_category_list {
  display: flex;
  flex-wrap: wrap;
}
.details_infotable .search_category_list li:not(:last-child) {
  margin-right: 3px;
}
.details_infotable .search_category_list li:not(:last-child)::after {
  content: ",";
}
.details_tags {
  width: 100%;
  padding: 24px;
  background-color: #fff;
}
@media screen and (max-width: 840px) {
  .details_tags {
    padding: 24px 16px;
    margin-top: 1px;
  }
}
.details_tags ul {
  display: flex;
  flex-wrap: wrap;
}
.details_tags ul li {
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.3px;
}
.details_tags ul li:not(:last-child) {
  margin-right: 24px;
}
.details_tags ul li a {
  color: #2c2e31;
}
.details_tags ul li a:hover {
  text-decoration: underline;
}

.of200 {
  position: relative;
  height: auto;
  transition-property: height;
  transition-duration: 0.25s;
}
.of200 .of_btn {
  display: none;
  position: absolute;
  bottom: 0;
  z-index: 5;
  width: 100%;
  height: 63px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
}
.of200 .of_btn .round_btn {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  color: #2c2e31;
  cursor: pointer;
  width: 120px;
  height: 32px;
  border-radius: 15px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: solid 1px #ebedf0;
}

.h200 {
  max-height: 200px;
  overflow: hidden;
}

.details_slide_wrap div {
  vertical-align: bottom;
}
.details_slide .slick-track {
  display: flex;
  margin-left: 0;
}
.details_slide_main {
  margin-bottom: 10px !important;
}
.details_slide_main_item {
  background-color: #f1f2f4;
}
.details_slide_main_item span, .details_slide_main_item a {
  height: 540px;
  width: 100%;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 840px) {
  .details_slide_main_item span, .details_slide_main_item a {
    height: 233px;
  }
}
.details_slide_main_item img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.details_slide_main_item .video {
  position: relative;
  z-index: 2;
  width: 100%;
  padding-top: 56.25%;
  margin: 0 auto;
  text-align: center;
}
.details_slide_main_item .video iframe {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.details_slide_main .slick-dots {
  bottom: -2.5em;
  margin-bottom: 5px;
  padding-bottom: 10px;
}
@media screen and (max-width: 840px) {
  .details_slide_main .slick-dots li {
    margin: 0 1px;
  }
}
.details_slide_main .slick-arrow {
  position: absolute;
  z-index: 99;
  background-color: #fff;
  right: auto;
  left: auto;
  width: 40px;
  height: 80px;
  transition-property: box-shadow, margin-top;
  transition-duration: 0.3s;
}
@media screen and (max-width: 840px) {
  .details_slide_main .slick-arrow {
    width: 24px;
    height: 48px;
  }
}
.details_slide_main .slick-arrow:hover {
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 840px) {
  .details_slide_main .slick-arrow:hover {
    box-shadow: none;
    margin-top: 0;
  }
}
.details_slide_main .slick-arrow:active {
  box-shadow: none;
  margin-top: 0;
}
.details_slide_main .slick-arrow:before {
  display: none;
}
.details_slide_main .slick-prev {
  position: absolute;
  left: 0%;
  top: 280px;
  background-image: url(../../common/img/common/bt-left.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
}
@media screen and (max-width: 840px) {
  .details_slide_main .slick-prev {
    top: 120px;
  }
}
.details_slide_main .slick-next {
  right: 0%;
  top: 280px;
  background-image: url(../../common/img/common/bt-right.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
}
@media screen and (max-width: 840px) {
  .details_slide_main .slick-next {
    top: 120px;
  }
}
.details_slide_sub .slick-slide {
  transition-property: opacity;
  transition-duration: 0.4s;
}
.details_slide_sub .slick-current {
  opacity: 0.5;
}
@media screen and (max-width: 1128px) {
  .details_slide_sub {
    display: none;
  }
}
.details_slide_sub .slick-slide {
  width: 72px;
}
.details_slide_sub .slick-track {
  display: flex;
  justify-content: space-between;
  transform: translate3d(0px, 0px, 0px) !important;
}
.details_slide_sub .slick-track:before, .details_slide_sub .slick-track:after {
  display: none;
}
.details_slide_sub .slick-track .slick-slide {
  width: auto !important;
}
.details_slide_sub_item {
  cursor: pointer;
  background-color: #f1f2f4;
}
.details_slide_sub_item span {
  width: 72px;
  height: 72px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.details_slide_sub_item img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.slide_caption {
  width: 100%;
  background-color: #fff;
  font-family: Roboto;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #707980;
  padding: 3px 0 0;
}

.remodal-wrapper {
  padding: 0;
  overflow: hidden;
}

.details_remodal {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  background-color: #fff;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.details_remodal_inner {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1280px;
  max-height: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 840px) {
  .details_remodal_inner {
    height: 80%;
  }
}
.details_remodal_header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
}
.details_remodal_header .all_num {
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.9px;
  color: #2c2e31;
}
.details_remodal_header_tools {
  margin-left: auto;
}
.details_remodal_main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100%;
}
.details_remodal_main_inner {
  height: 100%;
  overflow: hidden;
}
@media screen and (max-width: 840px) {
  .details_remodal_main_inner {
    height: auto;
    max-width: 80%;
  }
}
.details_remodal_main_inner img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
.details_remodal_footer {
  text-align: center;
  min-height: 55px;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.details_remodal_footer p {
  width: 100%;
  font-family: HiraginoSans-W4;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: 1.8px;
  color: #2c2e31;
}

.details_remodal-close img {
  cursor: pointer;
}

.arr {
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  right: auto;
  left: auto;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  width: 40px;
  height: 80px;
  transition-property: box-shadow, margin-top;
  transition-duration: 0.3s;
}
@media screen and (max-width: 840px) {
  .arr {
    width: 24px;
    height: 48px;
  }
}
.arr:hover {
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 840px) {
  .arr:hover {
    box-shadow: none;
  }
}
.arr:active {
  box-shadow: none;
}
.arr:before {
  display: none;
}

.arr_l {
  left: 0%;
  background-image: url(../../common/img/common/bt-left.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
}

.arr_r {
  right: 0%;
  background-image: url(../../common/img/common/bt-right.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
}

#ok-loupe {
  z-index: 10000 !important;
}

/*------------------------------------*\
    説明文
\*------------------------------------*/
.product_note_body {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: 0.7px;
  color: #2c2e31;
  text-align: justify;
  word-break: break-all;
}
.product_note_body > * {
  margin-top: 1.5rem;
}
@media screen and (max-width: 840px) {
  .product_note_body > * {
    margin-top: 1.5rem;
  }
}
.product_note_body h2 {
  font-family: HiraKakuPro-W6;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.5;
  letter-spacing: 1.5px;
  border-left: 4px solid #2c2e31;
  padding-left: 8px;
  margin-top: 3rem;
}
@media screen and (max-width: 840px) {
  .product_note_body h2 {
    font-size: 1.6rem;
  }
}
.product_note_body h3 {
  font-family: HiraKakuPro-W6;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 1.5px;
  margin-top: 3rem;
}
@media screen and (max-width: 840px) {
  .product_note_body h3 {
    font-size: 1.4rem;
  }
}
.product_note_body h4 {
  font-family: HiraKakuPro-W6;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 1.5px;
  margin-top: 1.5rem;
}
.product_note_body a {
  color: #2c2e31;
  border-bottom: 1px dotted #2c2e31;
}
.product_note_body figure a {
  display: block;
  border-bottom: none;
}
.product_note_body img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
.product_note_body figcaption {
  font-family: HiraKakuPro-W3;
  font-size: 1rem;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #707980;
  margin-top: 10px;
}
.product_note_body ol {
  padding-left: 1.2em;
}
.product_note_body ol li {
  list-style: decimal;
}
.product_note_body ul {
  padding-left: 1.2em;
}
.product_note_body ul li {
  list-style: disc;
}
.product_note_body blockquote {
  border-radius: 5px;
  background-color: #f4f6f8;
  padding: 24px 24px 24px 68px;
  background-repeat: no-repeat;
  background-position: left 24px top 24px;
  background-size: 30px 23px;
}
@media screen and (max-width: 840px) {
  .product_note_body blockquote {
    padding: 40px 16px 16px 16px;
    background-position: left 16px top 16px;
    background-size: 20px 16px;
  }
}
.product_note_body blockquote .source {
  font-size: 1.2rem;
  color: #707980;
  text-align: right;
  margin-top: 8px;
}
.product_note_body table {
  border-collapse: collapse;
  border-spacing: 0;
}
.product_note_body table th, .product_note_body table td {
  border: solid 1px #ebedef;
  padding: 9px 12px;
}

.login_annotation {
  font-size: 1.2rem;
  color: #707980;
}

.details_swiper_wrap .mainSwiper {
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 840px) {
  .details_swiper_wrap .mainSwiper {
    padding-bottom: 20px;
  }
}
.details_swiper_wrap .mainSwiper .swiper-slide .swiper-slide-img {
  width: 720px;
  height: 540px;
  background-color: #f1f2f4;
}
@media screen and (max-width: 840px) {
  .details_swiper_wrap .mainSwiper .swiper-slide .swiper-slide-img {
    width: 100%;
    height: 233px;
    aspect-ratio: 312/233;
  }
}
.details_swiper_wrap .mainSwiper .swiper-slide .swiper-slide-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: "object-fit: contain;";
}
.details_swiper_wrap .mainSwiper .swiper-slide .video_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.details_swiper_wrap .mainSwiper .swiper-slide .video {
  position: relative;
  z-index: 2;
  width: 100%;
  padding-top: 56.25%;
  margin: 0 auto;
  text-align: center;
}
.details_swiper_wrap .mainSwiper .swiper-slide .video iframe {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.details_swiper_wrap .subSwiper {
  margin-top: 10px;
  overflow: hidden;
}
@media screen and (max-width: 840px) {
  .details_swiper_wrap .subSwiper {
    display: none;
  }
}
.details_swiper_wrap .subSwiper .swiper-slide {
  width: 72px;
  height: 72px;
  background-color: #f1f2f4;
}
.details_swiper_wrap .subSwiper .swiper-slide.swiper-slide-thumb-active {
  opacity: 0.5;
}
.details_swiper_wrap .subSwiper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: "object-fit: contain;";
}
.details_swiper_wrap .swiper-pagination {
  display: none;
  bottom: 0 !important;
}
@media screen and (max-width: 840px) {
  .details_swiper_wrap .swiper-pagination {
    display: block;
  }
}
.details_swiper_wrap .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #000;
}
.details_swiper_wrap .swiper-button-next {
  margin-top: -45px;
  width: 40px;
  height: 80px;
  background-image: url(../../common/img/common/bt-right.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
  right: -1px;
  background-color: #fff;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.25s;
}
@media screen and (max-width: 840px) {
  .details_swiper_wrap .swiper-button-next {
    margin-top: -24px;
    width: 24px;
    height: 48px;
  }
}
.details_swiper_wrap .swiper-button-next::after {
  display: none;
}
.details_swiper_wrap .swiper-button-next:hover {
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 840px) {
  .details_swiper_wrap .swiper-button-next:hover {
    box-shadow: none;
  }
}
.details_swiper_wrap .swiper-button-prev {
  margin-top: -45px;
  width: 40px;
  height: 80px;
  background-image: url(../../common/img/common/bt-left.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
  left: 0;
  background-color: #fff;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.25s;
}
@media screen and (max-width: 840px) {
  .details_swiper_wrap .swiper-button-prev {
    margin-top: -24px;
    width: 24px;
    height: 48px;
  }
}
.details_swiper_wrap .swiper-button-prev::after {
  display: none;
}
.details_swiper_wrap .swiper-button-prev:hover {
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 840px) {
  .details_swiper_wrap .swiper-button-prev:hover {
    box-shadow: none;
  }
}

@media screen and (max-width: 840px) {
  #for_oil-bijutsutecho-to-page-sp + .catalog,
#for_oil-bijutsutecho-to-page + .catalog {
    padding-top: 0;
  }
}

.catalog {
  margin-bottom: 64px;
}
@media screen and (max-width: 840px) {
  .catalog {
    padding: 16px;
  }
}
.catalog_inner {
  max-width: 1128px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 840px) {
  .catalog_inner {
    display: block;
  }
}
.catalog_sidebar {
  width: 100%;
  max-width: 264px;
  margin-right: 20px;
}
@media screen and (max-width: 840px) {
  .catalog_sidebar {
    max-width: 100%;
    margin: 0 0 16px 0;
  }
}
.catalog_sort {
  background-color: #fff;
}
.catalog_sort dt {
  padding: 24px;
}
@media screen and (max-width: 840px) {
  .catalog_sort dt {
    padding: 17px 16px;
    background-image: url(../img/agreement/faq/plus.png);
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: right 16px center;
    cursor: pointer;
  }
}
.catalog_sort .dt_open {
  background-image: url(../img/agreement/faq/minus.png);
}
.catalog_sort h4 {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: 0.7px;
  color: #2c2e31;
}
.catalog_sort dd {
  padding: 16px 24px 24px;
  border-top: solid 1px #ebedf0;
}
@media screen and (max-width: 840px) {
  .catalog_sort dd {
    display: none;
    padding: 16px;
  }
}
.catalog_sort dd .radio_text,
.catalog_sort dd .checkbox_text {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  letter-spacing: 0.6px;
  color: #707980;
  transition-property: border;
  transition-duration: 0.25s;
}
@media screen and (max-width: 840px) {
  .catalog_sort dd .radio_text,
.catalog_sort dd .checkbox_text {
    margin-right: 12px;
    padding-left: 20px;
  }
}
.catalog_sort dd .radio_text:before {
  width: 16px;
  height: 16px;
}
.catalog_sort dd .radio_text:after {
  width: 8px;
  height: 8px;
}
.catalog_sort dd .checkbox_text span {
  position: relative;
  top: -2px;
}
.catalog_sort dd .checkbox_text:before {
  width: 16px;
  height: 16px;
  border: solid 1px #bfcbd5;
  transition-property: border;
  transition-duration: 0.25s;
}
.catalog_sort dd .checkbox_text:hover:before {
  border: solid 1px #2c2e31;
}
.catalog_sort dd .checkbox_text:after {
  top: calc(50% - 2.5px);
  left: 5.5px;
  width: 5px;
  height: 12px;
}
.catalog_sort dd input:checked + span {
  color: #2c2e31;
}
.catalog_sort_search {
  padding-bottom: 16px;
  border-bottom: solid 1px #ebedf0;
  margin-bottom: 16px;
}
@media screen and (max-width: 840px) {
  .catalog_sort_search {
    border-bottom: none;
    margin-bottom: 0;
  }
}
.catalog_sort_search input[type=search] {
  width: 100%;
  padding: 10px 10px 10px 40px;
  font-size: 1.6rem;
  border: solid 1px #dce0e3;
  background-image: url(../img/common/ic-search_b.png);
  background-position: left 12px center;
  background-size: 19px;
  background-repeat: no-repeat;
}
.catalog_sort_search ::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.catalog_sort_cat {
  padding-bottom: 16px;
  border-bottom: solid 1px #ebedf0;
  margin-bottom: 16px;
}
.catalog_sort_cat h5 {
  font-family: Roboto;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
  margin-bottom: 16px;
}
@media screen and (max-width: 840px) {
  .catalog_sort_cat ul {
    display: flex;
    flex-wrap: wrap;
  }
}
.catalog_sort_cat li {
  line-height: 1;
}
.catalog_sort_cat li:not(:last-child) {
  margin-bottom: 3px;
}
.catalog_sort_price {
  padding-bottom: 16px;
  border-bottom: solid 1px #ebedf0;
  margin-bottom: 16px;
}
.catalog_sort_price h5 {
  font-family: Roboto;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
  margin-bottom: 16px;
}
@media screen and (max-width: 840px) {
  .catalog_sort_price ul {
    display: flex;
    flex-wrap: wrap;
  }
}
.catalog_sort_price li {
  line-height: 1;
}
.catalog_sort_price li:not(:last-child) {
  margin-bottom: 3px;
}
.catalog_sort select {
  width: 100%;
  padding: 10px;
  font-size: 1.6rem;
  border: solid 1px #dce0e3;
  background-image: url(../img/common/arrow_b.png);
  background-position: right 12px center;
  background-repeat: no-repeat;
  background-size: 12px 7px;
}
.catalog_sort_souledout {
  padding-bottom: 4px;
  border-bottom: solid 1px #ebedf0;
  margin-bottom: 24px;
}
@media screen and (max-width: 840px) {
  .catalog_sort_souledout {
    padding-bottom: 0;
    border: none;
    margin-bottom: 20px;
  }
}
.catalog_sort_btn input[type=submit], .catalog_sort_btn input[type=button] {
  width: 100%;
  height: 48px;
  background-color: #2c2e31;
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  color: #ffffff;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.25s;
}
.catalog_sort_btn input[type=submit]:hover, .catalog_sort_btn input[type=button]:hover {
  background-color: #707980;
}
.catalog_main {
  width: 100%;
  max-width: 839px;
}
.catalog_main_head {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
@media screen and (max-width: 840px) {
  .catalog_main_head {
    margin-bottom: 16px;
  }
}
.catalog_main_head p {
  font-family: Roboto, HiraginoSans-W6;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .catalog_main_head p {
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    color: #2c2e31;
  }
}
.catalog_main_head p span {
  font-weight: bold;
  font-size: 1.6rem;
}
@media screen and (max-width: 840px) {
  .catalog_main_head p span {
    font-size: 1.4rem;
    letter-spacing: 0.6px;
  }
}
.catalog_main_head .displayed_number {
  padding-right: 16px;
}
.catalog_main_head .form {
  margin-left: auto;
  width: 100%;
  max-width: 264px;
}
@media screen and (max-width: 840px) {
  .catalog_main_head .form {
    max-width: 50%;
  }
}
.catalog_main_head .form select {
  width: 100%;
  max-width: 264px;
  height: 40px;
  padding: 0 16px;
  font-size: 1.4rem;
  background-position: right 24px center;
  border: none;
  border-radius: 0;
}
.catalog_main_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1px;
}
.catalog_main_inner .artworks_item {
  width: calc(33.333% - 1px);
}
@media screen and (max-width: 840px) {
  .catalog_main_inner .artworks_item {
    width: calc(50% - 1px);
  }
}
.catalog_main_inner .goods_item {
  width: calc(25% - 1px);
}
@media screen and (max-width: 840px) {
  .catalog_main_inner .goods_item {
    width: calc(50% - 1px);
  }
}

.pagination {
  display: flex;
  align-items: center;
  margin-top: 23px;
}
@media screen and (max-width: 840px) {
  .pagination {
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
  }
}
.pagination ul {
  display: flex;
  margin-right: auto;
}
@media screen and (max-width: 840px) {
  .pagination ul {
    width: 100%;
    justify-content: center;
    margin-right: 0;
    padding: 0 !important;
  }
}
.pagination ul li {
  width: 48px;
  height: 48px;
  background-color: #fff;
  text-align: center;
  border: solid 1px #fff;
  transition-property: border;
  transition-duration: 0.25s;
}
@media screen and (max-width: 840px) {
  .pagination ul li {
    width: 30px;
  }
}
.pagination ul li:hover {
  border: solid 1px #bfcbd5;
}
.pagination ul li:not(:last-child) {
  margin-right: 16px;
}
@media screen and (max-width: 840px) {
  .pagination ul li:not(:last-child) {
    margin-right: 2.66%;
  }
}
.pagination ul li a,
.pagination ul li span {
  height: 100%;
  display: block;
  line-height: 48px;
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.7px;
  color: #2c2e31;
}
.pagination ul .prev,
.pagination ul .next {
  width: 96px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
}
@media screen and (max-width: 840px) {
  .pagination ul .prev,
.pagination ul .next {
    width: 30px;
  }
}
.pagination ul .prev {
  background-image: url(../img/catalog/bt-left.png);
}
.pagination ul .next {
  background-image: url(../img/catalog/bt-right.png);
}
.pagination ul .first,
.pagination ul .last {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 16px;
}
.pagination ul .first {
  background-image: url(../img/catalog/bt-left2.png);
}
.pagination ul .last {
  background-image: url(../img/catalog/bt-right2.png);
}
.pagination ul .on {
  background-color: #2c2e31;
  border: solid 1px #2c2e31;
}
.pagination ul .on:hover {
  border: solid 1px #2c2e31;
}
.pagination ul .on span {
  color: #fff;
}
.pagination .page_num {
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .pagination .page_num {
    margin-top: 32px;
  }
}

.pagination_pc {
  display: flex;
}
@media screen and (max-width: 840px) {
  .pagination_pc {
    display: none;
  }
}

.pagination_sp {
  display: none;
}
@media screen and (max-width: 840px) {
  .pagination_sp {
    display: flex;
  }
}

.artists_list .pagination li:not(.on) {
  border: solid 1px #ebedf0;
}

.settlement_header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ebedef;
}
.settlement_header_inner {
  max-width: 1170px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 840px) {
  .settlement_header_inner {
    justify-content: center;
  }
}
.settlement_header_logo img {
  vertical-align: bottom;
}

.settlement_footer {
  background-color: #fff;
  padding: 24px 0 16px;
}
@media screen and (max-width: 840px) {
  .settlement_footer {
    padding: 32px 0;
  }
}
.settlement_footer_inner {
  max-width: 1170px;
  margin: 0 auto;
}
@media screen and (max-width: 840px) {
  .settlement_footer_inner {
    padding: 0 24px;
  }
}
.settlement_footer_logo {
  margin-bottom: 8px;
}
@media screen and (max-width: 840px) {
  .settlement_footer_logo {
    display: none;
  }
}
.settlement_footer_main {
  display: flex;
}
.settlement_footer_nav {
  display: flex;
  margin-left: auto;
}
@media screen and (max-width: 840px) {
  .settlement_footer_nav {
    display: block;
    margin-left: 0;
  }
}
.settlement_footer_nav li:not(:last-child) {
  margin-right: 32px;
}
@media screen and (max-width: 840px) {
  .settlement_footer_nav li:not(:last-child) {
    margin-right: 0;
  }
}
.settlement_footer_nav li a {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 1.6px;
  color: #707980;
}
@media screen and (max-width: 840px) {
  .settlement_footer_nav li a {
    font-size: 1.1rem;
    letter-spacing: 1.5px;
  }
}
.settlement_footer_nav li a:hover {
  text-decoration: underline;
}
.settlement_footer_copy {
  font-family: Roboto;
  font-size: 1.1rem;
  line-height: normal;
  letter-spacing: 1.5px;
  color: #707980;
}
@media screen and (max-width: 840px) {
  .settlement_footer_copy {
    margin-top: 48px;
  }
}

.settlement_error {
  margin: 24px 0;
}
.settlement_error li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px 9px 16px;
  background-color: #dce0e3;
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
}
.settlement_error li span {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("../img/common/ic-close_b.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px;
  cursor: pointer;
}
.settlement_error li span:hover {
  opacity: 0.7;
}
.settlement_error li + li {
  margin-top: 16px;
}
.settlement_error .error_important {
  background-color: #ffd8dc;
  color: #d94f4f;
}
.settlement_error .error_payment {
  background-color: #52ca88;
  color: #fff;
}
.settlement_error .error_payment_g {
  background-color: #bfcbd5;
  color: #fff;
}

.page_head {
  display: flex;
  margin-bottom: 24px;
}
@media screen and (max-width: 840px) {
  .page_head {
    margin-bottom: 16px;
  }
}
.page_title {
  font-family: PTSerif-Caption;
  font-size: 1.8rem;
  line-height: normal;
  letter-spacing: 2.5px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .page_title {
    font-size: 1.6rem;
    letter-spacing: 2.2px;
  }
}
.page_title span {
  font-family: HiraMinProN-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 1px;
  color: #707980;
  margin-left: 8px;
}
@media screen and (max-width: 840px) {
  .page_title span {
    font-size: 1.2rem;
  }
}
.page_modal {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2c2e31;
  margin-left: auto;
  text-decoration: underline;
}
@media screen and (max-width: 840px) {
  .page_modal {
    font-size: 1.2rem;
    letter-spacing: 0.2px;
  }
}
.page_modal:hover {
  text-decoration: none;
}

/*------------------------------------*\
    カート一覧
\*------------------------------------*/
.cart {
  max-width: 1128px;
  margin: 0 auto;
}
@media screen and (max-width: 840px) {
  .cart {
    padding: 16px 16px 0;
  }
}
.cart_main {
  background-color: #fff;
}
.cart_main:not(:first-child) {
  margin-top: 24px;
}
.cart_main:last-child {
  margin-bottom: 64px;
}
.cart_main_head {
  display: flex;
  flex-wrap: wrap;
}
.cart_main_head .cart_type {
  width: 145px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PTSerif-Caption;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.6px;
  text-align: center;
  color: #fff;
}
@media screen and (max-width: 840px) {
  .cart_main_head .cart_type {
    width: 106px;
    height: 47px;
    font-size: 1.2rem;
    letter-spacing: 1.4px;
  }
}
.cart_main_head .gallery_name {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 52px;
  font-family: Roboto;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #2c2e31;
  padding: 0 16px;
  border-bottom: solid 1px #ebedf0;
}
@media screen and (max-width: 840px) {
  .cart_main_head .gallery_name {
    height: 47px;
    font-size: 1.4rem;
    letter-spacing: 0.7px;
  }
}
.cart_main_head .gallery_name a {
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .cart_main_head .gallery_name a {
    width: 200px;
  }
}
.cart_main_head .gallery_name a:hover {
  opacity: 0.7;
}
.cart_main_confirm {
  padding: 0 24px;
}
.cart_main_confirm:not(:first-child) {
  margin-top: 0;
}
.cart_main_confirm .cart_inner {
  padding: 24px 0;
}
.cart_main_confirm .cart_info {
  padding-right: 26px;
}
.cart_main_confirm .cart_settlement .cart_price {
  padding-right: 0;
}
.cart_inner {
  display: flex;
  padding: 24px;
  border-bottom: solid 1px #ebedf0;
}
@media screen and (max-width: 840px) {
  .cart_inner {
    display: block;
    padding: 16px;
  }
}
.cart_info {
  width: 51.2%;
  display: flex;
}
@media screen and (max-width: 840px) {
  .cart_info {
    width: 100%;
    margin-bottom: 32px;
  }
}
.cart_img {
  margin-right: 24px;
  background-color: #fbfbfb;
}
@media screen and (max-width: 840px) {
  .cart_img {
    margin-right: 16px;
  }
}
.cart_img a, .cart_img span {
  width: 64px;
  height: 64px;
  background-color: #ebedf0;
  padding: 6px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart_img a:hover, .cart_img span:hover {
  opacity: 0.7;
}
.cart_img span:hover {
  opacity: 1;
}
.cart_img img {
  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;
  vertical-align: bottom;
}
.cart_text {
  flex: 1;
  padding-right: 20px;
}
@media screen and (max-width: 840px) {
  .cart_text {
    width: 100%;
    padding-right: 0;
  }
}
.cart_text p {
  word-break: break-all;
}
.cart_text a:hover {
  opacity: 0.7;
}
.cart_text_artist {
  font-family: HiraKakuPro-W3;
  font-size: 1.6rem;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .cart_text_artist {
    font-size: 1.4rem;
    letter-spacing: 0.7px;
  }
}
.cart_text_arttitle {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #707980;
}
.cart_text_artdate {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #707980;
}
.cart_settlement {
  width: 48.8%;
}
@media screen and (max-width: 840px) {
  .cart_settlement {
    width: 100%;
  }
}
.cart_settlement .cart_price {
  display: flex;
  align-items: center;
  padding: 0 0 16px 0;
  border-bottom: dashed 1px #bfcbd5;
}
@media screen and (max-width: 840px) {
  .cart_settlement .cart_price {
    padding: 0 0 16px 0;
  }
}
.cart_settlement .cart_price .unitprice {
  font-family: HiraginoSans-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2c2e31;
  margin-right: auto;
}
@media screen and (max-width: 840px) {
  .cart_settlement .cart_price .unitprice {
    display: none;
  }
}
.cart_settlement .cart_price .unitprice span {
  font-family: Roboto;
  font-size: 1.4rem;
}
.cart_settlement .cart_price .cart_quantity {
  display: flex;
  align-items: center;
}
.cart_settlement .cart_price .cart_quantity_p {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #707980;
  white-space: nowrap;
  margin-right: 8px;
}
.cart_settlement .cart_price .cart_quantity label {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #707980;
  white-space: nowrap;
  margin-right: 8px;
}
.cart_settlement .cart_price .cart_quantity select {
  width: 65px;
  height: 24px;
  padding: 0 8px;
  background-position: right 8px center;
  background-size: 7px 4px;
  font-family: Roboto;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
}
.cart_settlement .cart_price .cart_quantity .error_select {
  background-color: #ffd8dc;
  border: solid 1px #d94f4f;
}
.cart_settlement .cart_price .cart_del {
  margin-left: 12px;
}
.cart_settlement .cart_price .cart_del a {
  font-family: HiraginoSans-W4;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #2c2e31;
  text-decoration: underline;
}
@media screen and (max-width: 840px) {
  .cart_settlement .cart_price .cart_del a {
    font-size: 1.4rem;
    letter-spacing: 0.3px;
  }
}
.cart_settlement .cart_price .cart_del a:hover {
  text-decoration: none;
}
.cart_settlement .cart_price .cart_total {
  width: 130px;
  text-align: right;
  margin-left: 16px;
  font-family: HiraginoSans-W4;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .cart_settlement .cart_price .cart_total {
    margin-left: auto;
  }
}
.cart_settlement .cart_price .cart_total span {
  font-weight: normal;
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 500;
}
.cart_nb {
  padding: 14px 0 0;
}
@media screen and (max-width: 840px) {
  .cart_nb {
    display: flex;
  }
}
.cart_nb_heading {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #2c2e31;
  margin-bottom: 8px;
}
@media screen and (max-width: 840px) {
  .cart_nb_heading {
    width: 32.79%;
  }
}
.cart_nb_text {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: 1.33;
  letter-spacing: 0.6px;
  color: #707980;
  flex: 1;
}
.cart_main_total {
  padding: 24px;
  display: flex;
}
@media screen and (max-width: 840px) {
  .cart_main_total {
    display: block;
    padding: 18px 16px;
  }
}
.cart_main_total_text {
  width: 64.53%;
  padding-right: 24px;
  border-right: 1px solid #ebedf0;
}
@media screen and (max-width: 840px) {
  .cart_main_total_text {
    display: none;
  }
}
.cart_main_total_text p {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: 1.31;
  letter-spacing: 0.6px;
  color: #2c2e31;
}
.cart_main_total_text_sp {
  display: none;
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: 1.42;
  letter-spacing: 0.6px;
  color: #2c2e31;
  border-top: solid 1px #ebedf0;
  margin-top: 24px;
  padding-top: 16px;
}
@media screen and (max-width: 840px) {
  .cart_main_total_text_sp {
    display: block;
  }
}
.cart_main_total_price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 35.47%;
}
@media screen and (max-width: 840px) {
  .cart_main_total_price {
    width: 100%;
  }
}
.cart .total_price_table {
  width: 100%;
  max-width: 292px;
  margin-bottom: 20px;
}
@media screen and (max-width: 840px) {
  .cart .total_price_table {
    max-width: 100%;
  }
}
.cart .total_price_table th, .cart .total_price_table td {
  font-weight: normal;
  padding-bottom: 14px;
  text-align: right;
}
.cart .total_price_table th {
  max-width: 4.8em;
  white-space: nowrap;
  font-family: HiraKakuPro-W3;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .cart .total_price_table th {
    max-width: 7.1em;
  }
}
.cart .total_price_table td {
  font-family: HiraginoSans-W4;
  font-size: 1.2rem;
}
.cart .total_price_table td span {
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2c2e31;
}
.cart .total_price_table .deliverycharge {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.2px;
}
.cart .total_price_table .deliverycharge a {
  color: #2c2e31;
  text-decoration: underline;
}
.cart .total_price_table .deliverycharge a:hover {
  text-decoration: none;
}
.cart .total_price_table .total {
  border-top: solid 1px #ebedf0;
}
.cart .total_price_table .total th, .cart .total_price_table .total td {
  padding-bottom: 0;
  padding-top: 15px;
}
.cart .total_price_table .total td span {
  font-family: Roboto;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2c2e31;
}
.cart_main_tpoint {
  display: flex;
  align-items: center;
  width: 292px;
  height: 48px;
  border-radius: 3px;
  background-color: #f1f2f4;
  padding: 0 16px;
}
@media screen and (max-width: 840px) {
  .cart_main_tpoint {
    width: 100%;
  }
}
.cart_main_tpoint + .cart_main_tpoint {
  margin-top: 10px;
}
.cart_main_tpoint_heading {
  margin-right: auto;
  background-image: url(../img/common/ic-vpoint.png);
  background-repeat: no-repeat;
  background-size: 16px;
  padding-left: 20px;
  background-position: left center;
  font-family: Roboto, HiraginoSans-W4;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #2c2e31;
}
.cart_main_tpoint_num {
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2c2e31;
}
.cart_main_tpoint_procedure {
  text-align: right;
  margin-top: 8px;
}
.cart_main_tpoint_procedure a {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #2c2e31;
  text-decoration: underline;
}
.cart_main_tpoint_procedure a:hover {
  text-decoration: none;
}
.cart_settlement_btn {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  border-top: solid 1px #ebedf0;
}
@media screen and (max-width: 840px) {
  .cart_settlement_btn {
    padding: 16px;
  }
}

.settlement_submit {
  width: 100%;
  max-width: 225px;
  height: 40px;
  line-height: 40px;
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #fff;
  background-color: #4a90e2;
  transition-property: background-color;
  transition-duration: 0.25s;
}
@media screen and (max-width: 840px) {
  .settlement_submit {
    max-width: 100%;
  }
}
.settlement_submit:hover {
  background-color: #6ca7eb;
}

.cart_artworks .cart_type {
  background-color: #c2a47e;
}

.cart_goods .cart_type {
  background-color: #6e8ca8;
}

.cart_confirm {
  background-color: #fff;
  padding: 0 24px;
}
.cart_confirm_inner {
  display: flex;
  padding: 24px 0;
}
@media screen and (max-width: 840px) {
  .cart_confirm_inner {
    display: block;
  }
}
.cart_confirm_inner:not(:last-child) {
  border-bottom: solid 1px #ebedf0;
}
.cart_confirm_inner h4 {
  width: 187px;
  padding-left: 55px;
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.3px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .cart_confirm_inner h4 {
    padding-left: 0;
    margin-bottom: 16px;
  }
}
.cart_confirm_inner p {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.3px;
  color: #2c2e31;
}
.cart_confirm_table {
  width: 100%;
  max-width: 409px;
  flex: 1;
}
@media screen and (max-width: 840px) {
  .cart_confirm_table tr {
    display: block;
  }
}
.cart_confirm_table tr:first-child th, .cart_confirm_table tr:first-child td {
  padding-top: 0;
}
.cart_confirm_table tr:last-child th, .cart_confirm_table tr:last-child td {
  padding-bottom: 0;
}
.cart_confirm_table th, .cart_confirm_table td {
  padding: 4px 0;
}
@media screen and (max-width: 840px) {
  .cart_confirm_table th, .cart_confirm_table td {
    display: block;
  }
}
.cart_confirm_table th {
  font-family: HiraKakuPro-W6;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 1.1px;
  color: #2c2e31;
  width: 10em;
  white-space: nowrap;
}
@media screen and (max-width: 840px) {
  .cart_confirm_table th {
    padding-top: 0;
    padding-bottom: 9px;
  }
}
.cart_confirm_table td {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.3px;
  color: #2c2e31;
  text-indent: -0.7em;
  padding-left: 1em;
  text-align: left;
}
@media screen and (max-width: 840px) {
  .cart_confirm_table td {
    text-indent: 0;
    padding: 0 0 24px 0;
  }
}
.cart_confirm_table td:before {
  content: ":";
  margin-right: 3px;
}
@media screen and (max-width: 840px) {
  .cart_confirm_table td:before {
    display: none;
  }
}
.cart_confirm_table .same {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.3px;
  color: #2c2e31;
  text-indent: 0;
  padding-left: 0;
}
.cart_confirm_table .same:before {
  display: none;
}
.cart_confirm .confirm_btn_area {
  display: flex;
  justify-content: flex-end;
  padding-left: 40px;
}
@media screen and (max-width: 840px) {
  .cart_confirm .confirm_btn_area {
    padding-left: 0;
    margin-top: 24px;
  }
}
@media screen and (max-width: 840px) {
  .cart_confirm .confirm_btn_area form {
    display: block;
    width: 100%;
  }
}
.cart_confirm .confirm_btn {
  width: 80px;
  height: 32px;
  border: solid 1px #ebedf0;
  transition-property: border;
  transition-duration: 0.25s;
}
@media screen and (max-width: 840px) {
  .cart_confirm .confirm_btn {
    width: 100%;
    height: 40px;
  }
}
.cart_confirm .confirm_btn:hover {
  border: solid 1px #bfcbd5;
}
.cart_confirm .confirm_btn a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: HiraKakuPro-W6;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #707980;
}

.criterion_table {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
@media screen and (max-width: 840px) {
  .criterion_table {
    display: block;
  }
}
.criterion_table_ofs {
  max-width: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
@media screen and (max-width: 840px) {
  .criterion_table_ofs table {
    width: 615px;
  }
}
@media screen and (max-width: 840px) {
  .criterion_table_ofs + table {
    margin-top: 16px;
  }
}
.criterion_table table th, .criterion_table table td {
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: 1.1px;
  color: #2c2e31;
  white-space: nowrap;
  padding: 12px 10px;
  border: solid 1px #dce0e3;
  vertical-align: middle;
  text-align: center;
}
.criterion_table table th {
  font-family: HiraKakuPro-W6;
  background-color: #f1f2f4;
}
.criterion_table table td {
  font-family: HiraKakuPro-W3;
  line-height: 1.38;
}

.size_table tr:not(:first-child) th {
  background-color: transparent;
}

.criterion_small {
  max-width: 615px;
}

.cancel_policy {
  margin: 20px 0 0;
  padding-bottom: 30px;
}
.cancel_policy h3 {
  font-weight: bold;
  margin-bottom: 10px;
}
.cancel_policy p {
  line-height: 1.7;
  font-size: 1.2rem;
}
.cancel_policy ul li {
  line-height: 1.45;
  font-size: 1.2rem;
}
.cancel_policy ul li a {
  color: #4B90E2;
  text-decoration: underline;
}
.cancel_policy ul li a:hover {
  text-decoration: none;
}

.settlement {
  max-width: 1128px;
  margin: 0 auto 60px;
}
@media screen and (max-width: 840px) {
  .settlement {
    padding: 16px;
    margin: 0 auto 30px;
  }
}
.settlement_step {
  margin: 24px 0;
}
@media screen and (max-width: 840px) {
  .settlement_step {
    display: none;
  }
}
.settlement_step ul {
  display: flex;
}
.settlement_step ul li {
  display: flex;
  align-items: center;
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 1.6px;
  color: #bfcbd5;
}
.settlement_step ul li:not(:last-child)::after {
  display: inline-block;
  content: "";
  background-image: url(../img/settlement/ic-step-disable.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 5px 10px;
  width: 5px;
  height: 10px;
  margin: 0 16px;
}
.settlement_step ul li span {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bfcbd5;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
}
.settlement_step ul .on {
  color: #2c2e31;
}
.settlement_step ul .on:not(:last-child)::after {
  background-image: url(../img/settlement/ic-step-active.png);
}
.settlement_step ul .on span {
  background-color: #2c2e31;
}
.settlement_inner {
  width: 100%;
  display: flex;
}
@media screen and (max-width: 840px) {
  .settlement_inner {
    flex-direction: column;
  }
}
.settlement_main {
  flex: 1;
  margin-right: 24px;
}
@media screen and (max-width: 840px) {
  .settlement_main {
    margin: 0;
  }
}
.settlement_main_head {
  padding: 16px 24px;
  border-bottom: solid 1px #ebedf0;
  background-color: #fff;
}
.settlement_main_head h2 {
  font-family: Roboto;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #2c2e31;
}
.settlement_main_inner {
  padding: 36px 96px 32px;
  background-color: #fff;
}
@media screen and (max-width: 840px) {
  .settlement_main_inner {
    padding: 24px 16px 0;
    overflow: hidden;
  }
}
.settlement_main_inner_head {
  border-bottom: solid 1px #ebedef;
  display: flex;
  align-items: center;
  padding-bottom: 36px;
}
@media screen and (max-width: 840px) {
  .settlement_main_inner_head {
    display: block;
    text-align: center;
    padding-bottom: 24px;
  }
}
.settlement_main_inner_head h3 {
  margin-right: auto;
}
@media screen and (max-width: 840px) {
  .settlement_main_inner_head h3 {
    margin-bottom: 16px;
  }
}
.settlement_main_inner_head .btid_notes {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 1.1px;
  color: #707980;
}
@media screen and (max-width: 840px) {
  .settlement_main_inner_title {
    text-align: center;
  }
}
.settlement_main_inner_contents {
  padding: 16px 0;
}
.settlement_main_inner .settlement_customer_info {
  width: 100%;
}
@media screen and (max-width: 840px) {
  .settlement_main_inner .settlement_customer_info tr, .settlement_main_inner .settlement_customer_info td, .settlement_main_inner .settlement_customer_info th {
    display: block;
    width: 100%;
  }
  .settlement_main_inner .settlement_customer_info tr:not(:last-child) {
    margin-bottom: 24px;
  }
}
.settlement_main_inner .settlement_customer_info th, .settlement_main_inner .settlement_customer_info td {
  padding: 17px 0;
}
@media screen and (max-width: 840px) {
  .settlement_main_inner .settlement_customer_info th, .settlement_main_inner .settlement_customer_info td {
    padding: 0;
  }
}
.settlement_main_inner .settlement_customer_info th {
  white-space: nowrap;
  font-family: HiraKakuPro-W6;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 1.1px;
  color: #2c2e31;
  padding-right: 44px;
}
@media screen and (max-width: 840px) {
  .settlement_main_inner .settlement_customer_info th {
    padding-bottom: 8px;
  }
}
.settlement_main_inner .settlement_customer_info td {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .settlement_main_inner .settlement_customer_info td {
    padding: 0;
  }
}
.settlement_main_inner_section_head {
  margin-top: 16px;
  padding: 32px 0 24px;
  border-top: solid 1px #ebedef;
}
@media screen and (max-width: 840px) {
  .settlement_main_inner_section_head {
    padding: 24px 0;
    text-align: center;
  }
}
.settlement_main_inner_section .label-nb {
  font-family: HiraKakuPro-W6;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 1.1px;
  color: #2c2e31;
  text-align: left;
  margin: 24px 0 8px;
}
.settlement_main_inner_section .label-nb + textarea {
  width: 100%;
  height: 80px;
  border: solid 1px #dce0e3;
  padding: 13px 12px;
  font-size: 1.4rem;
}
.settlement_main_submit {
  margin-top: 1px;
  background-color: #fff;
  padding: 24px 96px;
}
@media screen and (max-width: 840px) {
  .settlement_main_submit {
    padding: 16px;
  }
}
.settlement_main_submit .form_btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 840px) {
  .settlement_main_submit .form_btns {
    flex-direction: column;
  }
}
.settlement_main_submit .form_btns p a {
  display: block;
  padding: 1em 0;
  font-family: HiraKakuPro-W6;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #707980;
}
.settlement_main_submit .form_btns p a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 840px) {
  .settlement_main_submit .form_btns p a {
    width: 100%;
    padding: 20px 0;
    margin-top: 10px;
  }
}
.settlement_main_submit .form_btns p {
  width: 100%;
  text-align: center;
}
.settlement_main_submit .form_btns_submit:last-child {
  display: block;
  width: 100%;
  max-width: 264px;
}
@media screen and (max-width: 840px) {
  .settlement_main_submit .form_btns_submit:last-child {
    max-width: 100%;
    order: -1;
  }
}
.settlement_main_submit .form_btns_submit:last-child input[type=submit], .settlement_main_submit .form_btns_submit:last-child input[type=button] {
  background-color: #4a90e2;
  width: 100%;
  height: 56px;
  color: #fff;
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  transition-property: background-color;
  transition-duration: 0.25s;
}
.settlement_main_submit .form_btns_submit:last-child input[type=submit]:hover, .settlement_main_submit .form_btns_submit:last-child input[type=button]:hover {
  background-color: #6ca7eb;
}
@media screen and (max-width: 840px) {
  .settlement_main_submit .form_btns_submit:last-child input[type=submit], .settlement_main_submit .form_btns_submit:last-child input[type=button] {
    height: 40px;
  }
}
.settlement_side {
  width: 360px;
}
@media screen and (max-width: 840px) {
  .settlement_side {
    width: 100%;
    margin-bottom: 20px;
    order: -1;
  }
}
.settlement_side_inner {
  position: sticky;
  top: 80px;
  padding: 32px;
  background-color: #fff;
}
@media screen and (max-width: 840px) {
  .settlement_side_inner {
    padding: 18px 16px;
  }
}
.settlement_side_price_table {
  width: 100%;
  margin-bottom: 21px;
}
.settlement_side_price_table th, .settlement_side_price_table td {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2c2e31;
  text-align: right;
  padding: 0 0 16px;
}
.settlement_side_price_table tr:last-child th, .settlement_side_price_table tr:last-child td {
  padding-bottom: 0;
  padding-top: 16px;
}
.settlement_side_price_table th {
  width: 8.25em;
  font-family: HiraKakuPro-W3;
}
.settlement_side_price_table td {
  font-family: HiraginoSans-W4;
  font-size: 1.2rem;
}
.settlement_side_price_table td span {
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.4rem;
}
.settlement_side_price_table .deliverycharge a {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  color: #2c2e31;
  text-decoration: underline;
}
.settlement_side_price_table .deliverycharge a:hover {
  text-decoration: none;
}
.settlement_side_price_table .total {
  border-top: solid 1px #ebedf0;
}
.settlement_section_title {
  font-family: HiraKakuPro-W6;
  font-size: 1.6rem;
  line-height: normal;
  letter-spacing: 1.5px;
  color: #2c2e31;
}

.slide_dl_inner {
  border: solid 1px #ebedf0;
}
.slide_dl_inner:not(:last-child) {
  border-bottom: none;
}
.slide_dl dt {
  padding: 16px 24px 8px;
}
.slide_dl dt:not(:first-child) {
  border-top: none;
}
@media screen and (max-width: 840px) {
  .slide_dl dt {
    padding: 21px 16px 10px;
  }
}
.slide_dl dt .radio_text {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: 1.8;
  letter-spacing: 1.3px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .slide_dl dt .radio_text {
    font-size: 1.3rem;
  }
}
.slide_dl dd {
  border-top: solid 1px #ebedf0;
  padding: 24px;
}
@media screen and (max-width: 840px) {
  .slide_dl dd {
    padding: 24px 16px;
  }
}
.slide_dl .has_dl {
  padding: 0;
}
.slide_dl .slide_dd {
  display: none;
}
.slide_dl h5 {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.3px;
  color: #2c2e31;
}
.slide_dl h5 + p {
  font-family: HiraKakuPro-W3;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #2c2e31;
  margin-top: 8px;
}
.slide_dl h5 + p + h5 {
  margin-top: 32px;
}
@media screen and (max-width: 840px) {
  .slide_dl .form_table tr:not(:last-child) {
    padding-bottom: 31px;
  }
}
.slide_dl .form_table th, .slide_dl .form_table td {
  padding: 12px 0;
  text-align: left;
}
@media screen and (max-width: 840px) {
  .slide_dl .form_table th, .slide_dl .form_table td {
    padding: 0;
  }
}
.slide_dl .form_table th {
  padding-top: 20px;
}
@media screen and (max-width: 840px) {
  .slide_dl .form_table th {
    padding: 0;
  }
}
.slide_dl .form_table td {
  width: 379px;
}
@media screen and (max-width: 840px) {
  .slide_dl .form_table td {
    width: 100%;
  }
}
.slide_dl .form_table td input, .slide_dl .form_table td select {
  border-radius: 0;
}
.slide_dl .form_table td select + input, .slide_dl .form_table td input + input {
  margin-top: 16px;
}
.slide_dl .form_table td select {
  background-position: right 12px center;
}
.slide_dl .form_table td p {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: 1.42;
  letter-spacing: 0.6px;
  color: #2c2e31;
  padding: 7px 0 16px;
}
.slide_dl .form_table tr:last-child td p {
  padding-bottom: 0;
}
@media screen and (max-width: 840px) {
  .slide_dl .form_table .col2 {
    display: block;
  }
}
.slide_dl .form_table .col2 input {
  width: 50%;
  max-width: 186px;
  margin: 0;
}
@media screen and (max-width: 840px) {
  .slide_dl .form_table .col2 input {
    width: 100%;
    max-width: 100%;
  }
  .slide_dl .form_table .col2 input + input {
    margin-top: 16px;
  }
}
.slide_dl .form_table .cal_btn {
  display: flex;
  justify-content: space-between;
}
.slide_dl .form_table .cal_btn input {
  width: 210px;
}
@media screen and (max-width: 840px) {
  .slide_dl .form_table .cal_btn input {
    width: 49%;
  }
}
.slide_dl .form_table .cal_btn .autozip {
  width: 160px;
  border: solid 1px #dce0e3;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: HiraKakuPro-W6;
  font-size: 1.3rem;
  line-height: 1;
  letter-spacing: normal;
  color: #707980;
  transition-property: border-color;
  transition-duration: 0.25s;
}
.slide_dl .form_table .cal_btn .autozip:hover {
  border-color: #bfcbd5;
}
@media screen and (max-width: 840px) {
  .slide_dl .form_table .cal_btn .autozip {
    width: 49%;
  }
}

.slide_dl2_inner:last-child {
  border-top: solid 1px #ebedf0;
}
.slide_dl2_inner:last-child dt {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}
@media screen and (max-width: 840px) {
  .slide_dl2_inner:last-child dt {
    flex-wrap: wrap;
  }
  .slide_dl2_inner:last-child dt label {
    width: 100%;
    margin-bottom: 10px;
  }
  .slide_dl2_inner:last-child dt label + img {
    margin-left: 25px;
  }
}
.slide_dl2_inner:last-child dt img + img {
  margin-left: 4px;
}
.slide_dl2_inner:last-child dd {
  display: none;
}
.slide_dl2 dt {
  padding: 16px 24px 8px 64px;
}
.slide_dl2 dt:not(:first-child) {
  border-top: none;
}
@media screen and (max-width: 840px) {
  .slide_dl2 dt {
    padding: 21px 16px 10px;
  }
}
.slide_dl2 dt .radio_text {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: 1.8;
  letter-spacing: 1.3px;
  color: #2c2e31;
}
.slide_dl2 dt .radio_text span {
  font-family: Roboto;
  font-weight: 400;
  margin-left: 20px;
}
@media screen and (max-width: 840px) {
  .slide_dl2 dt .radio_text {
    font-size: 1.3rem;
  }
}
.slide_dl2 dd {
  border-top: none;
  padding: 0 36px 24px 85px;
}
@media screen and (max-width: 840px) {
  .slide_dl2 dd {
    padding: 0 16px;
  }
}
.slide_dl2 dd table {
  width: 100%;
}
@media screen and (max-width: 840px) {
  .slide_dl2 dd table tr {
    display: block;
  }
}
.slide_dl2 dd table th, .slide_dl2 dd table td {
  padding: 8px 0;
}
@media screen and (max-width: 840px) {
  .slide_dl2 dd table th, .slide_dl2 dd table td {
    display: block;
  }
}
.slide_dl2 dd table th {
  font-family: HiraKakuPro-W6;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 1.1px;
  color: #2c2e31;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 840px) {
  .slide_dl2 dd table th {
    padding-bottom: 0;
  }
}
.slide_dl2 dd table td {
  text-align: right;
}
@media screen and (max-width: 840px) {
  .slide_dl2 dd table td {
    padding-bottom: 24px;
  }
}
.slide_dl2 dd table td input, .slide_dl2 dd table td select {
  display: inline-block;
  width: 268px;
}
@supports (-ms-ime-align: auto) {
  .slide_dl2 dd table td select {
    line-height: 1.4;
    padding-bottom: 3px;
  }
}
.slide_dl2 dd table td .error {
  border: solid 1px #f55367 !important;
}
.slide_dl2 dd table td .expirationdate {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}
@media screen and (max-width: 840px) {
  .slide_dl2 dd table td .expirationdate {
    justify-content: flex-start;
  }
}
.slide_dl2 dd table td .expirationdate select {
  width: 85px;
  margin-right: 9px;
  font-family: Roboto;
}
.slide_dl2 dd table td .expirationdate select:last-of-type {
  margin-left: 24px;
}
.slide_dl2 dd table td[colspan="2"] {
  text-align: left;
  padding-bottom: 0;
  padding-top: 16px;
}
@media screen and (max-width: 840px) {
  .slide_dl2 dd table td[colspan="2"] {
    padding-top: 0;
    padding-bottom: 16px;
  }
}
.slide_dl2 dd table td[colspan="2"] .checkbox_text {
  letter-spacing: 1.1px;
}
.slide_dl2 dd ul li {
  display: flex;
  align-items: center;
  font-family: HiraKakuPro-W6;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 1.1px;
  color: #2c2e31;
}
.slide_dl2 dd ul li a {
  margin-left: 5px;
}
.slide_dl2 dd ul li input, .slide_dl2 dd ul li select {
  max-width: 268px;
  margin-left: auto;
}
.slide_dl2 dd ul li + li {
  margin-top: 16px;
}

.has_cardicon {
  background: url(../img/settlement/discover.gif) right 24px top 18px/33px 24px no-repeat, url(../img/settlement/diners.png) right 61px top 18px/33px 24px no-repeat, url(../img/settlement/americanexpress.gif) right 98px top 18px/33px 24px no-repeat, url(../img/settlement/jcb.gif) right 135px top 18px/33px 24px no-repeat, url(../img/settlement/mastercard.png) right 172px top 18px/33px 24px no-repeat, url(../img/settlement/visacard.png) right 209px top 18px/31px 24px no-repeat;
}
@media screen and (max-width: 840px) {
  .has_cardicon {
    background: url(../img/settlement/discover.gif) left 219px top 50px/33px 24px no-repeat, url(../img/settlement/diners.png) left 181px top 50px/33px 24px no-repeat, url(../img/settlement/americanexpress.gif) left 145px top 50px/33px 24px no-repeat, url(../img/settlement/jcb.gif) left 112px top 50px/33px 24px no-repeat, url(../img/settlement/mastercard.png) left 76px top 50px/33px 24px no-repeat, url(../img/settlement/visacard.png) left 39px top 50px/31px 24px no-repeat;
  }
}

@media screen and (max-width: 840px) {
  .slide_dl .slide_dl_inner .has_cardicon {
    padding: 21px 16px 40px;
  }
}

.pay_tpoint {
  margin-top: 48px;
}
.pay_tpoint h4 {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.3px;
  color: #2c2e31;
  margin-bottom: 24px;
}
.pay_tpoint h4 span {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2c2e31;
  margin-left: 16px;
}
.pay_tpoint_list li {
  margin-bottom: 16px;
}
.pay_tpoint_list li:last-child {
  margin-top: 20px;
}
.pay_tpoint_list li .radio_text {
  font-family: HiraKakuPro-W6;
  font-size: 1.2rem;
  line-height: 1.9;
  letter-spacing: 0.5px;
  color: #2c2e31;
}
.pay_tpoint_form {
  display: flex;
  align-items: center;
}
.pay_tpoint_form .radio_text {
  margin-right: 21px;
}
.pay_tpoint_form input[type=text], .pay_tpoint_form input[type=number] {
  max-width: 112px;
  width: 100%;
  height: 32px;
  border: solid 1px #dce0e3;
  padding: 0 12px;
  margin-left: 0px;
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
}
.pay_tpoint_form input[type=number]::-webkit-outer-spin-button,
.pay_tpoint_form input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pay_tpoint_form input[type=number] {
  -moz-appearance: textfield;
}
.pay_tpoint_form span {
  margin-left: 8px;
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .pay_tpoint_form span {
    display: none;
  }
}

.info_icon {
  display: block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.1px;
  color: #fff;
  background-color: #bfcbd5;
  border-radius: 50%;
  margin-left: 8px;
}

.pay_tpoint_input[disabled] {
  background-color: #dce0e3;
}

.credit_disabled {
  color: #ccc !important;
  cursor: text !important;
}
.credit_disabled:hover:before {
  border: 1px solid #dce0e3 !important;
}

.credit_error {
  color: #707980;
  margin-top: 8px;
}

.settlement_complete {
  background-color: #fff;
  margin-bottom: 48px;
}
@media screen and (max-width: 840px) {
  .settlement_complete {
    margin-bottom: 32px;
  }
}
.settlement_complete_inner {
  padding: 80px 16px 75px;
}
@media screen and (max-width: 840px) {
  .settlement_complete_inner {
    padding: 40px 16px 16px;
  }
}
.settlement_complete .complete_title {
  text-align: center;
  position: relative;
  margin-bottom: 28px;
}
.settlement_complete .complete_title span {
  margin-bottom: 23px;
  text-align: center;
  z-index: 2;
  font-family: PTSerif-Caption;
  font-size: 40px;
  font-style: italic;
  line-height: 1;
  letter-spacing: 3.6px;
  color: #2c2e31;
  display: inline;
  position: relative;
  text-decoration: none;
}
@media screen and (max-width: 840px) {
  .settlement_complete .complete_title span {
    font-size: 30px;
    letter-spacing: 2.7px;
  }
}
.settlement_complete .complete_title:after {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: block;
  content: "";
  width: 264px;
  height: 17px;
  background-color: rgba(246, 163, 25, 0.3);
  transform: skew(-15deg);
}
@media screen and (max-width: 840px) {
  .settlement_complete .complete_title:after {
    width: 200px;
  }
}
.settlement_complete .complete_msg p {
  font-family: HiraKakuPro-W3;
  font-size: 1.6rem;
  line-height: 1.56;
  letter-spacing: 0.8px;
  text-align: center;
  color: #2c2e31;
}
.settlement_complete .complete_msg p b {
  font-family: HiraKakuPro-W6;
}
@media screen and (max-width: 840px) {
  .settlement_complete .complete_msg p {
    text-align: left;
    line-height: 1.5;
  }
  .settlement_complete .complete_msg p span {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}
.settlement_complete .complete_msg p + p {
  font-size: 14px;
  margin-top: 32px;
  text-align: center;
}
.settlement_complete_btn {
  margin-top: 80px;
}
@media screen and (max-width: 840px) {
  .settlement_complete_btn {
    margin-top: 64px;
  }
}
.settlement_complete_btn p {
  max-width: 225px;
  height: 40px;
  margin: 0 auto;
}
@media screen and (max-width: 840px) {
  .settlement_complete_btn p {
    max-width: 100%;
  }
}
.settlement_complete_btn p a {
  height: 100%;
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: 0.7px;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.settlement_complete_btn p + p {
  margin-top: 16px;
}
.settlement_complete_btn .btn_b {
  background-color: #2c2e31;
  transition-property: background-color;
  transition-duration: 0.25s;
}
.settlement_complete_btn .btn_b:hover {
  background-color: #707980;
}
.settlement_complete_btn .btn_w a {
  color: #707980;
}

.white_bg {
  background-color: #fff;
  border-bottom: solid 1px #f1f2f4;
}

@media screen and (max-width: 840px) {
  #for_oil-bijutsutecho-to-shop-sp + .gallery,
#for_oil-bijutsutecho-to-shop + .gallery {
    padding-top: 0;
  }
}

.gallery {
  max-width: 1128px;
  margin: 0 auto;
}
.gallery .page_head {
  margin-bottom: 32px;
}
@media screen and (max-width: 840px) {
  .gallery .page_head {
    flex-direction: column;
    padding: 24px 16px;
    margin-bottom: 0;
  }
  .gallery .page_head .page_title span {
    display: block;
    margin-left: 0;
    margin-top: 8px;
  }
}
.gallery .gallery_search {
  margin-left: auto;
}
@media screen and (max-width: 840px) {
  .gallery .gallery_search {
    margin-top: 24px;
  }
}
.gallery .gallery_search ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.gallery .gallery_search ul li:not(:last-child) {
  margin-right: 40px;
}
@media screen and (max-width: 840px) {
  .gallery .gallery_search ul li:last-child {
    width: 100%;
    margin-top: 24px;
  }
}
.gallery .gallery_search ul li label.checkbox_text {
  line-height: 2;
  padding-left: 29px;
}
.gallery .gallery_search ul li label.checkbox_text:before {
  width: 24px !important;
  height: 24px !important;
}
.gallery .gallery_search ul li label.checkbox_text:after {
  left: 8.4583333333px;
}
.gallery .gallery_search ul li input::-ms-clear {
  visibility: hidden;
}
.gallery .gallery_search ul li input[type=search] {
  width: 264px;
  background-color: #fff;
  margin-top: -5px;
  background-image: url(../img/common/ic-search_b.png);
  background-repeat: no-repeat;
  background-size: 19px;
  background-position: right 15px center;
}
.gallery .gallery_search ul li input[type=search]::-webkit-search-cancel-button, .gallery .gallery_search ul li input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
@media screen and (max-width: 840px) {
  .gallery .gallery_search ul li input[type=search] {
    width: 100%;
  }
}
.gallery .gallery_search .gallery_item_follow {
  margin: 0 12px;
}

.gallery_notfound {
  text-align: center;
  padding: 60px;
}
.gallery_notfound p {
  font-family: HiraKakuPro-W6;
  font-size: 1.8rem;
  line-height: normal;
  letter-spacing: normal;
  color: #707980;
}

.gallery_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
}
@media screen and (max-width: 840px) {
  .gallery_inner {
    padding: 0 16px;
    margin-left: 0;
  }
}

.pagination .pagination_ul {
  width: 100%;
  padding: 24px 24px 0;
  margin-bottom: 40px;
  border-top: solid 1px #f1f2f4;
}

.gallery_item {
  position: relative;
  width: calc(33.333% - 24px);
  margin-left: 24px;
  margin-bottom: 40px;
}
@media screen and (max-width: 840px) {
  .gallery_item {
    width: 100%;
    margin-left: 0;
    margin-bottom: 24px;
  }
}
.gallery_item:hover .gallery_item_img:after {
  background-color: rgba(0, 0, 0, 0.3);
}
.gallery_item:hover .gallery_item_img img {
  transform: scale(1.2);
}
.gallery_item .gallery_item_img {
  position: relative;
  overflow: hidden;
}
.gallery_item .gallery_item_img:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-property: background-color;
  transition-duration: 0.4s;
  background-color: transparent;
}
.gallery_item .gallery_item_img img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: transform 0.2s linear;
}
.gallery_item .gallery_item_text {
  margin-top: 12px;
  display: flex;
}
.gallery_item .gallery_item_text .gallery_item_info {
  max-width: 250px;
  flex: 1;
  min-width: 0;
}
@media screen and (max-width: 840px) {
  .gallery_item .gallery_item_text .gallery_item_info {
    max-width: 230px;
  }
}
.gallery_item .gallery_item_text .gallery_item_info h3 {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gallery_item .gallery_item_text .gallery_item_info p {
  font-family: HiraMinProN-W3;
  font-size: 1.1rem;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #2c2e31;
  margin-top: 8px;
}

.gallery_item_follow input[type=button] {
  cursor: pointer;
  width: 100px;
  height: 32px;
  padding: 9px 12px;
  text-align: right;
  border: solid 1px #dce0e3;
  border-radius: 21.5px;
  line-height: 1;
  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 1.6px;
  color: #2c2e31;
  background-image: url(../img/gallery/ic-follow.png);
  background-position: left 12px center;
  background-size: 12px;
  background-repeat: no-repeat;
  transition-property: border;
  transition-duration: 0.25s;
}
.gallery_item_follow input[type=button]:hover {
  border: solid 1px #bfcbd5;
}
.gallery_item_follow .following {
  background: #dce0e3 !important;
  color: #fff !important;
  text-align: center !important;
}
.gallery_item_follow .following:hover {
  border: solid 1px #dce0e3 !important;
}

.gallery_list .gallery_item_follow, .gallery_list .gallery_item_follow {
  position: absolute;
  right: 0;
  bottom: 1.5em;
  display: flex;
  align-items: flex-end;
}

.gallery .pagination {
  margin-bottom: 50px;
}
@media screen and (max-width: 840px) {
  .gallery .pagination {
    padding: 0 16px;
    margin-bottom: 30px;
  }
}
.gallery .pagination li:not(.on) {
  border: solid 1px #ebedf0;
  transition-property: border;
  transition-duration: 0.25s;
}
.gallery .pagination li:not(.on):hover {
  border: solid 1px #bfcbd5;
}

@media screen and (max-width: 840px) {
  .gallery_details {
    padding: 20px 16px;
  }
}

.gallery_introduction {
  width: 100%;
  background-color: #fff;
  padding: 48px 40px;
  margin-bottom: 48px;
  display: flex;
}
@media screen and (max-width: 840px) {
  .gallery_introduction {
    flex-direction: column;
    padding: 16px;
    margin-bottom: 33px;
  }
}
.gallery_introduction_text {
  width: 624px;
  margin-right: auto;
}
@media screen and (max-width: 840px) {
  .gallery_introduction_text {
    width: 100%;
    padding-bottom: 16px;
    margin-bottom: 16px;
    margin-right: 0;
    border-bottom: solid 1px #ebedf0;
  }
}
.gallery_introduction_text h3 {
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 2px;
  color: #2c2e31;
  margin-bottom: 24px;
}
@media screen and (max-width: 840px) {
  .gallery_introduction_text h3 {
    margin-bottom: 16px;
  }
}
.gallery_introduction_info {
  width: 336px;
  margin-left: 20px;
}
@media screen and (max-width: 840px) {
  .gallery_introduction_info {
    width: 100%;
    margin-left: 0;
  }
}
.gallery_introduction_info .policy {
  text-align: right;
  margin-top: 16px;
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  letter-spacing: 0.6px;
}
.gallery_introduction_info .policy a {
  color: #2c2e31;
  text-decoration: underline;
  transition-property: color;
  transition-duration: 0.25s;
}
.gallery_introduction_info .policy a:hover {
  text-decoration: none;
  color: #4a90e2;
}
.gallery_introduction_head {
  display: flex;
  margin-bottom: 24px;
}
.gallery_introduction_head .gallery_item_follow {
  margin-left: auto;
}
.gallery_introduction_title {
  padding-right: 16px;
}
.gallery_introduction_title h2 {
  text-align: left;
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-weight: bold;
  font-size: 2.8rem;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #2c2e31;
  margin-bottom: 8px;
}
@media screen and (max-width: 840px) {
  .gallery_introduction_title h2 {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.5px;
  }
}
.gallery_introduction_title .gallery_place {
  font-family: HiraMinPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #707980;
}
.gallery_introduction_main {
  margin-bottom: 28px;
}
@media screen and (max-width: 840px) {
  .gallery_introduction_main {
    margin-bottom: 20px;
  }
}
.gallery_introduction_main p {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: 1.69;
  letter-spacing: 0.6px;
  color: #2c2e31;
}
.gallery_introduction_artists ul {
  display: flex;
  flex-wrap: wrap;
}
.gallery_introduction_artists ul li {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
  width: 33.3333%;
  text-indent: -0.8em;
  padding-left: calc(0.8em + 24px);
  transition-property: color;
  transition-duration: 0.25s;
}
.gallery_introduction_artists ul li:nth-child(3n+1) {
  padding-left: 0.8em;
}
@media screen and (max-width: 840px) {
  .gallery_introduction_artists ul li {
    width: 50%;
    padding-left: calc(0.8em + 16px);
  }
  .gallery_introduction_artists ul li:nth-child(odd) {
    padding-left: 0.8em;
  }
}
.gallery_introduction_artists ul li a {
  color: #2c2e31;
}
.gallery_introduction_artists ul li a:hover {
  text-decoration: underline;
}
.gallery_introduction_artists ul li:not(:last-child) {
  margin-bottom: 18px;
}
.gallery_introduction_artists ul li:before {
  content: "-";
  padding-right: 0.5em;
}
.gallery_introduction_img {
  margin-bottom: 22px;
  background-color: #fbfbfb;
}
.gallery_introduction_img img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.gallery_introduction_modal h3 {
  font-family: HiraKakuPro-W6;
  margin-bottom: 8px;
}
.gallery_introduction_modal p {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: 1.85;
  letter-spacing: 1.2px;
  color: #2c2e31;
}
.gallery_introduction_modal p + h3 {
  margin-top: 24px;
}

.artists {
  max-width: 1128px;
  margin: 0 auto;
}
.artists .page_head {
  align-items: center;
}
@media screen and (max-width: 840px) {
  .artists .page_head {
    align-items: flex-start;
    flex-direction: column;
    padding: 24px 16px;
    margin-bottom: 0;
  }
}
.artists_footer {
  margin-bottom: 48px;
}
@media screen and (max-width: 840px) {
  .artists_footer {
    padding: 0 16px;
  }
}
.artists_footer ul {
  padding-top: 18px;
  border-top: solid 1px #f1f2f4;
}
@media screen and (max-width: 840px) {
  .artists_footer ul {
    padding-top: 24px;
  }
}
.artists_index {
  margin-left: auto;
  display: flex;
}
@media screen and (max-width: 840px) {
  .artists_index {
    width: 100%;
    margin-top: 24px;
    margin-left: 0;
    justify-content: space-between;
  }
}
.artists_index li:not(:last-child) {
  margin-right: 16px;
}
.artists_index li a {
  display: block;
  font-family: HiraKakuPro-W3;
  font-size: 1.6rem;
  color: #4a90e2;
}
.artists_index li a:hover {
  text-decoration: underline;
}
.artists_index .current a {
  font-family: HiraKakuPro-W6;
  color: #2c2e31;
}
.artists_search {
  margin-left: 40px;
}
@media screen and (max-width: 840px) {
  .artists_search {
    width: 100%;
    margin: 16px 0 0;
  }
}
.artists_search ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.artists_search ul li:not(:last-child) {
  margin-right: 40px;
}
@media screen and (max-width: 840px) {
  .artists_search ul li:last-child {
    width: 100%;
    margin-top: 0;
  }
}
.artists_search ul li input::-ms-clear {
  visibility: hidden;
}
.artists_search ul li input[type=search] {
  width: 264px;
  background-color: #fff;
  margin-top: -5px;
  background-image: url(../img/common/ic-search_b.png);
  background-repeat: no-repeat;
  background-size: 19px;
  background-position: right 15px center;
}
.artists_search ul li input[type=search]::-webkit-search-cancel-button, .artists_search ul li input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
@media screen and (max-width: 840px) {
  .artists_search ul li input[type=search] {
    width: 100%;
  }
}
@media screen and (max-width: 840px) {
  .artists_inner {
    padding: 0 16px;
  }
}
.artists .artists_list {
  margin-bottom: 40px;
}
@media screen and (max-width: 840px) {
  .artists .artists_list {
    margin-bottom: 32px;
  }
}
.artists .artists_list h3 {
  font-family: HiraKakuPro-W6;
  font-size: 2.4rem;
  line-height: 1;
  letter-spacing: normal;
  color: #bfcbd5;
  margin-bottom: 16px;
}
.artists .artists_list_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
}
@media screen and (max-width: 840px) {
  .artists .artists_list_inner {
    flex-direction: column;
    margin-left: 0;
  }
}

.artists_item {
  position: relative;
  width: calc(33.333% - 24px);
  margin-left: 24px;
  border: solid 1px #ebedef;
  margin-bottom: 16px;
  transition-property: border;
  transition-duration: 0.25s;
}
@media screen and (max-width: 840px) {
  .artists_item {
    width: 100%;
    margin-left: 0;
  }
}
.artists_item:hover {
  border: solid 1px #bfcbd5;
}
.artists_item a {
  display: flex;
  align-items: center;
  padding: 16px;
}
@media screen and (max-width: 840px) {
  .artists_item a {
    padding: 16px 8px;
  }
}
.artists_item:nth-last-child(-n+3) {
  margin-bottom: 0;
}
@media screen and (max-width: 840px) {
  .artists_item:nth-last-child(-n+3) {
    margin-bottom: 16px;
  }
}
.artists_item:last-child {
  margin-bottom: 0;
}
.artists_item_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
  background-color: #fbfbfb;
}
.artists_item_img img {
  width: 100%;
  height: auto;
}
.artists_item_text {
  max-width: 165px;
  flex: 1;
  min-width: 0;
}
.artists_item_text h4 {
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #2c2e31;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.artists_item_text p {
  font-family: HiraMinPro-W3;
  font-size: 1.1rem;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #707980;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.artists_item_follow {
  position: absolute;
  right: 16px;
  bottom: 1.5em;
  display: flex;
}
.artists_item_follow input[type=button] {
  cursor: pointer;
  width: 100px;
  height: 32px;
  padding: 9px 12px;
  text-align: right;
  border: solid 1px #dce0e3;
  border-radius: 21.5px;
  line-height: 1;
  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 1.6px;
  color: #2c2e31;
  background-image: url(../img/gallery/ic-follow.png);
  background-position: left 12px center;
  background-size: 12px;
  background-repeat: no-repeat;
  transition-property: border;
  transition-duration: 0.25s;
}
.artists_item_follow input[type=button]:hover {
  border: solid 1px #bfcbd5;
}
.artists_item_follow .following {
  background: #dce0e3 !important;
  color: #fff !important;
  text-align: center !important;
}
.artists_item_follow .following:hover {
  border: solid 1px #dce0e3 !important;
}

.artists_career ul li {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: 1.83;
  letter-spacing: 0.6px;
  color: #707980;
}
.artists_career ul li time {
  margin-right: 1em;
}

.artistSwiper {
  position: relative;
  overflow: hidden;
  padding-bottom: 25px;
}
.artistSwiper .swiper-pagination {
  bottom: 0px !important;
}
.artistSwiper .swiper-pagination .swiper-pagination-bullet {
  background: black;
  opacity: 0.25;
  margin: 0 10px !important;
}
.artistSwiper .swiper-pagination .swiper-pagination-bullet-active {
  background: black;
  opacity: 1;
}
.artistSwiper .artists_details_img .swiper-slide-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 336px;
  height: 336px;
  background-color: #f1f2f4;
}
.artistSwiper .artists_details_img .swiper-slide-img img {
  width: 336px;
  height: 336px;
  object-fit: contain;
}
.artistSwiper .artists_details_img_caption {
  font-size: 11px;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #707980;
  margin-top: 8px;
  max-width: 336px;
}
@media screen and (max-width: 840px) {
  .artistSwiper .artists_details_img_caption {
    padding: 0 20px;
  }
}

.wimg {
  width: 100% !important;
  height: auto !important;
}

.himg {
  height: 100% !important;
  width: auto !important;
}

.search_result_area .pan {
  margin-bottom: 0;
}
.search_result_area_white {
  background-color: #fff;
  border-bottom: solid 1px #ebedf0;
}
.search_result_area_white .search_result_head {
  border-bottom: solid 1px #ebedf0;
}
.search_result_area_white .pagination {
  border-top: solid 1px #f1f2f4;
  padding-top: 24px;
}
@media screen and (max-width: 840px) {
  .search_result_area_white .pagination {
    padding: 16px 16px 0;
    margin-bottom: 30px;
  }
}
.search_result_area_white .pagination li:not(.on) {
  border: solid 1px #ebedf0;
  transition-property: border;
  transition-duration: 0.25s;
}
.search_result_area_white .pagination li:not(.on):hover {
  border: solid 1px #bfcbd5;
}

.search_result_head {
  background-color: #fff;
  margin-bottom: 24px;
}
@media screen and (max-width: 840px) {
  .search_result_head {
    margin-bottom: 16px;
  }
}
.search_result_head_inner {
  max-width: 1128px;
  margin: 0 auto;
  padding: 16px 0 24px;
}
@media screen and (max-width: 840px) {
  .search_result_head_inner {
    padding: 16px;
  }
}
.search_result_head_form {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
@media screen and (max-width: 840px) {
  .search_result_head_form {
    flex-direction: column;
    margin-bottom: 0;
  }
}
.search_result_head_search {
  display: flex;
  width: 100%;
}
@media screen and (max-width: 840px) {
  .search_result_head_search {
    margin-bottom: 24px;
  }
}
.search_result_head_search::before {
  display: block;
  width: 35px;
  border-right: solid 1px #dce0e3;
  height: 40px;
  content: "";
  background-image: url(../img/common/ic-search_b.png);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 19px;
}
@media screen and (max-width: 840px) {
  .search_result_head_search::before {
    height: 24px;
  }
}
.search_result_head_search input {
  width: 100%;
  height: 40px;
  padding: 0 16px;
  font-family: HiraKakuPro-W3;
  font-size: 1.8rem;
  line-height: normal;
  letter-spacing: 0.9px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .search_result_head_search input {
    font-size: 1.4rem;
    letter-spacing: 0.7;
    height: 24px;
  }
}
.search_result_head_search input::-webkit-search-cancel-button, .search_result_head_search input::-webkit-search-decoration {
  -webkit-appearance: none;
}
@media screen and (max-width: 840px) {
  .search_result_head_select {
    width: 100%;
  }
}
.search_result_head_select_tab {
  display: none;
}
@media screen and (max-width: 840px) {
  .search_result_head_select_tab {
    width: 100%;
    margin-bottom: 8px;
    display: block;
  }
}
.search_result_head_select select {
  width: 160px;
  padding-right: 36px;
}
@media screen and (max-width: 840px) {
  .search_result_head_select select {
    width: 100%;
  }
  .search_result_head_select select:not(:last-child) {
    margin-right: 17px;
  }
}
.search_result_head_tab {
  display: flex;
}
@media screen and (max-width: 840px) {
  .search_result_head_tab {
    display: none;
  }
}
.search_result_head_tab li {
  width: 20%;
}
.search_result_head_tab li a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border: solid 1px #ebedf0;
  font-family: Roboto;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: 1.8px;
  color: #2c2e31;
  margin-left: -1px;
  z-index: 1;
  transition-property: border-color;
  transition-duration: 0.25s;
}
.search_result_head_tab li:not(.current) a:hover {
  z-index: 99;
  border-color: #bfcbd5;
}
.search_result_head_tab li:first-child a {
  border-left: solid 1px #ebedf0;
}
.search_result_head_tab .current a {
  border-color: #2c2e31;
  background-color: #2c2e31;
  color: #fff;
}
.search_result_main {
  max-width: 1128px;
  margin: 0 auto 64px;
}
@media screen and (max-width: 840px) {
  .search_result_main {
    padding: 0 16px;
  }
}
.search_result_main .gallery_inner {
  padding: 0;
  border: none;
}
.search_result_main .artist_list_inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
}
@media screen and (max-width: 840px) {
  .search_result_main .artist_list_inner {
    margin-left: 0;
  }
}

.index_wrap.crosssearch_wrap {
  background-color: #fff;
}

.crosssearch .crosssearch_inner {
  max-width: 1127px;
  margin: 0 auto;
  padding: 23px 32px;
  background-color: #F6F6F6;
}
@media screen and (max-width: 840px) {
  .crosssearch .crosssearch_inner {
    padding: 16px;
  }
}
.crosssearch .crosssearch_inner .form_wrap {
  display: flex;
  align-items: center;
}
.crosssearch .crosssearch_inner input[type=search] {
  display: block;
  width: 100%;
  max-width: 350px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.4;
  padding: 10px 20px;
}
@media screen and (max-width: 840px) {
  .crosssearch .crosssearch_inner input[type=search] {
    padding: 10px 7px;
    font-size: 1.2rem;
    height: 40px;
  }
}
.crosssearch .crosssearch_inner input[type=submit] {
  display: block;
  width: 66px;
  height: 40px;
  margin: 0 0 0 11px;
  background-color: #2C2E31;
  background-image: url(../img/common/ic-search_w.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 13px 14px;
}
.crosssearch .crosssearch_inner ::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.crosssearch_result_text {
  max-width: 1127px;
  margin: 31px auto 0;
}
@media screen and (max-width: 840px) {
  .crosssearch_result_text {
    padding: 0 16px;
  }
}
.crosssearch_result_text h2 {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #000000;
}

.crosssearch_result {
  max-width: 1127px;
  margin: 40px auto 0;
}
@media screen and (max-width: 840px) {
  .crosssearch_result {
    padding: 0 16px;
    margin: 20px 0 0;
  }
}

.crosssearch_result_main + .crosssearch_result_main {
  margin: 40px 0 0 0;
}
@media screen and (max-width: 840px) {
  .crosssearch_result_main + .crosssearch_result_main {
    margin: 20px 0 0 0;
  }
}
.crosssearch_result_main .crosssearch_result_title {
  display: inline-block;
  white-space: nowrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 21px;
  letter-spacing: 0.05em;
  margin: 0 21px 0 0;
  vertical-align: middle;
}
@media screen and (max-width: 840px) {
  .crosssearch_result_main .crosssearch_result_title {
    display: block;
    margin-bottom: 10px;
  }
}
.crosssearch_result_main a {
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #5398FF;
  line-height: 2.3;
  vertical-align: middle;
}
@media screen and (max-width: 840px) {
  .crosssearch_result_main a {
    line-height: 1.5;
  }
}
.crosssearch_result_main a:not(:last-child) {
  margin-right: 21px;
}

.crosssearch_result_items {
  max-width: 1127px;
  margin: 40px auto 0;
}
@media screen and (max-width: 840px) {
  .crosssearch_result_items {
    margin: 20px 0 0;
    padding: 0 16px;
  }
}
.crosssearch_result_items .crosssearch_result_items_head {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 840px) {
  .crosssearch_result_items .crosssearch_result_items_head {
    flex-wrap: wrap;
  }
}
.crosssearch_result_items .crosssearch_result_items_head .crosssearch_result_number {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 120%;
  letter-spacing: 0.05em;
  color: #2C2E31;
}
@media screen and (max-width: 840px) {
  .crosssearch_result_items .crosssearch_result_items_head .crosssearch_result_number {
    margin-left: auto;
  }
}
.crosssearch_result_items .crosssearch_result_items_head_form {
  display: flex;
  align-items: center;
  margin-left: auto;
}
@media screen and (max-width: 840px) {
  .crosssearch_result_items .crosssearch_result_items_head_form {
    width: 100%;
    justify-content: space-between;
    margin: 13px 0 0 0;
  }
}
.crosssearch_result_items .crosssearch_result_items_head_form label {
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  color: #000000;
}
.crosssearch_result_items .crosssearch_result_items_head_form label span {
  display: inline-block;
  margin-left: 8px;
}
.crosssearch_result_items .crosssearch_result_items_head_form select {
  width: 264px;
  padding: 11px 19px;
  border: 1px solid #E5E5E5;
  margin: 0 0 0 25px;
  font-size: 1.4rem;
  line-height: 120%;
  letter-spacing: 0.05em;
  background-image: url(../img/common/arrow_b.png);
  background-position: right 19px center;
  background-repeat: no-repeat;
  background-size: 12px 7px;
}
@media screen and (max-width: 840px) {
  .crosssearch_result_items .crosssearch_result_items_head_form select {
    width: 140px;
  }
}
.crosssearch_result_items .crosssearch_result_title {
  white-space: nowrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 21px;
  letter-spacing: 0.05em;
  margin: 0 21px 0 0;
  vertical-align: middle;
}
.crosssearch_result_items .pickup {
  margin: 16px 0 0 0;
}
.crosssearch_result_items .pickup .pickup_inner {
  margin: 0 auto;
  padding: 0;
}
.crosssearch_result_items .pickup .pickup_inner .pickup_wrap {
  margin: 0 0 0 -24px;
}
@media screen and (max-width: 840px) {
  .crosssearch_result_items .pickup .pickup_inner .pickup_wrap {
    margin: 0 0 0 -7px;
  }
}
.crosssearch_result_items .pagination.pagination_pc li:not(.on) {
  border: 1px solid #ebedf0;
}
.crosssearch_result_items .pagination.pagination_pc .pagination_ul {
  padding: 30px 0 0 0;
}
.crosssearch_result_items .pagination.pagination_sp li:not(.on) {
  border: 1px solid #ebedf0;
}

.unfound {
  max-width: 1127px;
  min-height: 120px;
  margin: 16px auto 0;
}
@media screen and (max-width: 840px) {
  .unfound {
    padding: 0 16px;
  }
}
.unfound .unfound_msg {
  font-size: 1.4rem;
  line-height: 120%;
  letter-spacing: 0.05em;
  color: #2C2E31;
}

.mypage .pan {
  margin-bottom: 0;
}

.mypage_wrap {
  margin-bottom: 64px;
}
@media screen and (max-width: 840px) {
  .mypage_wrap {
    margin-bottom: 48px;
  }
}

/*------------------------------------*\
    mypage_head
\*------------------------------------*/
.mypage_head {
  background-color: #fff;
}
.mypage_head_inner {
  max-width: 1128px;
  margin: 0 auto;
  padding: 24px 0 0;
}
@media screen and (max-width: 840px) {
  .mypage_head_inner {
    padding: 16px;
  }
}
.mypage_head_top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.mypage_head_top .sp_logout {
  display: none;
  margin-left: auto;
  font-family: HiraKakuPro-W3;
  font-size: 1.1rem;
  line-height: normal;
  letter-spacing: 1.5px;
  text-align: center;
}
.mypage_head_top .sp_logout a {
  color: #2c2e31;
  display: block;
  padding: 0.5em;
}
@media screen and (max-width: 840px) {
  .mypage_head_top .sp_logout {
    display: block;
  }
}
.mypage_head_top .mypage_tools {
  display: flex;
  margin-left: auto;
}
@media screen and (max-width: 840px) {
  .mypage_head_top .mypage_tools {
    width: 100%;
    margin-top: 16px;
  }
}
.mypage_head_top .mypage_tools li {
  font-size: 1.3rem;
}
.mypage_head_top .mypage_tools li:not(:last-child) {
  border-right: solid 1px #bfcbd5;
}
@media screen and (max-width: 840px) {
  .mypage_head_top .mypage_tools li {
    font-size: 1.1rem;
  }
  .mypage_head_top .mypage_tools li:last-child {
    display: none;
  }
  .mypage_head_top .mypage_tools li:nth-child(3) {
    border: none;
  }
}
.mypage_head_top .mypage_tools li a, .mypage_head_top .mypage_tools li span {
  color: #2c2e31;
  padding: 0 16px;
  line-height: 1;
}
@media screen and (max-width: 840px) {
  .mypage_head_top .mypage_tools li a, .mypage_head_top .mypage_tools li span {
    padding: 0 8px;
  }
}
.mypage_head_top .mypage_tools li a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 840px) {
  .mypage_head_top .mypage_tools li span {
    padding-left: 0;
  }
}
.mypage_head_top .mypage_tools .current a {
  font-weight: bold;
}

.mypage_nav {
  display: flex;
  margin-top: 23px;
}
@media screen and (max-width: 840px) {
  .mypage_nav {
    display: block;
  }
}
.mypage_nav ul {
  display: flex;
}
@media screen and (max-width: 840px) {
  .mypage_nav ul {
    flex-wrap: wrap;
  }
}
.mypage_nav ul + ul {
  margin-left: auto;
}
@media screen and (max-width: 840px) {
  .mypage_nav ul + ul {
    margin: 16px 0 0;
  }
}
@media screen and (max-width: 840px) {
  .mypage_nav ul .mypage_link {
    width: 100%;
  }
}
@media screen and (max-width: 840px) {
  .mypage_nav ul:first-child li:first-child {
    border-bottom: solid 1px #ebedf0;
  }
  .mypage_nav ul:first-child li:nth-last-child(-n+2) {
    border-bottom: solid 1px #ebedf0;
  }
  .mypage_nav ul:first-child li:nth-child(2), .mypage_nav ul:first-child li:nth-child(3) {
    border-top: none;
  }
  .mypage_nav ul:first-child li:last-child {
    border-right: solid 1px #ebedf0 !important;
  }
  .mypage_nav ul:first-child li:nth-child(even) {
    border-right: none;
  }
  .mypage_nav ul:last-child li:nth-last-child(-n+2) {
    border-bottom: solid 1px #ebedf0;
  }
  .mypage_nav ul:last-child li:last-child {
    border-right: solid 1px #ebedf0 !important;
  }
  .mypage_nav ul:last-child li:nth-child(odd) {
    border-right: none;
  }
}
@media screen and (max-width: 840px) {
  .mypage_nav ul li {
    width: 50%;
    border: solid 1px #ebedf0;
    border-bottom: none;
  }
}
.mypage_nav ul li a, .mypage_nav ul li span {
  display: block;
  padding: 16px;
  border-bottom: solid 3px #fff;
  font-family: Roboto;
  font-size: 1.3rem;
  line-height: 1;
  letter-spacing: 1.8px;
  text-align: center;
  color: #707980;
  transition-property: border-color;
  transition-duration: 0.25s;
}
@media screen and (max-width: 840px) {
  .mypage_nav ul li a, .mypage_nav ul li span {
    border: none;
    padding: 16px 10px;
    text-align: center;
    font-size: 1.2rem;
    letter-spacing: 1.7px;
    color: #2c2e31;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.mypage_nav ul li a:hover, .mypage_nav ul li span:hover {
  border-color: #2c2e31;
}
.mypage_nav ul .dummy {
  display: none;
  background-color: #ececec;
}
@media screen and (max-width: 840px) {
  .mypage_nav ul .dummy {
    display: block;
  }
}
.mypage_nav ul .current a {
  border-bottom: solid 3px #2c2e31;
  font-weight: 500;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .mypage_nav ul .current a {
    border: none;
    color: #fff;
    background-color: #2c2e31;
    border: solid 1px #2c2e31;
  }
}

/*------------------------------------*\
    mypage_main
\*------------------------------------*/
.order_status {
  max-width: 1128px;
  margin: 24px auto 0;
}
@media screen and (max-width: 840px) {
  .order_status {
    padding: 0 16px;
  }
}
.order_status_list {
  margin-top: 16px;
}
.order_status_list li {
  padding: 21px;
  background-color: #fff;
  border-bottom: solid 1px #f1f2f4;
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .order_status_list li {
    padding: 12px 16px;
    line-height: 1.46;
    text-align: left;
  }
}
.order_status_list li time {
  font-family: Roboto;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: 1px;
  color: #707980;
  margin-right: 16px;
}
@media screen and (max-width: 840px) {
  .order_status_list li time {
    display: block;
    margin-right: 0;
    margin-bottom: 8px;
  }
}
.order_status_link {
  background-color: #fff;
  text-align: right;
}
.order_status_link a {
  display: inline-block;
  height: 48px;
  line-height: 48px;
  padding: 0 21px;
}
@media screen and (max-width: 840px) {
  .order_status_link a {
    width: 100%;
    height: 40px;
    line-height: 40px;
  }
}
@media screen and (max-width: 840px) {
  .order_status_link {
    text-align: center;
  }
}
.order_status_link span {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
  background-image: url(/common/img/common/bt-link.png);
  background-position: right center;
  background-size: 14px 10px;
  background-repeat: no-repeat;
  padding-right: 22px;
}

/*------------------------------------*\

\*------------------------------------*/
.favorite_artworks {
  max-width: 1128px;
  margin: 48px auto 0;
}
@media screen and (max-width: 840px) {
  .favorite_artworks {
    padding: 0 16px;
    margin-top: 24px;
  }
}
.favorite_artworks .artworks_inner {
  margin-top: 16px;
}
.favorite_artworks .go_list_btn a {
  height: 48px;
  line-height: 48px;
}
@media screen and (max-width: 840px) {
  .favorite_artworks .go_list_btn a {
    height: 40px;
    line-height: 40px;
  }
}

.favorite_goods {
  max-width: 1128px;
  margin: 48px auto 0;
}
@media screen and (max-width: 840px) {
  .favorite_goods {
    padding: 0 16px;
    margin-top: 24px;
  }
}
.favorite_goods .goods_inner {
  margin-top: 16px;
}
.favorite_goods .go_list_btn a {
  height: 48px;
  line-height: 48px;
}
@media screen and (max-width: 840px) {
  .favorite_goods .go_list_btn a {
    height: 40px;
    line-height: 40px;
  }
}

.notfound {
  margin-top: 128px;
}
@media screen and (max-width: 840px) {
  .notfound {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
.notfound h3 {
  font-family: HiraKakuPro-W3;
  font-size: 1.8rem;
  line-height: 1;
  letter-spacing: 2.5px;
  text-align: center;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .notfound h3 {
    font-size: 1.4rem;
    letter-spacing: 1.9px;
  }
}
.notfound .notfound_msg {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: 1;
  letter-spacing: 1.8px;
  text-align: center;
  color: #707980;
  margin-top: 16px;
}
@media screen and (max-width: 840px) {
  .notfound .notfound_msg {
    font-size: 1.2rem;
    letter-spacing: 0.5px;
  }
}
.notfound .notfound_msg + p {
  margin-top: 40px;
}
.notfound p a {
  display: block;
  height: 40px;
  width: 175px;
  line-height: 40px;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  border: solid 1px #ebedf0;
  font-family: Roboto;
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  color: #2c2e31;
  transition-property: border-color;
  transition-duration: 0.25s;
}
.notfound p a:hover {
  border: solid 1px #bfcbd5;
}

.favorite_tab {
  max-width: 1128px;
  margin: 24px auto 0;
  display: flex;
}
@media screen and (max-width: 840px) {
  .favorite_tab {
    padding: 0 16px;
  }
}
.favorite_tab li {
  width: 50%;
  text-align: center;
  background-color: #fff;
}
.favorite_tab li a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  font-family: Roboto;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: 1.8px;
  color: #2c2e31;
  border: solid 1px #fff;
  transition-property: border;
  transition-duration: 0.25s;
}
.favorite_tab li a:hover {
  border: solid 1px #bfcbd5;
}
@media screen and (max-width: 840px) {
  .favorite_tab li a {
    height: 32px;
  }
}
.favorite_tab .current a {
  background-color: #2c2e31;
  color: #fff;
  border: solid 1px #2c2e31;
}
.favorite_tab .current a:hover {
  border: solid 1px #2c2e31;
}
.favorite_tab + .favorite_artworks {
  margin-top: 24px;
}
@media screen and (max-width: 840px) {
  .favorite_tab + .favorite_artworks {
    margin-top: 16px;
  }
}

.favorite_head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: 840px) {
  .favorite_head .page_title {
    width: 100%;
    order: 1;
    margin-top: 24px;
  }
}
.favorite_head ul {
  display: flex;
  margin-left: auto;
  margin-right: 16px;
}
@media screen and (max-width: 840px) {
  .favorite_head ul {
    margin-left: 0;
    margin-right: auto;
  }
}
.favorite_head ul li {
  width: 100px;
  height: 32px;
  background-color: #fff;
  font-family: HiraginoSans-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 1.7px;
  text-align: center;
}
@media screen and (max-width: 840px) {
  .favorite_head ul li {
    width: 82px;
  }
}
.favorite_head ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  color: #2c2e31;
  border: solid 1px #fff;
  transition-property: border;
  transition-duration: 0.25s;
}
.favorite_head ul li a:hover {
  border: solid 1px #bfcbd5;
}
.favorite_head ul .current {
  background-color: #2c2e31;
}
.favorite_head ul .current a {
  color: #fff;
  border: solid 1px #2c2e31;
}
.favorite_head ul .current a:hover {
  border: solid 1px #2c2e31;
}
.favorite_head .form select {
  width: 148px;
  height: 32px;
  padding: 0 5px !important;
  font-size: 1.1rem;
  text-align: left;
}
@media screen and (max-width: 840px) {
  .favorite_head .form select {
    width: 165px;
  }
}

.white_head {
  border-bottom: solid 1px #ebedf0;
}

.follow .page_title {
  margin-bottom: 24px;
}

.information {
  max-width: 1128px;
  margin: 24px auto 0;
}
@media screen and (max-width: 840px) {
  .information {
    padding: 0 16px;
  }
}

.information_art {
  margin-top: 16px;
}
.information_art_item {
  background-color: #fff;
  padding: 24px;
  display: flex;
}
.information_art_item:not(:last-child) {
  margin-bottom: 1px;
}
@media screen and (max-width: 840px) {
  .information_art_item {
    display: block;
    padding: 16px;
  }
}
.information_art_date {
  margin-right: 24px;
  font-family: Roboto;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: 1px;
  color: #707980;
  padding-top: 2px;
}
@media screen and (max-width: 840px) {
  .information_art_date {
    margin: 0 0 8px 0;
  }
}
.information_art_main h4 {
  word-break: break-all;
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.29;
  letter-spacing: 0.5px;
  color: #2c2e31;
  margin-bottom: 8px;
}
.information_art_main p {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: 1.92;
  letter-spacing: normal;
  color: #707980;
}
.information_art_main p a {
  color: #707980;
  text-decoration: underline;
}
.information_art_main p a:hover {
  text-decoration: none;
}

.payment_method {
  max-width: 1128px;
  margin: 24px auto 0;
}
@media screen and (max-width: 840px) {
  .payment_method {
    padding: 0 16px;
  }
}

.payment_registered {
  margin-top: 24px;
  background-color: #fff;
}
@media screen and (max-width: 840px) {
  .payment_registered {
    margin-top: 16px;
  }
}
.payment_registered_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 48px 16px;
}
@media screen and (max-width: 840px) {
  .payment_registered_inner {
    flex-direction: column;
    padding: 24px 16px;
  }
}
.payment_registered_inner h4 {
  margin-bottom: 32px;
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 1.1px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .payment_registered_inner h4 {
    margin-bottom: 16px;
    font-size: 1.2rem;
  }
}
.payment_registered_table {
  width: 100%;
  max-width: 268px;
}
@media screen and (max-width: 840px) {
  .payment_registered_table {
    width: 100%;
  }
}
.payment_registered_table tr:first-child th, .payment_registered_table tr:first-child td {
  padding-top: 0;
}
.payment_registered_table tr:last-child th, .payment_registered_table tr:last-child td {
  padding-bottom: 0;
}
.payment_registered_table th, .payment_registered_table td {
  font-family: HiraKakuPro-W3;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.3px;
  padding: 8px 0;
}
@media screen and (max-width: 840px) {
  .payment_registered_table th, .payment_registered_table td {
    padding: 4px 0;
  }
}
.payment_registered_table th {
  color: #2c2e31;
  white-space: nowrap;
  padding-right: 12px;
}
.payment_registered_table td {
  color: #2c2e31;
  word-break: break-all;
}
.payment_registered_table td:not([colspan="2"])::before {
  content: ": ";
}
.payment_registered_table td p {
  font-family: HiraKakuPro-W3;
  font-size: 1.1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e31;
  text-align: center;
}
@media screen and (max-width: 840px) {
  .payment_registered_table td p {
    max-width: 100%;
  }
}

.payment_btn_r, .payment_btn_w {
  display: block;
  text-align: center;
  font-family: HiraKakuPro-W6;
  font-weight: bold;
  font-size: 1.4rem;
  letter-spacing: 0.6px;
  max-width: 268px;
  width: 100%;
  height: 40px;
  line-height: 40px;
}
@media screen and (max-width: 840px) {
  .payment_btn_r, .payment_btn_w {
    max-width: 100%;
    font-size: 1.2rem;
  }
}

.payment_btn_w {
  border: solid 1px #ebedf0;
  background-color: #fff;
  color: #707980;
  transition-property: border-color;
  transition-duration: 0.25s;
}
.payment_btn_w:hover {
  border: solid 1px #bfcbd5;
}

.payment_btn_r {
  border: solid 1px #d94f4f;
  background-color: #d94f4f;
  color: #fff;
  margin-bottom: 8px;
}
.payment_btn_r:hover {
  opacity: 0.7;
}

.payment_regist {
  margin-top: 24px;
  background-color: #fff;
}
@media screen and (max-width: 840px) {
  .payment_regist {
    margin-top: 16px;
  }
}
.payment_regist_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
}
@media screen and (max-width: 840px) {
  .payment_regist_inner {
    padding: 16px 0;
    display: block;
  }
}
.payment_regist_inner h4 {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.3px;
  margin-bottom: 32px;
}
@media screen and (max-width: 840px) {
  .payment_regist_inner h4 {
    width: 100%;
    text-align: center;
    padding: 2px 16px 18px;
    border-bottom: solid 1px #ebedf0;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 840px) {
  .payment_regist .payment_regist_cap {
    text-align: center;
  }
}
.payment_regist form {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 840px) {
  .payment_regist form {
    padding: 0 16px;
  }
}
.payment_regist_table {
  margin-left: -42px;
}
@media screen and (max-width: 840px) {
  .payment_regist_table {
    width: 100%;
    padding: 0 16px;
    margin-left: 0;
  }
}
.payment_regist_table input[type=number] {
  width: 268px;
  max-width: 100%;
}
@media screen and (max-width: 840px) {
  .payment_regist_table input[type=number] {
    width: 100%;
  }
}
.payment_regist_table input[type=button] {
  cursor: pointer;
}
.payment_regist_table input[type=button]:hover {
  border: solid 1px #bfcbd5;
}
.payment_regist_table input[disabled] {
  background-color: #dce0e3;
}
.payment_regist_table tr:first-child th, .payment_regist_table tr:first-child td {
  padding-top: 0;
}
@media screen and (max-width: 840px) {
  .payment_regist_table tr:first-child td {
    padding-top: 8px;
  }
}
.payment_regist_table tr:last-child th, .payment_regist_table tr:last-child td {
  padding-bottom: 0;
}
@media screen and (max-width: 840px) {
  .payment_regist_table tr {
    width: 100%;
    display: block;
  }
}
.payment_regist_table th, .payment_regist_table td {
  padding: 8px 0;
}
@media screen and (max-width: 840px) {
  .payment_regist_table th, .payment_regist_table td {
    display: block;
    width: 100%;
  }
}
.payment_regist_table th {
  padding-right: 24px;
  white-space: nowrap;
  font-family: HiraKakuPro-W6;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 1.1px;
}
@media screen and (max-width: 840px) {
  .payment_regist_table th {
    padding: 8px 0 0 0;
  }
}
.payment_regist_table td {
  word-break: break-all;
}
.payment_regist_table .form_date {
  display: flex;
  align-items: center;
}
.payment_regist_table .form_date select {
  width: 85px;
  max-width: 100%;
}
.payment_regist_table .form_date span {
  font-family: HiraKakuPro-W6;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 1.1px;
  margin-left: 9px;
}
.payment_regist_table .form_date span + select {
  margin-left: 24px;
}

.card_date select + select {
  margin-left: 16px;
}

.order_history {
  max-width: 1128px;
  margin: 0 auto;
}
@media screen and (max-width: 840px) {
  .order_history {
    padding: 0 16px;
  }
}

.order_history_table {
  width: 100%;
  background-color: #fff;
  margin-top: 24px;
}
@media screen and (max-width: 840px) {
  .order_history_table {
    background-color: transparent;
  }
}
@media screen and (max-width: 840px) {
  .order_history_table tr, .order_history_table th, .order_history_table td {
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 840px) {
  .order_history_table tr {
    background-color: #fff;
  }
  .order_history_table tr:nth-child(2) {
    margin-top: 0;
  }
  .order_history_table tr + tr {
    margin-top: 24px;
  }
}
@media screen and (max-width: 840px) {
  .order_history_table tr:first-child {
    display: none;
  }
}
.order_history_table .order_canceled {
  background-color: #f6f8fa;
}
.order_history_table .order_canceled .btn_cansel {
  display: none;
}
.order_history_table th, .order_history_table td {
  border: solid 1px #ebedf0;
}
@media screen and (max-width: 840px) {
  .order_history_table th, .order_history_table td {
    border-top: none;
  }
}
.order_history_table th {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e31;
  padding: 14px 16px;
  vertical-align: middle;
}
.order_history_table td {
  text-align: left;
  padding: 12px 16px;
  font-family: Roboto, HiraKakuPro-W3;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.29;
  letter-spacing: normal;
  color: #2c2e31;
}
.order_history_table td:last-child {
  text-align: center;
}
.order_history_table td:last-child a, .order_history_table td:last-child span {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e31;
  text-decoration: underline;
  white-space: nowrap;
}
.order_history_table td:last-child a:hover, .order_history_table td:last-child span:hover {
  text-decoration: none;
}
@media screen and (max-width: 840px) {
  .order_history_table td:last-child a, .order_history_table td:last-child span {
    /*
            	text-decoration: none;
            	display: block;
            	width: 100%;
            	max-width: 311px;
            	height: 40px;
            	line-height: 40px;
            	border: solid 1px #ebedf0;
    */
  }
}
.order_history_table td:not(.order_data):not(.order_o-status):not(.order_address) {
  white-space: nowrap;
}
@media screen and (max-width: 840px) {
  .order_history_table td:nth-child(1), .order_history_table td:nth-child(2), .order_history_table td:nth-child(3) {
    border: none;
    padding: 8px 16px;
    display: flex;
  }
  .order_history_table td:nth-child(1) br, .order_history_table td:nth-child(2) br, .order_history_table td:nth-child(3) br {
    display: none;
  }
  .order_history_table td:nth-child(1) span, .order_history_table td:nth-child(2) span, .order_history_table td:nth-child(3) span {
    margin-right: auto;
  }
}
@media screen and (max-width: 840px) {
  .order_history_table td:nth-child(1) {
    padding-top: 16px;
  }
}
@media screen and (max-width: 840px) {
  .order_history_table td:nth-child(3) {
    border-bottom: solid 1px #ebedf0;
    padding-bottom: 16px;
  }
}
.order_history_table td span {
  display: none;
}
@media screen and (max-width: 840px) {
  .order_history_table td span {
    display: block;
    font-family: HiraKakuPro-W6;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: normal;
    color: #2c2e31;
  }
}
.order_history_table td small {
  font-size: 1.2rem;
}
@media screen and (max-width: 840px) {
  .order_history_table .order_data + td, .order_history_table .order_data + td + td {
    display: flex;
    text-align: right;
  }
  .order_history_table .order_data + td span, .order_history_table .order_data + td + td span {
    text-align: left;
    margin-right: auto;
  }
}
@media screen and (max-width: 840px) {
  .order_history_table .order_address {
    display: flex;
  }
  .order_history_table .order_address span {
    margin-right: auto;
  }
  .order_history_table .order_address .order_address_p {
    max-width: 164px;
  }
}
.order_history_table .o-date,
.order_history_table .o-numbar {
  text-align: center;
}
.order_history_table .o-plice, .order_history_table .o-quantity {
  white-space: nowrap;
}
.order_history_table .o-cancel {
  width: 100px;
}
.order_history_table .art_title {
  word-break: break-all;
  max-width: 180px;
  min-width: 180px;
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 8px;
}
.order_history_table .art_title a {
  word-break: break-all;
  color: #2c2e31;
  text-decoration: underline;
}
.order_history_table .art_title a:hover {
  text-decoration: none;
}
.order_history_table .order_o-status {
  min-width: 177px;
}
@media screen and (max-width: 840px) {
  .order_history_table .order_o-status {
    max-width: 100%;
  }
}
.order_history_table .order_address {
  min-width: 196px;
}
@media screen and (max-width: 840px) {
  .order_history_table .order_address {
    max-width: 100%;
  }
}
.order_history_table .order_o-quantity {
  text-align: center;
  font-family: Roboto;
  font-size: 1.4rem;
  color: #2c2e31;
}
.order_history_table .order_item {
  display: flex;
}
.order_history_table .order_item + .order_item {
  margin-top: 8px;
}
.order_history_table .order_item .order_img {
  margin-right: 24px;
  background-color: #fbfbfb;
}
@media screen and (max-width: 840px) {
  .order_history_table .order_item .order_img {
    margin-right: 8px;
  }
}
.order_history_table .order_item .order_img a, .order_history_table .order_item .order_img span {
  width: 64px;
  height: 64px;
  background-color: #ebedf0;
  padding: 6px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.order_history_table .order_item .order_img a:hover, .order_history_table .order_item .order_img span:hover {
  opacity: 0.7;
}
.order_history_table .order_item .order_img span:hover {
  opacity: 1;
}
.order_history_table .order_item .order_img img {
  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;
  vertical-align: bottom;
}
.order_history_table .order_item .order_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.order_history_table .order_item .order_text a:hover {
  opacity: 0.7;
}
.order_history_table .order_item .order_text_artist {
  font-family: Roboto, HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
}
.order_history_table .order_item .order_text_arttitle {
  font-family: Roboto, HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #707980;
  word-break: break-all;
}
.order_history_table .order_item .order_text_artdate {
  font-family: Roboto;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #707980;
}

.order_alert {
  max-width: 150px;
}
.order_alert h4 {
  padding-left: 28px;
  background-image: url(../img/mypage/group.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px 16px;
  font-family: HiraKakuPro-W6;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.23;
  letter-spacing: normal;
  color: #d94f4f;
  margin-bottom: 8px;
}
.order_alert p {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: normal;
  color: #d94f4f;
}

.order_cancel {
  max-width: 1128px;
  margin: 0 auto;
  padding-top: 24px;
}
@media screen and (max-width: 840px) {
  .order_cancel {
    padding: 16px;
  }
}
.order_cancel .return_order {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  letter-spacing: 0.7px;
}
.order_cancel .return_order a {
  color: #2c2e31;
}
.order_cancel .return_order a::before {
  display: inline-block;
  width: 8px;
  height: 12px;
  margin-right: 14px;
  content: "";
  vertical-align: text-bottom;
  background-image: url(../img/common/bt-left.png);
  background-size: 8px 12px;
}
.order_cancel .return_order a:hover {
  opacity: 0.7;
}
.order_cancel_inner {
  margin-top: 20px;
  padding: 53px 16px 40px;
  background-color: #fff;
}
@media screen and (max-width: 840px) {
  .order_cancel_inner {
    margin-top: 16px;
    padding: 32px 0 16px;
  }
}
.order_cancel_inner h4 {
  font-family: HiraKakuPro-W6;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #2c2e31;
  text-align: center;
}
.order_cancel_inner h4 + p {
  margin-top: 40px;
  margin-bottom: 32px;
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
  text-align: center;
}
@media screen and (max-width: 840px) {
  .order_cancel_inner h4 + p {
    margin: 24px auto;
    width: 300px;
  }
}
.order_cancel_list {
  max-width: 744px;
  margin: 0 auto;
  border: solid 1px #ebedf0;
}
@media screen and (max-width: 840px) {
  .order_cancel_list {
    border-left: none;
    border-right: none;
  }
}
.order_cancel_list_head {
  padding: 13px 24px;
  border-bottom: solid 1px #ebedf0;
}
@media screen and (max-width: 840px) {
  .order_cancel_list_head {
    padding: 13px 16px;
  }
}
.order_cancel_list_head dl {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 840px) {
  .order_cancel_list_head dl {
    display: block;
  }
}
.order_cancel_list_head dl div {
  display: flex;
}
.order_cancel_list_head dl dt {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .order_cancel_list_head dl dd {
    margin-left: 8px;
  }
}
.order_cancel_list_head dl dd::before {
  content: ":";
  margin: 0 4px 0 8px;
}
.order_cancel_list_head dl .order_date {
  margin-right: 32px;
}
@media screen and (max-width: 840px) {
  .order_cancel_list_head dl .order_date {
    margin-right: 0;
  }
}
.order_cancel_list_main {
  padding: 24px;
}
@media screen and (max-width: 840px) {
  .order_cancel_list_main {
    padding: 0;
  }
}

.order_store {
  display: flex;
  margin-bottom: 16px;
}
@media screen and (max-width: 840px) {
  .order_store {
    padding: 16px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
.order_store dt {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
}
.order_store dd {
  flex: 1;
  word-break: break-all;
  text-indent: -1.3em;
  margin-left: 1.3em;
}
.order_store dd::before {
  content: ":";
  margin: 0 4px 0 8px;
}

.order_info {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 840px) {
  .order_info {
    flex-wrap: wrap;
    padding: 16px;
  }
}
.order_info + .order_info {
  margin-top: 16px;
}
@media screen and (max-width: 840px) {
  .order_info + .order_info {
    margin: 0;
    border-top: solid 1px #ebedf0;
  }
}
.order_info .order_head {
  display: flex;
  flex: 1;
}
@media screen and (max-width: 840px) {
  .order_info .order_head {
    width: 100%;
    margin-bottom: 24px;
    flex: auto;
  }
}
.order_info .order_img {
  margin-right: 24px;
  background-color: #fbfbfb;
}
@media screen and (max-width: 840px) {
  .order_info .order_img {
    margin-right: 8px;
  }
}
.order_info .order_img a, .order_info .order_img span {
  width: 64px;
  height: 64px;
  background-color: #ebedf0;
  padding: 6px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.order_info .order_img a:hover, .order_info .order_img span:hover {
  opacity: 0.7;
}
.order_info .order_img span:hover {
  opacity: 1;
}
.order_info .order_img img {
  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;
  vertical-align: bottom;
}
@media screen and (max-width: 840px) {
  .order_info .order_text {
    width: 100%;
  }
}
.order_info .order_text_artist {
  font-family: HiraKakuPro-W3;
  font-size: 1.6rem;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #2c2e31;
}
.order_info .order_text_arttitle {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #707980;
  word-break: break-all;
}
.order_info .order_text_artdate {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #707980;
}
.order_info .order_number {
  min-width: 80px;
  margin-left: 32px;
}
@media screen and (max-width: 840px) {
  .order_info .order_number {
    margin-left: 0;
  }
}
.order_info .order_number p {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
}
.order_info .order_number p span {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
  margin-left: 8px;
}
.order_info .order_price {
  font-family: HiraKakuPro-W3;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2c2e31;
  margin-left: 16px;
}
.order_info .order_price span {
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.4rem;
}

.cancel_btn {
  margin-top: 32px;
  text-align: center;
}
.cancel_btn input[type=submit] {
  width: 225px;
  height: 40px;
  line-height: 40px;
  background-color: #2c2e31;
  font-family: HiraKakuPro-W6;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #ffffff;
  margin-bottom: 16px;
}
.cancel_btn p {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
}

.magazine_list .magazine {
  padding-bottom: 24px;
}
@media screen and (max-width: 840px) {
  .magazine_list .magazine {
    padding: 24px 16px;
  }
}
.magazine_list .magazine .page_head {
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: 840px) {
  .magazine_list .magazine .page_head {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 24px;
  }
}
.magazine_list .magazine .page_head .return_order {
  width: 100%;
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  letter-spacing: 0.7px;
  margin-bottom: 20px;
}
@media screen and (max-width: 840px) {
  .magazine_list .magazine .page_head .return_order {
    margin-bottom: 16px;
  }
  .magazine_list .magazine .page_head .return_order + .page_title span {
    display: block;
    margin-left: 0;
    margin-top: 8px;
  }
}
.magazine_list .magazine .page_head .return_order a {
  color: #2c2e31;
}
.magazine_list .magazine .page_head .return_order a::before {
  display: inline-block;
  width: 8px;
  height: 12px;
  margin-right: 14px;
  content: "";
  vertical-align: text-bottom;
  background-image: url(../img/common/bt-left.png);
  background-size: 8px 12px;
}
.magazine_list .magazine .page_head .return_order a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 840px) {
  .magazine_list .magazine .page_head .page_title {
    margin-bottom: 24px;
  }
}
.magazine_list .magazine .page_head .form {
  display: block;
  margin-left: auto;
}
@media screen and (max-width: 840px) {
  .magazine_list .magazine .page_head .form {
    width: 100%;
    display: flex;
  }
}
.magazine_list .magazine .page_head .form select {
  width: 180px;
}
@media screen and (max-width: 840px) {
  .magazine_list .magazine .page_head .form select {
    width: 50%;
  }
}
.magazine_list .magazine_inner {
  padding-bottom: 32px;
  margin-bottom: 24px;
  border-bottom: solid 1px #f1f2f4;
}

/*------------------------------------*\
    Article
\*------------------------------------*/
.article_page .pan {
  margin-bottom: 0;
}

.magazine_article {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto 48px;
}
@media screen and (max-width: 840px) {
  .magazine_article {
    margin-bottom: 32px;
  }
}
.magazine_article_bg {
  margin: auto;
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
@media screen and (max-width: 840px) {
  .magazine_article_bg {
    height: 204px;
  }
}
.magazine_article_main {
  max-width: 1128px;
  margin: 30px auto 48px;
  padding: 16px 16px 48px;
  background-color: #fff;
}
@media screen and (max-width: 840px) {
  .magazine_article_main {
    margin: -40px auto 0;
    padding-bottom: 0;
    background-color: transparent;
  }
}
.magazine_article_main_inner {
  max-width: 744px;
  margin: 0 auto;
  background-color: #fff;
}
@media screen and (max-width: 840px) {
  .magazine_article_main_inner {
    padding: 8px 16px;
  }
}
.magazine_article_head {
  padding-bottom: 48px;
  margin-bottom: 48px;
  border-bottom: solid 1px #f1f2f4;
}
@media screen and (max-width: 840px) {
  .magazine_article_head {
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
}
.magazine_article_head .img_cap {
  text-align: right;
  margin-bottom: 21px;
}
.magazine_article_head .img_cap a {
  font-family: HiraKakuPro-W3;
  font-size: 1.1rem;
  line-height: normal;
  letter-spacing: normal;
  color: #707980;
}
.magazine_article_head .img_cap a:hover {
  opacity: 0.7;
}
.magazine_article_head .art_info {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 840px) {
  .magazine_article_head .art_info {
    flex-direction: column;
  }
}
.magazine_article_head .art_cd {
  display: flex;
}
.magazine_article_head .art_cd li {
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2c2e31;
}
.magazine_article_head .art_cd li:not(:last-child):after {
  content: "|";
  margin: 0 0.3em;
}
.magazine_article_head .art_cd li a {
  color: #2c2e31;
}
.magazine_article_head .art_cd li a:hover {
  opacity: 0.7;
}
.magazine_article_head .addthis_inline_share_toolbox_szy3 {
  margin-left: auto;
}
@media screen and (max-width: 840px) {
  .magazine_article_head .addthis_inline_share_toolbox_szy3 {
    margin-left: 0;
    margin-top: 8px;
  }
}
.magazine_article_head .art_sns {
  display: flex;
  margin-left: auto;
}
.magazine_article_head .art_sns li a:hover {
  opacity: 0.7;
}
.magazine_article_head .art_sns li:not(:last-child) {
  margin-right: 20px;
}
.magazine_article_head .art_title {
  font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1.46;
  letter-spacing: 0.8px;
  color: #2c2e31;
  margin-top: 28px;
  margin-bottom: 24px;
  text-align: left;
}
@media screen and (max-width: 840px) {
  .magazine_article_head .art_title {
    font-size: 18px;
    line-height: 1.78;
    letter-spacing: 0.5px;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}
.magazine_article_head .art_caption {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #2c2e31;
}
@media screen and (max-width: 840px) {
  .magazine_article_head .art_caption {
    font-size: 1.2rem;
    line-height: 1.83;
  }
}
.magazine_article_head .art_credit {
  text-align: center;
  font-size: 1.3rem;
  color: #707980;
  font-weight: 500;
  margin: 14px 0 0;
}

/*------------------------------------*\
    記事本文
\*------------------------------------*/
.magazine_article_body {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: 0.7px;
  color: #2c2e31;
  text-align: justify;
  word-break: break-all;
}
.magazine_article_body > * {
  margin-top: 32px;
  margin-bottom: 32px;
}
@media screen and (max-width: 840px) {
  .magazine_article_body > * {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
.magazine_article_body h2 {
  font-family: HiraKakuPro-W6;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.5;
  letter-spacing: 1.5px;
  border-left: 4px solid #2c2e31;
  padding-left: 8px;
}
@media screen and (max-width: 840px) {
  .magazine_article_body h2 {
    font-size: 1.6rem;
  }
}
.magazine_article_body h3 {
  font-family: HiraKakuPro-W6;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 1.5px;
}
@media screen and (max-width: 840px) {
  .magazine_article_body h3 {
    font-size: 1.4rem;
  }
}
.magazine_article_body h4 {
  font-family: HiraKakuPro-W6;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 1.5px;
}
.magazine_article_body a {
  color: #2c2e31;
  border-bottom: 1px dotted #2c2e31;
}
.magazine_article_body figure a {
  display: block;
  border-bottom: none;
}
.magazine_article_body img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
.magazine_article_body figcaption {
  font-family: HiraKakuPro-W3;
  font-size: 1rem;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #707980;
  margin-top: 10px;
}
.magazine_article_body .videoEmbed {
  position: relative;
  z-index: 2;
  width: 100%;
  padding-top: 56.25%;
  margin: 0 auto 30px;
  text-align: center;
}
.magazine_article_body .videoEmbed iframe {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.magazine_article_body ol {
  padding-left: 1.2em;
}
.magazine_article_body ol li {
  list-style: decimal;
}
.magazine_article_body ul {
  padding-left: 1.2em;
}
.magazine_article_body ul li {
  list-style: disc;
}
.magazine_article_body blockquote {
  border-radius: 5px;
  background-color: #f4f6f8;
  padding: 24px 24px 24px 68px;
  background-image: url(../img/magazine/ic-inyo.png);
  background-repeat: no-repeat;
  background-position: left 24px top 24px;
  background-size: 30px 23px;
}
@media screen and (max-width: 840px) {
  .magazine_article_body blockquote {
    padding: 40px 16px 16px 16px;
    background-position: left 16px top 16px;
    background-size: 20px 16px;
  }
}
.magazine_article_body blockquote .source {
  font-size: 1.2rem;
  color: #707980;
  text-align: right;
  margin-top: 8px;
}
.magazine_article_body .magazine_author {
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e31;
  margin-top: 24px;
  text-align: right;
}

/*  */
.art_section_title {
  font-family: PTSerif-Caption;
  font-size: 1.6rem;
  line-height: normal;
  letter-spacing: 1.3px;
  color: #2c2e31;
  margin-bottom: 24px;
}

.artist_profile {
  padding-top: 24px;
  margin-bottom: 40px;
  border-top: solid 1px #f1f2f4;
}
@media screen and (max-width: 840px) {
  .artist_profile {
    padding-top: 32px;
    margin-bottom: 32px;
  }
}
.artist_profile:last-child {
  margin-bottom: 0;
}
.artist_profile_name {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.2px;
  color: #2c2e31;
  margin-bottom: 8px;
  word-break: break-all;
}
.artist_profile_text {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: 1.69;
  letter-spacing: 0.6px;
  color: #2c2e31;
}

.art_information {
  padding-top: 24px;
  margin-bottom: 40px;
  border-top: solid 1px #f1f2f4;
}
@media screen and (max-width: 840px) {
  .art_information {
    padding-top: 32px;
    margin-bottom: 32px;
  }
}
.art_information:last-child {
  margin-bottom: 0;
}
.art_information_wrap {
  display: flex;
}
@media screen and (max-width: 840px) {
  .art_information_wrap {
    display: block;
  }
}
.art_information_text {
  flex: 1;
}
.art_information_map {
  width: 369px;
  margin-left: 24px;
}
@media screen and (max-width: 840px) {
  .art_information_map {
    width: 100%;
    margin-left: 0;
    margin-top: 32px;
  }
  .art_information_map iframe {
    width: 100%;
  }
}
.art_information_name {
  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.2px;
  color: #2c2e31;
  margin-bottom: 24px;
  word-break: break-all;
}
.art_information_table {
  width: 100%;
  word-break: break-all;
}
.art_information_table tr:first-child th, .art_information_table tr:first-child td {
  padding-top: 0;
}
.art_information_table tr:last-child th, .art_information_table tr:last-child td {
  padding-bottom: 0;
}
.art_information_table th {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #707980;
  white-space: nowrap;
  padding: 6px 0;
}
.art_information_table td {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e31;
  padding: 6px 0 6px 0;
}
.art_information_table td a {
  color: #2c2e31;
  text-decoration: underline;
}
.art_information_table td a:hover {
  text-decoration: none;
}
.art_information_table td .view_map {
  display: none;
  font-family: HiraKakuPro-W6;
  font-size: 1.2rem;
  color: #2c2e31;
  width: 100%;
  max-width: 220px;
  height: 32px;
  border-radius: 3px;
  border: solid 1px #dce0e3;
  text-decoration: none;
  margin-top: 12px;
  align-items: center;
  justify-content: center;
}
.art_information_table td .view_map span {
  background-image: url(../img/magazine/ic-map-pin.png);
  background-size: 11px 14px;
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 18px;
}
@media screen and (max-width: 840px) {
  .art_information_table td .view_map {
    display: flex;
  }
}

.art_event {
  padding-top: 24px;
  margin-bottom: 40px;
  border-top: solid 1px #f1f2f4;
}
@media screen and (max-width: 840px) {
  .art_event {
    padding-top: 32px;
    margin-bottom: 32px;
  }
}
.art_event:last-child {
  margin-bottom: 0;
}
.art_event_heading {
  font-family: HiraginoSans-W6;
  font-size: 1.4rem;
  font-weight: bold;
  color: #2c2e31;
  margin-bottom: 8px;
}
.art_event_text {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: 1.69;
  letter-spacing: 0.6px;
  color: #2c2e31;
}
.art_event_text + .art_event_heading {
  margin-top: 32px;
}

.art_event_item dt {
  font-family: HiraginoSans-W6;
  font-size: 1.4rem;
  font-weight: bold;
  color: #2c2e31;
  margin-bottom: 8px;
}
.art_event_item dd {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  line-height: 1.69;
  letter-spacing: 0.6px;
  color: #2c2e31;
}
.art_event_item + .art_event_item {
  margin-top: 32px;
}

.art_link {
  padding-top: 24px;
  margin-bottom: 40px;
  border-top: solid 1px #f1f2f4;
}
.art_link:last-child {
  margin-bottom: 0;
}
.art_link ul li {
  font-family: HiraKakuPro-W3;
  font-size: 1.3rem;
  color: #2c2e31;
}
.art_link ul li::before {
  content: "-";
  color: #979797;
  margin-right: 5px;
}
.art_link ul li a {
  color: #2c2e31;
  text-decoration: underline;
}
.art_link ul li a:hover {
  text-decoration: none;
}

.artfoot_sns {
  padding-top: 24px;
  border-top: solid 1px #f1f2f4;
}
.artfoot_sns ul {
  display: flex;
}
.artfoot_sns ul li:not(:last-child) {
  margin-right: 5px;
}
.artfoot_sns ul li a:hover {
  opacity: 0.7;
}

.bg_modal {
  max-width: 1280px;
}
@media screen and (max-width: 840px) {
  .bg_modal {
    padding: 16px;
  }
}
.bg_modal_inner {
  width: 100%;
  max-width: 1280px;
}
.bg_modal_inner .caption {
  font-size: 11px;
  color: #707980;
  line-height: 1.2;
  text-align: justify;
  margin: 0;
  padding: 0 24px;
  margin-top: 8px;
  text-shadow: none;
  text-align: right;
}

@media screen and (max-width: 840px) {
  .inquiry_ask_form {
    padding: 16px 16px 48px;
  }
}
.inquiry_ask_form .agreement_title {
  font-family: HiraKakuPro-W3, sans-serif;
}
.inquiry_ask_form .contact p {
  font-size: 1.2rem;
  line-height: 168%;
}
.inquiry_ask_form .contact p + p {
  margin-top: 1em;
}
.inquiry_ask_form .inquiry_ask_main {
  width: 840px;
  max-width: 1168px;
  margin: 0 auto 48px;
  background-color: #fff;
  padding: 48px;
  flex: 1;
  word-break: break-all;
}
@media screen and (max-width: 840px) {
  .inquiry_ask_form .inquiry_ask_main {
    width: 100%;
    padding: 24px 16px;
  }
}
.inquiry_ask_form .ask_table {
  width: 100%;
  max-width: 630px;
  margin: 20px auto 0;
}
@media screen and (max-width: 840px) {
  .inquiry_ask_form .ask_table {
    display: block;
  }
  .inquiry_ask_form .ask_table tbody {
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 840px) {
  .inquiry_ask_form .ask_table tr {
    display: block;
    width: 100%;
  }
}
.inquiry_ask_form .ask_table td, .inquiry_ask_form .ask_table th {
  font-size: 1.2rem;
  text-align: left;
}
@media screen and (max-width: 840px) {
  .inquiry_ask_form .ask_table td, .inquiry_ask_form .ask_table th {
    display: block;
    width: 100%;
  }
}
.inquiry_ask_form .ask_table th {
  white-space: nowrap;
  width: 128px;
  vertical-align: top;
  padding: 13px 0;
}
@media screen and (max-width: 840px) {
  .inquiry_ask_form .ask_table th {
    padding: 10px 0 0;
  }
}
.inquiry_ask_form .ask_table td {
  padding: 13px 20px;
}
@media screen and (max-width: 840px) {
  .inquiry_ask_form .ask_table td {
    padding: 0 0;
  }
  .inquiry_ask_form .ask_table td textarea {
    width: 100%;
  }
}
.inquiry_ask_form .has_edit {
  display: flex;
}
.inquiry_ask_form .has_edit .inquiry_ask_edit {
  margin-left: auto;
}

.vip_password {
  padding: 60px 16px;
}
@media screen and (max-width: 840px) {
  .vip_password {
    padding: 16px;
  }
}
.vip_password .vip_password_inner {
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  margin: 0 auto;
  padding: 90px 16px;
  text-align: center;
}
@media screen and (max-width: 840px) {
  .vip_password .vip_password_inner {
    padding: 60px 16px;
  }
}
.vip_password .icon_lock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 66px;
  margin: 0 auto 24px;
  border-radius: 50%;
  background-color: #F6F6F6;
}
.vip_password .icon_lock img {
  width: 100%;
  max-width: 24px;
  height: auto;
}
.vip_password h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
}
@media screen and (max-width: 840px) {
  .vip_password h3 {
    font-size: 14px;
  }
}
.vip_password form {
  display: flex;
  flex-direction: column;
  max-width: 311px;
  margin: 24px auto 0;
}
.vip_password form input[type=password] {
  border: 1px solid #E5E5E5;
  padding: 10px 17px;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #2C2E31;
}
.vip_password form input[type=submit] {
  background-color: #000;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.05em;
  width: 311px;
  height: 40px;
  margin: 16px 0 0 0;
}

.vip .vip_inner {
  max-width: 1128px;
  margin: 0 auto;
}
@media screen and (max-width: 840px) {
  .vip .vip_inner {
    padding: 16px 16px 0;
  }
}
.vip .vip_header {
  display: flex;
  background-color: #2C2E31;
  max-height: 424px;
}
@media screen and (max-width: 840px) {
  .vip .vip_header {
    flex-direction: column;
    max-height: inherit;
    background-color: #000;
  }
}
.vip .vip_header .vip_header_img {
  width: 50%;
  max-height: 424px;
}
@media screen and (max-width: 840px) {
  .vip .vip_header .vip_header_img {
    width: 100%;
    order: 1;
    max-height: 224px;
  }
}
.vip .vip_header .vip_header_img img {
  width: 100%;
  height: 100%;
  max-height: 424px;
  object-fit: cover;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%;";
}
@media screen and (max-width: 840px) {
  .vip .vip_header .vip_header_img img {
    max-height: 224px;
  }
}
.vip .vip_header .vip_header_main {
  width: 50%;
  padding: 123px 16px;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 840px) {
  .vip .vip_header .vip_header_main {
    width: 100%;
    padding: 30px 16px;
  }
}
.vip .vip_header .vip_header_main p {
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 840px) {
  .vip .vip_header .vip_header_main p {
    font-size: 11px;
    line-height: 120%;
  }
}
.vip .vip_header .vip_header_main h3 {
  max-width: 400px;
  margin: 24px auto 0;
  font-size: 24px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 840px) {
  .vip .vip_header .vip_header_main h3 {
    max-width: 240px;
    margin: 16px auto 0;
    font-size: 14px;
    line-height: 140%;
  }
}

.vip_main {
  margin-top: 60px;
  padding-bottom: 60px;
}
@media screen and (max-width: 840px) {
  .vip_main {
    margin-top: 24px;
  }
}
.vip_main .vip_main_introduction {
  max-width: 800px;
  margin: 0 auto;
}
.vip_main .vip_main_introduction p {
  font-size: 15px;
  line-height: 160%;
  text-align: justify;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 840px) {
  .vip_main .vip_main_introduction p {
    font-size: 13px;
    line-height: 169%;
  }
}
.vip_main .vip_main_introduction p + p {
  margin-top: 1em;
}
.vip_main .pickup {
  margin-top: 60px;
}
.vip_main .pickup .pickup_inner {
  margin: 0;
  padding: 0;
}
.vip_main .pickup .pickup_inner + .pickup_inner {
  margin-top: 60px;
}
@media screen and (max-width: 840px) {
  .vip_main .pickup .pickup_inner + .pickup_inner {
    margin-top: 40px;
  }
}
.vip_main .pickup .pickup_inner .index_section_title, .vip_main .pickup .pickup_inner .index_section_title_gold {
  font-size: 16px;
}