.index_bg{
    background-color: #fff;
}

.index_wrap{
    font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, Verdana, sans-serif;
}

.delivery_small{
    max-width: 1160px;
    margin: 0 auto;
    padding: 0 16px;
    text-align: right;
    
    @include media_query(){
        padding: 0 30px;
    }
    
    a{
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 120%;
        letter-spacing: 0.05em;
        color: #2C2E31;
        background-image: url(../img/common/icon_info.png);
        background-size: 12px 12px;
        background-repeat: no-repeat;
        padding-left: 27px;
        
        &:hover{
            opacity: .7;
        }
    }
}

//index
.pickup_title{
    font-family: Roboto;
    font-weight: bold;
    font-size: 2rem;
    line-height: 23px;
    text-align: center;
    margin-bottom: 30px;
    color: #000;
    
    span{
        text-align: center;
        display: block;
        font-size: 1.2rem;
        line-height: 120%;
        margin-top: 9px;
        color: #2C2E31;
    }
}

//index
.index_section_title{
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 21px;
    color: #2C2E31;
    
    span{
        font-size: 1.2rem;
        line-height: 120%;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        margin-left: 16px;
    }
}

//index
.index_section_title_gold{
    @extend .index_section_title;
    color: #C2A47E;
}

//index_bg
.title_wrap{
    display: flex;
    align-items: center;
    
    .more_link{
        font-weight: bold;
        font-size: 12px;
        line-height: 120%;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.05em;
        margin-left: auto;
        
        @include media_query(){
            display: none;
        }
        
        a{
            color: #C2A47E;
            padding-right: 10px;
            background-image: url(../img/common/icon_more_link.png);
            background-repeat: no-repeat;
            background-position: center right;
            background-size: 4px 7px;
            
            &:hover{
                opacity: .7;
            }
        }
    }
}

.section_title{
  font-family: PTSerif-Caption;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  color: #2c2e31;

  @include media_query(){
  	font-size: 1.6rem;
  }

  span{
    font-family: HiraMinProN-W3;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #707980;
    margin-left: 8px;

    @include media_query(){
    	font-size: 1.1rem;
    }
  }
}

.section_subtitle{
  font-family: PTSerif-Caption;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 1.2px;
  color: #2c2e31;
  text-align: center;
  margin-bottom: 24px;
}

.go_list{
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #2c2e31;
  padding-right: 30px;
  background-image: url(/common/img/common/bt-link.png);
  background-position: right center;
  background-size: 16px 12px;
  background-repeat: no-repeat;

  transition-property: opacity;
  transition-duration: .25s;

  &:hover{
    opacity: .7;
  }

  @include media_query(){
  	display: none;
  }
}

//お気に入り
.add_like{
  .bt_hart{
    width: 16px;
    height: 14px;

    fill: transparent;
    stroke: #BFCBD5;
    stroke-width: 1.5;
    cursor: pointer;

    transition-property: stroke, fill;
    transition-duration: .2s;

    &:hover{
      stroke: #f55367;
    }
  }

  .bt_hart_on{
    fill: #f55367;
    stroke: #f55367;
  }
}





/*------------------------------------*\
    sp_search
\*------------------------------------*/
.sp_search{
    display: none;
    padding: 70px 30px 0;
    margin: -56px 0 0;
    
    @include media_query(){
        display: block;
    }
    
    form{
        display: flex;
        align-items: center;
    }
    
    input[type=search]{
        display: block;
        width: 100%;
        height: 40px;
        font-size: 1.2rem;
        padding: 16px 10px 16px 30px;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
        border-radius: 3px;
        
        background-image: url(/common/img/common/icon_search.png);
        background-position: left 10px center;
        background-repeat: no-repeat;
        background-size: 13px 14px;
    }
    
    ::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }
}




//likeボタン
.overlay_like{
    width: 37px;
    height: 37px;
    margin-left: auto;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    .icon_like{
        width: 16px;
        height: 18px;
        fill: transparent;
        stroke: #fff;
        transition-property: fill, stroke;
        transition-duration: .25s;
    }
    
    &:hover{
        .icon_like{
            fill: #fff;
            stroke: #fff;
        }
    }
    
    &.bt_hart_on{
        .icon_like{
            fill: #fff;
            stroke: #fff;
        }
    }
}





/*------------------------------------*\
    FEATURED ARTISTS 注目の作家
\*------------------------------------*/
.buyee_tag{
    max-width: 1128px !important;
    width: 100% !important;
    height: auto !important;
    margin: 0 auto;
}





/*------------------------------------*\
    FEATURED ARTISTS 注目の作家
\*------------------------------------*/
.featured_artists{
    padding: 30px 0 0 0;
}

.featured_artists_inner{
    background: linear-gradient(0deg, rgba(203, 203, 203, 0.4158) 0%, rgba(44, 46, 49, 0) 100%);
    padding: 10px 0 45px;
    min-height: 430px;
    
    @include media_query(){
        padding: 10px 0 24px;
        min-height: auto;
    }
}

#featured_artists{
    
    .slide_item{
        //max-width: 445px;
        display: inline-block;
        
        &.swiper-slide {
            width: auto;
        }
        
        &:hover{
            .slide_item_img_overlay{
                visibility: visible;
                opacity: 1;
            }
        }
    }
    
    .slide_item_img{
        position: relative;
        display: inline-block;
    }
    
    .img_box{
        display: inline-block;
        width: auto;
        
        img{
            //max-width: 445px;
            width: auto;
            height: 300px;
            box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
            vertical-align: bottom;
            
            @include media_query(){
                width: 240px;
                height: 240px;
                object-fit: cover;
            }
        }
    }
    
    .slide_item_img_overlay{
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        //max-width: 300px;
        width: 100%;
        //min-height: 85px;
        background: linear-gradient(0deg, rgba(100, 100, 100, 0.6) 0%, rgba(60, 60, 60, 0) 100%);
        color: #fff;
        //margin-top: -85px;
        padding: 13px 14px 13px 18px;
        
        visibility: hidden;
        opacity: 0;
        z-index: 99;
        
        transition-property: visibility, opacity;
        transition-duration: .25s;
        
        @include media_query(){
            visibility: visible;
            opacity: 1;
        }
        
        .overlay_info{
            flex: 1;
        }
        
        h3{
            font-size: 1.4rem;
            line-height: 120%;
            letter-spacing: 0.05em;
            margin-bottom: 6px;
        }
        
        .plice{
            font-family: Inter, sans-serif;
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 19px;
            letter-spacing: 0.05em;
        }
    }
    
    .slide_item_text{
        display: flex;
        margin-top: 20px;
        padding: 0 5.842%;
        max-width: 300px;
        
        .artist_img{
            width: 48px;
            height: 48px;
            overflow: hidden;
            border-radius: 100%;
            
            img{
                width: 48px;
                height: 48px;
                object-fit: cover;
                object-position: center center;
                font-family: "object-fit: cover;", "object-position: center center;";
            }
        }
        
        .artist_body{
            flex: 1;
            margin-left: 20px;
            
            .artist_name{
                font-weight: bold;
                font-size: 1.6rem;
                line-height: 150%;
                letter-spacing: 0.05em;
                color: #2C2E31;
                margin-bottom: 5px;
                
                a{
                    color: #2C2E31;
                }
            }
            
            .btn_follow{
                width: 100px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                text-align: center;
                font-size: 1.1rem;
                font-weight: bold;
                color: #000000;
                vertical-align: middle;
                
                cursor: pointer;
                
                span{
                    margin-top: -.25em;
                    vertical-align: text-bottom;
                }
                
                &.following{
                    background-color: #000;
                    color: #fff;
                    
                    span{
                        display: none;
                    }
                }
            }
        }
    }
}



/*------------------------------------*\
    HOT THEME 注目のテーマ
\*------------------------------------*/
.hot_theme{
    padding: 60px 0;
    background-color: #F7F7F7;
    
    @include media_query(){
        padding: 30px 0;
    }
}

.hot_theme_inner{
    max-width: 1128px;
    margin: 0 auto;
    position: relative;
    
    @include media_query(){
        overflow: hidden;
    }
    
    & + .hot_theme_inner{
        margin-top: 20px;
        
        @include media_query(){
            margin-top: 10px;
        }
    }
    
    .swiper-button-prev{
        left: auto;
        right: calc(100% + 8px);
        
        background-image: url(../../common/img/common/bt-left.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 8px 16px;
        
        opacity: 0;
        visibility: hidden;
        transition-property: visibility, opacity;
        transition-delay: .5s;
        transition-duration: .5s;
        
        &::after{
            display: none;
        }
    }
    
    .swiper-button-next{
        right: auto;
        left: calc(100% + 8px);
        
        background-image: url(../../common/img/common/bt-right.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 8px 16px;
        
        opacity: 0;
        visibility: hidden;
        transition-property: visibility, opacity;
        transition-delay: .5s;
        transition-duration: .5s;
        
        &::after{
            display: none;
        }
    }
    
    .swiper-button-disabled{
        z-index: -1;
    }
}

#hot_theme{
    .theme_wrap{
        max-width: 1128px;
        
        & + .theme_wrap{
            margin-top: 20px;
        }
    }
    
    .theme_item{
        position: relative;
        width: 300px;
        height: 400px;
        
        @include media_query(){
            width: 144px;
            height: 190px;
        }
        
        .theme_item_img{
            position: relative;
            width: 300px;
            height: 400px;
            
            &::before{
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                background-color: #000;
                visibility: visible;
                opacity: .3;
                z-index: 2;
                position: absolute;
                left: 0;
                top: 0;
                
                transition-property: visibility, opacity;
                transition-delay: .5s;
                transition-duration: .5s;
            }
            
            @include media_query(){
                width: 144px;
                height: 190px;
            }
            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                font-family: "object-fit: cover;";
            }
        }
        
        .theme_item_info{
            visibility: hidden;
            box-sizing: border-box;
            position: absolute;
            z-index: 9;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 40px 20px 18px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.52) 0%, rgba(0, 0, 0, 0) 105.33%);
            color: #fff;
            opacity: 0;
            
            transition-property: visibility, opacity;
            transition-delay: .5s;
            transition-duration: .5s;
            
            @include media_query(){
                padding: 15px 9px 10px;
            }
            
            .artist_name{
                font-weight: bold;
                font-size: 1.6rem;
                line-height: 150%;
                letter-spacing: 0.05em;
                
                @include media_query(){
                    font-size: 1.4rem;
                }
            }
            
            .art_title{
                font-weight: bold;
                font-size: 1.4rem;
                line-height: 140%;
                letter-spacing: 0.05em;
                
                @include media_query(){
                    font-size: 1.2rem;
                }
            }
            
            .art_plice{
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 19px;
                //text-align: right;
                margin-top: 6px;
                white-space: nowrap;
                font-family: Inter, sans-serif;
                font-weight: 600;
                
                @include media_query(){
                    font-size: 1.4rem;
                    margin-top: 3px;
                }
            }
        }
    }
    
    .theme_item_first{
        padding-right: 30px;
        margin-right: 20px !important;
        
        display: flex;
        align-items: center;
        
        overflow: hidden;
        
        @include media_query(){
            padding-right: 9px;
            width: 182px;
            height: 190px;
            padding-right: 9px;
            margin-right: 7px !important;
        }
        
        .theme_item_img{
            @include media_query(){
                width: 182px;
                height: 190px;
            }
        }
        
        .theme_title_box{
            //visibility: hidden;
            box-sizing: border-box;
            position: absolute;
            overflow: hidden;
            left: 30px;
            z-index: 9;
            //background-color: rgba(#fff, .2);
            width: 300px;
            padding: 30px;
            
            @include media_query(){
                left: 42px;
            }
            
            &::after{
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
                width: 0;
                height: 100%;
                background-color: #fff;
                transition-property: width;
                transition-duration: .5s;
                transition-delay: .5s;
            }
            
            @include media_query(){
                width: 148px;
                padding: 16px;
            }
            
            .theme_title_box_wrap{
                opacity: 0;
                transition-property: opacity;
                transition-duration: .5s;
                transition-delay: .5s;
            }
        }
        
        .theme_title{
            font-weight: 800;
            font-size: 2.4rem;
            line-height: 150%;
            display: flex;
            align-items: flex-end;
            letter-spacing: 0.05em;
            margin-bottom: 16px;
            color: #2C2E31;
            
            @include media_query(){
                font-size: 1.4rem;
            }
        }
        
        .theme_description{
            font-weight: bold;
            font-size: 1.4rem;
            line-height: 140%;
            letter-spacing: 0.05em;
            color: #2C2E31;
            
            @include media_query(){
                font-size: 1.1rem;
            }
        }
        
        .view_all{
            font-weight: bold;
            font-size: 1.4rem;
            line-height: 140%;
            letter-spacing: 0.05em;
            color: #000000;
            text-align: right;
            margin: 15px 10px 0 0;
            padding-right: 20px;
            background-image: url(../img/common/icon_viewmore.png);
            background-repeat: no-repeat;
            background-size: 13px 6px;
            background-position: right center;
            
            @include media_query(){
                display: none;
            }
        }
    }
    
    .inview{
        .theme_item .theme_item_img::before{
            visibility: hidden;
            opacity: 0;
        }
        
        .theme_item .theme_item_info{
            visibility: visible;
            opacity: 1;
        }
        
        .theme_item_first .theme_title_box{
            
            &::after{
                width: 100%;
            }
            
            .theme_title_box_wrap{
                opacity: 1;
            }
        }
        
        .swiper-button-next,.swiper-button-prev{
            opacity: 1;
            visibility: visible;
        }
    }
}






/*------------------------------------*\
    PICK UP ピックアップ
\*------------------------------------*/
.pickup{
    
    .pickup_inner{
        max-width: 1128px;
        margin: 0 auto;
        padding: 60px 0;
        
        @include media_query(){
            padding: 40px 16px;
        }
    }
    
    .pickup_wrap{
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 0 -24px;
        
        @include media_query(){
            margin: 10px 0 0 -7px;
        }
    }
    
    .pickup_item{
        position: relative;
        width: calc(25% - 24px);
        margin-left: 24px;
        
        @include media_query(){
            width: calc(50% - 7px);
            margin-left: 7px;
        }
        
        &:hover{
            .pickup_item_img_wrap::after{
                display: block;
                visibility: visible;
                opacity: 1;
                
                @include media_query(){
                    visibility: hidden;
                }
            }
            
            .pickup_item_img_wrap .overlay_like{
                visibility: visible;
                opacity: 1;
                
                @include media_query(){
                    visibility: hidden;
                }
            }
        }
        
        &:not(:nth-last-child(-n+4)){
            margin-bottom: 24px;
        }
        
        &:not(:nth-last-child(-n+2)){
            margin-bottom: 24px;
        }
        
        // OIL限定
        .oil_only{
            position: absolute;
            left: 0;
            top: 0;
            z-index: 99;
            display: inline-block;
            padding: 4px 18px;
            background: rgba(0, 0, 0, 0.6);
            
            font-weight: bold;
            font-size: 1.4rem;
            line-height: 150%;
            letter-spacing: 0.05em;
            color: #fff;
            
            @include media_query(){
                font-size: 1.2rem;
            }
        }
        
        .pickup_item_img{
            position: relative;
        }
        
        .pickup_item_img_wrap{
            position: relative;
            width: 100%;
            
            &:before {
                content:"";
                display: block;
                padding-top: 100%;
            }
            
            .inner_img{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
            
            img, picture{
                width: 100%;
                height: 100%;
                object-fit: cover;
                font-family: "object-fit: cover;";
                vertical-align: bottom;
            }
            
            &::after{
                display: none;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                visibility: hidden;
                opacity: 0;
                z-index: 2;
                //transition-property: visibility, opacity;
                //transition-duration: .25s;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
            }
            
            .overlay_like{
                visibility: hidden;
                opacity: 0;
                //transition-property: visibility, opacity;
                //transition-duration: .25s;
                position: absolute;
                right: 15px;
                bottom: 15px;
                z-index: 3;
                
                &.bt_hart_on{
                    visibility: visible;
                    opacity: 1;
                }
            }
            
            .overlay_like.bt_hart_on{
                @include media_query(){
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        
        .pickup_item_body{
            margin-top: 8px;
            //display: flex;
            
            @include media_query(){
                margin-top: 6px;
                display: block;
            }
            
            .art_info{
                flex: 1 1 auto;
                
                .art_info_name{
                    font-weight: bold;
                    font-size: 1.4rem;
                    line-height: 140%;
                    letter-spacing: 0.05em;
                    color: #2C2E31;
                    margin-bottom: 2px;
                }
                
                .art_info_title{
                    font-size: 1.2rem;
                    line-height: 140%;
                    text-align: left;
                    letter-spacing: 0.05em;
                    color: #2C2E31;
                }
                
                .art_info_date{
                    font-size: 1.1rem;
                    line-height: 120%;
                    text-align: justify;
                    letter-spacing: 0.05em;
                    color: #666666;
                    margin-top: 4px;
                    
                    span + span{
                        &::before{
                            content: " | ";
                            
                            @include media_query(){
                                content: "";
                            }
                        }
                    }
                    
                    @include media_query(){
                        margin-top: 4px;
                        
                        .production_year{
                            display: none;
                        }
                    }
                }
                
                .art_info_category{
                    flex-grow: 1;
                    font-size: 1.1rem;
                    line-height: 120%;
                    text-align: justify;
                    letter-spacing: 0.05em;
                    color: #666666;
                    margin-top: 4px;
                    
                    @include media_query(){
                        margin-top: 4px;
                    }
                }
                
                .art_info_foot{
                    //display: flex;
                    
                    @include media_query(){
                        display: block;
                        margin-top: 3px;
                    }
                }
            }
            
            .art_plice{
                align-self: flex-end;
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 19px;
                //text-align: right;
                color: #2C2E31;
                //padding-left: 1em;
                margin-top: 10px;
                white-space: nowrap;
                font-family: Inter, sans-serif;
                font-weight: 600;
                flex-grow: 1;
            }
        }
    }
    
}






/*------------------------------------*\
    FEATURED CATEGORY 注目のカテゴリ
\*------------------------------------*/
.featured_category{
    background-color: #F7F7F7;
    padding: 30px 0;
    
    .featured_category_inner{
        max-width: 1128px;
        margin: 0 auto;
        
        @include media_query(){
            .index_section_title_gold{
                padding-left: 16px;
            }
        }
    }
    
    .featured_category_container{
        margin-top: 16px;
    }
    
    .category_item{
        width: 200px;
        
        @include media_query(){
            width: 125px;
            
            &:first-child{
                padding-left: 16px;
            }
            
            &:last-child{
                padding-right: 16px;
                //background-color: #f00;
            }
        }
        
        &:hover{
            .category_item_img .category_item_img_inner .overlay{
                visibility: visible;
                opacity: 1;
            }
        }
        
        .category_item_img{
            box-sizing: border-box;
            width: 200px;
            height: 124px;
            border: 4px solid #FFFFFF;
            
            @include media_query(){
                width: 125px;
                height: 87px;
            }
            
            .category_item_img_inner{
                position: relative;
                width: 100%;
                height: 100%;
            }
            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                font-family: "object-fit: cover;";
            }
            
            .overlay{
                visibility: hidden;
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0.2);
                z-index: 9;
                font-weight: bold;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: 0.05em;
                color: #FFFFFF;
                
                transition-property: visibility, opacity;
                transition-duration: .25s;
            }
        }
        
        .category_item_text{
            font-weight: bold;
            font-size: 1.4rem;
            line-height: 140%;
            text-align: center;
            letter-spacing: 0.05em;
            color: #2C2E31;
            margin-top: 10px;
        }
    }
}





/*------------------------------------*\
    NEW ARTWORKS 新着作品
\*------------------------------------*/
.products{
    margin: 60px 0;
    
    @include media_query(){
        margin: 40px 0;
    }
    
    .products_inner{
        max-width: 1128px;
        margin: 0 auto;
        
        @include media_query(){
            padding: 0 16px;
        }
    }
    
    .products_wrap{
        margin: 16px 0 0 0;
        
        @include media_query(){
            margin: 10px 0 0 0;
        }
    }
    
    .products_item{
        position: relative;
        width: 240px;
        
        @include media_query(){
            width: 200px;
        }
        
        &:hover{
            .products_item_img_wrap::after{
                display: block;
                visibility: visible;
                opacity: 1;
                
                @include media_query(){
                    visibility: hidden;
                }
            }
            
            .products_item_img_wrap .overlay_like{
                visibility: visible;
                opacity: 1;
                
                @include media_query(){
                    visibility: hidden;
                }
            }
        }
        
        // OIL限定
        .oil_only{
            position: absolute;
            left: 0;
            top: 0;
            z-index: 99;
            display: inline-block;
            padding: 4px 18px;
            background: rgba(0, 0, 0, 0.6);
            
            font-weight: bold;
            font-size: 1.4rem;
            line-height: 150%;
            letter-spacing: 0.05em;
            color: #fff;
            
            @include media_query(){
                font-size: 1.2rem;
            }
        }
        
        .products_item_img{
            position: relative;
        }
        
        .products_item_img_wrap{
            position: relative;
            width: 100%;
            
            &:before {
                content:"";
                display: block;
                padding-top: 100%;
            }
            
            .inner_img{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
            
            img, picture{
                width: 100%;
                height: 100%;
                object-fit: cover;
                font-family: "object-fit: cover;";
                vertical-align: bottom;
            }
            
            &::after{
                display: none;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                visibility: hidden;
                opacity: 0;
                z-index: 2;
                //transition-property: visibility, opacity, background-color;
                //transition-duration: 1.25s;
                background-color: rgba(#000, .15);
            }
            
            .overlay_like{
                visibility: hidden;
                opacity: 0;
                //transition-property: visibility, opacity;
                //transition-duration: 1.25s;
                position: absolute;
                right: 15px;
                bottom: 15px;
                z-index: 3;
                
                &.bt_hart_on{
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        
        
        
        .products_item_body{
            margin-top: 8px;
            display: flex;
            
            @include media_query(){
                margin-top: 6px;
                display: block;
            }
            
            .art_info{
                flex: 1 1 auto;
                
                .art_info_name{
                    font-weight: bold;
                    font-size: 1.4rem;
                    line-height: 140%;
                    letter-spacing: 0.05em;
                    color: #2C2E31;
                    margin-bottom: 2px;
                }
                
                .art_info_title{
                    font-size: 1.2rem;
                    line-height: 1.4em;
                    text-align: left;
                    letter-spacing: 0.05em;
                    color: #2C2E31;
                    
                    // display: -webkit-box;
                    // -webkit-box-orient: vertical;
                    // -webkit-line-clamp: 2;
                    // overflow: hidden;
                }
                
                .art_info_date{
                    font-size: 1.1rem;
                    line-height: 120%;
                    text-align: justify;
                    letter-spacing: 0.05em;
                    color: #666666;
                    margin-top: 4px;
                    
                    span + span{
                        &::before{
                            content: " | ";
                            
                            @include media_query(){
                                content: "";
                            }
                        }
                        
                        @include media_query(){
                            margin-top: 4px;
                        }
                    }
                    
                    @include media_query(){
                        margin-top: 4px;
                        color: #999;
                        
                        span{
                            display: block;
                        }
                    }
                    
                    .production_year{
                        @include media_query(){
                            display: none;
                        }
                    }
                }
                
                .art_info_category{
                    flex-grow: 1;
                    font-size: 1.1rem;
                    line-height: 1;
                    text-align: justify;
                    letter-spacing: 0.05em;
                    color: #666666;
                    margin-top: 4px;
                    
                    @include media_query(){
                        margin-top: 4px;
                        color: #999;
                    }
                }
                
                .art_info_foot{
                    //display: flex;
                    
                    @include media_query(){
                        display: block;
                        margin-top: 3px;
                    }
                }
            }
            
            .art_plice{
                align-self: flex-end;
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 19px;
                //text-align: right;
                color: #2C2E31;
                //padding-left: 1em;
                margin-top: 10px;
                white-space: nowrap;
                font-family: Inter, sans-serif;
                font-weight: 600;
                flex-grow: 1;
            }
        }
    }
}

.sp_more_btn{
    display: none;
    
    @include media_query(){
        display: block;
    }
    a{
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.05em;
        color: #2C2E31;
        background-color: #f6f6f6;
        margin-top: 30px;
        
        span{
            display: inline-block;
            white-space: nowrap;
            padding-right: 10px;
            background-image: url(../img/common/right-arrow-black.png);
            background-repeat: no-repeat;
            background-position: center right;
            background-size: 4px 7px;
        }
    }
}

.sp_more_btn_w{
    @extend .sp_more_btn;
    
    padding: 0 16px;
    
    a{
        color: #C2A47E;
        background-color: #fff;
        
        span{
            background-image: url(../img/common/icon_more_link.png);
        }
    }
}






/*------------------------------------*\
    SPECIAL 記事一覧
\*------------------------------------*/
.special{
    background-color: #F7F7F7;
    padding: 30px 0;
    
    .special_inner{
        max-width: 1128px;
        margin: 0 auto;
        
        @include media_query(){
            .index_section_title_gold{
                padding-left: 16px;
            }
        }
    }
    
    .special_container{
        margin-top: 16px;
    }
    
    .special_item{
        width: 240px;
        
        @include media_query(){
            &:first-child{
                padding-left: 16px;
            }
            
            &:last-child{
                padding-right: 16px;
            }
        }
        
        &:hover{
            .category_item_img .category_item_img_inner .overlay{
                visibility: visible;
                opacity: 1;
            }
        }
        
        .special_item_img{
            box-sizing: border-box;
            width: 240px;
            height: 124px;
            border: 4px solid #FFFFFF;
            
            .special_item_img_inner{
                position: relative;
                width: 100%;
                height: 100%;
            }
            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                font-family: "object-fit: cover;";
            }
            
            .overlay{
                visibility: hidden;
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0.2);
                z-index: 9;
                font-weight: bold;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: 0.05em;
                color: #FFFFFF;
                
                transition-property: visibility, opacity;
                transition-duration: .25s;
            }
        }
        
        .special_item_text{
            margin-top: 10px;
            
            h3{
                font-weight: 600;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: 0.05em;
                color: #2C2E31;
            }
            
            .special_item_foot{
                display: flex;
                margin-top: 20px;
                
                .date{
                    display: flex;
                    align-items: center;
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 140%;
                    letter-spacing: 0.05em;
                    color: #999999;
                    margin-right: 12px;
                    
                    &::after{
                        display: block;
                        content: "";
                        width: 1px;
                        height: 11px;
                        background-color: #999;
                        margin-left: 12px;
                    }
                }
                
                .category{
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 140%;
                    letter-spacing: 0.05em;
                    color: #999999;
                }
            }
        }
    }
}





/*------------------------------------*\
    BANNER
\*------------------------------------*/
.banner_area{
    max-width: 1128px;
    margin: 60px auto 0;
    
    @include media_query(){
        padding: 0 16px 0;
        margin: 0;
    }
    
    .banner_area_inner{
        display: flex;
        margin-left: -15px;
        
        @include media_query(){
            flex-direction: column;
            margin: 0;
        }
        
        a{
            width: calc(50% - 15px);
            margin-left: 15px;
            
            transition-property: opacity;
            transition-duration: .25s;
            
            @include media_query(){
                width: 100%;
                margin: 0;
                
                &:not(:last-child){
                    margin-bottom: 16px;
                }
            }
            
            &:hover{
                opacity: .7;
            }
            
            img{
                width: 100%;
                height: auto;
                vertical-align: bottom;
            }
        }
    }
}





/*------------------------------------*\
    GALLERIES / STORES ギャラリー・ストア
\*------------------------------------*/
.galleries_stores{
    background-color: #f7f7f7;
    margin: 60px 0 0 0;
    padding: 30px 0 45px;
    
    @include media_query(){
        padding: 30px 16px 30px;
    }
    
    .galleries_stores_inner{
        max-width: 1128px;
        margin: 0 auto;
    }
    
    .galleries_stores_wrap{
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 0 -24px;
        
        @include media_query(){
            margin: 20px 0 0 -7px;
        }
        
        .galleries_stores_item{
            width: calc(25% - 24px);
            margin-left: 24px;
            
            transition-property: opacity;
            transition-duration: .25s;
            
            &:hover{
                opacity: .7;
            }
            
            @include media_query(){
                width: calc(50% - 7px);
                margin: 0 0 0 7px;
            }
            
            &:not(:nth-last-child(-n+4)){
                margin-bottom: 45px;
                
                @include media_query(){
                    margin-bottom: 24px;
                }
            }
            
            @include media_query(){
                &:not(:nth-last-child(-n+2)){
                    margin-bottom: 24px;
                }
            }
            
            .galleries_stores_item_img_wrap{
                position: relative;
                width: 100%;
                
                @include media_query(){
                    display: none;
                }
                
                &:before {
                    content:"";
                    display: block;
                    padding-top: 52.2727%;
                }
                
                .inner_img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
                
                img, picture{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    font-family: "object-fit: cover;";
                    vertical-align: bottom;
                }
            }
            
            .name{
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.05em;
                color: #2C2E31;
                margin-top: 14px;
                
                @include media_query(){
                    display: flex;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 120%;
                    text-align: left;
                    
                    &::before{
                        content: "";
                        width: .5em;
                        height: 1px;
                        margin: .5em .5em 0 0;
                        background-color: #2C2E31;
                    }
                }
            }
            
            .place{
                font-size: 14px;
                line-height: 120%;
                text-align: center;
                letter-spacing: 0.05em;
                color: #2C2E31;
                margin-top: 10px;
                
                @include media_query(){
                    display: none;
                }
            }
        }
    }
    
    .sp_more_btn_w{
        padding: 0;
    }
}



































/*------------------------------------*\
    .airtworks
\*------------------------------------*/
.artworks{
  max-width: 1128px;
  margin: 0 auto 48px;

  @include media_query(){
  	padding: 0 16px;
  }

  &_head{
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .go_list{
      margin-left: auto;
    }
  }

  &_inner{
    display: flex;
    flex-wrap: wrap;
    margin-left: -1px;
  }

  .go_list_btn a{
      transition-property: opacity;
      transition-duration: 0.25s;

      &:hover{
          opacity: .7;
      }
  }

  &_item{
    position: relative;
    display: block;
    width: calc( 25% - 1px );
    margin-left: 1px;
    background-color: #fff;
    margin-bottom: 1px;

    @include media_query(){
    	width: calc( 50% - 1px );
    }

    &:hover{
      .artworks_item_img{
        position: relative;

        img{
          transform: scale(1.2);
        }

        &:after{
          background-color: rgba(#000, .3);

          transition-property: background-color;
          transition-duration: 0.4s;
        }
      }
    }

    a{
      display: block;
      padding: 24px;

      @include media_query(){
      	padding: 8px 8px 18px;
      }
    }

    &_img{
      position: relative;
      overflow: hidden;
      background-color: #fbfbfb;

      &:after{
        content: "";
        display: block;

        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        width: 100%;
        height: 100%;

        transition-property: opacity;
        transition-property: background-color;
        transition-duration: 0.4s;
        background-color: transparent;
      }


      .oil_only{
        position: absolute;
        left: 0;
        top: 0;
        font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
        font-weight: bold;
        font-size: 1.2rem;
        letter-spacing: 1.2px;
        color: #fff;
        text-align: center;
        line-height: 28px;
        width: 68px;
        height: 28px;
        background-color: #4e7192;
        z-index: 9;
      }

      img{
        width: 100%;
        height: auto;

        vertical-align: bottom;

        transition: transform 0.2s linear;
      }
    }

    &_text{
      position: relative;
      margin-top: 16px;
      padding-top: 16px;
      border-top: solid 1px #ebedf0;

      @include media_query(){
      	margin-top: 8px;
      	padding-top: 8px;
      }

      h3{
        font-family: HiraKakuPro-W6;
        font-size: 1.4rem;
        line-height: normal;
        letter-spacing: 0.7px;
        text-align: center;
        color: #2c2e31;

        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media_query(){
          font-size: 1.2rem;
        	letter-spacing: 0.6px;
        }
      }

      .art_title{
        font-family: Roboto;
        font-size: 1.3rem;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #707980;
        margin-top: 6px;
        margin-bottom: 4px;

        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media_query(){
        	font-size: 1.1rem;
        	margin-top: 4px;
        }
      }

      .art_year{
        font-family: Roboto;
        font-size: 1.3rem;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #707980;
      }

      .art_plice{
        font-family: Roboto;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.7px;
        color: #2c2e31;
        text-align: center;
        margin-top: 16px;
        white-space: nowrap;

        @include media_query(){
          font-size: 1.2rem;
        	letter-spacing: 0.6px;
        	margin-top: 8px;
        }
      }
    }

    .add_like{
      position: absolute;
      right: 24px;
      bottom: 24px;
      line-height: 1;

      @include media_query(){
      	right: 8px;
      	bottom: 18px;
      }
    }
  }

  &_cat{
    padding: 24px;
    background-color: #fff;

    @include media_query(){
    	padding: 16px;
    }

    &_inner{
      ul{
        display: flex;
        flex-wrap: wrap;
        margin-left: -24px;

        @include media_query(){
        	margin-left: -7px;
        }

        li{
          width: calc(25% - 24px);
          margin-left: 24px;
          border: solid 2px #ebedf0;
          //padding: 16px 0;
          height: 60px;
          margin-bottom: 16px;

          transition: border 0.2s linear;

          &:hover{
            border: solid 2px #bfcbd5;
          }

          @include media_query(){
          	width: calc(50% - 7px);
          	margin-left: 7px;
          	margin-bottom: 8px;
          	padding: 8px 0;
          }

          &:nth-last-child(-n + 4){
            margin-bottom: 0;

            @include media_query(){
            	margin-bottom: 8px;
            }
          }

          @include media_query(){
          	&:nth-last-child(-n + 2){
              margin-bottom: 0;
            }
          }

          a{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          p{
            font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
            font-weight: bold;
            font-size: 1.4rem;
            line-height: normal;
            letter-spacing: 1.7px;
            text-align: center;
            color: #2c2e31;

            span{
              display: block;
              font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
              font-weight: bold;
              font-size: 1rem;
              letter-spacing: 1px;
              text-align: center;
              color: #707980;

              @include media_query(){
              	letter-spacing: 0.7px;
              }
            }
          }
        }
      }
    }
  }
}



.sp_go_list{
  display: none;
  padding: 11px 0;
  background-color: #fff;
  margin-top: 1px;
  text-align: center;
  border: solid 1px #ebedf0;
  margin-top: 24px;
  cursor: pointer;

  @include media_query(){
  	display: block;
  }

  span{
    font-family: HiraKakuPro-W3;
    font-size: 1.2rem;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #2c2e31;

    background-image: url(/common/img/common/bt-link.png);
    background-position: right center;
    background-size: 14px 10px;
    background-repeat: no-repeat;
    padding-right: 22px;
  }
}

.go_list_btn{
  width: 100%;
  line-height: 40px;
  //background-color: #ffffff;
  //border: solid 1px #ebedf0;
  text-align: right;
  //margin-top: 1px;

  @include media_query(){
  	text-align: center;
  }

  a{
    display: inline-block;
    height: 40px;
    padding: 0 24px;

    @include media_query(){
    	width: 100%;
    }
  }
  span{
    font-family: HiraKakuPro-W3;
    font-size: 1.2rem;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #2c2e31;

    background-image: url(/common/img/common/bt-link.png);
    background-position: right center;
    background-size: 14px 10px;
    background-repeat: no-repeat;
    padding-right: 22px;
  }
}

.sp_go_list_btn{
  display: none;
  width: 100%;
  height: 40px;
  margin-top: 24px;
  line-height: 40px;
  text-align: center;
  border: solid 1px #ebedf0;

  span{
    font-family: HiraKakuPro-W3;
    font-size: 1.2rem;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #2c2e31;
  }

  @include media_query(){
  	display: block;
  }
}








/*------------------------------------*\
    .goods
\*------------------------------------*/
.goods{
  max-width: 1128px;
  margin: 0 auto 48px;

  @include media_query(){
  	padding: 0 16px;
  }

  &_head{
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .go_list{
      margin-left: auto;
    }
  }

  &_inner{
    display: flex;
    flex-wrap: wrap;
    margin-left: -1px;
  }

  .go_list_btn a{
      transition-property: opacity;
      transition-duration: 0.25s;

      &:hover{
          opacity: .7;
      }
  }

  &_item{
    position: relative;
    display: block;
    width: calc( 16.6666% - 1px );
    margin-left: 1px;
    background-color: #fff;
    margin-bottom: 1px;

    @include media_query(){
    	width: calc( 50% - 1px );
    }

    &:hover{
      .goods_item_img{

        &:after{
          background-color: rgba(#000, .3);

          transition-property: background-color;
          transition-duration: 0.4s;
        }

        img{
          transform: scale(1.2);
        }
      }
    }

    a{
      display: block;
      padding: 16px;

      @include media_query(){
      	padding: 8px 8px 18px;
      }
    }

    &_img{
      position: relative;
      overflow: hidden;
      background-color: #fbfbfb;

      &:after{
        content: "";
        display: block;

        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        width: 100%;
        height: 100%;

        transition-property: opacity;
        transition-property: background-color;
        transition-duration: 0.4s;
        background-color: transparent;
      }

      .oil_only{
        position: absolute;
        left: 0;
        top: 0;
        font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
        font-weight: bold;
        font-size: 1.2rem;
        letter-spacing: 1.2px;
        color: #fff;
        text-align: center;
        line-height: 28px;
        width: 68px;
        height: 28px;
        background-color: #4e7192;
        z-index: 9;
      }

      img{
        width: 100%;
        height: auto;

        vertical-align: bottom;

        transition: transform 0.2s linear;
      }
    }

    &_text{
      position: relative;
      margin-top: 8px;
      
      .artist_names{
          position: relative;
          font-size: 1.3rem;
          font-family: HiraginoSans-W4;
          line-height: 1.54;
          letter-spacing: 0.7px;
          color: #2c2e31;
          margin-bottom: 8px;
          text-align: left;
      }

      h3{
        position: relative;
        font-size: 1.3rem;
        font-family: HiraginoSans-W4;
        line-height: 1.54;
        letter-spacing: 0.7px;
        color: #2c2e31;
        margin-bottom: 12px;
        text-align: left;
      }

      .goods_plice{
        font-family: Roboto;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.7px;
        color: #2c2e31;
      }
    }

    .add_like{
      position: absolute;
      right: 16px;
      bottom: 16px;
      line-height: 1;
    }
  }
}


.ellip {
  display: block;
  height: 100%;
}

.ellip-line {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  max-width: 100%;
}

.ellip,
.ellip-line {
  position: relative;
  overflow: hidden;
}



/*------------------------------------*\
    .banners
\*------------------------------------*/
.banners{
  max-width: 1128px;
  margin: 0 auto 48px;
  display: flex;
  justify-content: space-between;

  @include media_query(){
    padding: 0 16px;
    display: block;
    justify-content: unset;
  }
  a{
    display: block;
  }
  img{
    width: 556px;
    height: auto;
    vertical-align:top;
    @include media_query(){
      width: 100%;
      margin-bottom: 10px;
    }
  }
}


/*------------------------------------*\
    .magazine
\*------------------------------------*/
.section_wrap{
  background-color: #fff;
  padding: 48px 0;
  border-bottom: solid 1px #f1f2f4;

  @include media_query(){
  	padding: 24px 16px;
  }
}

.magazine{
  max-width: 1128px;
  margin: 0 auto;

  @include media_query(){
  	margin-bottom: 25px;
  }

  &_head{
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .go_list{
      margin-left: auto;
    }
  }

  &_inner{
    display: flex;
    flex-wrap: wrap;
    margin-left: -24px;

    @include media_query(){
    	margin-left: 0;
    }

    &+.go_list_btn{
      margin-top: 24px;

      @include media_query(){
      	a{
        	border: solid 1px #ebedf0;
      	}
      }
    }
  }

  .go_list_btn a{
      transition-property: opacity;
      transition-duration: 0.4s;

      &:hover{
          opacity: .7;
      }
  }

  &_item{
    position: relative;
    width: calc(33.333% - 24px);
    display: block;
    margin-left: 24px;
    margin-bottom: 45px;

    @include media_query(){
    	width: 100%;
    	margin-left: 0;

    	&:not(:last-child){
      	margin-bottom: 24px;
    	}
    }

    &:nth-last-child(-n + 3){
      margin-bottom: 0;

      @include media_query(){
      	margin-bottom: 24px;

      	&:last-child{
        	margin-bottom: 0;
      	}
      }
    }

    &:hover{
      .magazine_item_img{

        &:after{
          background-color: rgba(#000, .3);

          transition-property: background-color;
          transition-duration: 0.4s;
        }

        img{
          transform: scale(1.2);
        }
      }
    }

    a{
      display: block;
    }

    &_img{
      max-width: 359px;
      max-height: 202px;
      position: relative;
      overflow: hidden;
      background-color: #fbfbfb;

      &:after{
        content: "";
        display: block;

        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        width: 100%;
        height: 100%;

        transition-property: opacity;
        transition-property: background-color;
        transition-duration: 0.4s;
        background-color: transparent;
      }
      
      img{
        width: 100%;
        height: auto;

        vertical-align: bottom;

        transition: transform 0.2s linear;
      }
    }

    &_text{
      position: relative;
      margin-top: 8px;

      h3{
        font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.31;
        letter-spacing: 0.9px;
        color: #2c2e31;
        text-align: left;
        word-break: break-all;


        @include media_query(){
        	font-size: 1.4rem;
        	line-height: 1.5;
          letter-spacing: 0.8px;
        }
      }

      .magazine_info{
        font-family: Roboto;
        font-size: 1.2rem;
        line-height: normal;
        letter-spacing: normal;
        color: #2c2e31;
        margin-top: 12px;

        @include media_query(){
        	margin-top: 8px;
        }
      }
    }
  }
}










/*------------------------------------*\
    .artist
\*------------------------------------*/
.artist{
  max-width: 1128px;
  margin: 32px auto 32px;
  padding-bottom: 32px;
  border-bottom: solid 1px #f1f2f4;
  padding-top: 32px;
  border-top: solid 1px #f1f2f4;

  @include media_query(){
  	margin-bottom: 25px;
  }

  &_head{
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .go_list{
      margin-left: auto;
    }
  }

  &_inner{

  }

  ul{
      display: flex;
      flex-wrap: wrap;

    li{
      font-family: HiraKakuPro-W3;
      font-size: 1.4rem;
      line-height: normal;
      letter-spacing: 0.7px;
      color: #2c2e31;
      width: 20%;

      text-indent: -.8em;
      padding-left: calc(.8em + 24px);

      transition-property: color;
      transition-duration: .25s;

      @include media_query(){
          width: 50%;
          padding-left: calc(.8em + 16px);

          &:nth-child(odd){
              padding-left: .8em
          }
      }

      a{
        color: #2c2e31;

        &:hover{
          text-decoration: underline;
        }
      }

      &:not(:last-child){
        margin-bottom: 18px;
      }

      &:before{
        content: "-";
        padding-right: .5em;
      }
    }
  }
}










/*------------------------------------*\
    .galleries
\*------------------------------------*/
.galleries{
  max-width: 1128px;
  margin: 0 auto;

  &_head{
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .go_list{
      margin-left: auto;
    }

    @include media_query(){
    	.section_title{
        span{
          display: block;
          margin-left: 0;
          margin-top: 8px;
        }
      }
    }
  }

  &_inner{
    display: flex;
    flex-wrap: wrap;
    margin-left: -24px;

    @include media_query(){
    	margin-left: -18px;
    }
  }

  &_item{
    position: relative;
    display: block;
    width: calc( 25% - 24px );
    margin-left: 24px;
    margin-bottom: 32px;
    background-color: #fff;

    @include media_query(){
    	width: calc( 50% - 18px );
    	margin-bottom: 25px;
    	margin-left: 18px;
    }

    &:nth-last-child(-n + 4){
      margin-bottom: 0;

      @include media_query(){
      	margin-bottom: 25px;
      }
    }

    &:nth-last-child(-n + 2){

      @include media_query(){
      	margin-bottom: 0;
      }
    }


    &:hover{
      .galleries_item_img{
          
        img{
          transform: scale(1.2);
        }
      }
    }

    a{
      display: block;
    }

    &_img{
      position: relative;
      overflow: hidden;
      background-color: #fbfbfb;

      .oil_only{
        position: absolute;
        left: 0;
        top: 0;
        font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
        font-weight: bold;
        font-size: 1.2rem;
        letter-spacing: 1.2px;
        color: #fff;
        text-align: center;
        line-height: 28px;
        width: 68px;
        height: 28px;
        background-color: #4e7192;
        z-index: 9;
      }

      img{
        width: 100%;
        height: auto;

        vertical-align: bottom;

        transition: transform 0.2s linear;
      }
    }

    &_text{
      position: relative;
      margin-top: 8px;

      @include media_query(){
        margin-top: 5px;
      }

      h3{
        font-family: HiraKakuPro-W3;
        font-size: 1.4rem;
        line-height: normal;
        letter-spacing: 0.7px;
        color: #2c2e31;

        @include media_query(){
          font-size: 1.2rem;
          line-height: 1.33;
          letter-spacing: 0.6px;
        }
      }

      .galleries_place{
        font-family: HiraMinProN-W3;
        font-size: 1.1rem;
        line-height: normal;
        letter-spacing: 0.5px;
        color: #2c2e31;
        margin-top: 5px;

        @include media_query(){
          font-size: 1.1rem;
        }
      }
    }
  }
}

.might_component{
    .section_title{
        span{
            @include media_query(){
                display: block;
                margin-left: 0;
            }
        }
    }
}







