.payment_method{
  max-width: 1128px;
  margin: 24px auto 0;

  @include media_query(){
  	padding: 0 16px;
  }
}

.payment_registered{
  margin-top: 24px;
  background-color: #fff;

  @include media_query(){
  	margin-top: 16px;
  }

  &_inner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 48px 16px;

    @include media_query(){
    	flex-direction: column;
    	padding: 24px 16px;
    }

    h4{
        margin-bottom: 32px;
      font-family: HiraKakuPro-W6;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 1.1px;
      color: #2c2e31;

      @include media_query(){
      	margin-bottom: 16px;
      	font-size: 1.2rem;
      }
    }
  }

  &_table{
      width: 100%;
      max-width: 268px;

    @include media_query(){
    	width: 100%;
    }
    tr:first-child{
      th,td{
        padding-top: 0;
      }
    }

    tr:last-child{
      th,td{
        padding-bottom: 0;
      }
    }

    th,td{
      font-family: HiraKakuPro-W3;
      font-weight: normal;
      font-size: 1.4rem;
      line-height: normal;
      letter-spacing: 1.3px;

      padding: 8px 0;

      @include media_query(){
      	padding: 4px 0;
      }
    }

    th{
      color: #2c2e31;
      white-space: nowrap;
      padding-right: 12px;
    }

    td{
      color: #2c2e31;
      word-break: break-all;

      &:not([colspan="2"]){
        &::before{
          content: ": ";
        }
      }

      p{
        font-family: HiraKakuPro-W3;
        font-size: 1.1rem;
        line-height: normal;
        letter-spacing: normal;
        color: #2c2e31;
        text-align: center;

        @include media_query(){
        	max-width: 100%;
        }
      }
    }
  }
}

%payment_btn{
  display: block;
  text-align: center;
  font-family: HiraKakuPro-W6;
  font-weight: bold;
  font-size: 1.4rem;
  letter-spacing: 0.6px;

  max-width: 268px;
  width: 100%;
  height: 40px;
  line-height: 40px;

  @include media_query(){
  	max-width: 100%;
  	font-size: 1.2rem;
  }
}

.payment_btn_w{
  @extend %payment_btn;
  border: solid 1px #ebedf0;
  background-color: #fff;
  color: #707980;

  transition-property: border-color;
  transition-duration: .25s;

  &:hover{
    border: solid 1px #bfcbd5;
  }
}

.payment_btn_r{
  @extend %payment_btn;
  border: solid 1px #d94f4f;
  background-color: #d94f4f;
  color: #fff;
  margin-bottom: 8px;

  &:hover{
    opacity: .7;
  }
}









.payment_regist{
  margin-top: 24px;
  background-color: #fff;

  @include media_query(){
  	margin-top: 16px;
  }

  &_inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px;

    @include media_query(){
    	padding: 16px 0;
    	display: block;
    }

    h4{
      font-family: HiraKakuPro-W6;
      font-size: 1.4rem;
      line-height: normal;
      letter-spacing: 1.3px;
      margin-bottom: 32px;

      @include media_query(){
      	width: 100%;
      	text-align: center;
      	padding: 2px 16px 18px;
      	border-bottom: solid 1px #ebedf0;
      	margin-bottom: 24px;
      }
    }
  }

  .payment_regist_cap{
      @include media_query(){
      	text-align: center;
      }
  }

  form{
    width: 100%;
    display: flex;
    justify-content: center;

    @include media_query(){
    	padding: 0 16px;
    }
  }

  &_table{
    margin-left: -42px;

    @include media_query(){
      width: 100%;
      padding: 0 16px;
    	margin-left: 0;
    }

    input[type=number]{
      width: 268px;
      max-width: 100%;

      @include media_query(){
      	width: 100%;
      }
    }

    input[type=button]{
        cursor: pointer;

        &:hover{
            border: solid 1px #bfcbd5;
        }
    }

    input[disabled]{
        background-color: #dce0e3;
    }

    tr{
      &:first-child{
        th,td{
          padding-top: 0;
        }

        @include media_query(){
        	td{
          	padding-top: 8px;
        	}
        }
      }

      &:last-child{
        th,td{
          padding-bottom: 0;
        }
      }

      @include media_query(){
        width: 100%;
      	display: block;
      }
    }

    th,td{
      padding: 8px 0;

      @include media_query(){
      	display: block;
      	width: 100%;
      }
    }

    th{
      padding-right: 24px;
      white-space: nowrap;

      font-family: HiraKakuPro-W6;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 1.1px;

      @include media_query(){
      	padding: 8px 0 0 0;
      }
    }

    td{
        word-break: break-all;
    }


    .form_date{
      display: flex;
      align-items: center;

      select{
        width: 85px;
        max-width: 100%;
      }

      span{
        font-family: HiraKakuPro-W6;
        font-weight: bold;
        font-size: 1.2rem;
        line-height: normal;
        letter-spacing: 1.1px;
        margin-left: 9px;

        &+select{
          margin-left: 24px;
        }
      }
    }
  }
}

.card_date{
    select + select{
        margin-left: 16px;
    }
}
