label{
  &.radio, &.radio_text, &.checkbox, &.checkbox_text{
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    box-sizing: border-box;
  }
  &.radio{
    width: 18px;
    height: 18px;
    border: 1px solid #dce0e3;
    border-radius: 100%;
  }
  &.radio_text{
    min-height: calc(18px + 5px);
    margin-right: calc(18px + 5px);
    padding-left: calc(18px + 5px);
    line-height: calc(18px + 5px);
  }
  &.checkbox{
    width: 20px;
    height: 20px;
    border: 1px solid #B3B3B3;
    background: #fff;
  }
  &.checkbox_text{
    padding-left: calc(20px + 5px);
    line-height: 20px;
  }
  &.radio:before, &.radio_text:before, &.checkbox_text:before, &.checkbox:after, &.radio_text:after, &.checkbox_text:after{
    content: '';
    position: absolute;
    box-sizing: border-box;
  }
  &.radio:before, &.radio_text:before{
    border-radius: 100%;
  }
  &.radio:before{
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
  }
  &.checkbox:after{
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    border-right: 2px solid #2c2e31;
    border-bottom: 2px solid #2c2e31;
    top: calc(50% - (((17px / 12 ) * 5) / 2));
    display: block;
    margin-top: calc(((17px / 12 ) * 5) * -1);
    width: calc(((17px / 12 ) * 5));
    height: 17px;
    z-index: 1;
  }
  &.checkbox_text:after{
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    border-right: 2px solid #2c2e31;
    border-bottom: 2px solid #2c2e31;
    top: calc(50% - (((17px / 12 ) * 5) / 2));
    display: block;
    margin-top: calc(((17px / 12 ) * 5) * -1);
    width: calc(((17px / 12 ) * 5));
    height: 17px;
    z-index: 1;
  }
  &.radio_text{
    &:before, &:after{
      top: 0px;
      bottom: 0px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  &.radio:before{
    display: block;
    width: 10px;
    height: 10px;
    z-index: 1;
    background-color: #2c2e31;
  }
  &.radio_text:before{
    width: 18px;
    height: 18px;
    border: 1px solid #dce0e3;
    left: 0px;
    z-index: 3;
    transition-property: border;
    transition-duration: .25s;
  }
  &.radio_text:hover{
    &:before{
      border: 1px solid #2c2e31;
    }
  }
  &.checkbox_text:before{
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0;
    border: 1px solid #B3B3B3;
    z-index: 3;
    box-sizing: border-box;
  }
  &.radio_text:after{
    width: 10px;
    height: 10px;
    border-radius: 100%;
    left: calc(((18px/2) - (10px) / 2));
    background-color: #2c2e31;
    z-index: 1;
  }
  &.checkbox_text:after{
    left: calc((20px / 2) - (((17px / 12 ) * 5) / 2));
  }
  &.radio input[type="radio"], &.radio_text input[type="radio"], &.checkbox input[type="checkbox"], &.checkbox_text input[type="checkbox"]{
    -moz-appearance: none;
    -webkit-appearance: none;
    position: absolute;
    z-index: 2;
    margin: 0px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    outline: none;
  }
  &.radio input[type="radio"], &.radio_text input[type="radio"]{
    left: calc(18px * -1);
    width: 18px;
    height: 18px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 100%;
  }
  &.checkbox input[type="checkbox"], &.checkbox_text input[type="checkbox"]{
    left: calc(20px * -1);
    padding: 0;
  }
  &.radio input[type="radio"]{
    display: block;
    box-shadow: 18px 0px #FFF;
  }
  &.radio_text input[type="radio"]{
    box-shadow: 18px 0px #FFF;
  }
  &.checkbox input[type="checkbox"]{
    box-shadow: 20px 0px #FFF;
  }
  &.checkbox_text input[type="checkbox"]{
    display: block;
    box-shadow: 20px 0px #FFF;
    &:checked{
      box-shadow: none;
    }
  }
  &.checkbox input[type="checkbox"]:checked, &.radio_text input[type="radio"]:checked, &.radio input[type="radio"]:checked{
    box-shadow: none;
  }
  &.checkbox_text input[type="checkbox"]:checked:focus, &.checkbox input[type="checkbox"]:checked:focus{
    opacity: 0.1;
  }
  &.radio_text input[type="radio"]:focus, &.radio input[type="radio"]:focus{
    opacity: 0.2;
  }
}