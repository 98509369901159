.slide_dl2{

  &_inner{
    &:last-child{
      border-top: solid 1px #ebedf0;

      dt{
       display: flex;
       align-items: center;
       padding-bottom: 16px;
       
       @include media_query(){
        flex-wrap: wrap;
        
        label{
          width: 100%;
          margin-bottom: 10px;
          
          & + img{
            margin-left: 25px;
          }
        }
       }

       img+img{
         margin-left: 4px;
       }
      }

      dd{
        display: none;
      }
    }
  }

  dt{
    padding: 16px 24px 8px 64px;

    &:not(:first-child){
      border-top: none;
    }

    @include media_query(){
      padding: 21px 16px 10px;
    }

    .radio_text{
      font-family: HiraKakuPro-W3;
      font-size: 1.4rem;
      line-height: 1.8;
      letter-spacing: 1.3px;
      color: #2c2e31;

      span{
        font-family: Roboto;
        font-weight: 400;
        margin-left: 20px;
      }

      @include media_query(){
      	font-size: 1.3rem;
      }
    }
  }

  dd{
    border-top: none;

    padding: 0 36px 24px 85px;

    @include media_query(){
    	padding: 0 16px;
    }

    table{
      width: 100%;

      tr{
        @include media_query(){
        	display: block;
        }
      }

      th,td{
        padding: 8px 0;


        @include media_query(){
        	display: block;
        }
      }

      th{
        font-family: HiraKakuPro-W6;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 1.1px;
        color: #2c2e31;
        white-space: nowrap;

        display: flex;
        align-items: center;

        @include media_query(){
        	padding-bottom: 0;
        }
      }

      td{
        text-align: right;

        @include media_query(){
        	padding-bottom: 24px;
        }

        input,select{
          display: inline-block;
          width: 268px;
        }

        select{
            @supports (-ms-ime-align: auto) {
                line-height: 1.4;
                padding-bottom: 3px;
            }
        }

        .error{
          border: solid 1px #f55367 !important;
        }

        .expirationdate{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-left: auto;

          @include media_query(){
          	justify-content: flex-start;
          }

          select{
            width: 85px;
            margin-right: 9px;
            font-family: Roboto;

            &:last-of-type{
              margin-left: 24px;
            }
          }
        }
      }

      td[colspan="2"]{
        text-align: left;
        padding-bottom: 0;
        padding-top: 16px;

        @include media_query(){
        	padding-top: 0;
        	padding-bottom: 16px;
        }

        .checkbox_text{
          letter-spacing: 1.1px;
        }
      }
    }



    ul{
      li{
        display: flex;
        align-items: center;

        font-family: HiraKakuPro-W6;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 1.1px;
        color: #2c2e31;

        a{
          margin-left: 5px;
        }

        input,select{
          max-width: 268px;
          margin-left: auto;
        }

        &+li{
          margin-top: 16px;
        }


      }
    }
  }
}




.has_cardicon{
  background:
      url(../img/settlement/discover.gif) right 24px top 18px/33px 24px no-repeat,
      url(../img/settlement/diners.png) right 61px top 18px/33px 24px no-repeat,
      url(../img/settlement/americanexpress.gif) right 98px top 18px/33px 24px no-repeat,
      url(../img/settlement/jcb.gif) right 135px top 18px/33px 24px no-repeat,
      url(../img/settlement/mastercard.png) right 172px top 18px/33px 24px no-repeat, 
      url(../img/settlement/visacard.png) right 209px top 18px/31px 24px no-repeat;
      
  @include media_query(){
    background: 
    url(../img/settlement/discover.gif) left 219px top 50px/33px 24px no-repeat,
    url(../img/settlement/diners.png) left 181px top 50px/33px 24px no-repeat,
    url(../img/settlement/americanexpress.gif) left 145px top 50px/33px 24px no-repeat,
    url(../img/settlement/jcb.gif) left 112px top 50px/33px 24px no-repeat,
    url(../img/settlement/mastercard.png) left 76px top 50px/33px 24px no-repeat,
    url(../img/settlement/visacard.png) left 39px top 50px/31px 24px no-repeat;
  }
}

.slide_dl .slide_dl_inner .has_cardicon{
  @include media_query(){
    padding: 21px 16px 40px;
  }
}







.pay_tpoint{
  margin-top: 48px;

  h4{
    font-family: HiraKakuPro-W6;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 1.3px;
    color: #2c2e31;

    margin-bottom: 24px;

    span{
      font-family: HiraKakuPro-W3;
      font-size: 1.2rem;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #2c2e31;

      margin-left: 16px;
    }
  }

  &_list{
    li{
      margin-bottom: 16px;

      &:last-child{
        margin-top: 20px;
      }

      .radio_text{
        font-family: HiraKakuPro-W6;
        font-size: 1.2rem;
        line-height: 1.9;
        letter-spacing: 0.5px;
        color: #2c2e31;
      }
    }
  }

  &_form{
    display: flex;
    align-items: center;

    .radio_text{
      margin-right: 21px;
    }

    input[type=text], input[type=number]{
      max-width: 112px;
      width: 100%;
      height: 32px;
      border: solid 1px #dce0e3;
      padding: 0 12px;
      margin-left: 0px;

      font-family: HiraKakuPro-W3;
      font-size: 1.4rem;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance:textfield;
    }

    span{
      margin-left: 8px;

      font-family: HiraKakuPro-W3;
      font-size: 1.2rem;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #2c2e31;

      @include media_query(){
      	display: none;
      }
    }
  }
}


.info_icon{
  display: block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.1px;
  color: #fff;
  background-color: #bfcbd5;
  border-radius: 50%;
  margin-left: 8px;
}


.pay_tpoint_input[disabled]{
  background-color: #dce0e3;
}

.credit_disabled{
    color: #ccc !important;
    cursor: text !important;
    
    &:hover:before{
        border: 1px solid #dce0e3 !important;
    }
}

.credit_error{
    color: #707980;
    margin-top: 8px;
}

