//決済画面用のheader
.settlement_header{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;

  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ebedef;

  &_inner{
    max-width: 1170px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    @include media_query(){
    	justify-content: center;
    }
  }

  &_logo{
    img{
      vertical-align: bottom;
    }
  }
}





//決済画面用のfooter
.settlement_footer{
  background-color: #fff;
  padding: 24px 0 16px;

  @include media_query(){
  	padding: 32px 0;
  }

  &_inner{
    max-width: 1170px;
    margin: 0 auto;

    @include media_query(){
    	padding: 0 24px;
    }
  }

  &_logo{
    margin-bottom: 8px;

    @include media_query(){
    	display: none;
    }
  }

  &_main{
    display: flex;
  }

  &_nav{
    display: flex;
    margin-left: auto;

    @include media_query(){
    	display: block;
    	margin-left: 0;
    }

    li{

      &:not(:last-child){
        margin-right: 32px;

        @include media_query(){
        	margin-right: 0;
        }
      }

      a{
        font-family: HiraKakuPro-W3;
        font-size: 1.2rem;
        line-height: normal;
        letter-spacing: 1.6px;
        color: #707980;

        @include media_query(){
        	font-size: 1.1rem;
        	letter-spacing: 1.5px;
        }

        &:hover{
          text-decoration: underline;
        }
      }
    }
  }

  &_copy{
    font-family: Roboto;
    font-size: 1.1rem;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #707980;

    @include media_query(){
    	margin-top: 48px;
    }
  }
}





.settlement_error{

  margin: 24px 0;

  li{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px 9px 16px;

    background-color: #dce0e3;
    font-family: HiraKakuPro-W3;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 0.7px;
    // color: #2c2e31;
    color: #f00;

    span{
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      content: "";
      display: block;
      width: 40px;
      height: 40px;
      background-image: url("../img/common/ic-close_b.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 12px;
      cursor: pointer;

      &:hover{
        opacity: .7;
      }
    }

    &+li{
      margin-top: 16px;
    }
  }

  .error_important{
    background-color: #ffd8dc;
    color: #d94f4f;
  }

  .error_payment{
    background-color: #52ca88;
    color: #fff;
  }

  .error_payment_g{
    background-color: #bfcbd5;
    color: #fff;
  }

}






.page{
  &_head{
    display: flex;
    margin-bottom: 24px;

    @include media_query(){
    	margin-bottom: 16px;
    }
  }

  &_title{
    font-family: PTSerif-Caption;
    font-size: 1.8rem;
    line-height: normal;
    letter-spacing: 2.5px;
    color: #2c2e31;

    @include media_query(){
    	font-size: 1.6rem;
    	letter-spacing: 2.2px;
    }

    span{
      font-family: HiraMinProN-W3;
      font-size: 1.2rem;
      line-height: normal;
      letter-spacing: 1px;
      color: #707980;
      margin-left: 8px;

      @include media_query(){
    		font-size: 1.2rem;
    	}
    }
  }

  &_modal{
    font-family: HiraKakuPro-W3;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #2c2e31;
    margin-left: auto;
    text-decoration: underline;

    @include media_query(){
    	font-size: 1.2rem;
    	letter-spacing: 0.2px;
    }

    &:hover{
      text-decoration: none;
    }
  }
}





/*------------------------------------*\
    カート一覧
\*------------------------------------*/
.cart{
  max-width: 1128px;
  margin: 0 auto;

  @include media_query(){
  	padding: 16px 16px 0;
  }

  &_main{
    background-color: #fff;

    &:not(:first-child){
      margin-top: 24px;
    }

    &:last-child{
      margin-bottom: 64px;
    }

    &_head{
      display: flex;
      flex-wrap: wrap;

      .cart_type{
        width: 145px;
        height: 52px;

        display: flex;
        justify-content: center;
        align-items: center;

        font-family: PTSerif-Caption;
        font-size: 1.4rem;
        line-height: normal;
        letter-spacing: 1.6px;
        text-align: center;
        color: #fff;

        @include media_query(){
          width: 106px;
          height: 47px;
        	font-size: 1.2rem;
        	letter-spacing: 1.4px;
        }
      }

      .gallery_name{
        display: flex;
        flex: 1;
        align-items: center;
        width: 100%;
        height: 52px;
        font-family: Roboto;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.8px;
        color: #2c2e31;
        padding: 0 16px;
        border-bottom: solid 1px #ebedf0;

        @include media_query(){
          height: 47px;
        	font-size: 1.4rem;
        	letter-spacing: 0.7px;
        }

        a{
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #2c2e31;

          @include media_query(){
          	width: 200px;
          }

          &:hover{
            opacity: .7;
          }
        }
      }
    }

    //confirm用
    &_confirm{
      padding: 0 24px;

      &:not(:first-child){
        margin-top: 0;
      }

      .cart_inner{
        padding: 24px 0;
      }

      .cart_info{
        padding-right: 26px;
      }

      .cart_settlement{
        .cart_price{
          padding-right: 0;
        }
      }
    }
  }



  //商品カード
  &_inner{
    display: flex;
    padding: 24px;
    border-bottom: solid 1px #ebedf0;

    @include media_query(){
      display: block;
    	padding: 16px;
    }
  }

  &_info{
    width: 51.2%;

    display: flex;

    @include media_query(){
    	width: 100%;
    	margin-bottom: 32px;
    }
  }

  &_img{
    margin-right: 24px;
    background-color: #fbfbfb;

    @include media_query(){
    	margin-right: 16px;
    }

    a,span{
      width: 64px;
      height: 64px;
      background-color: #ebedf0;
      padding: 6px;
      overflow: hidden;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover{
        opacity: .7;
      }
    }

    span:hover{
      opacity: 1;
    }

    img{
      max-width: 100%;
      width: auto;
      max-height: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }

  &_text{
    flex: 1;
    padding-right: 20px;

    @include media_query(){
    	width: 100%;
    	padding-right: 0;
    }

    p{
      word-break: break-all;
    }

    a{
      &:hover{
        opacity: .7;
      }
    }

    &_artist{
      font-family: HiraKakuPro-W3;
      font-size: 1.6rem;
      line-height: normal;
      letter-spacing: 0.8px;
      color: #2c2e31;

      @include media_query(){
      	font-size: 1.4rem;
      	letter-spacing: 0.7px;
      }
    }

    &_arttitle{
      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      line-height: normal;
      letter-spacing: 0.6px;
      color: #707980;
    }

    &_artdate{
      font-family: HiraKakuPro-W3;
      font-size: 1.2rem;
      line-height: normal;
      letter-spacing: 0.6px;
      color: #707980;
    }
  }



  &_settlement{
    width: 48.8%;

    @include media_query(){
    	width: 100%;
    }

    .cart_price{
      display: flex;
      align-items: center;

      padding: 0 0 16px 0;
      border-bottom: dashed 1px #bfcbd5;

      @include media_query(){
      	padding: 0 0 16px 0;
      }

      .unitprice{
        font-family: HiraginoSans-W3;
        font-size: 1.2rem;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #2c2e31;

        margin-right: auto;

        @include media_query(){
        	display: none;
        }

        span{
          font-family: Roboto;
          font-size: 1.4rem;
        }
      }

      .cart_quantity{
        display: flex;
        align-items: center;

        &_p{
          font-family: HiraKakuPro-W3;
          font-size: 1.2rem;
          line-height: normal;
          letter-spacing: 0.6px;
          color: #707980;
          white-space: nowrap;
          margin-right: 8px;
        }

        label{
          font-family: HiraKakuPro-W3;
          font-size: 1.2rem;
          line-height: normal;
          letter-spacing: 0.6px;
          color: #707980;
          white-space: nowrap;
          margin-right: 8px;
        }

        select{
          width: 65px;
          height: 24px;
          padding: 0 8px;
          background-position: right 8px center;
          background-size: 7px 4px;

          font-family: Roboto;
          font-size: 1.4rem;
          line-height: normal;
          letter-spacing: 0.7px;
          color: #2c2e31;
        }

        .error_select{
          background-color: #ffd8dc;
          border: solid 1px #d94f4f;
        }
      }

      .cart_del{
        margin-left: 12px;

        a{
          font-family: HiraginoSans-W4;
          font-size: 1.2rem;
          line-height: normal;
          letter-spacing: 0.2px;
          color: #2c2e31;
          text-decoration: underline;

          @include media_query(){
          	font-size: 1.4rem;
          	letter-spacing: 0.3px;
          }

          &:hover{
            text-decoration: none;
          }
        }
      }

      .cart_total{
        width: 130px;
        text-align: right;
        margin-left: 16px;

        font-family: HiraginoSans-W4;
        font-size: 1.2rem;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #2c2e31;

        @include media_query(){
        	margin-left: auto;
        }

        span{
          font-weight: normal;
          font-family: Roboto;
          font-size: 1.4rem;
          font-weight: 500;
        }
      }
    }
  }

  &_nb{
    padding: 14px 0 0;

    @include media_query(){
    	display: flex;
    }

    &_heading{
      font-family: HiraKakuPro-W3;
      font-size: 1.2rem;
      line-height: normal;
      letter-spacing: 0.6px;
      color: #2c2e31;

      margin-bottom: 8px;

      @include media_query(){
      	width: 32.79%;
      }
    }

    &_text{
      font-family: HiraKakuPro-W3;
      font-size: 1.2rem;
      line-height: 1.33;
      letter-spacing: 0.6px;
      color: #707980;

      flex: 1;
    }
  }
  //商品カード



  &_main_total{
    padding: 24px;

    display: flex;

    @include media_query(){
    	display: block;
    	padding: 18px 16px;
    }
  }

  &_main_total_text{
    width: 64.53%;
    padding-right: 24px;
    border-right: 1px solid #ebedf0;

    @include media_query(){
    	display: none;
    }

    p{
      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      line-height: 1.31;
      letter-spacing: 0.6px;
      color: #2c2e31;
    }

    &_sp{
      display: none;

      font-family: HiraKakuPro-W3;
      font-size: 1.2rem;
      line-height: 1.42;
      letter-spacing: 0.6px;
      color: #2c2e31;

      border-top: solid 1px #ebedf0;
      margin-top: 24px;
      padding-top: 16px;

      @include media_query(){
      	display: block;
      }
    }
  }

  &_main_total_price{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 35.47%;

    @include media_query(){
    	width: 100%;
    }
  }

  .total_price_table{
    width: 100%;
    max-width: 292px;
    margin-bottom: 20px;

    @include media_query(){
    	max-width: 100%;
    }

    tr{

    }

    th,td{
      font-weight: normal;
      padding-bottom: 14px;
      text-align: right;
    }

    th{
      max-width: 4.8em;
      white-space: nowrap;

      font-family: HiraKakuPro-W3;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #2c2e31;

      @include media_query(){
      	max-width: 7.1em;
      }
    }

    td{
      font-family: HiraginoSans-W4;
      font-size: 1.2rem;

      span{
        font-family: Roboto;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #2c2e31;
      }
    }

    .deliverycharge{
      font-family: HiraKakuPro-W3;
      font-size: 1.2rem;
      line-height: normal;
      letter-spacing: 0.2px;

      a{
        color: #2c2e31;
        text-decoration: underline;

        &:hover{
          text-decoration: none;
        }
      }
    }

    .total{
      border-top: solid 1px #ebedf0;

      th,td{
        padding-bottom: 0;
        padding-top: 15px;
      }

      td{
        span{
          font-family: Roboto;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.3px;
          color: #2c2e31;
        }
      }
    }
  }

  &_main_tpoint{
    display: flex;
    align-items: center;
    width: 292px;
    height: 48px;
    border-radius: 3px;
    background-color: #f1f2f4;
    padding: 0 16px;

    @include media_query(){
    	width: 100%;
    }

    &+&{
      margin-top: 10px;
    }

    &_heading{
      margin-right: auto;
      background-image: url(../img/common/ic-vpoint.png);
      background-repeat: no-repeat;
      background-size: 16px;
      padding-left: 20px;
      background-position: left center;

      font-family: Roboto, HiraginoSans-W4;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.2px;
      color: #2c2e31;
    }

    &_num{
      font-family: Roboto;
      font-size: 1.4rem;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #2c2e31;
    }

    &_procedure{
      text-align: right;
      margin-top: 8px;

      a{
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #2c2e31;
        text-decoration: underline;

        &:hover{
          text-decoration: none;
        }
      }
    }
  }

  &_settlement_btn{
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    border-top: solid 1px #ebedf0;

    @include media_query(){
    	padding: 16px;
    }
  }
}


.settlement_submit{
  width: 100%;
  max-width: 225px;
  height: 40px;
  line-height: 40px;

  font-family: HiraKakuPro-W6;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #fff;

  background-color: #4a90e2;

  transition-property: background-color;
  transition-duration: .25s;

  @include media_query(){
  	max-width: 100%;
  }

  &:hover{
    background-color: #6ca7eb;
  }
}


.cart_artworks{
  .cart_type{
    background-color: #c2a47e;
  }
}

.cart_goods{
  .cart_type{
    background-color: #6e8ca8;
  }
}


























.cart_confirm{
  background-color: #fff;
  padding: 0 24px;

  &_inner{
    display: flex;
    padding: 24px 0;

    @include media_query(){
    	display: block;
    }

    &:not(:last-child){
      border-bottom: solid 1px #ebedf0;
    }

    h4{
      width: 187px;
      padding-left: 55px;
      font-family: HiraKakuPro-W6;
      font-size: 1.4rem;
      line-height: normal;
      letter-spacing: 1.3px;
      color: #2c2e31;

      @include media_query(){
      	padding-left: 0;
      	margin-bottom: 16px;
      }
    }

    p{
      font-family: HiraKakuPro-W3;
      font-size: 1.4rem;
      line-height: normal;
      letter-spacing: 1.3px;
      color: #2c2e31;
    }
  }

  &_table{
    width: 100%;
    max-width: 409px;
    flex: 1;

    tr{
      @include media_query(){
      	display: block;
      }

      &:first-child{
        th,td{
          padding-top: 0;
        }
      }
      &:last-child{
        th,td{
          padding-bottom: 0;
        }
      }
    }

    th,td{
      padding: 4px 0;

      @include media_query(){
      	display: block;
      }
    }

    th{
      font-family: HiraKakuPro-W6;
      font-size: 1.2rem;
      line-height: normal;
      letter-spacing: 1.1px;
      color: #2c2e31;
      width: 10em;

      white-space: nowrap;

      @include media_query(){
      	padding-top: 0;
      	padding-bottom: 9px;
      }
    }

    td{
      font-family: HiraKakuPro-W3;
      font-size: 1.4rem;
      line-height: normal;
      letter-spacing: 1.3px;
      color: #2c2e31;

      text-indent: -.7em;
      padding-left: 1em;
      text-align: left;

      @include media_query(){
      	text-indent: 0;
      	padding: 0 0 24px 0;
      }

      &:before{
        content: ":";
        margin-right: 3px;

        @include media_query(){
        	display: none;
        }
      }
    }

    .same{
      font-family: HiraKakuPro-W3;
      font-size: 1.4rem;
      line-height: normal;
      letter-spacing: 1.3px;
      color: #2c2e31;

      text-indent: 0;
      padding-left: 0;

      &:before{
        display: none;
      }
    }
  }

  .confirm_btn_area{
    display: flex;
    justify-content: flex-end;
    padding-left: 40px;

    @include media_query(){
    	padding-left: 0;
    	margin-top: 24px;
    }

    form{
        @include media_query(){
        	display: block;
        	width: 100%;
        }
    }
  }

  .confirm_btn{
    width: 80px;
    height: 32px;
    border: solid 1px #ebedf0;
    transition-property: border;
    transition-duration: .25s;

    @include media_query(){
    	width: 100%;
    	height: 40px;
    }

    &:hover{
      border: solid 1px #bfcbd5;
    }

    a{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: HiraKakuPro-W6;
      font-size: 1.2rem;
      line-height: normal;
      letter-spacing: 0.6px;
      color: #707980;
    }
  }
}

































.criterion_table{
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  @include media_query(){
  	display: block;
  }

  &_ofs{
    max-width: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    table{
      @include media_query(){
      	width: 615px;
      }
    }

    &+table{
      @include media_query(){
      	margin-top: 16px;
      }
    }
  }

  table{

    th,td{
      font-size: 1.3rem;
      line-height: normal;
      letter-spacing: 1.1px;
      color: #2c2e31;
      white-space: nowrap;

      padding: 12px 10px;
      border: solid 1px #dce0e3;
      vertical-align: middle;
      text-align: center;
    }

    th{
      font-family: HiraKakuPro-W6;
      background-color: #f1f2f4;
    }

    td{
      font-family: HiraKakuPro-W3;
      line-height: 1.38;
    }
  }
}



.deliverycharge_table{

}

.size_table{
  tr:not(:first-child){
    th{
      background-color: transparent;
    }
  }
}

.criterion_small{
  max-width: 615px;
}


















// キャンセルポリシー
.cancel_policy{
  
  margin: 20px 0 0;
  padding-bottom: 30px;
  
  h3{
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  p{
    line-height: 1.7;
    font-size: 1.2rem;
  }
  
  ul{
    li{
      line-height: 1.45;
      font-size: 1.2rem;
      
      a{
        color: #4B90E2;
        text-decoration: underline;
        
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}