/* BEGIN Bold */
@font-face {
	font-family: Roboto;
	@include fontdef-woff($FontPath, $FontName, $FontVersion, "Bold");
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: Roboto;
	@include fontdef-woff($FontPath, $FontName, $FontVersion, "Bold");
	font-weight: bold;
	font-style: normal;
}
/* END Bold */
