.artists{
  max-width: 1128px;
  margin: 0 auto;

  .page_head{
    align-items: center;

    @include media_query(){
      align-items: flex-start;
    	flex-direction: column;
    	padding: 24px 16px;
    	margin-bottom: 0;
    }
  }

  &_footer{
    margin-bottom: 48px;

    @include media_query(){
    	padding: 0 16px;
    }

    ul{
      padding-top: 18px;
      border-top: solid 1px #f1f2f4;

      @include media_query(){
      	padding-top: 24px;
      }
    }
  }

  &_index{
    margin-left: auto;
    display: flex;

    @include media_query(){
      width: 100%;
      margin-top: 24px;
      margin-left: 0;
    	justify-content: space-between;
    }

    li{
      &:not(:last-child){
        margin-right: 16px;
      }

      a{
        display: block;

        font-family: HiraKakuPro-W3;
        font-size: 1.6rem;
        color: #4a90e2;

        &:hover{
          text-decoration: underline;
        }
      }
    }

    .current a{
      font-family: HiraKakuPro-W6;
      color: #2c2e31;
    }
  }

  &_search{
    margin-left: 40px;

    @include media_query(){
      width: 100%;
    	margin: 16px 0 0;
    }

    ul{
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li{
        &:not(:last-child){
          margin-right: 40px;
        }

        &:last-child{
          @include media_query(){
          	width: 100%;
          	margin-top: 0;
          }
        }

        input::-ms-clear {
          visibility:hidden;
        }

        input[type=search]{
          width: 264px;
          background-color: #fff;
          margin-top: -5px;
          background-image: url(../img/common/ic-search_b.png);
          background-repeat: no-repeat;
          background-size: 19px;
          background-position: right 15px center;

          &::-webkit-search-cancel-button,
          &::-webkit-search-decoration{
            -webkit-appearance: none;
          }

          @include media_query(){
          	width: 100%;
          }
        }

      }

    }
  }

  &_inner{
    @include media_query(){
    	padding: 0 16px;
    }
  }

  .artists_list{
    margin-bottom: 40px;

    @include media_query(){
    	margin-bottom: 32px;
    }

    h3{
      font-family: HiraKakuPro-W6;
      font-size: 2.4rem;
      line-height: 1;
      letter-spacing: normal;
      color: #bfcbd5;
      margin-bottom: 16px;
    }

    &_inner{
      display: flex;
      flex-wrap: wrap;
      margin-left: -24px;

      @include media_query(){
      	flex-direction: column;
      	margin-left: 0;
      }
    }
  }
}

.artists_item{
    position: relative;
  width: calc(33.333% - 24px);
  margin-left: 24px;
  border: solid 1px #ebedef;
  margin-bottom: 16px;

  transition-property: border;
  transition-duration: .25s;

  @include media_query(){
  	width: 100%;
  	margin-left: 0;
  }

  &:hover{
    border: solid 1px #bfcbd5;
  }

  a{
    display: flex;
    align-items: center;
    padding: 16px;

    @include media_query(){
    	padding: 16px 8px;
    }
  }

  &:nth-last-child(-n + 3){
    margin-bottom: 0;

    @include media_query(){
    	margin-bottom: 16px;
    }
  }

  &:last-child{
    margin-bottom: 0;
  }

  &_img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
    background-color: #fbfbfb;

    img{
        width: 100%;
        height: auto;
    }
  }

  &_text{
    max-width: 165px;
    flex: 1;
    min-width: 0;

    h4{
      font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #2c2e31;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p{
      font-family: HiraMinPro-W3;
      font-size: 1.1rem;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #707980;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}



.artists_item_follow{
    position: absolute;
    right: 16px;
    bottom: 1.5em;
  display: flex;
  //align-items: flex-end;

  input[type=button]{
    cursor: pointer;
    width: 100px;
    height: 32px;
    padding: 9px 12px;
    text-align: right;
    border: solid 1px #dce0e3;
    border-radius: 21.5px;
    line-height: 1;
    font-family: Roboto;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 1.6px;
    color: #2c2e31;
    background-image: url(../img/gallery/ic-follow.png);
    background-position: left 12px center;
    background-size: 12px;
    background-repeat: no-repeat;
    transition-property: border;
    transition-duration: .25s;

    &:hover{
      border: solid 1px #bfcbd5;
    }
  }

  .following{
    background: #dce0e3 !important;
    color: #fff !important;
    text-align: center !important;

    &:hover{
      border: solid 1px #dce0e3 !important;
    }
  }
}









.artists_career{

  ul{
    li{
      time{
        margin-right: 1em;
      }

      font-family: HiraKakuPro-W3;
      font-size: 1.2rem;
      line-height: 1.83;
      letter-spacing: 0.6px;
      color: #707980;
    }
  }

}


.artistSwiper{
  position: relative;
  overflow: hidden;
  padding-bottom: 25px;
  
  .swiper-pagination{
    bottom: 0px !important;
    
    .swiper-pagination-bullet{
      background: black;
      opacity: 0.25;
      margin: 0 10px !important;
    }
    
    .swiper-pagination-bullet-active{
      background: black;
      opacity: 1;
    }
  }
  
  .swiper-wrapper{
  }
  
  .artists_details_img{
    .swiper-slide-img{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 336px;
      height: 336px;
      background-color: #f1f2f4;
      
      img{
          width: 336px;
          height: 336px;
          object-fit: contain;
      }
    }
  }
  
  .artists_details_img_caption{
    font-size: 11px;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #707980;
    margin-top: 8px;
    max-width: 336px;

    @include media_query(){
        padding: 0 20px;
    }
  }
}

// .artists_details_works{
  
  
  
  
//   li{
//     .artists_details_img{
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width: 336px;
//       height: 336px;
//       background-color: #f1f2f4;
      
      
//       img{
//         width: auto;
//         height: auto;
//         max-width: 100%;
//         max-height: 100%;
//       }
//     }

//     .artists_details_img_caption{
//       font-size: 11px;
//       line-height: normal;
//       letter-spacing: 0.5px;
//       color: #707980;
//       margin-top: 8px;
//       max-width: 336px;

//       @include media_query(){
//           padding: 0 20px;
//       }
//     }
//   }
// }

.wimg{
  width: 100% !important;
  height: auto !important;
  
  // @include media_query(){
  //     max-width: initial !important;
  //     width: auto !important;
  // }
}
.himg{
  height: 100% !important;
  width: auto !important;
  
  // @include media_query(){
  //     max-height: initial !important;
  //     height: auto !important;
  // }
}
