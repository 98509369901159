html,body{
  height: 100%;
}

.fixed{
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  right: 0;
}

body{
  font-size: 1.3rem;
  line-height: 1.85;
  letter-spacing: 1.2px;
  color: #2c2e31;
  background-color: #F1F2F4;

  font-family: "Roboto", "Hiragino Sans", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.bg_white{
    background-color: #fff;
}

img{
  vertical-align: text-bottom;
  //pointer-events: none;

}

.sp_br{
  display: none;

  @include media_query(){
  	display: block;
  }
}


.pc_br{
  display: block;

  @include media_query(){
  	display: none;
  }
}

a{
    cursor: pointer;
}


/*------------------------------------*\
    SVG用
\*------------------------------------*/
.defs{
  display: none;
}


//アンダーラインアニメーション
%underline_animate{
  @media screen and (min-width:640px) {
      position: relative;
      display: inline-block;

      &:before,
      &:after{
        position: absolute;
        bottom: 0;
        content: "";
        display: inline-block;
        width: 0;
        height: 2px;
        background: #2c2e31;
        transition-duration: .5s;
      }

      &:before{
        left: 50%;
      }

      &:after{
        right: 50%;
      }

      &:hover:before,
      &:hover:after{
        width: 50%;
      }
  }
}








/*------------------------------------*\
    .wrap
\*------------------------------------*/
.wrap{
  display: flex;
  flex-direction: column;

  height: 100%;
  min-width: 1170px;
  margin: 0 auto;

  @include media_query(840){
  	min-width: 100%;
  	width: 100%;
  }
}


















/*------------------------------------*\
    header
\*------------------------------------*/
.header{
  position: absolute;
  z-index: 999;
  width: 100%;
  //height: 180px;
  background-color: #fff;
  min-width: 1170px;

  @include media_query(840){
  	min-width: 100%;
    height: auto;
  }

  @include media_query(){
  	position: fixed;
  }

  &_inner{
    max-width: 1170px;
    margin: 0 auto;
    padding: 36px 20px 0;

    @include media_query(){
    	display: flex;
      align-items: center;
      padding: 15px 20px 16px 20px;
      height: 60px;
    }
  }

  &_top{
    position: relative;
    margin-bottom: 4px;

    @include media_query(){
    	width: 100%;
        margin-bottom: 0;
    }

    .art_guide{
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      display: flex;
      align-items: center;

      font-weight: bold;
      line-height: 1;

      a{
        color: #2c2e31;

        transition-property: opacity;
        transition-duration: .25s;

        &:hover{
          opacity: .7;
        }
      }

      @include media_query(){
      	display: none;
      }

      img{
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }

  .logo{
    text-align: center;

    img{
      vertical-align: bottom;
      width: auto;
      height: 64px;

      @include media_query(){
          padding: 0;
      }
    }

    @include media_query(){
    	width: 100%;

    	img{
            width: auto;
            height: 36px !important;
      }
    }
  }
}


//スクロール時のheader用スタイル
.h_hide{
  position: fixed;
  top: -100%;
  transition-property: top;
  transition-duration: 1.8s;
}
.h_show{
  position: fixed;
  top: 0%;
  transition-property: top;
  transition-duration: .25s;
}


//sp用メニューボタン
.sp_menu{
  display: none;

  position: absolute;
  left: 24px;
  z-index: 99;

  .menu_i{
    position: absolute;
    right: -5px;
    top: -5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;


    &:before{
      display: block;
      content: "";
      width: 8px;
      height: 8px;
      background-color: #f55367;
      border-radius: 50%;
    }
  }

  @include media_query(){
  	display: block;
  }
}

//header内ナビゲーション
.gnav{
  display: flex;
  align-items: center;

  height: 80px;

  @include media_query(){
    position: absolute;
    right: 26px;
    margin: 0;

  	justify-content: flex-end;
  }

  //SNSアイコン
  &_sns{
    display: flex;
    //justify-content: center;
    margin-right: auto;

    @include media_query(){
    	display: none;
    }

    li:not(:last-child){
      margin-right: 20px;
    }

    a:hover{
      opacity: .7;
    }
  }

  //グローバルメニュー
  &_menu{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;

    margin-right: 104px;

    @include media_query(){
    	display: none;
    }

    & > li{
        height: 80px;
        display: flex;
        align-items: center;

      & > a{
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 1.4rem;
        color: #2c2e31;

        @extend %underline_animate;
      }

      &:not(:last-child){
        margin-right: 35px;
      }
    }
  }

  .has_list{
      position: relative;
      display: flex;
      justify-content: center;
  }

  .inner_list{

      display: none;
      background-color: #fff;
      position: absolute;
      top: calc(100% - 1px);
      left: calc(50% - 80px);
      width: 160px;
      text-align: center;

      li{
          text-align: center;

          a{
              display: block;
              width: 160px;
              padding: 12px 0;
              color: #2c2e31;
              font-weight: bold;
              transition-property: color;
              transition-duration: .25s;

              &:hover{
                  color: #707980;
              }
          }
      }
  }

  //お気に入り・ユーザ・カート
  &_tools{
    display: flex;
    justify-content: center;

    .login_btn a{
      font-size: 1.3rem;
      font-weight: bold;
      color: #2c2e31;
    }

    img{
      vertical-align: middle;
    }

    li{
      position: relative;

      &:not(:last-child){
        margin-right: 24px;
        
        @include media_query(){
            margin-right: 16px;
        }
      }
    }

    a:hover{
      opacity: .7;
    }
    
    .icon_search{
        display: none;
        
        @include media_query(){
            display: block !important;
            
            img{
                width: auto;
                height: 18px;
            }
        }
    }

    @include media_query(){
    	li:not(:last-child){
      	display: none;
    	}
    }
  }

  //検索・メニュー
  &_tools_sub{
    display: flex;
    justify-content: center;

    margin-left: 47px;
    // padding-left: 25px;
    // border-left: solid 2px #f1f2f4;

    @include media_query(){
    	border: none;
    	padding: 0;
    	margin: 0;
    	padding-right: 24px;
    	order: -1;
    }

    img{
      vertical-align: middle;
    }

    li:not(:last-child){
      margin-right: 24px;

      @include media_query(){
      	margin: 0;
      }
    }

    a:hover{
      opacity: .7;
    }
  }

  @include media_query(){
    align-items: center;

  	.menu_btn{
    	display: none;
  	}
  }
}


//数字アイコン
.num_i{
  display: block;
  position: absolute;
  right: -3px;
  top: 0px;
  background-color: #f55367;
  font-style: normal;
  letter-spacing: -1px;

  color: #fff;
  font-size: 1rem;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  text-indent: -1px;
}


//検索エリア
.search_area{
  display: none;

  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99;
  width: 100%;

  background-color: #fff;
  border-top: solid 1px #dce0e3;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  padding-bottom: 40px;

  @include media_query(){
  	padding: 0;
  }

  &_inner{
    position: relative;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 28px;

    @include media_query(){
    	padding: 0 24px;
    }

    input{
      font-weight: 300;

      display: block;
      width: 100%;
      padding: 13px 3px 13px 53px;
      border: none;
      border-bottom: solid 1px #707980;
      font-size: 2.4rem;

      @include media_query(){
      	border-bottom: none;
      	font-size: 1.6rem;
      	padding: 20px 35px;
      }

      &::placeholder{
        letter-spacing: 1px;
        color: #b9c5ce;
      }

      &:-ms-input-placeholder,&::-ms-input-placeholder{
        color: #b9c5ce;
      }

      background-image: url(/common/img/common/ic-search_l_b.png);
      background-repeat: no-repeat;
      background-position: left 3px center;
      background-size: 28px;

      @include media_query(){
      	background-size: 19px;
      }
    }

    ::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &_close{
    position: absolute;
    top: 10px;
    bottom: 0;
    right: -154px;
    z-index: 99;
    margin: auto;
    width: 17px;
    height: 17px;

    @include media_query(){
    	display: none;
    }
  }
}



//下層ページ・TOPスクロール後には.header_smallがつく
.header_small{
  position: fixed;
  z-index: 999;
  border-bottom: 1px solid #ebedef;

  .header_inner{
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 24px;
    
    @include media_query(){
        height: 60px;
    }
  }

  .header_top{
      margin-bottom: 0;
  }

  .gnav{
    width: 100%;
    height: 64px;
    //margin: 0 0 0 35px;
    //margin-left: auto;
    //padding-left: 48px;
    //border-left: solid 2px #f1f2f4;

    @include media_query(){
        width: auto;
    }

    // &::before{
    //     content: "";
    //     width: 2px;
    //     height: 25px;
    //     background-color: #f1f2f4;
    //     margin-right: 48px;
    // }

    @include media_query(){
    	padding-left: 0;
    	margin-left: 0;
    	border: none;
    }

    &_menu{
      margin-right: 0;
      margin-left: auto;

      li a{
        font-size: 1.3rem;
      }
    }

    &_tools{
      //margin-left: auto;
      margin-left: 35px;

      @include media_query(){
      	margin-left: 0;
      }
    }
  }

  .art_guide, .gnav_sns{
    display: none;
  }

  .logo{
    img{
      //width: 90px;
      height: 36px;
    }
  }
}


.header_search{
    margin-left: 28px;
    
    @include media_query(){
        display: none;
    }
    
    form{
        display: flex;
        align-items: center;
    }
    
    input[type=search]{
        display: block;
        width: 311px;
        height: 40px;
        font-size: 1.2rem;
        padding: 11px 20px 11px 17px;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
        border-radius: 3px;
    }
    
    input[type=submit]{
        margin-left: -30px;
        width: 26px;
        height: 40px;
        background-image: url(/common/img/common/icon_search.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 13px 14px;
    }
    
    ::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }
}






//オーバーレイメニュー
.overlay{
  display: none;

  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #2c2e31;

  @include media_query(){
      overflow: scroll !important;
  }

  &_inner{
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;

    @include media_query(){
    	padding: 0 24px 50px;
    }
  }

  &_header{
    margin-bottom: 74px;

    @include media_query(){
    	margin-bottom: 16px;
    }

    &_inner{
      display: flex;
      // justify-content: center;
       align-items: center;
       height: 80px;
       padding: 0 24px 0 24px;

      @include media_query(){
      	//position: relative;
        height: 60px;
      	padding: 0;
      }

      .sp_close{
        display: none;
        position: absolute;
        left: 24px;
        z-index: 99;

        @include media_query(){
        	display: block;
        }
      }
    }
  }

  &_logo{

    @include media_query(){
        text-align: center;
    }
    img{
      vertical-align: bottom;
      height: 36px;
    }

    @include media_query(){
    	width: 100%;

    	img{
        //width: 90px;
        height: 36px;
      }
    }
  }

  &_icons{
    display: flex;
    align-items: center;
    margin-left: auto;

    @include media_query(){
    	position: absolute;
      right: 26px;
      margin: 0;
    }

    li{
      position: relative;

      &:not(:last-child){
        margin-right: 24px;
      }
    }

    //お気に入り・ユーザ・カート
    &_tools{
      display: flex;
      justify-content: center;

      .login_btn a{
        font-size: 1.3rem;
        font-weight: bold;
        color: #fff;
      }

      img{
        vertical-align: middle;
      }

      li{
        position: relative;

        &:not(:last-child){
          margin-right: 24px;
        }
      }

      a:hover{
        opacity: .7;
      }

      @include media_query(){
      	li:not(:last-child){
        	display: none;
      	}
      }
    }

    //検索・メニュー
    &_tools_sub{
      display: flex;
      justify-content: center;

      margin-left: 25px;
      padding-left: 25px;
      border-left: solid 2px rgba(241, 242, 244, 0.1);

      @include media_query(){
      	border: none;
      	padding: 0;
      	margin: 0;
      	padding-right: 24px;
      	order: -1;

      	li:last-child{
        	display: none;
      	}
      }

      img{
        vertical-align: middle;
      }

      li:not(:last-child){
        margin-right: 24px;

        @include media_query(){
        	margin: 0;
        }
      }

      a:hover{
        opacity: .7;
      }
    }
  }

  &_nav{
    justify-content: center;

    display: flex;

    @include media_query(){
    	display: block;
    }

    li{
      a{
        color: #fff;

        &:hover{
          text-decoration: underline;
        }
      }
    }

    &_main{
      width: 298px;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 3.2px;
      margin-right: 111px;

      @include media_query(){
      	width: 100%;
      	font-size: 1.8rem;
      }

      & > li{
        &:not(:last-child){
          margin-bottom: 24px;
        }

        & > a:hover{
          display: inline;
          position: relative;
          text-decoration: none;

          &:after{
            z-index: -1;
            position: absolute;
            left: 0;
            bottom: 0;
            display: block;
            content: "";
            width: 100%;
            height: 15px;
            background-color: #4a90e2;
          }
        }
      }

      .inner_list{
          padding-left: 1em;
          margin-top: 10px;
          li{
              &::before{
                  font-size: 1.6rem;
                  content: "-";
                  color: #fff;
                  font-weight: normal;
                  margin-right: 3px;

                  @include media_query(){
                      font-size: 1.4rem;
                  }
              }

              a{
                  display: inline-block;
                  font-size: 1.6rem;
                  padding: 10px 0;
                  text-decoration: none;

                  @include media_query(){
                      font-size: 1.4rem;
                      padding: 8px 0;
                  }

                  &:hover{
                      text-decoration: underline;
                  }
              }
          }
      }
    }

    &_inner{
      width: 252px;
      //width: 40%;

      @include media_query(){
      	width: 100%;
      }
    }

    &_tools{
      display: none;
      padding: 24px 0;
      margin: 24px 0;
      font-size: 1.6rem;
      font-weight: bold;
      border-top: solid 1px rgba(#f4f6f8, .1);
      border-bottom: solid 1px rgba(#f4f6f8, .1);

      @include media_query(){
      	display: block;
      }

      li:not(:last-child){
        margin-bottom: 20px;
      }

      li a{
        display: flex;
        align-items: center;

        img{
          margin-right: 16px;
        }

        &:hover{
          text-decoration: none;
        }
      }

      .num_icon{
        display: inline-block;
        background-color: #f55367;
        font-style: normal;
        letter-spacing: -1px;
        margin-left: 11px;

        color: #fff;
        font-size: 1rem;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        text-align: center;
        line-height: 16px;
        text-indent: -1px;


      }
    }

    &_wrap{
      font-size: 1.4rem;

      @include media_query(){
      	display: flex;

      	ul{
        	width: 50%;
      	}
      }

      ul:first-child{
        margin-bottom: 7px;
      }

      li{
        font-family: HiraKakuPro-W3;
        font-size: 1.4rem;
        letter-spacing: normal;
        text-align: justify;

        @include media_query(){
        	letter-spacing: normal;
        }
      }

      li:not(:last-child){
        margin-bottom: 7px;
      }
    }

    &_sns{
      margin-top: 32px;

      font-size: 1.4rem;
      line-height: 1;
      color: #707980;

      ul{
        display: flex;
        margin-top: 20px;

        li:not(:last-child){
          margin-right: 20px;
        }
      }

      a{
        &:hover{
          text-decoration: none;
          opacity: .7;
        }
      }
    }
  }
}

img.lazy-img {
  display: block;
  border: 0;
  opacity: 0;

  &:not(.initial) {
    transition: opacity 1s cubic-bezier(.7,0,.3,1) .45s;
  }

  &.initial,
  &.loaded,
  &.error {
    opacity: 1;
  }

  &:not([src]) {
    visibility: hidden;
  }
}

.lazy-img:not(img) {
  opacity: 0;
  transition: opacity 1s cubic-bezier(.7,0,.3,1) .45s;

  &[data-was-processed="true"] {
    opacity: 1;
  }
}


















/*------------------------------------*\
    main
\*------------------------------------*/
.main{
  display: block;
  flex: 1 0 auto;
  //padding-top: 184px;
  padding-top: 80px;

  @include media_query(){
  	padding-top: 60px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .main {
    overflow: hidden;
  }
}

.lower_main{
  padding-top: 81px;
  
  @include media_query(){
      padding-top: 61px;
  }
}


//エラーメッセージ
.errmsg{
  color: #f00 !important;
  font-size: 1.2rem !important;
  margin-top: 8px !important;

  &+input, &+textarea{
    margin-top: 13px;
  }

  &+select{
    margin-top: 13px;
  }
}





//パンくずリスト
.pan{
  background-color: #2c2e31;
  height: 48px;
  line-height: 48px;
  margin-bottom: 24px;

  @include media_query(){
  	display: none;
  }

  ul{
    display: flex;
    max-width: 1128px;
    margin: 0 auto;

    li{
      font-family: Roboto, 'Hiragino Kaku Gothic W4 JIS2004', 'Hiragino Kaku Gothic ProN';
      font-weight: 400;

      &:not(:last-child){
        &::after{
          display: inline-block;
          margin: 0 16px;
          content: "";
          color: #fff;
          width: 7px;
          height: 10px;
          background-image: url(../../common/img/common/right-arrow-white.png);
          background-size: cover;
        }
      }
      a,span{
        color: #fff;
      }

      span{
        display: block;
        max-width: 23em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  & + #for_oil-bijutsutecho-to-page,
  & + #for_oil-bijutsutecho-to-page-sp,
  & + #for_oil-bijutsutecho-to-shop-sp,
  & + #for_oil-bijutsutecho-to-shop{
      margin: 24px auto !important;
      max-width: 1128px !important;
      width: 100% !important;

      @include media_query(){
          margin: 20px auto !important;
          padding: 0 16px;
      }
  }
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-ms-clear {display: none;}
  &::-ms-reveal {display: none;}
  &::-ms-expand {display: none;}

  &::placeholder{
    letter-spacing: 1px;
    color: #b9c5ce;
  }
}

select{
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
}


.form{
  input, button, textarea, select {
    width: 100%;
    border: solid 1px #dce0e3;
    border-radius: 3px;
    font-size: 1.4rem;
    font-family: HiraKakuPro-W3;
    padding: 9px 12px 8px;

    &::-ms-clear {display: none;}
    &::-ms-reveal {display: none;}

    &::placeholder{
      letter-spacing: 1px;
      color: #b9c5ce;
    }
  }

  textarea{
    max-height: 167px;
  }

  select{
    width: 100%;
    padding: 8px 12px 6px;
    line-height: 1.9;
    border: solid 1px #dce0e3;
    border-radius: 3px;
    font-size: 1.4rem;
    background-color: #fff;
    background-image: url(../img/common/arrow_b.png);
    background-position: right 12px center;
    background-repeat: no-repeat;
    background-size: 12px 7px;

    @include media_query(){
    	background-position: right 12px center
    }
  }

  &:-ms-input-placeholder,&::-ms-input-placeholder{
    color: #b9c5ce;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance:textfield;
  }

  label{
    font-family: HiraKakuPro-W6;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #2c2e31;

    a{
      text-decoration: underline;
      color: #2c2e31;

      transition-property: color;
      transition-duration: .25s;

       &:hover{
         text-decoration: none;
       }
    }
  }

  .require{
    font-family: HiraKakuPro-W6;
    font-size: 1rem;
    background-color: #bfcbd5;
    color: #fff;
    padding: .1em .3em;
    margin-left: .3em;
    letter-spacing: 0.9px;
  }

  input[type=submit]{
    height: 40px;
    background-color: #2c2e31;
    line-height: 40px;
    padding: 0;

    font-family: HiraKakuPro-W6;
    font-size: 1.4rem;
    letter-spacing: 0.7px;
    color: #fff;
    border: none;
    border-radius: 0;

    transition-property: background-color;
    transition-duration: .25s;

    &:hover{
      background-color: #707980;
    }
  }
}

.form_table{
  width: 100%;

  tr{
    @include media_query(){
    	display: block;
    }
  }

  th,td{
    padding: 12px 0;

    @include media_query(){
      display: block;
    	padding: 0;
    }
  }

  th{
    text-align: right;
    padding: 24px 16px 0 0;
    white-space: nowrap;

    @include media_query(){
    	padding: 24px 0 5px 0;
    }
  }

  td{
    vertical-align: top;

    @include media_query(){
    	padding-top: 0;
    }

    .col2{
      display: flex;
      justify-content: space-between;

      input{
        width: 48%;
      }
    }

    .checkbox_text span{
      @include media_query(320){
      	font-size: 1.1rem;
      	vertical-align: top;
      }
    }
  }
}

.contact_form{

  .btn_b{
    background-color: #2c2e31;
    color: #fff;
    padding: 0;
    height: 40px;
    line-height: 40px;
    font-family: HiraKakuPro-W6;
    font-size: 1.4rem;

    transition-property: background-color;
    transition-duration: .25s;

    &:hover{
      background-color: #707980;
    }
  }

  .err_msg{
    display: none;
    font-size: 1.2rem;
    color: #f00;
    margin-top: 1em;
  }
}





.btn_w a{
  display: block;
  text-align: center;
  color: #707980;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  border: solid 1px #ebedf0;

  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 1.5px;
  color: #707980;

  transition-property: border;
  transition-duration: .25s;

  &:hover{
    border: solid 1px #bfcbd5;
  }
}

.btn_disable{
  background-color: #dce0e3 !important;
  cursor: default !important;
}













/*------------------------------------*\
    footer
\*------------------------------------*/
.footer{
  background-color: #fff;

  &_inner{
    max-width: 1168px;
    margin: 0 auto;
    padding: 30px 20px 33px;

    @include media_query(){
    	position: relative;

    	padding: 24px;
    }

    @include media_query(320){
    	padding: 24px 16px;
    }
  }

  &_head{
    display: flex;
    align-items: center;

    @include media_query(){
    	align-items: flex-start;
    }
  }

  &_logo{
    margin-right: auto;

    @include media_query(){
      width: 50%;

    	img{
      	width: 60px;
      	height: auto;
    	}
    }
  }

  &_nav{
    display: flex;

    @include media_query(){
    	display: block;
    }

    &_menu{
      display: flex;
      justify-content: center;

      margin-right: 44px;

      @include media_query(){
        margin: 0;
      	display: block;
      }

      li{
        a{
          font-family: 'Roboto', sans-serif;
          font-weight: 500;
          font-size: 1.3rem;
          text-align: left;
          color: #2c2e31;

          @extend %underline_animate;
        }

        &:not(:last-child){
          margin-right: 40px;

          @include media_query(){
          	margin: 0 0 14px 0;
          }
        }
      }
    }

    .inner_list{
        display: none;
    }

    &_sns{
      display: flex;
      justify-content: center;
      margin-right: auto;

      @include media_query(){
      	position: absolute;
      	left: 24px;
      	top: 73px;
      }

      li:not(:last-child){
        margin-right: 20px;
      }

      a:hover{
        opacity: .7;
      }
    }
  }

  .sub_nav{
    display: flex;
    margin: 34px 0 24px;
    padding-top: 15px;
    border-top: solid 1px #ebedf0;

    @include media_query(){
    	margin: 24px 0 50px;
    	padding-top: 24px;
    }

    ul{
      display: flex;

      @include media_query(){
      	display: block;
      	width: 50%;
      }

      &:first-child{
        margin-right: .8em;

        @include media_query(){
        	margin: 0;
        }

        &::after{
          display: inline-block;
          content: "|";
          color: #707980;
          padding-left: .8em;

          @include media_query(){
          	display: none;
          }
        }
      }

      li{
        font-size: 1.2rem;
        font-family: HiraginoSans-W3;

        @include media_query(){
        	font-size: 1.4rem;
        	letter-spacing: normal;
        	line-height: 1.6;
        }

        @include media_query(320){
        	font-size: 1.3rem;
        }

        &:not(:last-child){
          margin-right: .8em;

          @include media_query(){
          	margin: 0 0 10px 0;
          }

          &::after{
            display: inline-block;
            content: "|";
            color: #707980;
            padding-left: .8em;

            @include media_query(){
            	display: none;
            }
          }
        }

        a{
          color: #707980;

          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }

  .copyright{
    font-family: 'Roboto';
    font-weight: 400;

    color: #707980;
    font-size: 1.1rem;

    @include media_query(){
    	text-align: center;
    }
  }
}



















.error{
  padding-top: 168px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media_query(){
  	padding-top: 92px;
  }

  p{
    font-family: PTSerif-Caption;
    font-size: 2.4rem;
    line-height: normal;
    letter-spacing: 3px;
    text-indent: 3px;
    color: #bfcbd5;

    @include media_query(){
    	font-size: 2rem;
    	letter-spacing: 2.5px;
    }
  }

  &_code{
    font-family: PTSerif-Caption;
    font-size: 8rem;
    letter-spacing: 17px;
    text-indent: 17px;
    color: #bfcbd5;
    margin: 10px 0 26px;
    line-height: 1;

    @include media_query(){
    	font-size: 6.4rem;
    	letter-spacing: 13.6px;
    	margin-bottom: 8px;
    }
  }

  .btn_w{
    width: 168px;
    height: 40px;
  }
}








.error_page{
    padding-top: 80px;

    h2{
        text-align: center;

        font-family: Roboto;
        font-size: 2.8rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #677077;
        margin-bottom: 20px;
    }

    p{
        font-size: 1.4rem;
        text-align: center;
        letter-spacing: .1em;
        line-height: 1.75;
        color: #707980;
        margin: 24px 0 0;
    }
}
