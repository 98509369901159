 .magazine_list{

  .magazine{
    padding-bottom: 24px;

    @include media_query(){
    	padding: 24px 16px;
    }

    .page_head{
      flex-wrap: wrap;
      align-items: center;

      @include media_query(){
        align-items: flex-start;
      	flex-direction: column;
      	margin-bottom: 24px;
      }

      .return_order{
        width: 100%;
        font-family: HiraKakuPro-W3;
        font-size: 1.3rem;
        letter-spacing: 0.7px;
        margin-bottom: 20px;

        @include media_query(){
          margin-bottom: 16px;

        	&+.page_title span{
          	display: block;
          	margin-left: 0;
          	margin-top: 8px;
        	}
        }

        a{
          color: #2c2e31;

          &::before{
            display: inline-block;
            width: 8px;
            height: 12px;
            margin-right: 14px;
            content: "";
            vertical-align: text-bottom;
            background-image: url(../img/common/bt-left.png);
            background-size: 8px 12px;
          }

          &:hover{
            opacity: .7;
          }
        }

      }

      .page_title{
        @include media_query(){
        	margin-bottom: 24px;
        }
      }

      .form{
        display: block;
        margin-left: auto;

        @include media_query(){
          width: 100%;
        	display: flex;
        }

        select{
          width: 180px;

          @include media_query(){
          	//width: calc(50% - 8px);
            width: 50%;

          	&:last-child{
            	//margin-left: 16px;
          	}
          }
        }

        .magazine_category{
          //display: none;

          @include media_query(){
          	//display: block;
          }
        }
      }
    }

    &_inner{
      padding-bottom: 32px;
      margin-bottom: 24px;
      border-bottom: solid 1px #f1f2f4;
    }
  }

}







/*------------------------------------*\
    Article
\*------------------------------------*/
.article_page{
  .pan{
    margin-bottom: 0;
  }
}

.magazine_article{
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto 48px;

  @include media_query(){
  	margin-bottom: 32px;
  }

  &_bg{
    margin: auto;
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

    @include media_query(){
    	height: 204px;
    }
  }

  &_main{
    max-width: 1128px;
    margin: 30px auto 48px;
    padding: 16px 16px 48px;
    background-color: #fff;

    @include media_query(){
    	margin: -40px auto 0;
    	padding-bottom: 0;
    	background-color: transparent;
    }

    &_inner{
      max-width: 744px;
      margin: 0 auto;
      background-color: #fff;

      @include media_query(){
      	padding: 8px 16px;
      }
    }
  }

  &_head{
    padding-bottom: 48px;
    margin-bottom: 48px;
    border-bottom: solid 1px #f1f2f4;

    @include media_query(){
    	margin-bottom: 40px;
    	padding-bottom: 40px;
    }

    .img_cap{
      text-align: right;
      margin-bottom: 21px;

      a{
        font-family: HiraKakuPro-W3;
        font-size: 1.1rem;
        line-height: normal;
        letter-spacing: normal;
        color: #707980;

        &:hover{
          opacity: .7;
        }
      }
    }

    .art_info{
      display: flex;
      align-items: center;

      @include media_query(){
      	flex-direction: column;
      }
    }

    .art_cd{
      display: flex;

      li{
        font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #2c2e31;

        &:not(:last-child){

          &:after{
            content: "|";
            margin: 0 .3em;
          }
        }

        a{
          color: #2c2e31;

          &:hover{
            opacity: .7;
          }
        }
      }
    }

    .addthis_inline_share_toolbox_szy3{
        margin-left: auto;

        @include media_query(){
        	margin-left: 0;
        	margin-top: 8px;
        }
    }

    .art_sns{
      display: flex;
      margin-left: auto;

      li{
        a{
          &:hover{
            opacity: .7;
          }
        }

        &:not(:last-child){
          margin-right: 20px;
        }
      }
    }

    .art_title{
      font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
      font-size: 2.8rem;
      font-weight: bold;
      line-height: 1.46;
      letter-spacing: 0.8px;
      color: #2c2e31;
      margin-top: 28px;
      margin-bottom: 24px;
      text-align:left;

      @include media_query(){
        font-size: 18px;
        line-height: 1.78;
        letter-spacing: 0.5px;
        margin-top: 8px;
        margin-bottom: 16px;
      }
    }

    .art_caption{
      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      font-stretch: normal;
      line-height: 1.69;
      letter-spacing: normal;
      color: #2c2e31;

      @include media_query(){
      	font-size: 1.2rem;
      	line-height: 1.83;
      }
    }

    .art_credit{
        text-align: center;
        font-size: 1.3rem;
        color: #707980;
        font-weight: 500;
        margin: 14px 0 0;
    }
  }
}








/*------------------------------------*\
    記事本文
\*------------------------------------*/
.magazine_article_body{

  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: 0.7px;
  color: #2c2e31;
  text-align: justify;
  word-break: break-all;

  &>*{
    margin-top: 32px;
    margin-bottom: 32px;

    @include media_query(){
    	margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  h2{
    font-family: HiraKakuPro-W6;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.5;
    letter-spacing: 1.5px;
    border-left: 4px solid #2c2e31;
    padding-left: 8px;

    @include media_query(){
    	font-size: 1.6rem;
    }

  }

  h3{
    font-family: HiraKakuPro-W6;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 1.5px;

    @include media_query(){
    	font-size: 1.4rem;
    }
  }

  h4{
    font-family: HiraKakuPro-W6;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: 1.5px;
  }

  a{
    color: #2c2e31;
    border-bottom: 1px dotted #2c2e31;
  }

  figure{
      a{
        display: block;
        border-bottom: none;
      }
  }

  img{
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
  }

  figcaption{
    font-family: HiraKakuPro-W3;
    font-size: 1rem;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #707980;
    margin-top: 10px;
  }

  .videoEmbed{
    position: relative;
    z-index: 2;
    width: 100%;
    padding-top: 56.25%;
    margin: 0 auto 30px;
    text-align: center;

    iframe{
      position: absolute;
      margin: auto;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  ol{
    padding-left: 1.2em;
    li{
      list-style: decimal;
    }
  }

  ul{
    padding-left: 1.2em;

    li{
      list-style: disc;
    }
  }

  blockquote{
    border-radius: 5px;
    background-color: #f4f6f8;
    padding: 24px 24px 24px 68px;
    background-image: url(../img/magazine/ic-inyo.png);
    background-repeat: no-repeat;
    background-position: left 24px top 24px;
    background-size: 30px 23px;

    @include media_query(){
    	padding: 40px 16px 16px 16px;
    	background-position: left 16px top 16px;
    	background-size: 20px 16px;
    }

    .source{
      font-size: 1.2rem;
      color: #707980;
      text-align: right;
      margin-top: 8px;
    }
  }

  .magazine_author{
    font-family: HiraKakuPro-W3;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: normal;
    color: #2c2e31;
    margin-top: 24px;
    text-align: right;
  }
}








/*  */
.art_section_title{
  font-family: PTSerif-Caption;
  font-size: 1.6rem;
  line-height: normal;
  letter-spacing: 1.3px;
  color: #2c2e31;
  margin-bottom: 24px;
}

.artist_profile{
  padding-top: 24px;
  margin-bottom: 40px;
  border-top: solid 1px #f1f2f4;

  @include media_query(){
  	padding-top: 32px;
  	margin-bottom: 32px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  &_name{
    font-family: HiraKakuPro-W6;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 1.2px;
    color: #2c2e31;
    margin-bottom: 8px;
    word-break: break-all;
  }

  &_text{
    font-family: HiraKakuPro-W3;
    font-size: 1.3rem;
    line-height: 1.69;
    letter-spacing: 0.6px;
    color: #2c2e31;
  }
}






.art_information{
  padding-top: 24px;
  margin-bottom: 40px;
  border-top: solid 1px #f1f2f4;

  @include media_query(){
  	padding-top: 32px;
  	margin-bottom: 32px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  &_wrap{
    display: flex;

    @include media_query(){
    	display: block;
    }
  }

  &_text{
    flex: 1;
  }

  &_map{
    width: 369px;
    margin-left: 24px;

    @include media_query(){
    	width: 100%;
    	margin-left: 0;
    	margin-top: 32px;

    	iframe{
      	width: 100%;
    	}
    }
  }

  &_name{
    font-family: HiraKakuPro-W6;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 1.2px;
    color: #2c2e31;
    margin-bottom: 24px;
    word-break: break-all;
  }

  &_table{
    width: 100%;
    word-break: break-all;

    tr{
      &:first-child{
        th,td{
          padding-top: 0;
        }
      }
      &:last-child{
        th,td{
          padding-bottom: 0;
        }
      }
    }

    th{
      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #707980;
      white-space: nowrap;
      padding: 6px 0;
    }

    td{
      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      line-height: normal;
      letter-spacing: normal;
      color: #2c2e31;
      padding: 6px 0 6px 0;

      a{
        color: #2c2e31;
        text-decoration: underline;

        &:hover{
          text-decoration: none;
        }
      }

      .view_map{
        display: none;
        font-family: HiraKakuPro-W6;
        font-size: 1.2rem;
        color: #2c2e31;
        width: 100%;
        max-width: 220px;
        height: 32px;
        border-radius: 3px;
        border: solid 1px #dce0e3;
        text-decoration: none;
        margin-top: 12px;

        align-items: center;
        justify-content: center;

        span{
          background-image: url(../img/magazine/ic-map-pin.png);
          background-size: 11px 14px;
          background-repeat: no-repeat;
          background-position: left center;
          padding-left: 18px;
        }

        @include media_query(){
        	display: flex;
        }
      }
    }
  }
}





.art_event{
  padding-top: 24px;
  margin-bottom: 40px;
  border-top: solid 1px #f1f2f4;

  @include media_query(){
  	padding-top: 32px;
  	margin-bottom: 32px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  &_heading{
    font-family: HiraginoSans-W6;
    font-size: 1.4rem;
    font-weight: bold;
    color: #2c2e31;
    margin-bottom: 8px;
  }

  &_text{
    font-family: HiraKakuPro-W3;
    font-size: 1.3rem;
    line-height: 1.69;
    letter-spacing: 0.6px;
    color: #2c2e31;

    &+.art_event_heading{
      margin-top: 32px;
    }
  }
}



.art_event{
  &_item{

    dt{
      font-family: HiraginoSans-W6;
      font-size: 1.4rem;
      font-weight: bold;
      color: #2c2e31;
      margin-bottom: 8px;
    }

    dd{
      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      line-height: 1.69;
      letter-spacing: 0.6px;
      color: #2c2e31;
    }

    & + &{
      margin-top: 32px;
    }
  }
}



.art_link{
  padding-top: 24px;
  margin-bottom: 40px;
  border-top: solid 1px #f1f2f4;

  &:last-child{
    margin-bottom: 0;
  }

  ul{
    li{
      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      color: #2c2e31;

      &::before{
        content: "-";
        color: #979797;
        margin-right: 5px;
      }

      a{
        color: #2c2e31;
        text-decoration: underline;

        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}






.artfoot_sns{
  padding-top: 24px;
  border-top: solid 1px #f1f2f4;

  ul{
    display: flex;

    li{
      &:not(:last-child){
        margin-right: 5px;
      }

      a{
        &:hover{
          opacity: .7;
        }
      }
    }
  }
}





.bg_modal{
  max-width: 1280px;

  @include media_query(){
  	padding: 16px;
  }

  &_inner{
    width: 100%;
    max-width: 1280px;

    .caption{
      font-size: 11px;
      color: #707980;
      line-height: 1.2;
      text-align: justify;
      margin: 0;
      padding: 0 24px;
      margin-top: 8px;
      text-shadow: none;
      text-align: right;
    }
  }
}




















