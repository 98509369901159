///*------------------------------------*//
//    Fonts
//*------------------------------------*//
$font-family: "Roboto",  "ゴシックMB101 R", "Hiragino Sans", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, Verdana, sans-serif;
$line-height: 1.5;




///*------------------------------------*//
//    Colors#db4c34//#231815
//*------------------------------------*//
$main-color: #e6644f;
$accent-color: #f00;
$font-color: #2c2e31;
$base-color: #fff;
$bg-color: #fff;

$block: #2c2e31;
$gray: #b9c5ce;





///*------------------------------------*//
//    path
//*------------------------------------*//
$img_path:'./htdocs/common/img/';







///*------------------------------------*//
//     Placeholder selector
//*------------------------------------*//

//clearfix
%cf{
	&:after {
    content:"";
    display:block;
    clear:both;
  }
}



///*------------------------------------*//
//     Mixin
//*------------------------------------*//

//メディアクエリ挿入
@mixin media_query($width:840){
	@media screen and (max-width: $width+px){
		@content;
	}
}
