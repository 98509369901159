.mypage{
  .pan{
    margin-bottom: 0;
  }
}

.mypage_wrap{
  margin-bottom: 64px;
  
  @include media_query(){
  	margin-bottom: 48px;
  }
}






/*------------------------------------*\
    mypage_head
\*------------------------------------*/
.mypage_head{
  background-color: #fff;
  
  &_inner{
    max-width: 1128px;
    margin: 0 auto;
    padding: 24px 0 0;
    
    @include media_query(){
    	padding: 16px;
    }
  }
  
  &_top{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
    .sp_logout{
      display: none;
      
      margin-left: auto;
      font-family: HiraKakuPro-W3;
      font-size: 1.1rem;
      line-height: normal;
      letter-spacing: 1.5px;
      text-align: center;
      
      a{
        color: #2c2e31;
        display: block;
        padding: .5em;
      }
      
      @include media_query(){
      	display: block;
      }
    }
    
    .mypage_tools{
      display: flex;
      margin-left: auto;
      
      @include media_query(){
      	width: 100%;
      	margin-top: 16px;
      }
      
      li{
        font-size: 1.3rem;
        
        &:not(:last-child){
          border-right: solid 1px #bfcbd5;
        }
        
        @include media_query(){
        	font-size: 1.1rem;
        	
        	&:last-child{
            display: none;
          }
          
          &:nth-child(3){
            border: none;
          }
        }
        
        a,span{
          color: #2c2e31;
          padding: 0 16px;
          line-height: 1;
          
          @include media_query(){
          	padding: 0 8px;
          }
        }
        
        a:hover{
          text-decoration: underline;
        }
        
        span{
          @include media_query(){
          	padding-left: 0;
          }
        }
      }
      
      .current{
        a{
          font-weight: bold;
        }
      }
    }
  }
}

.mypage_nav{
  display: flex;
  margin-top: 23px;
  
  @include media_query(){
  	display: block;
  }
  
  ul{
    display: flex;
    
    @include media_query(){
    	flex-wrap: wrap;
    }
    
    &+ul{
      margin-left: auto;
      
      @include media_query(){
      	margin: 16px 0 0;
      }
    }
    
    .mypage_link{
      @include media_query(){
      	width: 100%;
      	
      }
    }
    
    @include media_query(){
    	
      &:first-child{
        li{ 
            
            &:first-child{
                border-bottom: solid 1px #ebedf0;
            }
        	&:nth-last-child(-n + 2){
          	border-bottom: solid 1px #ebedf0;
          }
          
          &:nth-child(2), &:nth-child(3){
          	border-top: none;
          }
          
          &:last-child{
            border-right: solid 1px #ebedf0 !important;
          }
        	
          &:nth-child(even){
           border-right: none;
          }
        }
      }
      
      &:last-child{
        li{
        	&:nth-last-child(-n + 2){
          	border-bottom: solid 1px #ebedf0;
          }
          
          &:last-child{
            border-right: solid 1px #ebedf0 !important;
          }
        	
          &:nth-child(odd){
           border-right: none;
          }
        }
      }
    }
    
    li{
      
      @include media_query(){
      	width: 50%;
      	
      	border: solid 1px #ebedf0;
      	border-bottom: none;
      }
      
      a, span{
        display: block;
        padding: 16px;
        border-bottom: solid 3px #fff;
        
        font-family: Roboto;
        font-size: 1.3rem;
        line-height: 1;
        letter-spacing: 1.8px;
        text-align: center;
        color: #707980;
        
        transition-property: border-color;
        transition-duration: .25s;
        
        @include media_query(){
        	border: none;
        	padding: 16px 10px;
        	text-align: center;
        	
        	font-size: 1.2rem;
        	letter-spacing: 1.7px;
        	color: #2c2e31;
            
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
        
        &:hover{
          border-color: #2c2e31;
        }
      }
    }
    
    .dummy{
      display: none;
      background-color: #ececec;
      
      @include media_query(){
      	display: block;
      }
    }
    
    .current a{
      border-bottom: solid 3px #2c2e31;
      
      font-weight: 500;
      color: #2c2e31;
      
      @include media_query(){
      	border: none;
      	color: #fff;
      	
      	background-color: #2c2e31;
      	border: solid 1px #2c2e31;
      }
    }
  }
}





/*------------------------------------*\
    mypage_main
\*------------------------------------*/
.mypage_main{
  
}





.order_status{
  max-width: 1128px;
  margin: 24px auto 0;
  
  @include media_query(){
  	padding: 0 16px;
  }
  
  &_list{
    margin-top: 16px;
    
    li{
      padding: 21px;
      background-color: #fff;
      border-bottom: solid 1px #f1f2f4;
      
      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #2c2e31;
      
      @include media_query(){
      	padding: 12px 16px;
      	line-height: 1.46;
        text-align: left;
      }
      
      time{
        font-family: Roboto;
        font-size: 1.3rem;
        line-height: normal;
        letter-spacing: 1px;
        color: #707980;
        margin-right: 16px;
        
        @include media_query(){
        	display: block;
        	margin-right: 0;
        	margin-bottom: 8px;
        }
      }
    }
  }
  
  &_link{
    background-color: #fff;
    text-align: right;
    
    a{
      display: inline-block;
      height: 48px;
      line-height: 48px;
      padding: 0 21px;
      
      @include media_query(){
        width: 100%;
      	height: 40px;
      	line-height: 40px;
      }
    }
    
    @include media_query(){
    	text-align: center;
    }
    
    span{
      font-family: HiraKakuPro-W3;
      font-size: 1.4rem;
      line-height: normal;
      letter-spacing: 0.7px;
      color: #2c2e31;
      
      background-image: url(/common/img/common/bt-link.png);
      background-position: right center;
      background-size: 14px 10px;
      background-repeat: no-repeat;
      padding-right: 22px;
    }
  }
}






/*------------------------------------*\
    
\*------------------------------------*/
.favorite_artworks{
  max-width: 1128px;
  margin: 48px auto 0;
  
  @include media_query(){
  	padding: 0 16px;
  	margin-top: 24px;
  }
  
  .artworks_inner{
    margin-top: 16px;
  }
  
  .go_list_btn a{
    height: 48px;
    line-height: 48px;
    
    @include media_query(){
    	height: 40px;
    	line-height: 40px;
    }
  }
}


.favorite_goods{
  max-width: 1128px;
  margin: 48px auto 0;
  
  @include media_query(){
  	padding: 0 16px;
  	margin-top: 24px;
  }
  
  .goods_inner{
    margin-top: 16px;
  }
  
  .go_list_btn a{
    height: 48px;
    line-height: 48px;
    
    @include media_query(){
    	height: 40px;
    	line-height: 40px;
    }
  }
}















.notfound{
  margin-top: 128px;
  
  @include media_query(){
  	margin-top: 80px;
  	margin-bottom: 80px;
  }
  
  h3{
    font-family: HiraKakuPro-W3;
    font-size: 1.8rem;
    line-height: 1;
    letter-spacing: 2.5px;
    text-align: center;
    color: #2c2e31;
    
    @include media_query(){
    	font-size: 1.4rem;
    	letter-spacing: 1.9px;
    }
  }
  
  .notfound_msg{
    font-family: HiraKakuPro-W3;
    font-size: 1.3rem;
    line-height: 1;
    letter-spacing: 1.8px;
    text-align: center;
    color: #707980;
    margin-top: 16px;
    
    @include media_query(){
    	font-size: 1.2rem;
    	letter-spacing: 0.5px;
    }
    
    &+p{
      margin-top: 40px;
    }
  }
  
  p{
    a{
      display: block;
      height: 40px;
      width: 175px;
      line-height: 40px;
      margin: 0 auto;
      text-align: center;
      background-color: #fff;
      border: solid 1px #ebedf0;
      
      font-family: Roboto;
      font-size: 1.4rem;
      letter-spacing: 0.7px;
      color: #2c2e31;
      
      transition-property: border-color;
      transition-duration: .25s;
      
      &:hover{
        border: solid 1px #bfcbd5;
      }
    }
  }
}