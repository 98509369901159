.inquiry_ask_form{
    
    @include media_query(){
        padding: 16px 16px 48px;
    }
    
    .agreement_title{
        font-family: HiraKakuPro-W3, sans-serif;
    }
    
    .contact{
        p{
            font-size: 1.2rem;
            line-height: 168%;
            
            & + p{
                margin-top: 1em;
            }
        }
    }
    
    .inquiry_ask_main{
        width: 840px;
        max-width: 1168px;
        margin: 0 auto 48px;
        background-color: #fff;
        padding: 48px;
        flex: 1;
        word-break: break-all;
        
        @include media_query(){
            width: 100%;
            padding: 24px 16px;
        }
    }
    
    .ask_table{
        width: 100%;
        max-width: 630px;
        margin: 20px auto 0;
        
        @include media_query(){
            display: block;
            
            tbody{
                display: block;
                width: 100%;
            }
        }
        
        tr{
            @include media_query(){
                display: block;
                width: 100%;
            }
        }
        
        td,th{
            font-size: 1.2rem;
            text-align: left;
            
            @include media_query(){
                display: block;
                width: 100%;
            }
        }
        
        th{
            white-space: nowrap;
            width: 128px;
            vertical-align: top;
            padding: 13px 0;
            
            @include media_query(){
                padding: 10px 0 0;
            }
        }
        
        td{
            padding: 13px 20px;
            
            @include media_query(){
                padding: 0 0;
                
                textarea{
                    width: 100%;
                }
            }
        }
    }
    
    .has_edit{
        display: flex;
        
        .inquiry_ask_edit{
            margin-left: auto;
        }
    }
}