.search_result_area{
  .pan{
    margin-bottom: 0;
  }
  
  &_white{
    background-color: #fff;
    border-bottom: solid 1px #ebedf0;
    
    .search_result_head{
      border-bottom: solid 1px #ebedf0;
    }
    
    .pagination{
      border-top: solid 1px #f1f2f4;
      padding-top: 24px;
      
      @include media_query(){
      	padding: 16px 16px 0;
      	margin-bottom: 30px;
      }
      
      li:not(.on){
        border: solid 1px #ebedf0;
        transition-property: border;
        transition-duration: .25s;
        
        &:hover{
          border: solid 1px #bfcbd5;
        }
      }
    }
  }
}

.search_result{
  
  &_head{
    background-color: #fff;
    margin-bottom: 24px;
    
    @include media_query(){
    	margin-bottom: 16px;
    }
    
    &_inner{
      max-width: 1128px;
      margin: 0 auto;
      padding: 16px 0 24px;
      
      @include media_query(){
      	padding: 16px;
      }
    }
    
    &_form{
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      
      @include media_query(){
      	flex-direction: column;
      	margin-bottom: 0;
      }
    }
    
    &_search{
      display: flex;
      width: 100%;
      
      @include media_query(){
      	margin-bottom: 24px;
      }
      
      &::before{
        display: block;
        width: calc(19px + 16px);
        border-right: solid 1px #dce0e3;
        height: 40px;
        content: "";
        background-image: url(../img/common/ic-search_b.png);
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 19px;
        
        @include media_query(){
        	height: 24px;
        }
      }
      
      input{
        width: 100%;
        height: 40px;
        padding: 0 16px;
        
        font-family: HiraKakuPro-W3;
        font-size: 1.8rem;
        line-height: normal;
        letter-spacing: 0.9px;
        color: #2c2e31;
        
        @include media_query(){
        	font-size: 1.4rem;
        	letter-spacing: 0.7;
        	height: 24px;
        }
        
        &::-webkit-search-cancel-button,
        &::-webkit-search-decoration{
          -webkit-appearance: none;
        }
      }
    }
    
    &_select{
      
      @include media_query(){
        width: 100%;
      	//display: flex;
      }
      
      &_tab{
        display: none;
        
        @include media_query(){
          width: 100%;
          margin-bottom: 8px;
        	display: block;
        }
      }
      
      select{
        width: 160px;
        padding-right: 36px;
        
        @include media_query(){
          width: 100%;
        	
        	&:not(:last-child){
          	margin-right: 17px;
        	}
        }
      }
    }
    
    &_tab{
      display: flex;
      
      @include media_query(){
      	display: none;
      }
      
      li{
        width: 20%;
        
        a{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          border: solid 1px #ebedf0;
          //border-left: none;
          font-family: Roboto;
          font-size: 1.3rem;
          line-height: normal;
          letter-spacing: 1.8px;
          color: #2c2e31;
          margin-left: -1px;
          z-index: 1;
          
          transition-property: border-color;
          transition-duration: .25s;
        }
        
        &:not(.current) a{
          &:hover{
            z-index: 99;
            border-color: #bfcbd5;
          }
        }
        
        &:first-child a{
          border-left: solid 1px #ebedf0;
        }
      }
      
      .current a{
        border-color: #2c2e31;
        background-color: #2c2e31;
        color: #fff;
      }
    }
  }
  
  &_main{
    max-width: 1128px;
    margin: 0 auto 64px;
    
    @include media_query(){
    	padding: 0 16px;
    }
    
    .gallery_inner{
      padding: 0;
      border: none;
    }
    
    .artist_list_inner{
      display: flex;
      flex-wrap: wrap;
      margin-left: -24px;
      
      @include media_query(){
      	margin-left: 0;
      }
    }
  }
}

















.index_wrap.crosssearch_wrap{
    background-color: #fff;
}

.crosssearch{
    .crosssearch_inner{
        max-width: 1127px;
        margin: 0 auto;
        padding: 23px 32px;
        background-color: #F6F6F6;
        
        @include media_query(){
            padding: 16px;
        }
        
        .form_wrap{
            display: flex;
            align-items: center;
        }
        
        input[type=search]{
            display: block;
            width: 100%;
            max-width: 350px;
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 1.4;
            padding: 10px 20px;
            
            @include media_query(){
                padding: 10px 7px;
                font-size: 1.2rem;
                height: 40px;
            }
        }
        
        input[type=submit]{
            display: block;
            width: 66px;
            height: 40px;
            margin: 0 0 0 11px;
            background-color: #2C2E31;
            
            background-image: url(../img/common/ic-search_w.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 13px 14px;
        }
        
        //入力削除ボタンの削除
        ::-webkit-search-cancel-button {
           -webkit-appearance: none;
        }
    }
}

.crosssearch_result_text{
    max-width: 1127px;
    margin: 31px auto 0;
    
    @include media_query(){
        padding: 0 16px;
    }
    
    h2{
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 140%;
        letter-spacing: 0.05em;

        color: #000000;
    }
}




.crosssearch_result{
    max-width: 1127px;
    margin: 40px auto 0;
    
    @include media_query(){
        padding: 0 16px;
        margin: 20px 0 0;
    }
}

.crosssearch_result_main{
    
    & + .crosssearch_result_main{
        margin: 40px 0 0 0;
        
        @include media_query(){
            margin: 20px 0 0 0;
        }
    }
    
    .crosssearch_result_title{
        display: inline-block;
        white-space: nowrap;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 21px;
        letter-spacing: 0.05em;
        margin: 0 21px 0 0;
        vertical-align: middle;
        
        @include media_query(){
            display: block;
            margin-bottom: 10px;
        }
    }
            
            
    a{
        display: inline-block;
        font-size: 14px;
        letter-spacing: 0.05em;
        color: #5398FF;
        line-height: 2.3;
        vertical-align: middle;
        
        @include media_query(){
            line-height: 1.5;
        }
        
        &:not(:last-child){
            margin-right: 21px;
        }
    }
}





.crosssearch_result_items{
    max-width: 1127px;
    margin: 40px auto 0;
    
    @include media_query(){
        margin: 20px 0 0;
        padding: 0 16px;
    }
    
    .crosssearch_result_items_head{
        display: flex;
        align-items: center;
        
        @include media_query(){
            flex-wrap: wrap;
        }
        
        .crosssearch_result_number{
            font-weight: bold;
            font-size: 1.2rem;
            line-height: 120%;
            letter-spacing: 0.05em;
            color: #2C2E31;
            
            @include media_query(){
                margin-left: auto;
            }
        }
    }
    
    .crosssearch_result_items_head_form{
        display: flex;
        align-items: center;
        margin-left: auto;
        
        @include media_query(){
            width: 100%;
            justify-content: space-between;
            margin: 13px 0 0 0;
        }
        
        label{
            font-size: 1.4rem;
            letter-spacing: 0.05em;
            color: #000000;
            
            span{
                display: inline-block;
                margin-left: 8px;
            }
        }
        
        select{
            width: 264px;
            padding: 11px 19px;
            border: 1px solid #E5E5E5;
            margin: 0 0 0 25px;
            
            font-size: 1.4rem;
            line-height: 120%;
            letter-spacing: 0.05em;
            
            background-image: url(../img/common/arrow_b.png);
            background-position: right 19px center;
            background-repeat: no-repeat;
            background-size: 12px 7px;
            
            @include media_query(){
                width: 140px;
            }
        }
    }
    
    .crosssearch_result_title{
        white-space: nowrap;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 21px;
        letter-spacing: 0.05em;
        margin: 0 21px 0 0;
        vertical-align: middle;
    }
    
    .pickup{
        margin: 16px 0 0 0;
        
        .pickup_inner{
            margin: 0 auto;
            padding: 0;
            
            .pickup_wrap{
                margin: 0 0 0 -24px;
                
                @include media_query(){
                    margin: 0 0 0 -7px;
                }
            }
        }
    }
    
    .pagination.pagination_pc{
        li:not(.on){
            border: 1px solid #ebedf0;
        }
        
        .pagination_ul{
            padding: 30px 0 0 0;
        }
    }
    
    .pagination.pagination_sp{
        li:not(.on){
            border: 1px solid #ebedf0;
        }
    }
}

.unfound{
    max-width: 1127px;
    min-height: 120px;
    margin: 16px auto 0;
    
    @include media_query(){
        padding: 0 16px;
    }
    
    .unfound_msg{
        font-size: 1.4rem;
        line-height: 120%;
        letter-spacing: 0.05em;
        color: #2C2E31;
    }
}