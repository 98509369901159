.settlement{
  max-width: 1128px;
  margin: 0 auto 60px;
  
  @include media_query(){
  	padding: 16px;
  	margin: 0 auto 30px;
  }
  
  &_step{
    margin: 24px 0;
    
    @include media_query(){
    	display: none;
    }
    
    ul{
      display: flex;
      
      li{
        display: flex;
        align-items: center;
        
        font-family: HiraKakuPro-W3;
        font-size: 1.2rem;
        line-height: normal;
        letter-spacing: 1.6px;
        color: #bfcbd5;
        
        &:not(:last-child){
          &::after{
            display: inline-block;
            content: "";
            background-image: url(../img/settlement/ic-step-disable.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 5px 10px;
            width: 5px;
            height: 10px;
            margin: 0 16px;
          }
        }
        
        span{
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #bfcbd5;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          margin-right: 8px;
          
          font-family: Roboto;
          font-size: 1.2rem;
          font-weight: bold;
          line-height: 1;
          letter-spacing: normal;
          color: #fff;
        }
      }
      
      .on{
        color: #2c2e31;
        
        &:not(:last-child){
          &::after{
            background-image: url(../img/settlement/ic-step-active.png);
          }
        }
        
        span{
          background-color: #2c2e31;
        }
      }
    }
  }
  
  
  
  &_inner{
    width: 100%;
    display: flex;
    
    @include media_query(){
    	flex-direction: column;
    }
  }
  
  &_main{
    flex: 1;
    margin-right: 24px;
    
    @include media_query(){
    	margin: 0;
    }
    
    
    &_head{
      padding: 16px 24px;
      border-bottom: solid 1px #ebedf0;
      background-color: #fff;
      
      h2{
        font-family: Roboto;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.8px;
        color: #2c2e31;
      }
    }
    
    &_inner{
      padding: 36px 96px 32px;
      background-color: #fff;
      
      @include media_query(){
      	padding: 24px 16px 0;
      	overflow: hidden;
      }
      
      &_head{
        border-bottom: solid 1px #ebedef;
        display: flex;
        align-items: center;
        padding-bottom: 36px;
        
        @include media_query(){
        	display: block;
        	text-align: center;
        	padding-bottom: 24px;
        }
        
        h3{
          margin-right: auto;
          
          @include media_query(){
          	margin-bottom: 16px;
          }
        }
        
        .btid_notes{
          font-family: HiraKakuPro-W3;
          font-size: 1.2rem;
          line-height: normal;
          letter-spacing: 1.1px;
          color: #707980;
        }
      }
      
      &_title{
        @include media_query(){
        	text-align: center;
        }
      }
      
      &_contents{
        padding: 16px 0;
      }
      
      .settlement_customer_info{
        width: 100%;
        
        @include media_query(){
        	tr,td,th{
          	display: block;
          	width: 100%;
        	}
        	
        	tr:not(:last-child){
          	margin-bottom: 24px;
        	}
        }
        
        th,td{
          padding: 17px 0;
          
          @include media_query(){
          	padding: 0;
          }
        }
        
        th{
          white-space: nowrap;
          font-family: HiraKakuPro-W6;
          font-size: 1.2rem;
          line-height: normal;
          letter-spacing: 1.1px;
          color: #2c2e31;
          
          padding-right: 44px;
          
          @include media_query(){
          	padding-bottom: 8px;
          }
        }
        
        td{
          font-family: HiraKakuPro-W3;
          font-size: 1.4rem;
          line-height: normal;
          letter-spacing: normal;
          color: #2c2e31;
          
          @include media_query(){
          	padding: 0;
          }
        }
      }
      
      &_section{
        &_head{
          margin-top: 16px;
          padding: 32px 0 24px;
          border-top: solid 1px #ebedef;
          
          @include media_query(){
          	padding: 24px 0;
          	text-align: center;
          }
        }
        
        .label-nb{
          font-family: HiraKakuPro-W6;
          font-size: 1.2rem;
          line-height: normal;
          letter-spacing: 1.1px;
          color: #2c2e31;
          text-align: left;
          margin: 24px 0 8px;
          
          &+textarea{
            width: 100%;
            height: 80px;
            border: solid 1px #dce0e3;
            padding: 13px 12px;
            font-size: 1.4rem;
          }
        }
        
      }
      
    }
    
    &_submit{
      margin-top: 1px;
      background-color: #fff;
      padding: 24px 96px;
      
      @include media_query(){
      	padding: 16px;
      }
      
      .form_btns{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        @include media_query(){
        	flex-direction: column;
        }
        
        p a{
          display: block;
          padding: 1em 0;
          font-family: HiraKakuPro-W6;
          font-size: 1.2rem;
          line-height: normal;
          letter-spacing: 0.6px;
          color: #707980;
          
          &:hover{
            text-decoration: underline;
          }
          
          @include media_query(){
            width: 100%;
            padding: 20px 0;
            margin-top: 10px;
          }
        }
        
        p{
          width: 100%;
          text-align: center;
        }
        
        &_submit:last-child{
          display: block;
          width: 100%;
          max-width: 264px;
          
          @include media_query(){
            max-width: 100%;
          	order: -1;
          }
          
          input[type=submit],input[type=button]{
            background-color: #4a90e2;
            width: 100%;
            height: 56px;
            color: #fff;
            font-family: HiraKakuPro-W6;
            font-size: 1.4rem;
            line-height: normal;
            letter-spacing: 0.7px;
            
            transition-property: background-color;
            transition-duration: .25s;
            
            &:hover{
              background-color: #6ca7eb;
            }
            
            @include media_query(){
              height: 40px;
            }
          }
        }
      }
    }
  }
  
  &_side{
    width: 360px;
    
    @include media_query(){
    	width: 100%;
    	margin-bottom: 20px;
    	order: -1;
    }
    
    &_inner{
      position: sticky;
      top: 80px;
      padding: 32px;
      background-color: #fff;
      
      @include media_query(){
      	padding: 18px 16px;
      }
    }
    
    &_price{
      &_table{
        width: 100%;
        margin-bottom: 21px;
        
        th,td{
          font-size: 1.4rem;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          color: #2c2e31;
          text-align: right;
          padding: 0 0 16px;
        }
        
        tr:last-child{
          th,td{
            padding-bottom: 0;
            padding-top: 16px;
          }
        }
        
        th{
          width: 8.25em;
          font-family: HiraKakuPro-W3;
        }
        
        td{
          font-family: HiraginoSans-W4;
          font-size: 1.2rem;
          
          span{
            font-family: Roboto;
            font-weight: 500;
            font-size: 1.4rem;
          }
        }
        
        .deliverycharge{
          a{
            font-family: HiraKakuPro-W3;
            font-size: 1.2rem;
            color: #2c2e31;
            text-decoration: underline;
            
            &:hover{
              text-decoration: none;
            }
          }
        }
        
        .total{
          border-top: solid 1px #ebedf0;
        }
      }
    }
    
  }
  
  &_section_title{
    font-family: HiraKakuPro-W6;
    font-size: 1.6rem;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #2c2e31;
  }
  
}




.slide_dl{
  
  &_inner{
    border: solid 1px #ebedf0;
    
    &:not(:last-child){
      border-bottom: none;
    }
  }
  
  dt{
    padding: 16px 24px 8px;
    
    &:not(:first-child){
      border-top: none;
    }
    
    @include media_query(){
      padding: 21px 16px 10px;
    }
    
    .radio_text{
      font-family: HiraKakuPro-W6;
      font-size: 1.4rem;
      line-height: 1.8;
      letter-spacing: 1.3px;
      color: #2c2e31;
      
      @include media_query(){
      	font-size: 1.3rem;
      }
    }
  }
  
  dd{
    border-top: solid 1px #ebedf0;
    
    padding: 24px;
    
    @include media_query(){
    	padding: 24px 16px;
    }
  }
  
  .has_dl{
    padding: 0;
  }
  
  .slide_dd{
    display: none;
  }
  
  
  h5{
    font-family: HiraKakuPro-W6;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 1.3px;
    color: #2c2e31;
    
    &+p{
      font-family: HiraKakuPro-W3;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #2c2e31;
      
      margin-top: 8px;
      
      &+h5{
        margin-top: 32px;
      }
    }
  }
  
  
  
  
  
  
  
  
  .form_table{
        
    @include media_query(){
    	tr:not(:last-child){
      	padding-bottom: 31px;
    	}
    }
    
    th,td{
      padding: 12px 0;
      text-align: left;
      
      @include media_query(){
      	padding: 0;
      }
    }
    
    th{
      padding-top: 20px;
      
      @include media_query(){
      	padding: 0;
      }
    }
    
    td{
      width: 379px;
      
      @include media_query(){
      	width: 100%;
      }
      
      input,select{
        border-radius: 0;
      }
      
      select+input,input+input{
        margin-top: 16px;
      }
      
      select{
        background-position: right 12px center;
      }
      
      p{
        font-family: HiraKakuPro-W3;
        font-size: 1.2rem;
        line-height: 1.42;
        letter-spacing: 0.6px;
        color: #2c2e31;
        
        padding: 7px 0 16px;
      }
    }
    
    tr:last-child td p{
      padding-bottom: 0;
    }
    
    .col2{
      @include media_query(){
      	display: block;
      }
      
      input{
        width: 50%;
        max-width: 186px;
        margin: 0;
        
        @include media_query(){
        	width: 100%;
        	max-width: 100%;
        	
        	&+input{
          	margin-top: 16px;
        	}
        }
      }
    }
    
    .cal_btn{
      display: flex;
      justify-content: space-between;
      
      input{
        width: 210px;
        
        @include media_query(){
        	width: 49%;
        }
      }
      
      .autozip{
        width: 160px;
        border: solid 1px #dce0e3;
        cursor: pointer;
        
        display: flex;
        justify-content: center;
        align-items: center;
        
        font-family: HiraKakuPro-W6;
        font-size: 1.3rem;
        line-height: 1;
        letter-spacing: normal;
        color: #707980;
        
        transition-property: border-color;
        transition-duration: .25s;
        
        &:hover{
          border-color: #bfcbd5;
        }
        
        @include media_query(){
        	width: 49%;
        }
      }
    }
  }
}