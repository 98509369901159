.vip_password{
    padding: 60px 16px;
    
    @include media_query(){
        padding: 16px;
    }
    
    .vip_password_inner{
        width: 100%;
        max-width: 800px;
        background-color: #fff;
        margin: 0 auto;
        padding: 90px 16px;
        text-align: center;
        
        @include media_query(){
            padding: 60px 16px;
        }
    }
    
    .icon_lock{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 66px;
        height: 66px;
        margin: 0 auto 24px;
        border-radius: 50%;
        background-color: #F6F6F6;
        
        img{
            width: 100%;
            max-width: 24px;
            height: auto;
        }
    }
    
    h3{
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.05em;
        color: #000000;
        
        @include media_query(){
            font-size: 14px;
        }
    }
    
    form{
        display: flex;
        flex-direction: column;
        max-width: 311px;
        margin: 24px auto 0;
        
        input[type=password]{
            border: 1px solid #E5E5E5;
            padding: 10px 17px;
            font-weight: bold;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.05em;
            color: #2C2E31;
        }
        
        input[type=submit]{
            background-color: #000;
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.05em;
            width: 311px;
            height: 40px;
            margin: 16px 0 0 0;
        }
    }
}




.vip{
    .vip_inner{
        max-width: 1128px;
        margin: 0 auto;
        
        @include media_query(){
            padding: 16px 16px 0;
        }
    }
    
    .vip_header{
        display: flex;
        background-color: #2C2E31;
        max-height: 424px;
        
        @include media_query(){
            flex-direction: column;
            max-height: inherit;
            background-color: #000;
        }
        
        .vip_header_img{
            width: 50%;
            max-height: 424px;
            
            @include media_query(){
                width: 100%;
                order: 1;
                max-height: 224px;
            }
            
            img{
                width: 100%;
                height: 100%;
                max-height: 424px;
                object-fit: cover;
                object-position: 50% 50%;
                font-family: "object-fit: cover; object-position: 50% 50%;";
                
                @include media_query(){
                    max-height: 224px;
                }
            }
        }
        
        .vip_header_main{
            width: 50%;
            padding: 123px 16px;
            color: #fff;
            text-align: center;
            
            @include media_query(){
                width: 100%;
                padding: 30px 16px;
            }
            
            p{
                font-weight: 500;
                font-size: 12px;
                line-height: 120%;
                letter-spacing: 0.05em;
                
                @include media_query(){
                    font-size: 11px;
                    line-height: 120%;
                }
            }
            
            h3{
                max-width: 400px;
                margin: 24px auto 0;
                font-size: 24px;
                font-weight: bold;
                line-height: 150%;
                letter-spacing: 0.05em;
                
                @include media_query(){
                    max-width: 240px;
                    margin: 16px auto 0;
                    font-size: 14px;
                    line-height: 140%;
                }
            }
        }
    }
}

.vip_main{
    margin-top: 60px;
    padding-bottom: 60px;
    
    @include media_query(){
        margin-top: 24px;
    }
    
    .vip_main_introduction{
        max-width: 800px;
        margin: 0 auto;
        
        p{
            font-size: 15px;
            line-height: 160%;
            text-align: justify;
            letter-spacing: 0.05em;
            
            @include media_query(){
                font-size: 13px;
                line-height: 169%;
            }

            & + p{
                margin-top: 1em;
            }
        }
    }
    
    .pickup{
        margin-top: 60px;
        
        .pickup_inner{
            margin: 0;
            padding: 0;
            
            & + .pickup_inner{
                margin-top: 60px;
                
                @include media_query(){
                    margin-top: 40px;
                }
            }
            
            .index_section_title{
                font-size: 16px;
            }
        }
    }
}