.order_history{
  max-width: 1128px;
  margin: 0 auto;

  @include media_query(){
  	padding: 0 16px;
  }
}

.order_history_table{
  width: 100%;
  background-color: #fff;
  margin-top: 24px;

  @include media_query(){
  	background-color: transparent;
  }

  tr,th,td{
    @include media_query(){
    	display: block;
    	width: 100%;
    }
  }

  tr{
    @include media_query(){
      background-color: #fff;

      &:nth-child(2){
        margin-top: 0;
      }

    	&+tr{
      	margin-top: 24px;
    	}
    }
  }

  tr:first-child{
    @include media_query(){
    	display: none;
    }
  }

  .order_canceled{
    background-color: #f6f8fa;

    .btn_cansel{
        display: none;
    }
  }

  th,td{
    border: solid 1px #ebedf0;

    @include media_query(){
    	border-top: none;
    }
  }

  th{
    font-family: HiraKakuPro-W6;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: #2c2e31;
    padding: 14px 16px;
    vertical-align: middle;
  }

  td{
    text-align: left;
    padding: 12px 16px;

    font-family: Roboto, HiraKakuPro-W3;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.29;
    letter-spacing: normal;
    color: #2c2e31;

    &:last-child{
      text-align: center;

      a, span{
        font-family: HiraKakuPro-W3;
        font-size: 1.4rem;
        line-height: normal;
        letter-spacing: normal;
        color: #2c2e31;
        text-decoration: underline;
        white-space: nowrap;

        &:hover{
          text-decoration: none;
        }

        @include media_query(){
/*
        	text-decoration: none;
        	display: block;
        	width: 100%;
        	max-width: 311px;
        	height: 40px;
        	line-height: 40px;
        	border: solid 1px #ebedf0;
*/
        }
      }
    }

    &:not(.order_data):not(.order_o-status):not(.order_address){
      white-space: nowrap;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3){
      @include media_query(){
      	border: none;
        padding: 8px 16px;

        display: flex;

        br{
          display: none;
        }

        span{
          margin-right: auto;
        }
      }
    }

    &:nth-child(1){
      @include media_query(){
      	padding-top: 16px;
      }
    }
    &:nth-child(3){
      @include media_query(){
      	border-bottom: solid 1px #ebedf0;
      	padding-bottom: 16px;
      }
    }

    span{
      display: none;

      @include media_query(){
      	display: block;

      	font-family: HiraKakuPro-W6;
        font-size: 1.4rem;
        line-height: normal;
        letter-spacing: normal;
        color: #2c2e31;
      }
    }

    small{
      font-size: 1.2rem;
    }
  }

  .order_data{
    &+td,&+td+td{
      @include media_query(){
      	display: flex;
      	text-align: right;

      	span{
        	text-align: left;
        	margin-right: auto;
      	}
      }
    }
  }

  .order_address{
    @include media_query(){
    	display: flex;

    	span{
      	margin-right: auto;
    	}

    	.order_address_p{
      	max-width: 164px;
    	}
    }
  }

  .o-date,
  .o-numbar{
    text-align: center;
  }

  .o-plice,.o-quantity{
      white-space: nowrap;
  }

  .o-cancel{
      width: 100px;
  }

  .art_title{
    word-break: break-all;
    max-width: 180px;
    min-width: 180px;
    font-family: HiraKakuPro-W3;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 8px;

    a{
      word-break: break-all;
      color: #2c2e31;
      text-decoration: underline;

      &:hover{
        text-decoration: none;
      }
    }
  }

  .order_o-status{
    min-width: 177px;

    @include media_query(){
    	max-width: 100%;
    }
  }

  .order_address{
    min-width: 196px;

    @include media_query(){
    	max-width: 100%;
    }
  }

  .order_o-quantity{
      text-align: center;

      font-family: Roboto;
      font-size: 1.4rem;
      color: #2c2e31;
  }

  .order_item{
    display: flex;

    & + .order_item{
      margin-top: 8px;
    }

    .order_img{
      margin-right: 24px;
      background-color: #fbfbfb;

      @include media_query(){
      	margin-right: 8px;
      }

      a,span{
        width: 64px;
        height: 64px;
        background-color: #ebedf0;
        padding: 6px;
        overflow: hidden;

        display: flex;
        justify-content: center;
        align-items: center;

        &:hover{
          opacity: .7;
        }
      }

      span:hover{
        opacity: 1;
      }

      img{
        max-width: 100%;
        width: auto;
        max-height: 100%;
        height: auto;
        vertical-align: bottom;
      }
    }

    .order_text{
      display: flex;
      flex-direction: column;
      justify-content: center;

      a:hover{
        opacity: .7;
      }

      &_artist{
        font-family: Roboto, HiraKakuPro-W3;
        font-size: 1.4rem;
        line-height: normal;
        letter-spacing: 0.7px;
        color: #2c2e31;
      }

      &_arttitle{
        font-family: Roboto, HiraKakuPro-W3;
        font-size: 1.3rem;
        line-height: normal;
        letter-spacing: 0.6px;
        color: #707980;
        word-break: break-all;
      }

      &_artdate{
        font-family: Roboto;
        font-size: 1.2rem;
        line-height: normal;
        letter-spacing: 0.6px;
        color: #707980;
      }
    }
  }
}

.order_alert{
  max-width: 150px;

  h4{
    padding-left: 28px;
    background-image: url(../img/mypage/group.png);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 20px 16px;

    font-family: HiraKakuPro-W6;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.23;
    letter-spacing: normal;
    color: #d94f4f;
    margin-bottom: 8px;
  }

  p{
    font-family: HiraKakuPro-W3;
    font-size: 1.2rem;
    line-height: 1.5;
    letter-spacing: normal;
    color: #d94f4f;
  }
}








.order_cancel{
  max-width: 1128px;
  margin: 0 auto;
  padding-top: 24px;

  @include media_query(){
  	padding: 16px;
  }

  .return_order{
    font-family: HiraKakuPro-W3;
    font-size: 1.3rem;
    letter-spacing: 0.7px;

    a{
      color: #2c2e31;

      &::before{
        display: inline-block;
        width: 8px;
        height: 12px;
        margin-right: 14px;
        content: "";
        vertical-align: text-bottom;
        background-image: url(../img/common/bt-left.png);
        background-size: 8px 12px;
      }

      &:hover{
        opacity: .7;
      }
    }


  }

  &_inner{
    margin-top: 20px;
    padding: 53px 16px 40px;
    background-color: #fff;

    @include media_query(){
      margin-top: 16px;
    	padding: 32px 0 16px;
    }

    h4{
     font-family: HiraKakuPro-W6;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0.8px;
      color: #2c2e31;
      text-align: center;

     &+p{
      margin-top: 40px;
      margin-bottom: 32px;

      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      line-height: normal;
      letter-spacing: 0.7px;
      color: #2c2e31;
      text-align: center;

      @include media_query(){
      	margin: 24px auto;
      	width: 300px;
      }
     }
    }
  }

  &_list{
    max-width: 744px;
    margin: 0 auto;
    border: solid 1px #ebedf0;

    @include media_query(){
    	border-left: none;
    	border-right: none;
    }

    &_head{
      padding: 13px 24px;
      border-bottom: solid 1px #ebedf0;

      @include media_query(){
      	padding: 13px 16px;
      }

      dl{
        display: flex;
        flex-wrap: wrap;

        @include media_query(){
        	display: block;
        }

        div{
          display: flex;
        }

        dt{
          font-family: HiraKakuPro-W6;
          font-size: 1.4rem;
          font-weight: bold;
          line-height: normal;
          letter-spacing: 0.7px;
          color: #2c2e31;
        }

        dd{

          @include media_query(){
          	margin-left: 8px;
          }

          &::before{
            content: ":";
            margin: 0 4px 0 8px;
          }
        }

        .order_date{
          margin-right: 32px;

          @include media_query(){
          	margin-right: 0;
          }
        }
      }
    }

    &_main{
      padding: 24px;

      @include media_query(){
      	padding: 0;
      }
    }
  }
}

.order_store{
    display: flex;
    margin-bottom: 16px;

    @include media_query(){
    	    padding: 16px;
    	    margin-bottom: 0;
    	    padding-bottom: 0;
    }

    dt{
        font-family: HiraKakuPro-W6;
          font-size: 1.4rem;
          font-weight: bold;
          line-height: normal;
          letter-spacing: 0.7px;
          color: #2c2e31;
    }

    dd{
        flex: 1;
        word-break: break-all;

        text-indent: -1.3em;
        margin-left: 1.3em;

        &::before{
            content: ":";
            margin: 0 4px 0 8px;
          }
    }
}

.order_info{
  display: flex;
  align-items: center;

  @include media_query(){
  	flex-wrap: wrap;
  	padding: 16px;
  }

  & + &{
    margin-top: 16px;

    @include media_query(){
      margin: 0;
      border-top: solid 1px #ebedf0;
    }
  }

  .order_head{
    display: flex;
    flex: 1;

    @include media_query(){
    	width: 100%;
    	margin-bottom: 24px;
    	flex: auto;
    }
  }

  .order_img{
    margin-right: 24px;
    background-color: #fbfbfb;

    @include media_query(){
    	margin-right: 8px;
    }

    a,span{
      width: 64px;
      height: 64px;
      background-color: #ebedf0;
      padding: 6px;
      overflow: hidden;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover{
        opacity: .7;
      }
    }

    span:hover{
      opacity: 1;
    }

    img{
      max-width: 100%;
      width: auto;
      max-height: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }

  .order_text{

    @include media_query(){
    	width: 100%;
    }

    &_artist{
      font-family: HiraKakuPro-W3;
      font-size: 1.6rem;
      line-height: normal;
      letter-spacing: 0.8px;
      color: #2c2e31;
    }

    &_arttitle{
      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      line-height: normal;
      letter-spacing: 0.6px;
      color: #707980;
      word-break: break-all;
    }

    &_artdate{
      font-family: HiraKakuPro-W3;
      font-size: 1.2rem;
      line-height: normal;
      letter-spacing: 0.6px;
      color: #707980;
    }
  }

  .order_number{
      min-width: 80px;
    margin-left: 32px;

    @include media_query(){
    	margin-left: 0;
    }

    p{
      font-family: HiraKakuPro-W3;
      font-size: 1.4rem;
      line-height: normal;
      letter-spacing: 0.7px;
      color: #2c2e31;

      span{
        font-family: HiraKakuPro-W3;
        font-size: 1.4rem;
        line-height: normal;
        letter-spacing: 0.7px;
        color: #2c2e31;
        margin-left: 8px;
      }
    }
  }

  .order_price{
    font-family: HiraKakuPro-W3;
    font-size: 1.2rem;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #2c2e31;
    margin-left: 16px;

    span{
      font-family: Roboto;
      font-weight: 500;
      font-size: 1.4rem;
    }
  }
}

.cancel_btn{
  margin-top: 32px;
  text-align: center;

  input[type=submit]{
    width: 225px;
    height: 40px;
    line-height: 40px;
    background-color: #2c2e31;

    font-family: HiraKakuPro-W6;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 0.7px;
    color: #ffffff;
    margin-bottom: 16px;
  }

  p{
    font-family: HiraKakuPro-W3;
    font-size: 1.3rem;
    line-height: normal;
    letter-spacing: 0.7px;
    color: #2c2e31;
  }
}

