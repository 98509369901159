.information{
  max-width: 1128px;
  margin: 24px auto 0;

  @include media_query(){
  	padding: 0 16px;
  }
}

.information_art{
  margin-top: 16px;

  &_item{
    background-color: #fff;
    padding: 24px;

    display: flex;

    &:not(:last-child){
      margin-bottom: 1px;
    }

    @include media_query(){
    	display: block;
    	padding: 16px;
    }
  }

  &_date{
    margin-right: 24px;
    font-family: Roboto;
    font-size: 1.3rem;
    line-height: normal;
    letter-spacing: 1px;
    color: #707980;
    padding-top: 2px;

    @include media_query(){
    	margin: 0 0 8px 0;
    }
  }

  &_main{
    h4{
      word-break: break-all;
      font-family: HiraKakuPro-W6;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.29;
      letter-spacing: 0.5px;
      color: #2c2e31;
      margin-bottom: 8px;
    }

    p{
      font-family: HiraKakuPro-W3;
      font-size: 1.2rem;
      line-height: 1.92;
      letter-spacing: normal;
      color: #707980;

      a{
        color: #707980;
        text-decoration: underline;

        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}