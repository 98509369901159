.favorite_tab{
  max-width: 1128px;
  margin: 24px auto 0;
  display: flex;
  
  @include media_query(){
  	padding: 0 16px;
  }
  
  li{
    width: 50%;
    text-align: center;
    background-color: #fff;
    
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      font-family: Roboto;
      font-size: 1.3rem;
      line-height: normal;
      letter-spacing: 1.8px;
      color: #2c2e31;
      
      border: solid 1px #fff;
      transition-property: border;
      transition-duration: .25s;
      
      &:hover{
        border: solid 1px #bfcbd5;
      }
      
      @include media_query(){
      	height: 32px;
      }
    }
  }
  
  .current a{
    background-color: #2c2e31;
    color: #fff;
    border: solid 1px #2c2e31;

    &:hover{
      border: solid 1px #2c2e31;
    }
  }
  
  &+.favorite_artworks{
    margin-top: 24px;
    
    @include media_query(){
    	margin-top: 16px;
    }
  }
}

.favorite_head{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
  @include media_query(){
  	.page_title{
    	width: 100%;
    	order: 1;
    	margin-top: 24px;
  	}
  }
  
  ul{
    display: flex;
    margin-left: auto;
    margin-right: 16px;
    
    @include media_query(){
    	margin-left: 0;
    	margin-right: auto;
    }
    
    li{
      width: 100px;
      height: 32px;
      background-color: #fff;
      
      font-family: HiraginoSans-W3;
      font-size: 1.2rem;
      line-height: normal;
      letter-spacing: 1.7px;
      text-align: center;
      
      @include media_query(){
      	width: 82px;
      }
      
      a{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        color: #2c2e31;
        border: solid 1px #fff;
        transition-property: border;
        transition-duration: .25s;
        
        &:hover{
          border: solid 1px #bfcbd5;
        }
      }
    }
    
    .current{
      background-color: #2c2e31;
      
      a{
        color: #fff;
        border: solid 1px #2c2e31;

        &:hover{
          border: solid 1px #2c2e31;
        }
      }
    }
  }
  
  .form{
    select{
      width: 148px;
      height: 32px;
      padding: 0 5px!important;
      font-size: 1.1rem;
      text-align: left;
      
      @include media_query(){
      	width: 165px;
      }
    }
  }
}