.cart_modal{
  text-align: left;
  padding: 0;
  
  &_inner{
    
  }
  
  &_head{
    position: relative;
    padding: 24px 32px;
    border-bottom: solid 1px #ebedf0;
    
    @include media_query(){
    	padding: 18px 16px;
    	
    }
    
    .modal_title{
      font-family: HiraKakuPro-W6;
      font-size: 1.4rem;
      letter-spacing: 1.3px;
      color: #2c2e31;
      line-height: 1;
    }
    
    .remodal-close{
      width: 30px;
      height: 30px;
      right: 26px;
      left: auto;
      top: 0;
      bottom: 0;
      margin: auto;
      
      background-image: url(../img/settlement/ic-modalclose.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 17px 17px;
      
      @include media_query(){
      	right: 16px;
      }
      
      &:hover{
        opacity: .7;
      }
      
      &::before{
        display: none;
      }
    }
  }
  
  &_main{
    padding: 24px 32px;
    
    @include media_query(){
    	padding: 18px 16px;
    }
    
    .cart_modal_table{
      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      line-height: 1.85;
      letter-spacing: 1.2px;
      color: #2c2e31;
      border-top: solid 1px #DADADA;
      
      tr{
        th,td{
          border-bottom: solid 1px #DADADA;
          padding: 8px 10px;
          word-break: break-all;
        }
        th{
          min-width: 14em;
          font-weight: normal;
          
          @include media_query(){
            min-width: 7em;
          }
        }
        
        td{
        }
      }
      
      & + h3{
        margin-top: 24px;
      }
    }
    
    p{
      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      line-height: 1.85;
      letter-spacing: 1.2px;
      color: #2c2e31;
      word-break: break-all;
    }
  }
  
  &_foot{
    display: flex;
    justify-content: flex-end;
    
    padding: 16px 24px;
    border-top: solid 1px #ebedf0;
    
    @include media_query(){
    	padding: 16px;
    }
    
    .close_btn{
      font-family: HiraKakuPro-W6;
      font-size: 1.4rem;
      line-height: normal;
      letter-spacing: 0.7px;
      color: #707980;
      cursor: pointer;
      
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px #ebedf0;
      transition-property: border;
      transition-duration: .25s;
      
      &:hover{
        border: solid 1px #bfcbd5;
      }
    }
  }
}

.modal{
  &_deliverycharge{
    max-width: 552px;
  }
  
  &_storesettlement{
    max-width: 552px;
  }
  
  &_deliverycharge-criterion{
    max-width: 984px;
  }
}




.remodal-wrapper{
  padding: 16px !important;
  overflow: scroll !important;
  height: 100vh !important;
}

.remodal-overlay{
  background-color: rgba(44, 46, 49, 0.7);
}