#lightcase-overlay{
  background-color: #fff;
  opacity: 1 !important;
}

.lightcase-icon-close{
  &::before{
    color: #000;
  }
}

.lightcase-icon-prev{
  &::before{
    color: #000;
  }
}

.lightcase-icon-next{
  &::before{
    color: #000;
  }
}



#lightcase-case, #lightcase-content, .lightcase-contentInner, .lightcase-inlineWrap, .modal{
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  left: 0 !important;
  top: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: #fff !important;
  //z-index: 9999;
  overflow: hidden;
  
  @include media_query(){
  	width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: #fff !important;
    //z-index: 9999;
  }
}

.modal{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &_wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    
    @include media_query(){
    	padding-top: 50px;
    }
  }
  
  &_inner{
    width: 100%;
    max-width: 1240px;
    height: 100%;
    max-height: 800px;
    display: flex;
    flex-direction: column;
  }
  
  &_main{
    position: relative;
    //flex: 1;
    padding: 30px;
    height: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 20px;
    
    @include media_query(){
    	max-height: 350px;
    }
    
    img{
      //position: absolute;
      

      width: 100%;
      height: 100%;
      object-fit: contain;
      font-family: "object-fit: contain;";


      cursor: pointer;
    }
  }
  
  &_cap{
    flex: 1;
    max-height: 140px;
    font-size: 10px;
    color: #707980;
    line-height: 1;
    //text-align: justify;
    margin: 0;
    padding: 10px 24px;
    margin-top: 8px;
    text-shadow: none;
    
    @include media_query(){
    	margin-top: 5px;
    }
  }
}
  
  #lightcase-caption, .modal_cap{
    font-size: 11px;
    color: #707980;
    line-height: 1.2;
    //text-align: justify;
    margin: 0;
    padding: 0 24px;
    margin-top: 8px;
    text-shadow: none;
    text-align: center;
  }
  
  .fi{
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
  }
  
  .csa{
    cursor: auto !important;
  }