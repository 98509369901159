.agreement{
  display: flex;
  max-width: 1168px;
  padding: 0 20px 64px;
  margin: 0 auto;

  @include media_query(){
  	padding: 16px 16px 48px;
  }

  &_sidebar{
      position: relative;
      width: 264px;
    margin-right: 24px;

    @include media_query(){
    	display: none;
    }

    &_inner{
        width: 264px;
    }
  }

  &_nav{
    background-color: #fff;

    ul{
      li{
        font-family: HiraKakuPro-W3;

        &:not(:last-child){
          border-bottom: solid 1px #ebedf0;
        }
        a{
          display: block;
          padding: 15px 32px 15px 24px;
          color: #707980;
          font-size: 1.4rem;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;

          transition-property: color,font-weight;
          transition-duration: .25s;

          img{
              vertical-align: text-bottom;
          }

          &:hover{
            color: #2c2e31;
            font-weight: bold;
          }
        }

        .in_list{
            padding-bottom: 8px;

            li{
                border-bottom: none;
                padding-left: 1em;

                a{
                    font-weight: normal;
                    background-image: none;
                    padding: 8px 32px 8px 24px;

                    &:hover{
                        opacity: .7;
                    }
                }
            }
        }
      }

      .on{
        a{
          color: #2c2e31;
          font-weight: bold;
          background-image: url(../../common/img/common/right-arrow-black.png);
          background-position: right 13px center;
          background-repeat: no-repeat;
          background-size: 6px 11px;
        }
      }
    }
  }

  .art_guide_banner{
    margin-top: 16px;



    a{
      &:hover{
        opacity: .7;
      }

      img{
          vertical-align: bottom;
      }
    }
  }

  &_main{
    width: 100%;
    max-width: 840px;
    background-color: #fff;
    padding: 48px;

    flex: 1;

    word-break: break-all;

    @include media_query(){
    	padding: 24px 16px;
    }
  }

  &_title{
    font-family: PTSerif-Caption, serif;
    letter-spacing: 2.5px;
    color: #2c2e31;
    font-size: 1.8rem;
    margin-bottom: 30px;

    @include media_query(){
    	line-height: normal;
    	margin-bottom: 24px;
    	font-size: 1.6rem;
    }

    span{
      font-family: HiraMinProN-W3, sans-serif;
      letter-spacing: 1px;
      color: #707980;
      font-size: 1.2rem;

      @include media_query(){
      	display: block;
      	margin-top: 8px;
      	line-height: normal;
      }
    }
  }
}


.fixed_slide{
    position: fixed;
    top: 80px;
}

.fixed_slide_end{
    position: absolute;
    bottom: 0;
}


.about{
    max-width: 600px;
    margin: 0 auto;
    
  p{
    font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
      font-weight: bold;
    font-size: 1.3rem;
    line-height: 2.15;
    color: #2c2e31;
    letter-spacing: normal;

    &+p{
      margin-top: 30px;
    }
  }
}

.contract{
  h3{
    font-family: HiraKakuPro-W6;
    font-size: 1.6rem;
    line-height: 1.46;
    letter-spacing: 0.6px;
    color: #2c2e31;

    &:not(:first-child){
      margin-top: 1em;
    }
  }

  p{
    font-family: HiraKakuPro-W3;
    font-size: 1.3rem;
    line-height: 1.46;
    letter-spacing: 0.6px;
    color: #2c2e31;
  }

  a{
    color: #4a90e2;
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }
  }
}

.contact{

  p{
    font-family: HiraKakuPro-W3;
    font-size: 1.3rem;
    line-height: 1.54;
    letter-spacing: 0.6px;
    color: #2c2e31;

    a{
      color: #2c2e31;
      text-decoration: underline;

      &:hover{
        text-decoration: none;
      }
    }
  }

  ul{
    padding-left: 1.5em;
    margin: 13px 0 20px;

    @include media_query(){
    	margin: 13px 0 33px;
    }

    li{
      font-family: HiraKakuPro-W3;
      font-size: 1.2rem;
      list-style: disc;
      line-height: 1.67;
      letter-spacing: 0.6px;
      color: #707980;

      &:not(:last-child){
        margin-bottom: 17px;
      }
    }
  }

  .form_table{
    max-width: 628px;
    margin: 0 auto;

    @include media_query(){
      display: flex;
      flex-direction: column;
    }

    th{
      width: 21%;

      @include media_query(){
      	width: 100%;
      	text-align: left;
      }
    }

    tr:first-child{
      border-bottom: solid 1px #ebedef;

      th{
        @include media_query(){
        	padding-top: 0;
        }
      }

      td{
        padding-bottom: 22px;

        @include media_query(){
        	padding-bottom: 24px;
        }
      }

      &+tr{
        th{
          padding-top: 30px;

          @include media_query(){
          	padding-top: 24px;
          }
        }

        td{
          padding-top: 22px;

          @include media_query(){
          	padding-top: 0;
          }
        }
      }
    }
  }

  .co-ordernumber,
  .co-itemnumber{
    display: none;
  }
}



.faq{

  &_inner{
    &:not(:first-child){
      margin-top: 32px;
    }

    h3{
      padding-top: 90px;
      margin-top: -90px;
      margin-bottom: 16px;

      font-family: PTSerif-Caption;
      font-size: 1.4rem;
      line-height: normal;
      letter-spacing: 1.9px;
      color: #2c2e31;
    }
  }

  &_main{

    border: solid 1px #ebedef;

    &:first-child{
      border-radius: 3px 3px 0 0;
    }

    &:last-child{
      border-radius: 0 0 3px 3px;
    }

    &:not(:last-child){
      border-bottom: none;
    }

    dt{
      display: flex;
      font-family: HiraKakuPro-W6;
      font-weight: bold;
      font-size: 1.4rem;
      letter-spacing: normal;
      color: #2c2e31;

      padding: 24px 28px 24px 16px;

      @include media_query(){
        cursor: pointer;

      	background-image: url(../../common/img/agreement/faq/plus.png);
        background-repeat: no-repeat;
        background-position: right 16px top 30px;
        background-size: 13px;
        padding-right: 45px;
      }

      &::before{
        content: "Q";
        margin-right: 16px;
        font-family: PTSerif-Caption;
        font-size: 1.8rem;
        font-weight: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2c2e31;
      }
    }

    .dt_open{
      @include media_query(){
      	background-image: url(../../common/img/agreement/faq/minus.png);
        background-repeat: no-repeat;
        background-position: right 16px top 36px;
        background-size: 13px;
        padding-right: 45px;
      }
    }

    dd{
      font-family: HiraKakuPro-W3;
      font-size: 1.4rem;
      line-height: 1.57;
      letter-spacing: normal;
      color: #2c2e31;
      word-break: normal;

      padding: 0 28px 24px 16px;

      @include media_query(){
      	display: none;
      }

      .faq_a_inner{
        display: flex;

        @include media_query(){
        	display: block;
        }

        &::before{
          display: block;
          content: "A";
          margin-right: 16px;
          font-family: PTSerif-Caption;
          font-size: 1.8rem;
          font-weight: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #f55367;

          @include media_query(){
          	width: 100%;
          }
        }

        h4{
            font-weight: bold;
            margin-top: 8px;
        }

        a{
            color: #2c2e31;
            text-decoration: underline;

            &:hover{
                text-decoration: none;
            }
        }
      }
    }

  }
}


.information_area{
    &_item{
        border-bottom: solid 1px #ebedef;
        padding: 24px 0;

        &:first-child{
            border-top: solid 1px #ebedef;
        }
    }

    &_data{
        .label{
            display: inline-block;
            font-family: HiraKakuPro-W6;
            font-size: 1.2rem;
            color: #fff;
            padding: 4px 8px;
            border-radius: 3px;
            background-color: #2c2e31;

            &_maintenance{
                //background-color: #2c2e31;
            }

            &_update{
                //background-color: #b273a8;
            }

            &_news{
                //background-color: #4a90e2;
            }
        }

        time{
            font-family: HiraKakuPro-W3;
            font-size: 1.4rem;
            color: #1b1c1d;
        }
    }

    &_main{
        h4{
            font-family: HiraKakuPro-W6;
            font-size: 1.4rem;
            font-weight: bold;
            color: #2c2e31;
            margin: 16px 0 8px;
        }

        p{
            font-family: HiraKakuPro-W3;
            font-size: 1.4rem;
            line-height: 1.43;
            letter-spacing: normal;
            color: #2c2e31;

            a{
                color: #2c2e31;
                text-decoration: underline;

                &:hover{
                    text-decoration: none;
                }
            }
        }

    }
}

.contract_agreement{
  h4{
      font-size: 1.3rem;
      font-weight: bold;
      margin: 1em 0 .5em;
      line-height: 1.46;
      letter-spacing: 0.6px;
      color: #2c2e31;
  }

  ul{
      //padding-left: 1em;

      ul{
          padding-left: 1em;

          ul{
              padding-left: 1em;
          }
      }
  }
}

.contract_inner{
  
  width: 100%;
  // max-height: 60vh;
  // overflow-y: scroll;
  
}

.contract_btns_wrap{
  margin-top: 40px;
  
  .checkbox_text{
    display: none;
  }
  
  .contract_btns{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    
    @include media_query(){
      flex-direction: column;
      gap: 10px;
    }
    
    input[type=submit]{
      display: block;
      width: 100%;
      max-width: 296px;
      background-color: #4185DE;
      border: none;
      border-radius: 0;
      color: #fff;
      cursor: pointer;
      font-family: HiraKakuPro-W6;
      font-size: 1.4rem;
      height: 48px;
      letter-spacing: .7px;
      line-height: 40px;
      padding: 0;
      transition: opacity .4s;
      
      &:hover{
        opacity: .7;
      }
    }
    
    a{
      display: block;
      width: 100%;
      max-width: 296px;
      // background-color: #2c2e31;
      border: none;
      border-radius: 0;
      color: #707980;
      cursor: pointer;
      font-family: HiraKakuPro-W6;
      font-size: 1.4rem;
      height: 48px;
      letter-spacing: .7px;
      line-height: 48px;
      padding: 0;
      transition-duration: .25s;
      transition-property: background-color;
      text-align: center;
    }
  }
}
