$details_slide_breakpoint: 1128;

.details{
  margin-bottom: 48px;

  @include media_query(){
    margin-bottom: 32px;
  	padding: 16px 16px 0;
  }

  &_inner{
    position: relative;
    max-width: 1128px;
    margin: 0 auto;

    .oil_only{
      position: absolute;
      right: 0;
      top: 0;
      z-index: 99;
      width: 80px;
      height: 32px;
      line-height: 32px;
      background-color: #4e7192;
      text-align: center;
      font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 1.4px;
      color: #ffffff;

      @include media_query(){
      	right: 16px;
      	top: 16px;
      }
    }
  }

  &_wrap{
    display: flex;
    margin-bottom: 1px;

    @include media_query(){
    	display: block;
    	margin-bottom: 0;
    }
  }

  &_slide{
    width: 68.086%;
    padding-right: 1px;

    @include media_query(){
    	width: 100%;
    	padding-right: 0;
    }

    &_inner{
      height: 100%;
      padding: 24px;
      background-color: #fff;

      @include media_query(){
      	padding: 16px;
      }
    }
  }

  &_about{
    margin-top: 16px;

    @include media_query($details_slide_breakpoint){
    	margin-top: 30px;
    }

    @include media_query(){
    	display: none;
    }

    p{
      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      line-height: 1.69;
      letter-spacing: 0.6px;
      color: #2c2e31;
    }
  }

  &_outline{
    width: 31.914%;
    padding: 32px;
    background-color: #fff;

    @include media_query(){
    	width: 100%;
    	padding: 16px 16px 24px;
    }

    #for_oil-bijutsutecho-to-item,
    #for_oil-bijutsutecho-to-item-sp{
        padding: 10px 0;

        @include media_query(){
            padding-top: 0;
            margin-top: -26px !important;
        }
    }

    &_head{
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: solid 1px #ebedf0;

      @include media_query(){
      	padding-bottom: 0;
      	border: none;
      }

      h2{
        font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: normal;
        letter-spacing: 0.8px;
        color: #2c2e31;
        margin-bottom: 8px;

        a{
            color: #2c2e31;
            text-decoration: underline;

            &:hover{
                text-decoration: none;
            }
        }
      }

      h1{
        font-family: PTSerif-Caption;
        font-size: 1.6rem;
        line-height: normal;
        letter-spacing: 0.8px;
        color: #2c2e31;
        margin-bottom: 4px;
      }

      .date{
        font-family: PTSerif-Caption;
        font-size: 1.2rem;
        line-height: normal;
        letter-spacing: 0.6px;
        color: #2c2e31;
      }
    }

    &_main{
      padding-bottom: 24px;
      margin-bottom: 20px;
      border-bottom: solid 1px #ebedf0;

      .plice{
        font-family: Roboto;
        font-size: 2.4rem;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.5px;
        color: #2c2e31;
        margin-bottom: 16px;

        @include media_query(){
        	font-size: 2.2rem;
        }

        small{
          font-family: HiraginoSans-W4;
          font-size: 1.2rem;
          letter-spacing: 0.3px;
        }
      }

      .plice_closed{
        font-family: Roboto;
        font-size: 2.4rem;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.5px;
        color: #2c2e31;
        margin-bottom: 16px;

        a{
          color: #2c2e31;
          text-decoration: underline;

          &:hover{
            text-decoration: none;
          }
        }

        @include media_query(){
        	font-size: 2.2rem;
        }
      }

      .plice_soldout{
        font-family: Roboto;
        font-size: 2.4rem;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.5px;
        color: #2c2e31;
        margin-bottom: 16px;

        @include media_query(){
        	font-size: 2.2rem;
        }
      }

      .form{
        input[type="submit"],input[type="button"]{
          height: 48px;

          background-color: #2c2e31;
        line-height: 40px;
        padding: 0;
        font-family: HiraKakuPro-W6;
        font-size: 1.4rem;
        letter-spacing: 0.7px;
        color: #fff;
        border: none;
        border-radius: 0;
        transition-property: background-color;
        transition-duration: .25s;
        cursor: pointer;

        &:hover{
            opacity: .7;
        }
        }
      }

      .soldout_text{
        font-family: HiraKakuPro-W3;
        font-size: 1.3rem;
        line-height: 1.69;
        letter-spacing: 0.5px;
        color: #2c2e31;
        margin-bottom: 24px;
      }

      .details_about_sp{
        display: none;

        margin-top: 24px;
        font-family: HiraKakuPro-W3;
        font-size: 1.3rem;
        font-stretch: normal;
        line-height: 1.69;
        letter-spacing: 0.6px;
        color: #2c2e31;

        @include media_query(){
        	display: block;
        }
      }
    }

    .item_form{
        display: flex;
        align-items: center;
        justify-content: space-between;

      &_number{
        display: flex;
        align-items: center;

        label{
          font-family: HiraKakuPro-W3;
          font-size: 1.2rem;
          line-height: normal;
          letter-spacing: 0.6px;
          color: #707980;
          margin-right: 8px;
        }

        select{
          width: 70px;
          padding: .4em;
          background-position: right 6px center;

          font-family: HiraKakuPro-W3;
          font-size: 1.4rem;
          line-height: 1;
          letter-spacing: 0.7px;
          color: #2c2e31;
        }
      }

      .shipping{
        display: flex;

        li{
          font-family: HiraKakuPro-W3;
          font-size: 1.2rem;
          line-height: normal;
          letter-spacing: 0.3px;

          a{
            color: #2c2e31;
            text-decoration: underline;

            transition-property: color;
            transition-duration: .25s;

            &:hover{
              color: #4a90e2;
              text-decoration: none;
            }
          }

          &:first-child{
            font-weight: bold;

            &:after{
              content: "|";
              padding: .5em;
            }
          }
        }
      }
    }

    .tpoint{
      margin: 24px 0 22px;
      display: flex;
      flex-direction: column;

      p{
        &:not(:first-child){
          margin-top: 16px;
        }
      }

      .point{
        padding-left: 20px;
        margin-right: 28px;

        font-family: HiraginoSans-W4;
        font-size: 1.2rem;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #2c2e31;

        background-image: url(../../common/img/common/ic-vpoint.png);
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: left top;
      }

      a{
        font-family: Roboto;
        font-size: 1.2rem;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #2c2e31;
        text-decoration: underline;

        transition-property: color;
        transition-duration: .25s;

        &:hover{
          color: #4a90e2;
        }
      }
    }
  }

  &_btns{
    display: flex;
    margin: 16px 0 0 -16px;

    .btn_w{
      width: calc(50% - 16px);
      margin-left: 16px;

      a{
        height: 48px;
        line-height: 48px;
        padding-left: 10px;

        font-family: HiraKakuPro-W6;
        font-size: 1.4rem;
        letter-spacing: 0.7px;
        color: #707980;
      }
    }

    .like a{
      background-image: url(../../common/img/details/bt-heart.png);
      background-position: left 12% center;
      background-size: 18px 16px;
      background-repeat: no-repeat;
    }

    .like.on a{
      background-image: url(../../common/img/details/bt-heart-active.png);
    }

    .contact a{
      background-image: url(../../common/img/details/ic-mail.png);
      background-position: left 10% center;
      background-size: 20px 16px;
      background-repeat: no-repeat;
      padding-left: 20px;
    }
  }

  &_infotable{
    width: 100%;

    th,td{
      font-family: HiraKakuPro-W3;
      font-size: 1.2rem;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0.6px;
      color: #2c2e31;
      padding: 4px 0;
      text-align: left;

      a{
          font-weight: bold;
          color: #2c2e31;
          text-decoration: underline;

          &:hover{
              text-decoration: none;
          }
      }
    }

    th{
      position: relative;
      white-space: nowrap;
      padding-right: .8em;

      @include media_query(840){
      	max-width: 10em;
      }

      &:after{
        content: ":";
        position: absolute;
        right: 0;
      }
    }

    td{
      padding-left: .8em;
    }

    .search_category_list{
        display: flex;
        flex-wrap: wrap;

        li{
            &:not(:last-child){
                margin-right: 3px;

                &::after{
                    content: ",";
                }
            }
        }
    }
  }

  &_tags{
    width: 100%;
    padding: 24px;
    background-color: #fff;

    @include media_query(){
    	padding: 24px 16px;
    	margin-top: 1px;
    }

    ul{
      display: flex;
      flex-wrap: wrap;

      li{
        font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
        font-weight: bold;
        font-size: 1.2rem;
        line-height: normal;
        letter-spacing: 0.3px;

        &:not(:last-child){
          margin-right: 24px;
        }

        a{
          color: #2c2e31;

          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }

}








.of200{
  position: relative;
  height: auto;

  transition-property: height;
  transition-duration: .25s;

  .of_btn{
    display: none;

    position: absolute;
    bottom: 0;
    z-index: 5;

    width: 100%;
    height: 63px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);

    .round_btn{
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      z-index: 6;

      display: flex;
      justify-content: center;
      align-items: center;

      font-family: Roboto;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1px;
      color: #2c2e31;
      cursor: pointer;

      width: 120px;
      height: 32px;
      border-radius: 15px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border: solid 1px #ebedf0;
    }
  }
}

.h200{
  max-height: 200px;
  overflow: hidden;
}






.details_slide{
  &_wrap{
    div{
      vertical-align: bottom;
    }
  }

  .slick-track{
    display: flex;
    margin-left: 0;
  }

  &_main{
    margin-bottom: 10px !important;

    &_item{
      background-color: #f1f2f4;

      span,a{
        height: 540px;
        width: 100%;
        overflow: hidden;

        display: flex !important;
        justify-content: center;
        align-items: center;

        @include media_query(){
        	height: 233px;
        }
      }

      img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }

      .video{
        position: relative;
        z-index: 2;
        width: 100%;
        padding-top: 56.25%;
        margin: 0 auto;
        text-align: center;

        iframe{
          position: absolute;
          margin: auto;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .slick-dots{
      bottom: -2.5em;
      margin-bottom: 5px;
      padding-bottom: 10px;

      @include media_query(){
      	li{
        	margin: 0 1px;
      	}
      }
    }

    //次へ前へ共通
    .slick-arrow{
      position: absolute;
      z-index: 99;
      background-color: #fff;
      right: auto;
      left: auto;

      width: 40px;
      height: 80px;
      transition-property: box-shadow,margin-top;
      transition-duration: .3s;

      @include media_query(){
      	width: 24px;
      	height: 48px;
      }

      &:hover{
        box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
        //margin-top: -3px;

        @include media_query(){
        	box-shadow: none;
        	margin-top: 0;
        }
      }

      &:active{
        box-shadow: none;
        margin-top: 0;
      }

      &:before{
        display: none;
      }
    }

    //<
    .slick-prev{
      position: absolute;
      left: 0%;
      top: 280px;

      background-image: url(../../common/img/common/bt-left.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 8px 16px;

      @include media_query(){
      	top: 120px;
      }
    }

    //>
    .slick-next{
      right: 0%;
      top: 280px;

      background-image: url(../../common/img/common/bt-right.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 8px 16px;

      @include media_query(){
      	top: 120px;
      }
    }
  }

  &_sub{

    .slick-slide{
      transition-property: opacity;
      transition-duration: .4s;
    }

    .slick-current{
      opacity: .5;
    }

    @include media_query($details_slide_breakpoint){
    	display: none;
    }

    .slick-slide{
      width: 72px;
    }

    .slick-track{
      //width: 100% !important;
      display: flex;
      justify-content: space-between;
      transform: translate3d(0px, 0px, 0px) !important;

      &:before,&:after{
        display: none;
      }

      .slick-slide{
        width: auto !important;
      }
    }

    &_item{
      cursor: pointer;
      background-color: #f1f2f4;

      span{
        width: 72px;
        height: 72px;
        display: flex !important;
        justify-content: center;
        align-items: center;
      }

      img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}



.slide_caption{
  width: 100%;
  background-color: #fff;
  font-family: Roboto;
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #707980;
  padding: 3px 0 0;
}





.remodal-wrapper{
  padding: 0;
  overflow: hidden;
}

.details_remodal{
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  background-color: #fff;
  padding: 0;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  &_inner{
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1280px;
    max-height: 960px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @include media_query(){
    	height: 80%;
    }
  }

  &_header{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;

    .all_num{
      font-family: Roboto;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1.9px;
      color: #2c2e31;
    }

    &_tools{
      margin-left: auto;
    }
  }

  &_main{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;

    &_inner{
      height: 100%;
      overflow: hidden;

      @include media_query(){
      	height: auto;
      	max-width: 80%;
      }

      img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        overflow: hidden;
      }
    }
  }

  &_footer{
    text-align: center;
    min-height: 55px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p{
      width: 100%;
      font-family: HiraginoSans-W4;
      font-size: 1.3rem;
      line-height: normal;
      letter-spacing: 1.8px;
      color: #2c2e31;
    }
  }
}

.details_remodal-close{
  img{
    cursor: pointer;
  }
}











//次へ前へ共通
.arr{
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  right: auto;
  left: auto;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;

  width: 40px;
  height: 80px;
  transition-property: box-shadow,margin-top;
  transition-duration: .3s;

  @include media_query(){
  	width: 24px;
  	height: 48px;
  }

  &:hover{
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
    //margin-top: -3px;

    @include media_query(){
    	box-shadow: none;
    	//margin-top: 0;
    }
  }

  &:active{
    box-shadow: none;
    //margin-top: 0;
  }

  &:before{
    display: none;
  }
}

//<
.arr_l{
  left: 0%;

  background-image: url(../../common/img/common/bt-left.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
}

//>
.arr_r{
  right: 0%;

  background-image: url(../../common/img/common/bt-right.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 8px 16px;
}



#ok-loupe{
  z-index: 10000 !important;
}

/*------------------------------------*\
    説明文
\*------------------------------------*/
.product_note_body{
  font-family: HiraKakuPro-W3;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: 0.7px;
  color: #2c2e31;
  text-align: justify;
  word-break: break-all;

  &>*{
    margin-top: 1.5rem;
    //margin-bottom: 32px;

    @include media_query(){
      margin-top: 1.5rem;
      //margin-bottom: 24px;
    }
  }

  h2{
    font-family: HiraKakuPro-W6;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.5;
    letter-spacing: 1.5px;
    border-left: 4px solid #2c2e31;
    padding-left: 8px;
    margin-top: 3rem;

    @include media_query(){
      font-size: 1.6rem;
    }

  }

  h3{
    font-family: HiraKakuPro-W6;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 1.5px;
    margin-top: 3rem;

    @include media_query(){
      font-size: 1.4rem;
    }
  }

  h4{
    font-family: HiraKakuPro-W6;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: 1.5px;
    margin-top: 1.5rem;
  }

  a{
    color: #2c2e31;
    border-bottom: 1px dotted #2c2e31;
  }

  figure{
      a{
        display: block;
        border-bottom: none;
      }
  }

  img{
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
  }

  figcaption{
    font-family: HiraKakuPro-W3;
    font-size: 1rem;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #707980;
    margin-top: 10px;
  }

  ol{
    padding-left: 1.2em;
    li{
      list-style: decimal;
    }
  }

  ul{
    padding-left: 1.2em;

    li{
      list-style: disc;
    }
  }

  blockquote{
    border-radius: 5px;
    background-color: #f4f6f8;
    padding: 24px 24px 24px 68px;
    background-repeat: no-repeat;
    background-position: left 24px top 24px;
    background-size: 30px 23px;

    @include media_query(){
      padding: 40px 16px 16px 16px;
      background-position: left 16px top 16px;
      background-size: 20px 16px;
    }

    .source{
      font-size: 1.2rem;
      color: #707980;
      text-align: right;
      margin-top: 8px;
    }
  }

  table {
      border-collapse:collapse;
      border-spacing:0;

    th, td {
      border:solid 1px #ebedef;
      padding: 9px 12px;
    }
  }
}

.login_annotation {
  font-size: 1.2rem;
  color: #707980;
}










// 作り直したスライダー
.details_swiper_wrap{
  .mainSwiper{
    overflow: hidden;
    position: relative;
    
    @include media_query(){
      padding-bottom: 20px;
    }
    
    .swiper-slide{
      .swiper-slide-img{
        width: 720px;
        height: 540px;
        background-color: #f1f2f4;
        
        @include media_query(){
          width: 100%;
          height: 233px;
          aspect-ratio: 312 / 233;
        }
        
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
          font-family: "object-fit: contain;";
        }
      }
      
      .video_wrap{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .video{
        position: relative;
        z-index: 2;
        width: 100%;
        padding-top: 56.25%;
        margin: 0 auto;
        text-align: center;
        
        iframe{
          position: absolute;
          margin: auto;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  
  .subSwiper{
    margin-top: 10px;
    overflow: hidden;
    
    @include media_query(){
      display: none;
    }
    
    .swiper-slide{
      width: 72px;
      height: 72px;
      background-color: #f1f2f4;
      
      &.swiper-slide-thumb-active{
        opacity: .5;
      }
      
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        font-family: "object-fit: contain;";
      }
    }
  }
  
  .swiper-pagination{
    display: none;
    bottom: 0 !important;
    
    @include media_query(){
      display: block;
    }
    
    .swiper-pagination-bullet-active{
      background-color: #000;
    }
    
  }
  
  .swiper-button-next{
    margin-top: -45px;
    width: 40px;
    height: 80px;
    background-image: url(../../common/img/common/bt-right.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 8px 16px;
    right: -1px;
    background-color: #fff;
    box-shadow: 0 5px 8px 0 rgba(#000, 0);
    transition: box-shadow .25s;
    
    @include media_query(){
      margin-top: -24px;
      width: 24px;
      height: 48px;
    }
    
    &::after{
      display: none;
    }
    
    &:hover{
      box-shadow: 0 5px 8px 0 rgba(#000, 0.5);

      @include media_query(){
        box-shadow: none;
      }
    }
  }
  
  .swiper-button-prev{
    margin-top: -45px;
    width: 40px;
    height: 80px;
    background-image: url(../../common/img/common/bt-left.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 8px 16px;
    left: 0;
    background-color: #fff;
    box-shadow: 0 5px 8px 0 rgba(#000, 0);
    transition: box-shadow .25s;
    
    @include media_query(){
      margin-top: -24px;
      width: 24px;
      height: 48px;
    }
    
    &::after{
      display: none;
    }
    
    &:hover{
      box-shadow: 0 5px 8px 0 rgba(#000, 0.5);

      @include media_query(){
        box-shadow: none;
      }
    }
  }
}