.settlement_complete{
  background-color: #fff;
  margin-bottom: 48px;
  
  @include media_query(){
  	margin-bottom: 32px;
  }
  
  &_inner{
    padding: 80px 16px 75px;
    
    @include media_query(){
    	padding: 40px 16px 16px;
    }
  }
  
  .complete_title{
    text-align: center;
    position: relative;
    margin-bottom: 28px;
    
    span{
      
      margin-bottom: 23px;
      text-align: center;
      z-index: 2;
      
      font-family: PTSerif-Caption;
      font-size: 40px;
      font-style: italic;
      line-height: 1;
      letter-spacing: 3.6px;
      color: #2c2e31;
      display: inline;
      position: relative;
      text-decoration: none;
      
      @include media_query(){
      	font-size: 30px;
      	letter-spacing: 2.7px;
      }
    }
    
    &:after{
      //z-index: -1;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: block;
      content: "";
      width: 264px;
      height: 17px;
      background-color: rgba(246, 163, 25, 0.3);
      
      transform:skew(-15deg);
      
      @include media_query(){
      	width: 200px;
      }
    }
  }
  
  .complete_msg{
    p{
      font-family: HiraKakuPro-W3;
      font-size: 1.6rem;
      line-height: 1.56;
      letter-spacing: 0.8px;
      text-align: center;
      color: #2c2e31;
      
      b{
        font-family: HiraKakuPro-W6;
      }
      
      @include media_query(){
        text-align: left;
      	line-height: 1.5;
      	
      	span{
        	display: inline-block;
        	width: 100%;
        	text-align: center;
      	}
      }
      
      &+p{
        font-size: 14px;
        margin-top: 32px;
        text-align: center;
      }
    }
  }
  
  &_btn{
    margin-top: 80px;
    
    @include media_query(){
    	margin-top: 64px;
    }
    
    p{
      max-width: 225px;
      height: 40px;
      margin: 0 auto;
      
      @include media_query(){
      	max-width: 100%;
      }
      
      a{
        height: 100%;
        font-family: HiraKakuPro-W6;
        font-size: 1.4rem;
        line-height: 1;
        letter-spacing: 0.7px;
        color: #fff;
        text-align: center;
        
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      &+p{
        margin-top: 16px;
      }
    }
    
    .btn_b{
      background-color: #2c2e31;
      transition-property: background-color;
      transition-duration: .25s;
      
      &:hover{
        background-color: #707980;
      }
    }
    
    .btn_w{
      a{
        color: #707980;
      }
    }
  }
}