/* ***** ***** *****
** トップスライダー
***** ***** ***** */

@keyframes OverlaySlide {
    0% {
        visibility: hidden;
        opacity: 0;
        right: -100%;
    }
    50% {
        visibility: visible;
        opacity: 1;
        right: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
        right: 0;
    }
}

.index_slider{
    width: 100%;
    max-width: 1250px;
    padding: 0 40px;
    margin: 0 auto;
    height: 360px;
    //overflow: hidden;
    margin-bottom: 15px;
    
    @include media_query(){
        background: rgba(250, 250, 250, 0.9);
        height: auto;
        overflow: auto;
        padding: 0;
    }
    
    &.slick-dotted.slick-slider{
        margin-bottom: 15px;
    }
    
    & + .featured_artists{
        padding-top: 0;
    }
    
    .slider_item{
        position: relative;
        width: 100%;
        height: 360px;
        // overflow: hidden;
        
        @include media_query(){
            height: auto;
        }
        
        a{
            width: 100%;
            height: 100%;
            display: flex;
            
            @include media_query(){
                flex-direction: column;
            }
        }
        
        picture, img{
            width: 100%;
            //max-width: 630px;
            height: 100%;
            aspect-ratio: 7 / 4;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            
            @include media_query(){
                height: calc(100vw * 0.571);
            }
        }
    }
    
    .slider_item_overlay{
        max-width: 565px;
        background: rgba(250, 250, 250, 0.9);
        padding: 0 58px;
        display: flex;
        align-items: center;
        
        width: 100%;
        height: 100%;
        
        @include media_query(){
            position: relative;
            visibility: visible;
            opacity: 1;
            right: 0;
            padding: 20px 16px;
        }
        
        .datetime{
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 14px;
            letter-spacing: 0.012em;
            color: #2C2E31;
            margin-bottom: 1em;
            
            @include media_query(){
                margin-bottom: 9px;
            }
        }
        
        h2{
            font-family: Hiragino Sans;
            font-style: normal;
            font-weight: 700;
            font-size: 2.6rem;
            line-height: 138%;
            letter-spacing: 0.05em;
            color: #2C2E31;
            margin-bottom: 1em;
            
            @include media_query(){
                font-size: 1.8rem;
                margin-bottom: 0;
            }
        }
        
        .read_sentence{
            font-family: Hiragino Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 1.3rem;
            line-height: 170%;
            color: #2C2E31;
            
            @include media_query(){
                display: none;
            }
        }
    }
    
    .slick-current{
        .slider_item_overlay{
            // visibility: visible;
            // opacity: 1;
            // right: 0;
            
            //animation: 1.5s OverlaySlide ease-in-out forwards;
            
            @include media_query(){
                width: 100%;
                animation: none;
                visibility: visible;
                opacity: 1;
                right: 0;
            }
        }
    }
    
    .slick-dots{
        bottom: 14px;
        
        li{
            margin: 0;
        }
        
        button:before{
            color: #fff;
            opacity: 1;
        }
        
        .slick-active{
            opacity: 1;
            
            button:before{
                color: #000;
                opacity: 1;
            }
        }
    }
    
    //次へ前へ共通
    .slick-arrow{
        position: absolute;
        z-index: 99;
        //background-color: #fff;
        right: auto;
        left: auto;
        margin-top: -4px;

        width: 40px;
        height: 80px;
        // transition-property: box-shadow,margin-top;
        // transition-duration: .3s;

        // &:hover{
        //     box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
        //     //margin-top: -3px;
        // 
        //     @include media_query(){
        //         box-shadow: none;
        //     }
        // }

        // &:active{
        //     box-shadow: none;
        //     margin-top: 0;
        // }

        &:before{
            display: none;
        }

        @include media_query(){
            width: 30px;
            height: 100%;
            background-color: transparent;
        }
    }

    //<
    .slick-prev{
        position: absolute;
        left: 0;
        margin-right: 380px;

        @include media_query(){
            left: 0;
            right: auto;
            margin: auto;
        }

        background-image: url(../../common/img/common/bt-left.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 8px 16px;

        @include media_query(){
            background-image: url(../../common/img/common/bt-sp-left.png);
            background-size: 7px 12px;
            background-position: center top 181px;
        }
    }

    //>
    .slick-next{
        right: 0;
        margin-left: 380px;

        @include media_query(){
            left: auto;
            right: 0;
            margin: auto;
        }

        background-image: url(../../common/img/common/bt-right.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 8px 16px;

        @include media_query(){
            background-image: url(../../common/img/common/bt-sp-right.png);
            background-size: 7px 12px;
            background-position: center top 181px;
        }
    }
}