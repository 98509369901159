.white_bg{
  background-color: #fff;
  border-bottom: solid 1px #f1f2f4;
}

#for_oil-bijutsutecho-to-shop-sp,
#for_oil-bijutsutecho-to-shop{
  & + .gallery{
      @include media_query(){
          padding-top: 0;
      }
  }
}

.gallery{
  max-width: 1128px;
  margin: 0 auto;

  .page_head{
    margin-bottom: 32px;

    @include media_query(){
    	flex-direction: column;
    	padding: 24px 16px;
    	margin-bottom: 0;

    	.page_title span{
      	display: block;
      	margin-left: 0;
      	margin-top: 8px;
    	}
    }
  }

  .gallery_search{
    margin-left: auto;

    @include media_query(){
    	margin-top: 24px;
    }

    ul{
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li{
        &:not(:last-child){
          margin-right: 40px;
        }

        &:last-child{
          @include media_query(){
          	width: 100%;
          	margin-top: 24px;
          }
        }

        label.checkbox_text{
          line-height: 2;
          padding-left: calc(24px + 5px);

          &:before{
            width: 24px !important;
            height: 24px !important;
          }

          &:after {
            left: calc((24px / 2) - (((17px / 12 ) * 5) / 2));
          }
        }

        input::-ms-clear {
          visibility:hidden;
        }

        input[type=search]{
          width: 264px;
          background-color: #fff;
          margin-top: -5px;
          background-image: url(../img/common/ic-search_b.png);
          background-repeat: no-repeat;
          background-size: 19px;
          background-position: right 15px center;

          &::-webkit-search-cancel-button,
          &::-webkit-search-decoration{
            -webkit-appearance: none;
          }

          @include media_query(){
          	width: 100%;
          }
        }

      }
    }

    .gallery_item_follow{
      margin: 0 12px;
    }
  }
}


.gallery_notfound{
  text-align: center;
  padding: 60px;

  p{
    font-family: HiraKakuPro-W6;
    font-size: 1.8rem;
    line-height: normal;
    letter-spacing: normal;
    color: #707980;
  }
}


.gallery_inner{
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;

  @include media_query(){
  	padding: 0 16px;
  	margin-left: 0;
  }
}

.pagination .pagination_ul{
    width: 100%;
    padding: 24px 24px 0;
    margin-bottom: 40px;
    border-top: solid 1px #f1f2f4;
}


.gallery_item{
  position: relative;
  width: calc(33.333% - 24px);
  margin-left: 24px;
  margin-bottom: 40px;

  @include media_query(){
  	width: 100%;
  	margin-left: 0;
  	margin-bottom: 24px;
  }

  // &:nth-last-child(-n + 3){
  //   margin-bottom: 0;
  // 
  //   @include media_query(){
  //   	margin-bottom: 24px;
  // 
  //   	&:last-child{
  //     	margin-bottom: 40px;
  //   	}
  //   }
  // }

  &:hover{
    .gallery_item_img{
      &:after{
        background-color: rgba(#000, .3);
      }

      img{
        transform: scale(1.2);
      }
    }
  }

  .gallery_item_img{
    position: relative;
    overflow: hidden;

    &:after{
      content: "";
      display: block;

      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
      width: 100%;
      height: 100%;

      transition-property: opacity;
      transition-property: background-color;
      transition-duration: 0.4s;
      background-color: transparent;
    }

    img{
      width: 100%;
      height: auto;
      vertical-align: bottom;
      transition: transform 0.2s linear;
    }
  }

  .gallery_item_text{
    margin-top: 12px;
    display: flex;

    .gallery_item_info{
      max-width: 250px;
      flex: 1;
      min-width: 0;
        
        @include media_query(){
            max-width: 230px;
        }
        
      h3{
        font-family: HiraKakuPro-W3;
        font-size: 1.4rem;
        line-height: normal;
        letter-spacing: 0.7px;
        color: #2c2e31;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      p{
        font-family: HiraMinProN-W3;
        font-size: 1.1rem;
        line-height: normal;
        letter-spacing: 0.5px;
        color: #2c2e31;
        margin-top: 8px;
      }
    }
  }

}


.gallery_item_follow{
  //   position: absolute;
  //   right: 0;
  //   bottom: 1.5em;
  // display: flex;
  //align-items: flex-end;

  input[type=button]{
    cursor: pointer;
    width: 100px;
    height: 32px;
    padding: 9px 12px;
    text-align: right;
    border: solid 1px #dce0e3;
    border-radius: 21.5px;
    line-height: 1;
    font-family: Roboto;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 1.6px;
    color: #2c2e31;
    background-image: url(../img/gallery/ic-follow.png);
    background-position: left 12px center;
    background-size: 12px;
    background-repeat: no-repeat;
    transition-property: border;
    transition-duration: .25s;

    &:hover{
      border: solid 1px #bfcbd5;
    }
  }

  .following{
    background: #dce0e3 !important;
    color: #fff !important;
    text-align: center !important;

    &:hover{
      border: solid 1px #dce0e3 !important;
    }
  }
}

.gallery_list, .gallery_list{
    .gallery_item_follow{
        position: absolute;
        right: 0;
        bottom: 1.5em;
        display: flex;
        align-items: flex-end;
  }
}


.gallery{
  .pagination{
    margin-bottom: 50px;

    @include media_query(){
    	padding: 0 16px;
    	margin-bottom: 30px;
    }

    li:not(.on){
      border: solid 1px #ebedf0;
      transition-property: border;
      transition-duration: .25s;

      &:hover{
        border: solid 1px #bfcbd5;
      }
    }
  }
}






.gallery_details{
    //width: 1128px;
  @include media_query(){
  	padding: 20px 16px;
  }
}


//ギャラリー詳細
.gallery_introduction{
  width: 100%;
  background-color: #fff;
  padding: 48px 40px;
  margin-bottom: 48px;

  display: flex;
  //justify-content: space-between;

  @include media_query(){
  	flex-direction: column;
  	padding: 16px;
  	margin-bottom: 33px;
  }

  &_text{
    width: 624px;
    margin-right: auto;

    @include media_query(){
        width: 100%;
    	padding-bottom: 16px;
    	margin-bottom: 16px;
        margin-right: 0;
    	border-bottom: solid 1px #ebedf0;
    }

    h3{
      font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: normal;
      letter-spacing: 2px;
      color: #2c2e31;
      margin-bottom: 24px;

      @include media_query(){
      	margin-bottom: 16px;
      }
    }
  }

  &_info{
    width: 336px;
    margin-left: 20px;

    @include media_query(){
        width: 100%;
        margin-left: 0;
    }

    .policy{
      text-align: right;
      margin-top: 16px;

      font-family: HiraKakuPro-W3;
      font-size: 1.2rem;
      letter-spacing: 0.6px;

      a{
        color: #2c2e31;
        text-decoration: underline;
        transition-property: color;
        transition-duration: .25s;

        &:hover{
          text-decoration: none;
          color: #4a90e2;
        }
      }
    }
  }

  &_head{
    display: flex;
    margin-bottom: 24px;

    .gallery_item_follow{
      margin-left: auto;
    }
  }

  &_title{
    padding-right: 16px;

    h2{
      text-align: left;
      font-family: HiraMinPro-W6, 游明朝, "Yu Mincho", YuMincho, serif;
      font-weight: bold;
      font-size: 2.8rem;
      line-height: normal;
      letter-spacing: 0.8px;
      color: #2c2e31;
      margin-bottom: 8px;

      @include media_query(){
      	font-size: 16px;
        line-height: 1.19;
        letter-spacing: 0.5px;
      }
    }

    .gallery_place{
      font-family: HiraMinPro-W3;
      font-size: 1.2rem;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #707980;
    }

  }

  &_main{
    margin-bottom: 28px;

    @include media_query(){
    	margin-bottom: 20px;
    }

    p{
      font-family: HiraKakuPro-W3;
      font-size: 1.3rem;
      line-height: 1.69;
      letter-spacing: 0.6px;
      color: #2c2e31;
    }
  }

  &_artists{
      ul{
          display: flex;
          flex-wrap: wrap;

        li{
          font-family: HiraKakuPro-W3;
          font-size: 1.4rem;
          line-height: normal;
          letter-spacing: 0.7px;
          color: #2c2e31;
          width: 33.3333%;

          text-indent: -.8em;
          padding-left: calc(.8em + 24px);

          &:nth-child(3n+1){
              padding-left: .8em
          }

          transition-property: color;
          transition-duration: .25s;

          @include media_query(){
              width: 50%;
              padding-left: calc(.8em + 16px);

              &:nth-child(odd){
                  padding-left: .8em
              }
          }

          a{
            color: #2c2e31;

            &:hover{
              text-decoration: underline;
            }
          }

          &:not(:last-child){
            margin-bottom: 18px;
          }

          &:before{
            content: "-";
            padding-right: .5em;
          }
        }
      }
  }


  &_img{
    margin-bottom: 22px;
    background-color: #fbfbfb;

    img{
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }


}



.gallery_introduction_modal{
  h3{
    font-family: HiraKakuPro-W6;
    margin-bottom: 8px;
  }

  p{
    font-family: HiraKakuPro-W3;
    font-size: 1.3rem;
    line-height: 1.85;
    letter-spacing: 1.2px;
    color: #2c2e31;

    &+h3{
      margin-top: 24px;
    }
  }
}
