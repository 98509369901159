#for_oil-bijutsutecho-to-page-sp,
#for_oil-bijutsutecho-to-page{
    
    & + .catalog{
        @include media_query(){
            padding-top: 0;
        }
    }
}

.catalog{
  margin-bottom: 64px;
  
  @include media_query(){
  	padding: 16px;
  }
  
  &_inner{
    max-width: 1128px;
    margin: 0 auto;
    
    display: flex;
    justify-content: space-between;
    
    @include media_query(){
    	display: block;
    }
  }
  
  &_sidebar{
    width: 100%;
    max-width: 264px;
    margin-right: 20px;
    
    @include media_query(){
    	max-width: 100%;
    	margin: 0 0 16px 0;
    }
  }
  
  &_sort{
    background-color: #fff;
    
    dt{
      padding: 24px;
      
      @include media_query(){
      	padding: 17px 16px;
      	background-image: url(../img/agreement/faq/plus.png);
      	background-repeat: no-repeat;
      	background-size: 12px;
      	background-position: right 16px center;
      	cursor: pointer;
      }
    }
    
    .dt_open{
      background-image: url(../img/agreement/faq/minus.png);
    }
    
    h4{
      font-family: HiraKakuPro-W6;
      font-size: 1.4rem;
      line-height: 1;
      letter-spacing: 0.7px;
      color: #2c2e31;
    }
    
    dd{
      padding: 16px 24px 24px;
      border-top: solid 1px #ebedf0;
      
      @include media_query(){
      	display: none;
      	padding: 16px;
      }
      
      .radio_text,
      .checkbox_text{
        font-family: HiraKakuPro-W3;
        font-size: 1.2rem;
        letter-spacing: 0.6px;
        color: #707980;
        transition-property: border;
        transition-duration: .25s;
        
        @include media_query(){
        	margin-right: 12px;
        	padding-left: calc(18px + 2px);
        }
      }
      
      .radio_text{
        &:before{
          width: 16px;
          height: 16px;
        }
        
        &:after{
          width: 8px;
          height: 8px;
        }
      }
      
      .checkbox_text{
        span{
          position: relative;
          top: -2px;
        }
        &:before{
          width: 16px;
          height: 16px;
          border: solid 1px #bfcbd5;
          transition-property: border;
          transition-duration: .25s;
        }
        
        &:hover{
          &:before{
            border: solid 1px #2c2e31;
          }
        }
        
        &:after{
          top: calc(50% - (((12px / 12 ) * 5) / 2));
          left: calc((16px / 2) - (((12px / 12 ) * 5) / 2));
          width: calc(((12px / 12 ) * 5));
          height: 12px;
        }
      }
      
      
      input:checked+span{
        color: #2c2e31;
      }
    }
    
    &_search{
      padding-bottom: 16px;
      border-bottom: solid 1px #ebedf0;
      margin-bottom: 16px;
      
      @include media_query(){
      	border-bottom: none;
      	margin-bottom: 0;
      }
      
      input[type=search]{
        width: 100%;
        padding: 10px 10px 10px 40px;
        font-size: 1.6rem;
        border: solid 1px #dce0e3;
        background-image: url(../img/common/ic-search_b.png);
        background-position: left 12px center;
        background-size: 19px;
        background-repeat: no-repeat;
      }
      
      ::-webkit-search-cancel-button {
          -webkit-appearance: none;
      }
    }
    
    &_cat{
      padding-bottom: 16px;
      border-bottom: solid 1px #ebedf0;
      margin-bottom: 16px;
      
      h5{
        font-family: Roboto;
        font-size: 1.3rem;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.7px;
        color: #2c2e31;
        margin-bottom: 16px;
      }
      
      ul{
        @include media_query(){
        	display: flex;
        	flex-wrap: wrap;
        }
      }
      
      li{
        line-height: 1;
        
        &:not(:last-child){
          margin-bottom: 3px;
        }
      }
    }
    
    &_price{
      padding-bottom: 16px;
      border-bottom: solid 1px #ebedf0;
      margin-bottom: 16px;
      
      h5{
        font-family: Roboto;
        font-size: 1.3rem;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.7px;
        color: #2c2e31;
        margin-bottom: 16px;
      }
      
      ul{
        @include media_query(){
        	display: flex;
        	flex-wrap: wrap;
        }
      }
      
      li{
        line-height: 1;
        
        &:not(:last-child){
          margin-bottom: 3px;
        }
      }
    }
    
    select{
      width: 100%;
      padding: 10px;
      font-size: 1.6rem;
      border: solid 1px #dce0e3;
      background-image: url(../img/common/arrow_b.png);
      background-position: right 12px center;
      background-repeat: no-repeat;
      background-size: 12px 7px;
    }
    
    &_souledout{
      padding-bottom: 4px;
      border-bottom: solid 1px #ebedf0;
      margin-bottom: 24px;
      
      @include media_query(){
      	padding-bottom: 0;
      	border: none;
      	margin-bottom: 20px;
      }
    }
    
    &_btn{
      input[type=submit],input[type=button]{
        width: 100%;
        height: 48px;
        background-color: #2c2e31;
        font-family: HiraKakuPro-W6;
        font-size: 1.4rem;
        letter-spacing: 0.7px;
        color: #ffffff;
        cursor: pointer;
        transition-property: background-color;
        transition-duration: .25s;
        
        &:hover{
          background-color: #707980;
        }
      }
    }
  }
  
  
  
  &_main{
    width: 100%;
    max-width: 839px;
    
    &_head{
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      
      @include media_query(){
      	margin-bottom: 16px;
      }
      
      p{
        font-family: Roboto, HiraginoSans-W6;
        font-size: 1.3rem;
        line-height: normal;
        letter-spacing: 0.7px;
        color: #2c2e31;
        
        @include media_query(){
        	font-size: 1.4rem;
          letter-spacing: 0.5px;
          color: #2c2e31;
        }
        
        span{
          font-weight: bold;
          font-size: 1.6rem;
          
          @include media_query(){
          	font-size: 1.4rem;
            letter-spacing: 0.6px;
          }
        }
      }
      
      .displayed_number{
          padding-right: 16px;
      }
      
      .form{
        margin-left: auto;
        width: 100%;
        max-width: 264px;
        
        @include media_query(){
        	max-width: 50%;
        }
        
        select{
          width: 100%;
          max-width: 264px;
          height: 40px;
          padding: 0 16px;
          font-size: 1.4rem;
          background-position: right 24px center;
          border: none;
          border-radius: 0;
        }
      }
    }
    
    &_inner{
      display: flex;
      flex-wrap: wrap;
      margin-left: -1px;
      
      .artworks_item{
        width: calc(33.333% - 1px);
        
        @include media_query(){
        	width: calc(50% - 1px);
        }
      }
      
      .goods_item{
        width: calc(25% - 1px);
        
        @include media_query(){
        	width: calc(50% - 1px);
        }
      }
    }
  }
}

.pagination{
  display: flex;
  align-items: center;
  margin-top: 23px;
  
  @include media_query(){
    flex-direction: column;
  	justify-content: center;
  	margin-top: 16px;
  }
  
  ul{
    display: flex;
    margin-right: auto;
    
    @include media_query(){
      width: 100%;
    	justify-content: center;
        //flex-wrap: wrap;
    	margin-right: 0;
        padding: 0 !important;
    }
    
    li{
      width: 48px;
      height: 48px;
      background-color: #fff;
      text-align: center;
      border: solid 1px #fff;
      transition-property: border;
      transition-duration: .25s;
      
      @include media_query(){
          width: 30px;
      }
      
      &:hover{
        border: solid 1px #bfcbd5;
      }
      
      &:not(:last-child){
        margin-right: 16px;
        
        @include media_query(){
        	margin-right: 2.66%;
        }
      }
      
      a,
      span{
        height: 100%;
        display: block;
        line-height: 48px;
        font-family: Roboto;
        font-size: 1.4rem;
        font-weight: 500;
        letter-spacing: 0.7px;
        color: #2c2e31;
      }
    }
    
    .prev,
    .next{
      width: 96px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 8px 16px;
      
      @include media_query(){
      	width: 30px;
      }
    }
    
    .prev{
      background-image: url(../img/catalog/bt-left.png)
    }
    
    .next{
      background-image: url(../img/catalog/bt-right.png)
    }
    
    .first,
    .last{
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 16px;
    }
    
    .first{
        background-image: url(../img/catalog/bt-left2.png)
    }
    
    .last{
        background-image: url(../img/catalog/bt-right2.png)
    }
    
    .on{
      background-color: #2c2e31;
      border: solid 1px #2c2e31;
      
      &:hover{
        border: solid 1px #2c2e31;
      }
      
      span{
        color: #fff;
      }
    }
  }
  
  .page_num{
    font-family: Roboto;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    color: #2c2e31;
    
    @include media_query(){
    	margin-top: 32px;
    }
  }
}


.pagination_pc{
  display: flex;
  
  @include media_query(){
  	display: none;
  }
}

.pagination_sp{
  display: none;
  
  @include media_query(){
  	display: flex;
  }
}

.artists_list .pagination li:not(.on){
    border: solid 1px #ebedf0;
}












