//Google Fonts
.robot300{
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
.robot400{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.robot500{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.robot700{
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.pt400{
  font-family: 'PT Serif Caption', serif;
  font-weight: 400;
}

//TypeScript
.hmw3{
  font-family: 'Hiragino Mincho W3 JIS2004', 'Hiragino Mincho ProN', serif;
  font-weight: 300;
}
.hmw6{
  font-family: 'Hiragino Mincho W6 JIS2004', 'Hiragino Mincho ProN', serif;
  font-weight: 600;
}

.hgw3{
  font-family: 'Hiragino Kaku Gothic W3 JIS2004', 'Hiragino Kaku Gothic ProN', sans-serif;
  font-weight: 300;
}
.hgw4{
  font-family: 'Hiragino Kaku Gothic W4 JIS2004', 'Hiragino Kaku Gothic ProN', sans-serif;
  font-weight: 400;
}
.hgw6{
  font-family: 'Hiragino Kaku Gothic W6 JIS2004', 'Hiragino Kaku Gothic ProN', sans-serif;
  font-weight: 600;
}